import React, { useCallback, useContext, useMemo, useState } from 'react';
import { dayStatuses } from 'constants/DayStatuses';
import ContractButton from '../ContractButton';
import { confirmProposalsApi, deleteMissionProposalsByDatesApi, getDayStatusesLang } from 'containers/mission/helpers';
import useLanguage from 'hooks/useLanguage';
import { Store } from 'store/store';
import classNames from 'classnames';
import classes from 'styles/classes.module.scss';
import { isEmpty } from 'lodash';
import Button from 'components/Button';
import ConfirmAddDates from 'containers/scheduler/components/ConfirmAddDates';
import { date2unix } from 'modules/dates';
import toast from 'components/toast';

const FreelanceMissionActions = ({ mission, setMission = () => { } }) => {
	const {
		state: { user },
	} = useContext(Store);
	const dayStatusesLangs = useLanguage('dayStatuses');
	const dayStatusesLang = useMemo(
		() => getDayStatusesLang(dayStatusesLangs, user.type),
		[user.type, dayStatusesLangs],
	);
	const lang = useLanguage('mission');
	const [loading, setLoading] = useState(false);
	const [modalOpen, setModalOpen] = useState(false);
	const [selectedDays, setSelectedDays] = useState({});
	const appointmentWhosWaitingAction = useMemo(
		() =>
			(mission.appointments || []).filter((appointment) => {
				switch (appointment.status) {
					case dayStatuses.CONFIRMED:
					case dayStatuses.WAITING_ALL_CONTRACTS:
					case dayStatuses.WAITING_FREELANCE_CONTRACT:
					case dayStatuses.WAITING_FREELANCE_DAYS_CONFIRMATION:
					case dayStatuses.WAITING_REVIEW:
					case dayStatuses.WAITING_FREELANCE_REVIEW:
						return true;
					default:
						return false;
				}
			}),
		[mission],
	);

	const openModal = useCallback(() => {
		const daysCopy = Object.entries(mission?.proposalsDays).reduce(
			(acc, [freelanceId, value]) => ({ ...acc, [freelanceId]: { ...value } }),
			{},
		);

		setSelectedDays(daysCopy);
		setModalOpen(true);
	}, [mission?.proposalsDays]);

	const onSubmit = useCallback(async () => {
		setLoading(true);
		const daysLength = Object.keys(selectedDays).length;
		const dates = Object.values(selectedDays)
			.map(({ dates }) => dates)
			.flat()
			.map((date) => date2unix(date));

		try {
			await confirmProposalsApi(dates, user.id, mission.id);
			toast.success(lang.proposalAccepted(daysLength));
		} catch (err) {
			toast.error(lang.unableToAcceptProposals(daysLength));
		}

		setLoading(false);
		closeModal();
	}, [selectedDays, user.id, mission.id, lang]);

	const closeModal = () => setModalOpen(false);

	const removeSelectedDays = useCallback(
		(dateToDelete) => {
			const nextSelectedDaysToRemove = { ...selectedDays };
			delete nextSelectedDaysToRemove[dateToDelete];
			setSelectedDays(nextSelectedDaysToRemove);
			if (isEmpty(nextSelectedDaysToRemove)) setModalOpen(false);
		},
		[selectedDays],
	);

	const removeAllProposals = useCallback(async () => {
		if (isEmpty(mission.proposalsDays) || isEmpty(mission.proposals.freelances)) return;

		const freelancesWithDatesToUnix = Object.entries(mission.proposals.freelances).reduce(
			(acc, [freelanceId, { days }]) => {
				acc[freelanceId] = {
					days: Object.entries(days).reduce((acc, [date, { dates }]) => {
						acc[date] = { dates: dates.map(date2unix) };
						return acc;
					}, {}),
				};
				return acc;
			},
			{},
		);

		const daysLength = Object.keys(mission.proposalsDays);
		try {
			await deleteMissionProposalsByDatesApi(freelancesWithDatesToUnix, user.id, mission.id);
			toast.success(lang.proposalCanceled(daysLength));
		} catch (err) {
			toast.error(lang.unableToDeleteProposals(daysLength));
		}
		setModalOpen(false);
	}, [user.id, mission, lang]);
	return (
		<div className={classNames(classes.flex, classes.gap1)}>
			<div className={classNames(classes.flex, classes.columnDirection, classes.gap05)}>
				{!isEmpty(mission?.proposals) && <Button type='success' onClick={openModal}>{lang.showStudioProposal}</Button>}
				{appointmentWhosWaitingAction.map((appointment, index) => (
					<ContractButton
						key={index}
						isMissionPage
						missionId={mission.id}
						{...appointment}
						appointment={appointment.id}
						contractDays={appointment.days}
						contractId={appointment.contract}
						dayStatusesLang={dayStatusesLang}
					/>
				))}
			</div>
			<ConfirmAddDates
				days={selectedDays}
				open={modalOpen}
				removeDate={removeSelectedDays}
				onCancel={closeModal}
				onSubmit={onSubmit}
				loading={loading}
				deleteAllDays={removeAllProposals}
			/>
		</div>
	);
};

export default FreelanceMissionActions;

import classNames from 'classnames';
import React from 'react';
import classes from './StatusMission.module.scss'

const StatusMissionComponent = ({
    eventType,
    langStatus,
}) => {
    return (
        <div className={classNames(classes.root, classes[eventType] || '')}>
        {langStatus}
    </div>
    );
};

export default StatusMissionComponent;
import { dayStatuses } from 'constants/DayStatuses';
import { DayStatusesEventTypes } from 'constants/DayStatusesEventTypes';
import { fetchApi, getKeyFromValue, getObjectByUserType } from 'containers/helpers';
import { isEmpty, isString } from 'lodash';
import { isEqual, momentFromDateString } from 'modules/dates';

export const createMissionAPI = (missionData) => {
	return fetchApi('/mission', { method: 'POST', body: { ...missionData } });
};

export const createMissionSlotsApi = (missionId, slots) =>
	fetchApi(`/slots/${missionId}`, { method: 'POST', body: slots });

export const updateMission = (missionId, body) => {
	return fetchApi('/mission/' + missionId, { method: 'PATCH', body });
};

export const confirmProposalsApi = (dates, userId, missionId) => {
	return fetchApi('/proposals/confirmed', {
		method: 'PUT',
		body: {
			dates,
			user: userId,
			mission: missionId,
		},
	});
};

export const deleteMissionProposalsByDatesApi = (freelances, userId, missionId) =>
	fetchApi('/proposals/freelances', {
		method: 'DELETE',
		body: {
			freelances,
			user: userId,
			missionId,
		},
	});

export const deleteSlotsForAppointmentsMissionIdApi = (dates, missionId) =>
	fetchApi(`/slots/removeAppointmentSlots`, {
		method: 'PATCH',
		body: {
			dates,
			missionId,
		},
	});

export const deleteAppointmentByIdApi = (appointmentId) =>
	fetchApi(`/appointment/${appointmentId}`, { method: 'DELETE' });

export const updateMissionDays = (missionId, days) => {
	return fetchApi('/slot/mission/' + missionId, { method: 'PATCH', body: { days } });
};

export const getLatestMissions = (n) => {
	return fetchApi(`/mission/latest/${n}`);
};

export const getMission = (missionId) => fetchApi('/mission/' + missionId);
export const getDays = (missionId) => fetchApi('/days/mission/' + missionId);
export const getMissions = () => fetchApi('/missions');

export const getAppointmentIdsApi = () => fetchApi('/appointments');
export const getAppointmentApi = (id) => fetchApi(`/appointment/${id}?plateform=0`);
export const getStudioProposalsApi = (studioId) => fetchApi(`/proposals/studio/${studioId}`);
export const getStudioMissionOpenDays = (studioId) => fetchApi(`/missions/open/days`);
export const getUserDaysApi = () => fetchApi('/slots');
export const getFreelanceAppointmentsApi = () => fetchApi('/appointments/freelance');

export const createContractApi = (appointmentId, userId) =>
	fetchApi('/contract', {
		body: {
			appointmentId,
			userId,
		},
	});

export const removeContractApi = (contractId) =>
	fetchApi(`/contract/${contractId}`, {
		method: 'DELETE',
	});

export const removeMissionAPI = (missionId) =>
	fetchApi('/mission/' + missionId, {
		method: 'DELETE',
	});

export const askToUnlockFundsAPI = (contractId) =>
	fetchApi('/contract/unlockFunds/' + contractId, {
		method: 'PATCH',
	});

export const getFreelanceMissionStatus = (mission) => {
	if (isEmpty(mission)) return 0;

	const { proposals, appointmnents, contract, status } = mission;

	if (!isEmpty(contract)) return status;
	if (!isEmpty(appointmnents)) return dayStatuses.BOOKED;
	if (!isEmpty(proposals)) return dayStatuses.STUDIO_PROPOSAL;
	return dayStatuses.OPEN;
};

export const isMissionWithoutContractsDates = (mission) => {
	const contractsDates = mission.contracts.flatMap(({ dates }) => dates.map((date) => new Date(date)));

	const missionDaysDate = Object.keys(mission.days).map(({ date }) => momentFromDateString(date));
	const missionNotContractsDays = missionDaysDate.filter(
		(date) => !!!contractsDates.find((contractDate) => isEqual(contractDate, date)),
	);

	const days = Object.keys(mission.days).filter(
		({ date }) => !!missionNotContractsDays.find((missionDate) => isEqual(new Date(date), missionDate)),
	);

	return days.length > 0
		? {
				...mission,
				status: days[0].status,
				mission: mission.id,
				days,
		  }
		: false;
};

export const transformMissionsToContracts = (missions) => {
	const reduceMissionWithContracts = (mission) =>
		[
			...mission.contracts.map((contract) => ({
				...mission,
				days: mission.days,
				contractId: contract.id,
			})),
		].filter((e) => !!e);

	return missions.reduce((prev, mission) => {
		if (mission.contracts && mission.contracts.length > 0) {
			return [...prev, ...reduceMissionWithContracts(mission)];
		}
		return [...prev, mission];
	}, []);
};

export const getDayStatusesLang = (dayStatusesLangs, type) => getObjectByUserType(dayStatusesLangs, type);

export const getStatusByMissionContext = (status, mission) => {
	if (isEmpty(mission)) return dayStatuses.OPEN;

	const { contract, appointments, proposals } = mission;

	if (!isEmpty(contract)) return status;
	if (!isEmpty(appointments)) return dayStatuses.CONFIRMED;
	if (!isEmpty(proposals)) return dayStatuses.WAITING_PROPOSAL;
	return dayStatuses.OPEN;
};

// export const getStatusByContext = (status, userType, days) => {
// 	if (userType === UserTypes.FREELANCE) {
// 		return status
// 	}
// 	if (!days || days.length === 0) {
// 		return status
// 	}
// 	const statuses = Object.keys(days.reduce((prev, { status }) => ({
// 		...prev,
// 		[status]: true,
// 	}), {})).map(Number)
// 	if (statuses.length === 1) {
// 		return statuses[0]
// 	} else if (statuses.length === 2 && statuses.includes(dayStatuses.OPEN)) {
// 		return statuses.find(s => s !== dayStatuses.OPEN)
// 	}
// 	const studioActionStatus = [
// 		dayStatuses.CONFIRMED,
// 		dayStatuses.WAITING_ALL_CONTRACTS,
// 		dayStatuses.WAITING_STUDIO_CONTRACT,
// 		dayStatuses.WAITING_FUNDS,
// 		dayStatuses.WAITING_TEAM_FUNDS_RECEIVED,
// 		dayStatuses.WAITING_STUDIO_DAYS_CONFIRMATION,
// 		dayStatuses.WAITING_ADMIN_REVIEW_DAYS_CONFIRMATION,
// 		dayStatuses.WAITING_REVIEW,
// 		dayStatuses.WAITING_STUDIO_REVIEW,
// 	]
// 	const actionStatus = studioActionStatus.find(actionStatus => statuses.includes(actionStatus))
// 	if (!!actionStatus) {
// 		return actionStatus
// 	}
// 	const { startDate, endDate } = getStartAndEndDate(days.map(({ date }) => new Date(date)))
// 	const today = new Date()
// 	if (inDateRange(today, startDate, endDate)) {
// 		return 'IN_PROGRESS'
// 	} else if (endDate < today) {
// 		return 'FINISHED'
// 	} else if (startDate > today) {

// 	}
// }

export const getStatusEventType = (status, userType) =>
	status != null &&
	getObjectByUserType(DayStatusesEventTypes, userType)[
		isString(status) ? status : getKeyFromValue(status, dayStatuses)
	];

export const getLangStatus = (dayStatusesLang, status) => dayStatusesLang[status];

export const missionHasDaysWithoutContractsOnes = (mission = { days: [] }) => {
	if (!mission.contracts || mission.contracts.length === 0) {
		return true;
	}
	return !!isMissionWithoutContractsDates(mission);
};
//Date, [Date]
export const isCreateContractDay = (dayDate, contractsToCreate) =>
	contractsToCreate.find(({ datesTimestamp }) => !!datesTimestamp.find((date) => isEqual(dayDate, date)));

import { useContext, useState, useCallback } from 'react'
import { Store } from 'store/store'
import Button from 'components/Button/Button';
import toast from 'components/toast';
import { sendConfirmationMail } from 'containers/account/confirmAccount/helper'
import Typography from 'components/Typography';
import classes from '../Onboarding/Onboarding.module.scss'

const EmailValidationHelp = () => {
	const { state: { user, language: { onboard: lang } } } = useContext(Store)
	const s = lang.emailValidationHelp
	const link = lang.link_emailValidationHelp
	const emailSentLang = lang.emailSent

	const [loading, setLoading] = useState(false)
	const sendEmailValidationLink = useCallback(async () => {
		setLoading(true)
		try {

			await sendConfirmationMail({ email: user.email })
			toast(emailSentLang)
		} catch (error) {
			toast(error.message)
			console.log('error', error)
		} finally {
			setLoading(false)
		}
	}, [emailSentLang, user.email])

	return (
		<div>
			<Typography primaryColor dangerouslySetInnerHTML={{ __html: s }} />
			<div className={classes.centeredContainer}>
				<Button loading={loading} onClick={sendEmailValidationLink}>{link}</Button>
			</div>
		</div>
	);
};

export default EmailValidationHelp;
import { dayStatuses } from 'constants/DayStatuses';
import { UserTypes } from 'constants/UserTypes';

export const FR = {
	[dayStatuses.OPEN]: 'Ouvert',
	[dayStatuses.WAITING_PROPOSAL]: {
		[UserTypes.FREELANCE]: 'En attente de proposition',
		[UserTypes.STUDIO]: 'En attente de proposition',
	},
	[dayStatuses.CONFIRMED]: {
		[UserTypes.FREELANCE]: 'La mission est confirmée',
		[UserTypes.STUDIO]: 'La mission est confirmée',
	},
	[dayStatuses.WAITING_ALL_CONTRACTS]: 'Veuillez signer le contrat',
	[dayStatuses.WAITING_FREELANCE_CONTRACT]: {
		[UserTypes.FREELANCE]: 'Veuillez signer le contrat',
		[UserTypes.STUDIO]: 'En attente de la signature du freelance',
	},
	[dayStatuses.WAITING_STUDIO_CONTRACT]: {
		[UserTypes.FREELANCE]: "En attente de la signature de l'entreprise d'accueil",
		[UserTypes.STUDIO]: 'Veuillez signer le contrat',
	},
	[dayStatuses.WAITING_FUNDS]: {
		[UserTypes.FREELANCE]: 'En attente du paiement de la mission',
		[UserTypes.STUDIO]: 'Veuillez payer la mission',
	},
	[dayStatuses.WAITING_TEAM_FUNDS_RECEIVED]: {
		[UserTypes.FREELANCE]: 'Fonds envoyés, nous attendons de les recevoir pour valider la mission',
		[UserTypes.STUDIO]: 'Fonds envoyés, nous attendons de les recevoir pour valider la mission',
	},
	[dayStatuses.BOOKED]: 'Mission confirmée',
	[dayStatuses.WAITING_STUDIO_DAYS_CONFIRMATION]: {
		[UserTypes.FREELANCE]: "En attente de la confirmation de l'entreprise d'accueil des journées effectuées",
		[UserTypes.STUDIO]: 'Confirmer les journées effectuées de la mission',
	},
	[dayStatuses.WAITING_FREELANCE_DAYS_CONFIRMATION]: {
		[UserTypes.FREELANCE]: 'Confirmer les journées effectuées de la mission',
		[UserTypes.STUDIO]: 'En attente de la confirmation du freelance des journées effectuées',
	},
	[dayStatuses.WAITING_ADMIN_REVIEW_DAYS_CONFIRMATION]:
		'Vous avez déclaré un conflit sur les journées effectuées. Un admin reviendra prochainement vers vous.',
	[dayStatuses.WAITING_REVIEW]: "Veuillez effectuer l'évaluation de la mission",
	[dayStatuses.WAITING_FREELANCE_REVIEW]: {
		[UserTypes.FREELANCE]: "Veuillez effectuer l'évaluation de la mission",
		[UserTypes.STUDIO]: "En attente de l'évaluation du freelance",
	},
	[dayStatuses.WAITING_STUDIO_REVIEW]: {
		[UserTypes.FREELANCE]: "En attente de l'évaluation de l'entreprise d'accueil",
		[UserTypes.STUDIO]: "Veuillez effectuer l'évaluation de la mission",
	},
	[dayStatuses.REVIEWED_DAYS]: {
		[UserTypes.FREELANCE]: 'Mission évaluée',
		[UserTypes.STUDIO]: 'Terminée',
	},
	[dayStatuses.WAITING_UNLOCK_FUNDS]: {
		[UserTypes.FREELANCE]: "Dans l'attente du paiement",
		[UserTypes.STUDIO]: "Dans l'attente de l'envoie du paiement au freelance",
	},
	[dayStatuses.FUNDS_UNLOCKED]: {
		[UserTypes.FREELANCE]: 'Le paiement a été envoyé',
		[UserTypes.STUDIO]: 'Terminée',
	},
	[dayStatuses.CANCELLED]: 'Mission annulée',
	[dayStatuses.FINISHED]: 'Mission terminée',
	FINISHED: 'Mission terminée',
	WAITING_START: 'Démarre bientôt',
	IN_PROGRESS: 'En cours',
};

export const EN = {
	[dayStatuses.OPEN]: 'Open',
	[dayStatuses.DECLINED]: 'Declined',
	[dayStatuses.WAITING_PROPOSAL]: 'Mission proposal',
	[dayStatuses.CONFIRMED]: {
		[UserTypes.FREELANCE]: 'Waiting studio validation',
		[UserTypes.STUDIO]: 'Waiting contract creation',
	},
	[dayStatuses.WAITING_ALL_CONTRACTS]: 'Please create the contract',
	[dayStatuses.WAITING_FREELANCE_CONTRACT]: {
		[UserTypes.FREELANCE]: 'Please sign the contract',
		[UserTypes.STUDIO]: 'Waiting haidresser signature',
	},
	[dayStatuses.WAITING_STUDIO_CONTRACT]: {
		[UserTypes.FREELANCE]: 'Waiting studio signature',
		[UserTypes.STUDIO]: 'Please sign the contract',
	},
	[dayStatuses.WAITING_FUNDS]: {
		[UserTypes.FREELANCE]: 'Waiting mission payment',
		[UserTypes.STUDIO]: 'Waiting mission payment',
	},
	[dayStatuses.WAITING_TEAM_FUNDS_RECEIVED]: {
		[UserTypes.FREELANCE]: 'Funds sent, we waiting reception',
		[UserTypes.STUDIO]: 'Funds sent, we waiting reception',
	},
	[dayStatuses.BOOKED]: 'Mission booked',
	[dayStatuses.WAITING_STUDIO_DAYS_CONFIRMATION]: {
		[UserTypes.FREELANCE]: 'Waiting studio days confirmations',
		[UserTypes.STUDIO]: 'Please confirm mission days done',
	},
	[dayStatuses.WAITING_FREELANCE_DAYS_CONFIRMATION]: {
		[UserTypes.FREELANCE]: 'Please confirm mission days done',
		[UserTypes.STUDIO]: 'Waiting freelance days confirmations',
	},
	[dayStatuses.WAITING_ADMIN_REVIEW_DAYS_CONFIRMATION]:
		'Conflicts appears during days confirmations, an admin in on that',
	[dayStatuses.WAITING_REVIEW]: 'Please review the mission',
	[dayStatuses.WAITING_FREELANCE_REVIEW]: {
		[UserTypes.FREELANCE]: 'Please review the mission',
		[UserTypes.STUDIO]: 'Waiting freelance mission review',
	},
	[dayStatuses.WAITING_STUDIO_REVIEW]: {
		[UserTypes.FREELANCE]: 'Waiting studio mission review',
		[UserTypes.STUDIO]: 'Please review the mission',
	},
	[dayStatuses.REVIEWED_DAYS]: {
		[UserTypes.FREELANCE]: 'Mission reviewed',
		[UserTypes.STUDIO]: 'Finished',
	},
	[dayStatuses.WAITING_UNLOCK_FUNDS]: {
		[UserTypes.FREELANCE]: 'Waiting unlock funds',
		[UserTypes.STUDIO]: 'Waiting for the payment to be sent to the freelance',
	},
	[dayStatuses.FUNDS_UNLOCKED]: {
		[UserTypes.FREELANCE]: 'Funds unlocked, will arrive in your bank account soon',
		[UserTypes.STUDIO]: 'Waiting for the payment to be sent to the freelance',
	},
	[dayStatuses.CANCELLED]: 'Mission cancelled',
	[dayStatuses.FINISHED]: 'Mission finished',
	FINISHED: 'Mission finished',
	WAITING_START: 'Start soon',
	IN_PROGRESS: 'In progress',
};

export default {
	fr: FR,
	us: EN,
};

import { Form } from 'semantic-ui-react'
import Label from './Label'
import InfoInput from './InfoInput'
import { Autocomplete, createFilterOptions, TextField } from '@mui/material'
import { useCallback, useEffect, useMemo, useState } from 'react';
import classes from './Form.module.scss'
import classNames from 'classnames';
import { fetchApi } from 'containers/helpers';
import { debounce } from 'lodash';

const filter = createFilterOptions();

const FormAutocomplete = ({
	id,
	name,
	placeholder,
	onChange,
	onBlur,
	maxLength = null,
	label,
	legend = null,
	required,
	error,
	silentRequired = false,
	initialValue,
	handleEffect,
	options: optionsFromParams = [], //[value]
	requiredLang,
	info,
	formError = false,
	searchMatchLink = false,
	multi = false,
	add = false,
	idForExtern = false, //cette option sert à ce qu'on entre en value un tableau d'id et que le onUpdate renvoie ce même tableau d'id en fonction des options fournies (qui contiennent id, title par ex)
}) => {
	const parseOptions = useCallback((optionsReceived) => {
		if (idForExtern) {
			return (optionsReceived)
		}
		return (optionsReceived.map(option => ({ title: option })))
	}, [idForExtern])
	const [options, setOptions] = useState(parseOptions(optionsFromParams || []))
	const [value, setValue] = useState(multi ? idForExtern ? options.filter(o => (initialValue || []).includes(o.id)) : (initialValue || []).map(val => ({ title: val })) : (initialValue ? { title: initialValue } : ''));

	useEffect(() => {
		const optionsParsed = parseOptions(optionsFromParams)
		setOptions(optionsParsed)
		if (initialValue) {
			setValue(idForExtern ? optionsParsed.filter(o => (initialValue || []).includes(o.id)) : (initialValue || ''))
		}
	}, [initialValue, optionsFromParams, idForExtern, parseOptions])

	const onValueChange = useCallback((event, newValue) => {
		let valueToSend = null
		if (multi) {
			valueToSend = newValue.map(item => item.inputValue || item.title || item);
			setValue(valueToSend.map(val => ({ title: val })));
		} else {
			if (typeof newValue === 'string') {
				valueToSend = newValue
			} else if (newValue && newValue.inputValue) {
				// Create a new value from the user input
				valueToSend = newValue.inputValue
			} else if (newValue && newValue.title) {
				// Create a new value from the user input
				valueToSend = newValue.title
			} else {
				valueToSend = newValue
			}
			setValue({ title: valueToSend })
		}

		return handleEffect({
			target: {
				value: multi && required && newValue.length === 0
					? null
					: idForExtern ? options.filter(o => newValue.map(e => e.title).includes(o.title)).map(o => o.id) : valueToSend || ''
			}
		})
	}, [handleEffect, multi, idForExtern, options, required])
	const isError = useMemo(() => formError && error && required, [error, formError, required])

	const searchElementsMatch = useCallback(debounce(async (evt, tappedValue) => {
		if (!searchMatchLink) {
			return
		}
		try {
			const receivedMatch = await fetchApi(searchMatchLink + encodeURIComponent(tappedValue))
			setOptions((receivedMatch || []).map(option => ({ title: option })))
		} catch (error) {
			console.log('error', error)
		}
	}, 500), [searchMatchLink, setOptions])

	return (
		<Form.Field
			required={required}
			className={classNames(classes.field, classes.flex)}
		>
			{label ?
				<label className={classes.conformLabel} htmlFor={name + (initialValue ? '-' + initialValue : '')}>
					{label}
				</label> : null
			}

			<Autocomplete
				multiple={multi}
				onInputChange={searchElementsMatch}
				className={classNames(classes.myInputIsChild, isError && classes.error)}
				value={value}
				onChange={onValueChange}
				filterOptions={(options, params) => {
					const { inputValue } = params;
					const isExisting = options.some((option) => inputValue === option.title);
					let filtered = searchMatchLink ? [...options] : filter(options, params);
					if (multi) {
						filtered = filtered.filter(f => !value.map(({ title }) => title).includes(f.title))
					}
					// Suggest the creation of a new value
					if (inputValue !== '' && !isExisting && !!add) {
						filtered.push({
							inputValue,
							title: `→ "${inputValue}"`,
						});
					}

					return filtered;
				}}
				selectOnFocus
				clearOnBlur
				handleHomeEndKeys
				id={name + (initialValue ? '-' + initialValue : '')}
				name={name}
				options={options}
				getOptionLabel={(option) => {
					// Value selected with enter, right from the input
					if (typeof option === 'string') {
						return option || '';
					}
					// Add "xxx" option created dynamically
					if (option.inputValue) {
						return option.inputValue || '';
					}
					// Regular option
					return option.title || '';
				}}
				renderOption={(props, option) => <li {...props}>{option.title}</li>}
				sx={{ width: 300 }}
				freeSolo
				renderInput={(params) => (
					<TextField {...params} /*label={placeholder || ''}*/ />
				)}
			/>

			{legend ? <p className={classes.legendInput}>{legend}</p> : null}
			{info ? <InfoInput info={info} /> : null}
			{required && error && !silentRequired ?
				<Label
					style={{ display: 'inline-table' }}
					display={requiredLang}
					pointing={'left'}
					color={'red'} /> : null
			}
		</Form.Field>
	)
}

export default FormAutocomplete

import { useForm, Controller } from 'react-hook-form';
import Slider from 'components/Slider';
import { Form, TextArea } from 'semantic-ui-react';
import ReviewQuestionTypes from 'constants/ReviewQuestionTypes';
import { Store } from 'store/store';
import { useContext, useMemo } from 'react';
import classes from './ReviewForm.module.scss';
import Button from 'components/Button';
import { omit, upperFirst } from 'lodash';

function ReviewForm({ onSubmit, questions }) {
	const lang = useContext(Store).state.language.review;
	const customSliderMarks = useMemo(
		() =>
			Object.keys(lang.satisfactionLevels)
				.sort()
				.map((level) => ({
					value: Number(level),
					label: upperFirst(String(lang.satisfactionLevels[level])),
				})),
		[lang.satisfactionLevels],
	);

	const {
		control,
		handleSubmit,
		formState: { isValid },
	} = useForm({
		mode: 'onChange',
		reValidateMode: 'onChange',
	});

	const sliderQs = questions.filter((question) => question.type === ReviewQuestionTypes.SLIDE);
	const textAreaQs = questions.filter((question) => question.type === ReviewQuestionTypes.TEXT);

	return (
		<Form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
			{sliderQs.map(({ id, text_key }, i) => {
				const label = lang.questions[text_key];
				return (
					<div key={i} className={classes.formField}>
						<label htmlFor={`${id}`}>{label}</label>
						<Controller
							label={label}
							control={control}
							name={`${id}`}
							defaultValue={2}
							render={({ field }) => (
								<Slider {...omit(field, 'ref')} step={1} marks={customSliderMarks} min={0} max={4} />
							)}
						/>
					</div>
				);
			})}
			{textAreaQs.map(({ id, text_key }, i) => {
				const label = lang.questions[text_key];
				return (
					<div key={i} className={classes.formField}>
						<label htmlFor={`${id}`}>{label}</label>
						<br></br>
						<Controller
							control={control}
							name={`${id}`}
							defaultValue=""
							render={({ field }) => <TextArea {...field} />}
						/>
					</div>
				);
			})}
			<Button type="submit" disabled={!isValid} positive={true}>
				{lang.sendReview}
			</Button>
		</Form>
	);
}

export default ReviewForm;

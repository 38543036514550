import { useContext } from 'react'
import { getAllNotifications, isUniqueNotification, readNotificationAPI, getAllNotificationsWithRead } from 'containers/notification/helpers'
import { Store } from 'store/store'
import { readAllNotificationsAPI } from '../containers/notification/helpers'

const useNotification = () => {
    const { dispatch, state: { notifications, language } } = useContext(Store)

    const receiveNotification = notification => {
        dispatch({
            type: 'ADD_NOTIFICATION',
            payload: { ...notification, persistant: true, read: false },
        })
    }

    const initAllNotification = async (noDispatching = false) => {
        const notifications = await getAllNotificationsWithRead()
        if (noDispatching) {
            return notifications
        }
        return dispatch({
            type: 'INIT_NOTIFICATIONS',
            payload: notifications
                .map(n => ({ ...n }))
                .filter(isUniqueNotification),
        })
    }

    const initNotification = async (noDispatching = false) => {
        const notifications = await getAllNotifications()
        if (noDispatching) {
            return notifications
        }
        return dispatch({
            type: 'INIT_NOTIFICATIONS',
            payload: notifications
                .map(n => ({ ...n }))
                .filter(isUniqueNotification),
        })
    }

    const readNotification = id => {
        readNotificationAPI(id)
            .then(() => dispatch({
                type: 'REMOVE_NOTIFICATION',
                payload: id
            }))
    }
    const readAllNotifications = (notDispatch = false) => {
        readAllNotificationsAPI()
            .then(() => notDispatch && dispatch({
                type: 'INIT_NOTIFICATIONS',
                payload: []
            }))
    }
    return {
        initNotification,
        initAllNotification,
        readAllNotifications,
        receiveNotification,
        readNotification,
        notifications,
        language
    }
}

export default useNotification
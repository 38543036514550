export const getStyleepFee = (price) => {
	return Math.round(price * 0.03 * 1.2);
};

export const getStripeFee = (price) => {
	return Math.round(price * 0.029) + 0.3;
};

export const getFee = (price) => getStripeFee(price) + getStyleepFee(price);
export const getFeePourcentage = (fee, price) => ((fee / price) * 100).toFixed(2);

import { List, Message } from 'semantic-ui-react';
import Button from 'components/Button';
import Spacer from 'components/Spacer';
import Typography from 'components/Typography';
import classes from './DatesForm.module.scss';
import {
	appointmentsToEvents,
	date2moment,
	dateToDateFormat,
	proposalsByMissionsToEvents,
	removeExtraSlotByFavoriteDayStatus,
} from 'modules/dates';
import useLanguage from 'hooks/useLanguage';
import useLocale from 'hooks/useLocale';
import FavoriteStudioSlots from './FavoriteStudioSlots';
import SelectableScheduler from 'containers/scheduler/SelectableScheduler';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Popover } from 'antd';
import { isEmpty } from 'lodash';
import { useDayStatusesLang } from 'hooks/useDayStatusesLang';
import dayStatusEventProp from 'containers/calendar/components/dayStatusEventProp';
import { Store } from 'store/store';

function DatesForm({
	mission,
	days,
	onAddDates,
	onDeleteDates,
	onClickDeleteAll,
	showDayStatus = false,
	showFavoriteDayStatus = false,
}) {
	const {
		state: { user },
	} = useContext(Store);
	const dayStatusesLang = useDayStatusesLang();
	const calLang = useLanguage('calendar');
	const lang = useLanguage('datesForm');
	const missLang = useLanguage('mission');
	const locale = useLocale();
	const [daysWithoutExtraSlots, setDaysWithoutExtraSlots] = useState({});
	const [events, setEvents] = useState([]);

	useEffect(() => {
		if (isEmpty(days)) return;

		const _daysWithoutExtraSlots = Object.entries(days).reduce(
			(acc, [key, { dates, dayStatus, favoriteDayStatus, ...rest }]) => {
				acc[key] = {
					...rest,
					dayStatus: dayStatus || 'day',
					dates: isEmpty(favoriteDayStatus)
						? dates
						: removeExtraSlotByFavoriteDayStatus(dates, favoriteDayStatus),
				};
				return acc;
			},
			{},
		);

		setDaysWithoutExtraSlots(_daysWithoutExtraSlots);
	}, [days]);

	const init = useCallback(async () => {
		if (isEmpty(mission) || !mission.id) return;
		const { proposals, appointments } = mission;

		setEvents(
			[
				...proposalsByMissionsToEvents(proposals || {}, dayStatusesLang),
				...appointmentsToEvents(appointments || {}, dayStatusesLang, missLang, user.type),
			].sort((a, b) => a.start - b.start),
		);
	}, [mission, dayStatusesLang, missLang]);

	useEffect(() => {
		init();
	}, [init]);

	const EventWrapper = (props) => {
		const {
			children,
			event: { title },
		} = props;
		return <Popover title={title}>{children}</Popover>;
	};

	const components = {
		eventWrapper: EventWrapper,
	};

	const handleRemoveDates = useCallback(
		async (dayKeysToRemove) => {
			onDeleteDates(dayKeysToRemove);
		},
		[onDeleteDates],
	);

	const handleAddDates = useCallback(
		async (daysToAdd) => {
			onAddDates(daysToAdd);
		},
		[onAddDates],
	);

	const handleDeleteAll = useCallback(async () => {
		onClickDeleteAll();
	}, [onClickDeleteAll]);

	return (
		<>
			<Spacer />
			<FavoriteStudioSlots />
			{!isEmpty(daysWithoutExtraSlots) ? (
				<>
					<div className={classes.titleWrapper}>
						<Typography style={{ display: 'block' }} variant="subTitle2">
							{lang.missionDatesTitle}
						</Typography>
						<Button type="danger" onClick={handleDeleteAll}>
							{lang.deleteAll}
						</Button>
					</div>
					<List divided className={'list-wrapper'}>
						{Object.entries(daysWithoutExtraSlots).map(([key, { dates }]) => {
							return (
								<List.Item key={key}>
									<List.Content className={'schedule-item'}>
										<Typography variant="primaryBody">
											{dateToDateFormat(dates[0], locale)}{' '}
											{calLang.slot(
												date2moment(dates[0]).format('HH:mm'),
												date2moment(dates[dates.length - 1])
													.add(15, 'minutes')
													.format('HH:mm'),
											)}
										</Typography>
										<Button type="cancelLight" onClick={() => onDeleteDates([key])}>
											{lang.delete}
										</Button>
									</List.Content>
								</List.Item>
							);
						})}
					</List>
				</>
			) : (
				<>
					<Typography style={{ display: 'block' }} variant="subTitle2">
						{lang.missionDatesTitle}
					</Typography>
					<Message>{lang.noDate} </Message>
				</>
			)}

			<SelectableScheduler
				monthOnly={false}
				moreComponents={components}
				scheduledDates={days}
				eventPropGetter={dayStatusEventProp}
				events={events}
				pushDates={handleAddDates}
				deleteDates={handleRemoveDates}
				showDayStatus={showDayStatus}
				showFavoriteDayStatus={showFavoriteDayStatus}
			/>
		</>
	);
}

export default DatesForm;

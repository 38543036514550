import { useCallback, useMemo } from 'react'
import FormComponent from 'components/Form/Form'

function CreateGeneralIssue({
    onSubmit: onSubmitFromParams,
    lang,
    issueType,
}) {
    const onSubmit = useCallback((values) => {
        const toSubmit = {
            type: issueType,
            title: values.title,
            description: values.description,
            step: values.step,
        }

        return onSubmitFromParams(toSubmit)


    }, [issueType, onSubmitFromParams])

    const inputs = useMemo(() => [
        {
            type: 'text',
            name: 'title',
            label: lang.form.title.label,
            placeholder: lang.form.title.placeholder,
            required: true,
            silentRequired: true,
        },
        {
            type: 'textArea',
            name: 'description',
            label: lang.form.description.label,
            placeholder: lang.form.description.placeholder,
            required: true,
            silentRequired: true,
            legend: lang.form.description.legend,
            maxLength: 1000,
        },
    ].filter(e => !!e), [lang])

    return (
            <FormComponent sendForm={onSubmit} inputs={inputs} />
    )
}

export default CreateGeneralIssue

import { UserTypes } from 'constants/UserTypes';

export const FR = {
	title: 'Veuillez confirmer les dates réellement effectuées lors de la mission',
	subtitle: {
		[UserTypes.STUDIO]: "Décochez les dates sur lesquelles $1 ne s'est pas présenté.",
		[UserTypes.FREELANCE]: "Décochez les dates sur lesquelles vous ne vous êtes pas présenté au lieu d'accueil.",
	},
	confirmButton: 'Confirmer ces dates',
	areYourSure: 'Êtes-vous sûr ?',
	notSure: 'Je préfère vérifier de nouveau',
	validTheseDays: 'Je valide ces jours',
	explanations: 'Une fois les journées validées, vous ne pourrez pas revenir dessus.',
	dateDifferes: (dates) => `Les dates suivantes : ${dates} diffèrent des dates originales.`,
};

export const EN = {
	title: 'Please confirm real dates done by freelance',
	subtitle: {
		[UserTypes.STUDIO]: "Uncheck dates who $1 was'nt here.",
		[UserTypes.FREELANCE]: "Uncheck dates where you was'nt at the studio.",
	},
	confirmButton: 'Confirm these dates',
	areYourSure: 'Are you sure ?',
	notSure: 'I prefer to check again',
	validTheseDays: 'I valid theses days',
	explanations: "Once days validated, you couldn't revalidate them",
	dateDifferes: (dates) => `Theses dates : ${dates} not match with original dates.`,
};

export default {
	fr: FR,
	us: EN,
};

import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import toast from 'components/toast';
import { dayStatuses } from 'constants/DayStatuses';
import { removeMissionAPI } from '../helpers';
import Button from 'components/Button';
import useLanguage from 'hooks/useLanguage';

const DeleteMissionButton = ({
    missionId,
    mission
}) => {
    const lang = useLanguage('mission')
    const navigate = useNavigate()
    const removeMission = useCallback( async () => {
		try {
			await removeMissionAPI(missionId)
			navigate('/missions')
		} catch (error) {
			toast(error.message)
		}
	}, [missionId, navigate])
	const isDeletable = useMemo(() => {
        if (!mission || !mission.days || mission.days.length === 0){
            return false
        }
        const dayTaken = mission.days.findIndex(({status}) => status !== dayStatuses.OPEN) > -1
        const hasFreelance = mission.freelances.length > 0
        return !dayTaken && !hasFreelance
    }, [mission])
    return (
        isDeletable && <Button type="danger" onClick={removeMission}>{lang.removeMission}</Button>
    );
};

export default DeleteMissionButton;
import { IconButton, Tooltip } from '@material-ui/core'
import classNames from 'classnames'
import { getFileByNameVisualisationUrl } from 'containers/contract/helpers'
import { getToken } from 'containers/helpers'
import classes from './CreateSvg.module.scss'


const ImageButton = ({
    title = null,
    onClick,
    name = '',
    hovered = false
}) => {
    return (
        <Tooltip title={title || name}>
            <IconButton
                className={classNames(classes.shadow, hovered && classes.hovered)}
                onClick={onClick}
            >
                <img
                    alt={name}
                    src={`${getFileByNameVisualisationUrl(getToken(), name)}?${global.Date.now()}`}
                    width={100}
                    height={50}
                />
            </IconButton>
        </Tooltip>
    )
}

export default ImageButton
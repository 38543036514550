import { getMyGodsons } from 'containers/profile/godsons/GodsonsPage/helpers';
import React, { useEffect, useState } from 'react';

export const useGodsons = () => {
    const [godsons, setGodsons] = useState([])

    useEffect(() => {
        getMyGodsons()
            .then(setGodsons)
    }, [])

    return {
        godsons,
        setGodsons
    }
};

import RightSide from 'components/RightSide/RightSide';
import TitlePage from 'components/TitlePage';
import useLanguage from 'hooks/useLanguage';
import { useContext, useEffect, useState } from 'react';
import { Store } from 'store/store';
import { getLatestOpenInvoice } from './helper';
import InvoicePaymentComponent from './InvoicePaymentComponent';
import Typography from 'components/Typography';
import classes from 'styles/classes.module.scss';
import { isEmpty } from 'lodash';

const LatestOpenInvoicePage = () => {
	const lang = useLanguage('invoice');
	const [openInvoice, setOpenInvoice] = useState({});
	const {
		user: { email },
	} = useContext(Store).state;

	useEffect(() => {
		getLatestOpenInvoice(email)
			.then(setOpenInvoice)
			.catch((error) => {
				console.error('Error fetching latest open invoice:', error);
			});
	}, [email]);

	return (
		<RightSide>
			<TitlePage>{lang.pastDue.title}</TitlePage>
			<Typography className={classes.mb3} variant="subTitle2">
				{lang.pastDue.description}
			</Typography>
			{!isEmpty(openInvoice) ? (
				<InvoicePaymentComponent invoice={openInvoice} />
			) : (
				<Typography variant="body2">{lang.pastDue.noInvoice}</Typography>
			)}
		</RightSide>
	);
};

export default LatestOpenInvoicePage;

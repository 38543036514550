import Calendar from 'components/Calendar';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Popover } from 'antd';
import { appointmentToEvents, appointmentsToEvents, convertTimeZoneFromDate, proposalsToEvents } from 'modules/dates';
import dayStatusEventProp from './dayStatusEventProp';
import { arr2obj } from 'modules/data';
import { UserTypes } from 'constants/UserTypes';
import useLanguage from 'hooks/useLanguage';
import { Store } from 'store/store';
import Button from 'components/Button';
import { styleDayStatus } from 'components/Calendar/helpers';
import { isEmpty } from 'lodash';
import { EventTypes } from 'constants/EventTypes.constant';
import classes from 'styles/classes.module.scss';
import cn from 'classnames';
import ContractButton from 'containers/mission/components/ContractButton';
import { useDayStatusesLang } from 'hooks/useDayStatusesLang';

const SingleMissionCalendar = ({
	mission,
	modalOpen,
	declineProposal = () => {},
	acceptProposal = () => {},
	cancelAppointment = () => {},
	contract = null,
	isMissionPage = false,
}) => {
	const dayStatusesLang = useDayStatusesLang();
	const lang = useLanguage('mission');
	const me = useContext(Store).state.user;
	const [events, setEvents] = useState([]);
	const [loading, setLoading] = useState(true);
	const missionDays = useMemo(
		() =>
			mission
				? arr2obj({
						arr: mission.days.map(({ date }) => date),
						value: true,
				  })
				: {},
		[mission],
	);

	function BackgroundWrapper(props) {
		const { children, value } = props;
		const dateTs = convertTimeZoneFromDate(value);
		const child = React.Children.only(props.children);

		if (missionDays[dateTs]) {
			const dayStatus = mission.days.find(({ date }) => date === dateTs).dayStatus;
			return React.cloneElement(child, {
				style: { ...children.style, ...styleDayStatus[dayStatus] },
			});
		}

		return children;
	}

	const getAppointmentPopoverContent = useCallback(
		(event) => {
			return (
				<ContractButton
					{...mission}
					{...event}
					appointment={event.appointmentId}
					missionId={mission.id}
					cancelAppointment={cancelAppointment}
					isMissionPage={isMissionPage}
				/>
			);
		},
		[cancelAppointment, mission, isMissionPage],
	);

	const getProposalPopoverContent = useCallback(() => {
		if (me.type === UserTypes.STUDIO) {
			return (
				<Button type="danger" onClick={declineProposal}>
					{lang.cancelMyProposal}
				</Button>
			);
		} else if (me.type === UserTypes.FREELANCE) {
			return (
				<div className={cn(classes.flex, classes.gap05)}>
					<Button onClick={acceptProposal}>{lang.showStudioProposal}</Button>
					<Button type="danger" onClick={declineProposal}>
						{lang.refuseThisProposal}
					</Button>
				</div>
			);
		}
		return null;
	}, [me.type, lang, declineProposal, acceptProposal]);

	const getPopoverContent = useCallback(
		({ eventType, freelance, ...event }) => {
			switch (eventType) {
				case EventTypes.PROPOSAL:
					return getProposalPopoverContent();
				case EventTypes.APPOINTMENT:
					return getAppointmentPopoverContent(event);
				default:
					return null;
			}
		},
		[getProposalPopoverContent, getAppointmentPopoverContent],
	);

	const EventWrapper = useCallback(
		(props) => {
			const { children, event } = props;

			return (
				<Popover title={event.title} trigger="click" content={getPopoverContent(event)}>
					{children}
				</Popover>
			);
		},
		[getPopoverContent],
	);

	useEffect(() => {
		if (isEmpty(mission) || !mission.id) return;
		const { proposals, appointments } = mission;
		setEvents(
			contract
				? appointmentToEvents(contract, dayStatusesLang, lang, me.type, false, true)
				: [
						...proposalsToEvents(proposals || {}, dayStatusesLang),
						...appointmentsToEvents(appointments || {}, dayStatusesLang, lang, me.type),
				  ].sort((a, b) => a.start - b.start),
		);

		setLoading(false);
	}, [mission, contract, me, lang, dayStatusesLang]);

	const components = useCallback(
		{
			dateCellWrapper: me.type === UserTypes.STUDIO ? BackgroundWrapper : undefined,
			eventWrapper: !modalOpen ? EventWrapper : undefined,
		},
		[BackgroundWrapper, EventWrapper, modalOpen],
	);

	return (
		<Calendar
			defaultDate={events && events.length ? new Date(events[0]?.start) : new Date()}
			components={components}
			eventPropGetter={dayStatusEventProp}
			events={events}
			style={{ height: '600px', width: '100%' }}
			loading={loading}
		/>
	);
};

export default SingleMissionCalendar;

import { useCallback, useEffect, useMemo, useState } from 'react'
import ModalComponent from 'components/Modal'
import FormComponent from 'components/Form/Form'
import { getBrands, updateStudio } from '../helpers'
import { useStoreUtils } from 'hooks/useStoreUtils'
import { toDateInput } from 'modules/dates'
import { getOnlyUpdatedValues, isNull, objectToFormData } from 'containers/helpers'
import toast from 'components/toast'
import { isEmpty } from 'lodash'

function StudioInfoForm({ /* setLoading = () => {},*/ onSuccess = () => { }, onError = () => { } }) {
	const [loading, setLoading] = useState(false)
	const { state, updateLocalUser } = useStoreUtils()
	const [modal, setModal] = useState(false)
	const [error, setError] = useState('')
	const {
		language: { login: copy, onboard: onboardLang, form: formLang },
		user
	} = state

	const [franchises, setFranchises] = useState([])

	useEffect(() => {
		getBrands()
			.then(brands => setFranchises(brands.map(({ name }) => name)))
	}, [])

	const knowByChoices = useMemo(() => onboardLang.knowByChoices[user.type], [onboardLang, user])

	const inputs = useMemo(() => {
		return [
			{
				type: 'text',
				name: 'studio_name',
				placeholder: copy.studioName,
				value: user.studio_name,
				label: copy.studioName,
				required: true,
				silentRequired: true,
				fluid: true,
			},
			{
				type: 'image',
				name: 'image',
				label: onboardLang.image,
				value: user.image,
				preview: true,
				required: true,
				silentRequired: true,
				fluid: true,
				info: onboardLang.studioImageInfo
			},
			{
				type: 'select',
				name: 'collaborators',
				fluid: true,
				value: !isNull( user.collaborators) ? String(user.collaborators) : null,
				options: [
					{ key: '0', value: '0', text: '1' },
					{ key: '1', value: '1', text: '2 - 3' },
					{ key: '2', value: '2', text: '3 - 5' },
					{ key: '3', value: '3', text: '5 - 10' },
					{ key: '4', value: '4', text: '10 +' },
				],
				required: true,
				silentRequired: true,
				placeholder: copy.collaborators,
				label: copy.collaborators,
			},
			{
				type: 'date',
				name: 'since',
				value: user.since ? toDateInput(user.since) : '',
				fluid: true,
				placeholder: copy.creationDate,
				required: true,
				silentRequired: true,
				label: copy.creationDate,
			},
			{
				type: 'phone',
				name: 'phone',
				placeholder: copy.phone,
				value: user.phone,
				label: copy.phone,
				required: true,
				silentRequired: true,
				fluid: true,
			},
			{
				type: 'autocompleteAdd',
				name: 'franchise',
				value: user.franchise_name,
				placeholder: copy.franchise,
				label: copy.franchise,
				fluid: true,
				options: franchises,
			},
			{
				type: 'autocompleteAdd',
				name: 'know_by',
				placeholder: copy.knowBy,
				label: copy.knowBy,
				value: user.know_by,
				required: true,
				silentRequired: true,
				fluid: true,
				options: knowByChoices,
				info: onboardLang.knowByChoicesInfo
			},
		]
	}
		, [copy, franchises, knowByChoices, onboardLang, user])
	const sendForm = useCallback(async (values) => {
		setLoading(true)
		try {
			const updatedValues = getOnlyUpdatedValues(user, values)
			if (isEmpty(updatedValues)) {
				setLoading(false)
				toast(formLang.nothingUpdated)
				return
			}
			const studio = await updateStudio(objectToFormData(updatedValues), user.type_id)
			setLoading(false)
			updateLocalUser(studio)
			toast(formLang.success)
		} catch (e) {
			console.error(e)
			setError(e.message)
			setModal(true)
			toast(formLang.error + ': ' + error.message)
			setLoading(false)
		}
	}, [error, formLang, updateLocalUser, user])

	const closeErrorModal = () => {
		setError('')
		setModal(false)
	}

	return (
		<>
			<FormComponent
				inputs={inputs}
				sendForm={sendForm}
				centered={true}
				responsive={false}
				segment={false}
				loading={loading}
			/>
			<ModalComponent
				onClose={closeErrorModal}
				open={modal}
				title={error}
			// description={error}
			// onClick={error !== '' ? closeErrorModal : goToLogin}
			// button={error !== '' ? copy.signupModalButtonError : copy.signupModalButtonSuccess}
			/>
		</>
	)
}

export default StudioInfoForm

export const FR = {
	save: 'Enregistrer',
	monday: 'Lundi',
	tuesday: 'Mardi',
	wednesday: 'Mercredi',
	thursday: 'Jeudi',
	friday: 'Vendredi',
	saturday: 'Samedi',
	sunday: 'Dimanche',
	week: 'semaine',
	customise: 'Horaires personnalisées',
	days: 'Tous les jours de la semaine (week-end inclus)',
	openingHours: "Vos horaires d'ouverture",
	updatedSuccessfully: 'Mise à jour effectuée',
	updatedError: 'Une erreur est survenue',
};

export const EN = {
	save: 'Save',
	monday: 'Monday',
	tuesday: 'Tuesday',
	wednesday: 'Wednesday',
	thursday: 'Thursday',
	friday: 'Friday',
	saturday: 'Saturday',
	sunday: 'Sunday',
	week: 'week',
	customise: 'Customise schedule',
	days: 'Every day of the week (week-end included)',
	openingHours: 'Your opening hours',
	updatedSuccessfully: 'Updated successfully',
	updatedError: 'An error occurred',
};

export default {
	fr: FR,
	us: EN,
};

export const EN = {
  subtitle:      'Survey payment',
  underSubtitle: 'Last step before launching your survey !',
  firstname:     'Firstname',
  lastname:      'Lastname',
}

export const FR = {
  subtitle:      'Paiement de l\'étude',
  underSubtitle: 'Dernière étape avant de lancer votre étude !',
  firstname:     'Prénom',
  lastname:      'Nom',
}

export default {
  fr: FR,
  us: EN,
}

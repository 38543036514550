import toast from 'components/toast';
import { dayStatuses } from 'constants/DayStatuses';
import { UserTypes } from 'constants/UserTypes';
import { getFreelanceMissionStatus, getMission } from 'containers/mission/helpers';
import { isEmpty } from 'lodash';
import { convertTimeZoneFromDate, getStartAndEndDate } from 'modules/dates';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { removeEvent, socket } from 'socket';
import { Store } from '../store/store';
import { useContract } from './useContract';
import moment from 'moment-timezone';
import { useDayStatusesLang } from './useDayStatusesLang';

export const useMission = (missionId, contractId) => {
	const navigate = useNavigate();
	const {
		state: { socketConnected, user },
	} = useContext(Store);
	const [mission, setMission] = useState({
		openDays: {},
		freelances: [],
		availables: [],
		contracts: [],
		proposalsDays: {},
		appointmentsDays: {},
	});
	const { contract } = useContract(contractId);
	const proposals = useMemo(
		() => (!contractId && !isEmpty(mission.proposals) ? mission.proposals[missionId] : {}),
		[mission.proposals, missionId, contractId],
	);

	const proposalsDays = useMemo(() => {
		if (!isEmpty(contract) || !mission?.proposals || !missionId) return {};
		const { freelances } = proposals;

		return Object.values(freelances || {}).reduce((acc, { days }) => ({ ...acc, ...days }), {});
	}, [proposals, missionId, mission, contract]);

	const appointmentsDays = useMemo(() => {
		if (!isEmpty(contract) || !mission?.appointments) return {};
		return mission?.appointments.reduce((acc, { days }) => ({ ...acc, ...days }), {});
	}, [mission.appointments, contract]);

	const openDays = useMemo(() => {
		return !isEmpty(contract)
			? {}
			: Object.entries(mission.days || {}).reduce((prev, [date, values]) => {
					if (!proposalsDays[date] && !appointmentsDays[date] && !prev[date]) {
						prev[date] = values;
					}
					return prev;
			  }, {});
	}, [mission.days, appointmentsDays, proposalsDays, contract]);

	const proposalsLength = useMemo(() => Object.keys(proposalsDays).length, [proposalsDays]);
	const appointmentsLength = useMemo(() => Object.keys(appointmentsDays).length, [appointmentsDays]);
	const openDaysLength = useMemo(() => Object.keys(openDays).length, [openDays]);

	const isMissionContractPageGesture = useCallback(
		(mission) => {
			// if (location.pathname.includes('page') && !isEmpty(mission.contracts) && user.type === UserTypes.FREELANCE)
			//     return navigate(`/mission/${mission.id}/contract/${mission.contracts[mission.contracts.length - 1].id}`)

			setMission(mission);
			const { proposals, appointments } = mission;
			if (user.type === UserTypes.FREELANCE && isEmpty(proposals) && isEmpty(appointments)) {
				navigate('/missions');
			}
		},
		[navigate, user.type],
	);

	const init = useCallback(async () => {
		if (!missionId && !contract.mission) return;

		try {
			const mission = await getMission(missionId || contract.mission);
			return isMissionContractPageGesture(mission);
		} catch (err) {
			toast.error(err.message);
			navigate('/missions');
		}
	}, [missionId, navigate, isMissionContractPageGesture, contract.mission]);

	useEffect(() => {
		if (socketConnected && socket && missionId) {
			socket.emit('join_mission', missionId);
			socket.on(`mission updated`, () => {
				init();
			});
		}
		return () => {
			if (socketConnected && socket && missionId) {
				socket.emit('leave_mission', missionId);
				removeEvent(`mission updated`);
			}
		};
	}, [missionId, socketConnected, init]);

	useEffect(() => {
		if (missionId || (!missionId && contract && contract.mission)) {
			init();
		}
	}, [missionId, contract, init]);
	const dayStatusesLang = useDayStatusesLang();
	const days = useMemo(() => {
		let newDays = Object.entries(contract?.days || mission.days || {}).map(([date, { dates, ...rest }]) => ({
			...rest,
			slots: dates.map(convertTimeZoneFromDate),
			date: convertTimeZoneFromDate(moment(date, 'DD/MM/YYYY')),
		}));

		return newDays.map((day) => {
			return {
				...day,
			};
		});
	}, [mission, contract]);

	const daysCount = useMemo(() => days.length, [days]);
	const { startDate, endDate } = useMemo(() => getStartAndEndDate(days.map(({ date }) => new Date(date))), [days]);
	const status = useMemo(
		() => (user.type === UserTypes.FREELANCE ? getFreelanceMissionStatus(mission) : null),
		[mission, user],
	);

	const isReviewVisible = useMemo(() => contract && contract.status > dayStatuses.WAITING_REVIEW, [contract]);

	const contracts = useMemo(() => {
		if (mission && mission.contracts) {
			if (user.type === UserTypes.FREELANCE) return mission.contracts;
			return mission.contracts.map((contract) => ({
				...contract,
				displayName: (
					(mission.freelances || []).find((freelance) => freelance.id === Number(contract.freelance)) || {}
				).displayName,
			}));
		}
		return [];
	}, [mission, user]);

	return {
		mission: {
			...mission,
			proposals,
			days,
			contracts,
			proposalsDays,
			appointmentsDays,
			openDays,
			proposalsLength,
			appointmentsLength,
			openDaysLength,
		},
		setMission,
		startDate,
		endDate,
		daysCount,
		status,
		contract: !isEmpty(contract) ? contract : null,
		days,
		dayStatusesLang,
		isReviewVisible,
	};
};

import nt from 'common/features/notification/constants/NotificationTypes'
import { fetchApi } from '../helpers'
import valueToKey from 'common/utils/data/value2key'
import { isFunction } from 'lodash'

export const isUniqueNotification = (notif, index, arr) => {
    for (let i = index - 1; i >= 0; i--)
        if (JSON.stringify(notif) === JSON.stringify(arr[i]))
            return false
    return true
}

export const getAllNotifications = () => fetchApi('/notifications')

export const getAllNotificationsWithRead = () => fetchApi('/notifications/readtoo')

export const readNotification = (notificationId, dispatch) =>
    fetchApi('/notification/read/' + notificationId, {
        method: 'PUT',
    }).then(() => dispatch && dispatch({
        type: 'REMOVE_NOTIFICATION',
        payload: notificationId,
    }))

export const readNotificationAPI = (notificationId) =>
    fetchApi('/notification/read/' + notificationId, {
        method: 'PUT',
    })

export const updateNotification = (id) => fetchApi(`/notification/${id}`, {
    method: 'PUT'
})

export const readAllNotificationsAPI = () => fetchApi('/notification/read', {
    method: 'PUT'
})

const keyType = {
    USER: { type: 'user', operator: '@' },
    STUDIO: { type: 'studio', operator: '@' },
    FREELANCE: { type: 'freelance', operator: '@' },
    MISSION: { type: 'mission', operator: '~' },
    ISSUE: { type: 'issue', operator: '+' }
}

const keysMessageType = {
    [nt.USER]: [keyType.USER, keyType.USER],
    [nt.MISSION_PROPOSAL]: [keyType.USER, keyType.MISSION],
    [nt.MISSION_CREATED]: [keyType.USER, keyType.MISSION],
    [nt.FREELANCE_CONFIRMED]: [keyType.MISSION],
    [nt.STUDIO_CONFIRMED]: [keyType.MISSION],

    [nt.WAITING_ALL_CONTRACTS]: [keyType.MISSION],
    [nt.WAITING_FREELANCE_CONTRACT]: [keyType.STUDIO, keyType.MISSION],
    [nt.WAITING_STUDIO_CONTRACT]: [keyType.FREELANCE, keyType.MISSION],
    [nt.CONTRACT_DELETED]: [keyType.MISSION],
    [nt.BANK_TRANSFER_SENT]: [keyType.MISSION],
    [nt.BANK_TRANSFER_RECEIVED]: [keyType.MISSION],
    [nt.WAITING_FUNDS]: [keyType.MISSION],
    [nt.COMPLETED_REVIEW]: [keyType.MISSION],

    [nt.ISSUE_MISSION]: [keyType.MISSION],
    [nt.ISSUE]: [keyType.ISSUE],

    [nt.WAITING_STUDIO_DAYS_CONFIRMATION]: [keyType.MISSION],
    [nt.WAITING_FREELANCE_DAYS_CONFIRMATION]: [keyType.MISSION],
    [nt.WAITING_ADMIN_REVIEW_DAYS_CONFIRMATION]: [keyType.MISSION],
    [nt.CONTRACT_UPDATED]: [keyType.MISSION],
    [nt.CANCELLED]: [keyType.MISSION],
    [nt.DECLINE_PROPOSAL]: [keyType.MISSION, keyType.USER],
    [nt.BOOKED]: [keyType.MISSION, keyType.USER],
    [nt.WAITING_REVIEW]: [keyType.MISSION],
    [nt.WAITING_FREELANCE_REVIEW]: [keyType.STUDIO, keyType.MISSION],
    [nt.WAITING_STUDIO_REVIEW]: [keyType.FREELANCE, keyType.MISSION],
    [nt.REVIEWED]: [keyType.MISSION],

    [nt.FUNDS_UNLOCKED]: [keyType.MISSION],
    [nt.ALLOW_SCHEDULE_MEETING]: [keyType.ISSUE],
    [nt.NEW_REVIEW_STUDIO_CONTRACT_CONFIRMATIONS]: [keyType.MISSION],
    [nt.CONTRACT_FINISHED]: [keyType.MISSION],
}

const getKeyTypeByMessageType = (index = 0, notifType = 0) => {
    return keysMessageType[notifType][index] || keyType.USER
}

export const getNotificationTypeReadable = (type) =>
    valueToKey({ object: nt, value: type }) || 'MISSION_CREATED'

export const parseKeys = (message, keysReceived, notifType, privateConvLang, userType) => {
    const sliceKeysFromMessage = (isFunction(message) ? message(userType) : message).split(/\$[1-9]*/)
    let keyIndex = 0
    const finalArr = []
    for (let index = 0; index < sliceKeysFromMessage.length; index++) {
        const element = sliceKeysFromMessage[index];
        finalArr.push(!!element ? { message: element, type: 'message' } : null)
        if (index !== sliceKeysFromMessage.length - 1) {
            finalArr.push(
                {
                    message: keysReceived[keyIndex],
                    type: 'key',
                    keyType: getKeyTypeByMessageType(keyIndex, notifType)
                }

            )
            keyIndex++
        }
    }
    return finalArr.filter(e => !!e)
}

export default {
    readNotification,
    getAllNotifications,
    readNotificationAPI,
    getAllNotificationsWithRead,
    nt: nt,
    readAllNotificationsAPI,
    getNotificationTypeReadable,
    parseKeys,
}

import classes from 'components/StatusMission/StatusMission.module.scss';
import { dayStatuses } from 'constants/DayStatuses';
import { UserTypes } from 'constants/UserTypes';

const getEventState = (status, type) => {
	switch (status) {
		case dayStatuses.OPEN:
			return 'open';
		case dayStatuses.DECLINED:
			return 'cancelled';
		case dayStatuses.WAITING_PROPOSAL:
			return 'waiting';
		case dayStatuses.CONFIRMED:
			return type === UserTypes.FREELANCE ? 'inProgress' : 'inProgress';
		case dayStatuses.WAITING_ALL_CONTRACTS:
			return 'waiting';
		case dayStatuses.WAITING_FREELANCE_CONTRACT:
			return type === UserTypes.FREELANCE ? 'waiting' : 'inProgress';
		case dayStatuses.WAITING_STUDIO_CONTRACT:
			return type === UserTypes.STUDIO ? 'waiting' : 'inProgress';
		case dayStatuses.WAITING_FUNDS:
			return type === UserTypes.STUDIO ? 'waiting' : 'inProgress';
		case dayStatuses.WAITING_TEAM_FUNDS_RECEIVED:
		case dayStatuses.BOOKED:
			return 'inProgress';
		case dayStatuses.WAITING_STUDIO_DAYS_CONFIRMATION:
			return type === UserTypes.STUDIO ? 'waiting' : 'inProgress';
		case dayStatuses.WAITING_FREELANCE_DAYS_CONFIRMATION:
			return type === UserTypes.FREELANCE ? 'waiting' : 'inProgress';
		case dayStatuses.WAITING_ADMIN_REVIEW_DAYS_CONFIRMATION:
			return 'inProgress';
		case dayStatuses.REVIEWED_DAYS:
			return 'inProgress';
		case dayStatuses.WAITING_UNLOCK_FUNDS:
			return type === UserTypes.FREELANCE ? 'waiting' : 'inProgress';
		case dayStatuses.FUNDS_UNLOCKED:
			return 'inProgress';
		case dayStatuses.CANCELLED:
			return 'cancelled';
		case dayStatuses.WAITING_REVIEW:
			return 'waiting';
		case dayStatuses.WAITING_FREELANCE_REVIEW:
			return type === UserTypes.FREELANCE ? 'waiting' : 'inProgress';
		case dayStatuses.WAITING_STUDIO_REVIEW:
			return type === UserTypes.STUDIO ? 'waiting' : 'inProgress';
		case dayStatuses.FINISHED:
			return 'finished';
		default:
			return 'inProgress';
	}
	return 'inProgress';
};

const DayStatusEventProp = ({ status, type }) => {
	return {
		className: classes[getEventState(status, type)],
	};
};

export default DayStatusEventProp;

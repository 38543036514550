import { useCallback, useContext, useEffect, useState } from 'react';
import Button from 'components/Button';
import { getMission, getMissions } from '../helpers';
import { socket, removeEvent } from 'socket';
import { useSockets } from 'hooks/useSockets';
import MissionListComponent from './MissionListComponent';
import RightSide from 'components/RightSide/RightSide';
import TitlePage from 'components/TitlePage/TitlePage';
import useLanguage from 'hooks/useLanguage';
import { Store } from 'store/store';
import { UserTypes } from 'constants/UserTypes';
import { useStoreUtils } from 'hooks/useStoreUtils';

const MissionsPage = () => {
	const { user } = useContext(Store).state;
	const [missions, setMissions] = useState([]);
	const lang = useLanguage('mission');
	const { socketConnected } = useSockets();
	const { setTableLoadingOn, setTableLoadingOff } = useStoreUtils();

	useEffect(() => {
		if (socketConnected && socket) {
			socket.on('add mission', (newMission) => {
				setMissions((missions) => [...missions, newMission]);
			});
			socket.on('remove mission', (missionRemoved) => {
				setMissions((missions) => missions.filter((mission) => missionRemoved.id !== mission.id));
			});
		}
		return () => {
			if (socketConnected && socket) {
				removeEvent('add mission');
				removeEvent('remove mission');
			}
		};
	}, [socketConnected]);

	const init = useCallback(async () => {
		setTableLoadingOn();

		try {
			const missionsIds = await getMissions();
			const _missions = await Promise.all(missionsIds.map(({ id }) => getMission(id)));
			setMissions(_missions);
			setTableLoadingOff();
		} catch (error) {
			console.error('error', error);
		}
	}, []);

	useEffect(() => {
		init();
	}, [init]);

	return (
		<RightSide>
			<TitlePage
				subtitle={
					user.type === UserTypes.STUDIO ? (
						<Button link={'/mission/create/0'}>{lang.createMission}</Button>
					) : null
				}
			>
				{lang.missionList}
			</TitlePage>
			<MissionListComponent missions={missions} />
		</RightSide>
	);
};

export default MissionsPage;

import Typography from 'components/Typography';
import React, { useMemo } from 'react'
import classes from './TitlePage.module.scss'

const TitlePage = ({ children, subtitle = false }) => {

	const title = useMemo(() => <Typography classes={{ root: subtitle ? classes.noMargin : classes.root }} variant='title'>
		{children}
	</Typography>, [children, subtitle])
	return (
		subtitle ? <div className={classes.root}>
			{title}
			{subtitle}
		</div> : title

	)
}

export default TitlePage

import { getContract } from 'containers/contract/helpers';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import toast from 'components/toast';
import { removeEvent, socket } from 'socket';
import { Store } from '../store/store';

export const useContract = (contractId = null) => {
    const { dispatch, state: { socketConnected } } = useContext(Store);
    const [contract, setContract] = useState({})
    const navigate = useNavigate()

    const loadContract = useCallback(async () => {
        try {
            const newContract = await getContract(contractId)
            setContract(newContract)
        } catch (error) {
            console.log('error', error)
        }

    }, [contractId])

    useEffect(() => {
        if (contractId) {
            loadContract(contractId)
        }
    }, [contractId, loadContract])

    useEffect(() => {
        if (contractId && socketConnected && socket) {
            socket.emit('join_contract', contractId);
            socket.on(`contract updated`, () => {
                loadContract()
            });
            socket.on(`contract removed`, () => {
                toast('Le contrat a été supprimé.')
                navigate('/missions')
            });
        }
        return () => {
            if (contractId && socketConnected && socket) {
                socket.emit('leave_contract', contractId);
                removeEvent(`contract updated`);
                removeEvent(`contract removed`);
            }
        };
    }, [contractId, socketConnected])

//freelanceName studioName
    return {
        contract,
        setContract
    };
};

import ExternalScheduler from 'components/ExternalScheduler';
import React from 'react';
import { getAllOtherUsersStartTime_api, getUserMeeting_api } from 'containers/meeting/helpers'
import Typography from 'components/Typography'
import useLanguage from 'hooks/useLanguage';
import classes from '../Onboarding/Onboarding.module.scss'

const ScheduleOnboardMeeting = () => {
    const lang = useLanguage('onboard')
    return (
        <div>
            <Typography variant='subTitle2' primaryColor>
                {lang.accountValidated}<br/>
            </Typography>
            <Typography primaryColor>{lang.pleaseChooseMeeting}</Typography>
            <div className={classes.mt1}>
                <ExternalScheduler
                    getBlockedTimes={getAllOtherUsersStartTime_api}
                    getScheduledTimes={getUserMeeting_api}
                />
            </div>
        </div>
    );
};

export default ScheduleOnboardMeeting;
export const FR = {
	seeOffTable: 'Voir le tableau des réductions',
	tarif: 'Tarif',
	tarifTotal: 'Tarif total',
	availableForAll: 'Disponible pour toute la mission',
	wholeMissionAvailable: 'Tous les jours',
	allLevels: 'Tous les niveaux',
	level: 'Niveau',
	day: 'jour',
	pageTitle: 'Profils disponibles',
	filters: 'Filtres',
	reset: 'Réinitialiser',
	beginner: 'Débutant',
	intermediate: 'Intermédiaire',
	experienced: 'Experimenté',
	availability: 'Disponibilité',
	years: 'ans',
	availableFor: (nDays) => `Disponible pour ${nDays} ${nDays === 1 ? 'jour' : 'jours'}`,
	noProfilesAvailables: 'Pas de freelances disponibles pour cette mission',
	reductionInfo: (initialPrice, percentile, price) => `Une réduction est appliqué sur l'ensemble des jours à partir de 8 jours de mission.
	<br/><br/>
	Prix initial ${initialPrice} € / ${percentile} = ${price} € HT
	<br/><br/>
	Veuillez vous référer au tableau des réductions, accessible via le bouton du menu filtres (à votre gauche).
	`,
	reductionAboutDays: (percentile, dayCount) => `Réduction de ${percentile} sur ${dayCount} jours appliquée.`,
	hour: 'heure',
}

export const EN = {
	seeOffTable: 'See table of discount',
	tarif: 'Rate',
	tarifTotal: 'Total rate',
	availableForAll: 'Available for full mission days',
	wholeMissionAvailable: 'Everydays',
	level: 'Level',
	allLevels: 'All levels',
	day: 'day',
	pageTitle: 'Available Profiles',
	filters: 'Filters',
	reset: 'Reset',
	beginner: 'Beginner',
	intermediate: 'Intermediate',
	experienced: 'Experienced',
	availability: 'Availability',
	years: 'years',
	availableFor: (nDays) => `Available for ${nDays} ${nDays === 1 ? 'day' : 'days'}`,
	noProfilesAvailables: 'None profiles availables for this mission',
	reductionInfo: (initialPrice, percentile, price) => `A reduction is applied to all days from 8 days of mission.
	<br/><br/>
	Initial price ${initialPrice} / ${percentile} = ${price} excluding taxes
	<br/><br/>
	Please refer to the discount table, accessible via the filters menu button (to your left).
	`,
	reductionAboutDays: (percentile, dayCount) => `Reduction of ${percentile} on ${dayCount} days applied.`,
	hour: 'hour',
}

export default {
	fr: FR,
	us: EN,
}

import { UserTypes } from "./UserTypes";

const freelanceAwait = {
    [UserTypes.FREELANCE]: 'inProgress',
    [UserTypes.STUDIO]: 'waiting',
}

const studioAwait = {
    [UserTypes.FREELANCE]: 'waiting',
    [UserTypes.STUDIO]: 'inProgress',
}

export const DayStatusesEventTypes = {
    OPEN: 'open',
	WAITING_PROPOSAL: 'waiting',
    CONFIRMED: freelanceAwait,
    WAITING_ALL_CONTRACTS: 'waiting', // --> fixer ensemble dates
    WAITING_FREELANCE_CONTRACT: studioAwait, // --> fixer ensemble dates
    WAITING_STUDIO_CONTRACT: freelanceAwait, // --> fixer ensemble dates
    WAITING_FUNDS: freelanceAwait,
    WAITING_TEAM_FUNDS_RECEIVED: 'inProgress',
    BOOKED: 'inProgress',
    WAITING_STUDIO_DAYS_CONFIRMATION: freelanceAwait, // on attend que le studio confirme ou non les dates
    WAITING_FREELANCE_DAYS_CONFIRMATION: studioAwait,
    WAITING_ADMIN_REVIEW_DAYS_CONFIRMATION: 'inProgress', //si il y a un conflit sur les dates confirmées
    WAITING_REVIEW: 'waiting',
    WAITING_FREELANCE_REVIEW: studioAwait,
    WAITING_STUDIO_REVIEW: freelanceAwait,
    REVIEWED: 'inProgress',
    WAITING_UNLOCK_FUNDS: {
        [UserTypes.FREELANCE]: 'waiting',
        [UserTypes.STUDIO]: 'finished',
    },
    FUNDS_UNLOCKED: {
        [UserTypes.FREELANCE]: 'inProgress',
        [UserTypes.STUDIO]: 'finished',
    },
    ISSUE: 'inProgress',// à voir si on le laisse ici car on ne peut pas accéder au statut précédent
    CANCELLED: 'cancelled',
    FINISHED: 'finished',
    IN_PROGRESS: 'inProgress',
    WAITING_START: 'waiting',
}
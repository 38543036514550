import React from 'react';
import { useStoreUtils } from 'hooks/useStoreUtils';
import MyServicePage from './MyServicePage';
import SubscribePage from 'containers/subscription/SubscribePage';

const MyServices = () => {
	const { isPremium } = useStoreUtils();

	return isPremium ? <MyServicePage /> : <SubscribePage />;
};

export default MyServices;

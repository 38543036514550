import React, { useState } from 'react';
import { FormField, Input } from 'semantic-ui-react';
import classes from './CreateSvg.module.scss'

const CreateCursive = React.forwardRef(({
    text = 'Area sum inum',
    input = false,
    onChange: onChangeFromProps = () => {}
}, ref) => {
    const [value, setValue] = useState('')
    const onChange = ({ target }) => {
        setValue(target.value)
        onChangeFromProps(target.value)
    }

    return (<div>
        <div ref={ref} className={classes.readAndApproved}>
            {input ? value || text : text}
        </div>
        {input && <FormField> <Input onChange={onChange} /></FormField>}
    </div>
    );
});

export default CreateCursive;
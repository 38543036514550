import fetchApi from './fetchApi'
import saveTokens from './saveTokens'
import isObject from '../data/isObject'
import history from './history'
import logOut from './logOut'

const isTokenRegenerate = async (res, apiRoute, params) => {
	const body = (await res.json()) || {}
	res.message = body.message
	if (isObject(res) && res.status) {
		if (body.message === 'regenerateTokens') {
			saveTokens(body.tokens, () => {})
			return fetchApi(apiRoute, params)
		}
		if (body.message === 'disconnect') {
			logOut()
		} else if (body.message === 'forbidden') {
			return history.push('/')
		}
	}
	return Promise.reject(res)
}

export default isTokenRegenerate
import { useNavigate } from 'react-router-dom'
import toast from 'components/toast'
import { deleteContractAPI } from '../helpers'
import useLanguage from 'hooks/useLanguage'
import Button from 'components/Button'

const DeleteContractButton = ({
    contractId
}) => {
    const lang = useLanguage('contract')
    const navigate = useNavigate()
    const deleteContract = async () => {
        try {
            await deleteContractAPI(contractId)
            toast.success(lang.contractDeleted)
            navigate('/missions')
        } catch (error) {
            toast.error(error.message)
        }
    }
    return (
        <Button type='black' onClick={deleteContract} >
           {lang.deleteContract}
        </Button>
    )
}

export default DeleteContractButton
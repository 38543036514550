import MyTable from 'components/MyTable/MyTable';
import { roundTo2Dec } from 'containers/helpers';
import useLanguage from 'hooks/useLanguage';
import { formatSlotsCountInTime } from 'modules/dates';
import { useMemo } from 'react';

const ServicesTablePrices = ({ services = [] }) => {
	const lang = useLanguage('appointment');

	const columns = useMemo(
		() =>
			[
				{ title: lang.columns.id, type: 'text', field: 'id' },
				{ title: lang.columns.title, type: 'text', field: 'title' },
				{ title: lang.columns.description, type: 'text', field: 'description' },
				{ title: lang.columns.time, type: 'text', render: ({ slots }) => `${formatSlotsCountInTime(slots)}` },
				{
					title: lang.columns.price,
					type: 'text',
					render: ({ price }) => `${price} €`,
				},
			].filter((e) => e),
		[lang],
	);

	const data = useMemo(
		() =>
			services.map((service) => ({
				...service,
				price: roundTo2Dec(Number(service.price)),
			})),
		[services],
	);

	return (
		<MyTable
			columns={columns}
			data={data}
			options={{
				paging: false,
			}}
		/>
	);
};

export default ServicesTablePrices;

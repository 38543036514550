import AddDates from 'components/AddDates';
import RightSide from 'components/RightSide/RightSide';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { createProposals, getFreelanceForMission } from '../helpers';
import toast from 'components/toast';
import MissionTitle from 'containers/mission/components/MissionTitle';
import useLanguage from 'hooks/useLanguage';
import { useMission } from 'hooks/useMission';
import Typography from 'components/Typography';
import { useFreelanceUtils } from 'hooks/useFreelanceUtils';
import LastMissions from 'containers/marketplace/components/LastMissions';
import StudiosVisited from 'containers/marketplace/components/StudiosVisited';
import classes from './FreelanceSelected.module.scss';
import Button from 'components/Button';
import classNames from 'classnames';
import FreelanceProfileCard from 'components/FreelanceProfileCard';
import { isNumber } from 'containers/helpers';
import { date2unix } from 'modules/dates';

const FreelanceSelected = () => {
	const lang = useLanguage('freelanceSelected');
	const navigate = useNavigate();
	const { missionId, userId } = useParams();
	const [days, setDays] = useState([]);
	const [initialDays, setInitialDays] = useState(0);
	const [freelance, setFreelance] = useState();
	const freelanceForUtils = useMemo(
		() => ({
			...(freelance || {}),
		}),
		[freelance],
	);
	const { level, priceHour, badges, imageUrl, type, typeLang } = useFreelanceUtils(freelanceForUtils);

	const { mission, ...props } = useMission(Number(missionId));

	const matchingResult = useMemo(() => {
		if (freelance && mission) {
			const availabilitiesCount = Object.keys(freelance.days).length;
			const slotsCount = mission.days.length;

			return availabilitiesCount === slotsCount
				? lang.allDaysAvailables
				: `${availabilitiesCount} ${lang.on} ${slotsCount} ${lang.day}${slotsCount > 1 ? 's' : ''} ${
						lang.missionAvailables
				  }`;
		}
		return '';
	}, [freelance, mission, lang]);

	useEffect(() => {
		if (isNumber(userId) && isNumber(missionId)) {
			getFreelanceForMission(userId, missionId)
				.then(({ mission, freelance }) => {
					setFreelance(freelance);
					setDays(freelance.days);
					setInitialDays(freelance.days);
				})
				.catch((e) => {
					toast.error(e.message);
					navigate(-1);
				});
		}
	}, [missionId, userId, navigate]);

	const chooseOnly = useCallback(
		(key) => {
			setDays((prev) => {
				const newDays = { ...prev };
				return { [key]: newDays[key] };
			});
		},
		[setDays],
	);

	const removeDate = useCallback(
		(key) => {
			setDays((prev) => {
				const newDays = { ...prev };
				delete newDays[key];
				return newDays;
			});
		},
		[setDays],
	);

	// faire le traitement afin de récupérer les slots via les dates
	const sendProposal = useCallback(async () => {
		try {
			const dates = Object.values(days)
				.map(({ dates }) => dates)
				.flat()
				.map((date) => date2unix(date));

			const availabilitiesUpdated = await createProposals(missionId, dates, userId);
			if (availabilitiesUpdated.length === 0) {
				toast.error(lang.datasAlreadyUpdated);
			} else {
				toast.success(lang.proposalSent);
			}
			return navigate('/mission/page/' + missionId);
		} catch (error) {
			return toast.error(error.message);
		}
	}, [days, missionId, navigate, userId, lang]);

	const reinitializeDays = useCallback(() => setDays({ ...initialDays }), [initialDays]);

	const hoursCountForOpenDays = useMemo(() => {
		const hours = Object.values(days).reduce((prev, { slots, dayStatus }) => {
			let currentHours = slots.length / 4;
			if (dayStatus === 'day') currentHours -= 1;

			return prev + currentHours;
		}, 0);

		return hours;
	}, [days]);

	return (
		<RightSide>
			<MissionTitle mission={mission} {...mission} {...props} subPage={lang.title} />
			<div>
				<Typography variant="subTitle3">{matchingResult}</Typography>
				<div className={classes.infosContainer}>
					<FreelanceProfileCard
						img={imageUrl}
						availableDays={Object.keys(days)}
						hourRate={priceHour}
						totalRate={priceHour * hoursCountForOpenDays}
						firstName={(freelance || {}).displayName}
						level={level}
						isAvailableForWholeMission={(freelance || {}).matchWithAllDates}
						badges={badges}
						rating={(freelance || {}).rating}
						nRatings={(freelance || {}).nRatings}
						created_at={(freelance || {}).created_at}
						type={type}
						typeLang={typeLang}
					/>
					{(freelance || {}).appointments && (
						<div className={classes.contractContainer}>
							<StudiosVisited appointments={(freelance || {}).appointments} />
							<LastMissions appointments={freelance.appointments} />
						</div>
					)}
				</div>
				<AddDates
					days={days}
					filterWeekends={false}
					toggleFilterWeekends={false}
					chooseOnly={chooseOnly}
					removeDate={removeDate}
					cantRemoveIfOne
					showTimeSlot
					showFavoriteTimeSlot
				/>
				<div className={classNames(classes.flexWrap, classes.flex, classes.gap05)}>
					{Object.keys(days).length !== Object.keys(initialDays).length && (
						<Button type="secondary" onClick={reinitializeDays}>
							{lang.reinitializeDates}
						</Button>
					)}
					<Button link={`/mission/marketplace/${missionId}`}>{lang.goBack}</Button>
					{Object.keys(days).length > 0 && (
						<Button type="success" onClick={sendProposal}>
							{lang.sendProposal}
						</Button>
					)}
				</div>
			</div>
		</RightSide>
	);
};

export default FreelanceSelected;

import classNames from 'classnames'
import Image from 'components/Image'
import classes from './Loader.module.scss'

const Loader = () =>
    <Image
        src='/assets/logoLoader.png'
        size='large'
        alt='logo Styleep'
        className={classNames(classes.loader, classes.appLogo)}
    />


export default Loader

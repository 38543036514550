import MaterialTable from '@material-table/core';
import { useStoreUtils } from 'hooks/useStoreUtils';
import React, { useEffect, useState } from 'react';
import HeaderTable from './HeaderTable';
import './MyTable.scss'

const MyTable = ({
    title = '',
    columns = [],
    data = [],
    options = {},
    filters = null,
    onRowClick = null
}) => {
    const [rows, setRows] = useState(data)
    const { state: { tableLoading } } = useStoreUtils()
    useEffect(() => {
        setRows(data)
    }, [data])
    return <div
        className='root'
    >
        <HeaderTable filters={filters} rows={data} setRows={setRows} />
        <MaterialTable
            {...(onRowClick ? { onRowClick } : {})}
            title={title}
            columns={columns}
            data={rows}
            options={{
                ...options,
                search: false,
                paging: false
            }}
            isLoading={tableLoading}
        />
    </div>
};

export default MyTable;
import Button from 'components/Button/Button';
import FormComponent from 'components/Form/Form';
import TitlePage from 'components/TitlePage/TitlePage';
import toast from 'components/toast/toast';
import { fetchApi, isNull, roundTo2Dec } from 'containers/helpers';
import useLanguage from 'hooks/useLanguage';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classes from './ManageService.module.scss';
import useIsMobile from 'hooks/useIsMobile';
import { useProfession } from 'hooks/useProfession';

const ManageService = ({ serviceId = null, onUpdate = () => {} }) => {
	const { userProfessions } = useProfession();
	const isMobile = useIsMobile();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const lang = useLanguage('services');
	const [service, setService] = useState();
	const onSubmit = useCallback(
		async (values) => {
			try {
				setLoading(true);
				values.slots *= 4;
				values.profession = Number(values.profession);
				const serviceReceived = await fetchApi(`/service${serviceId ? `/${serviceId}` : ''}`, {
					method: serviceId ? 'PATCH' : 'POST',
					body: values,
				});
				setLoading(false);
				onUpdate();
				serviceId
					? setService((s) => ({ ...s, ...serviceReceived }))
					: navigate('/service/' + serviceReceived.id);
			} catch (error) {
				setLoading(false);
				toast.error(error.message);
			}
		},
		[serviceId, navigate, onUpdate],
	);

	const removeService = useCallback(async () => {
		try {
			setLoading(true);
			await fetchApi(`/service/${serviceId}`, { method: 'DELETE' });
			setLoading(false);
			onUpdate();
			navigate('/services');
		} catch (error) {
			setLoading(false);
			toast.error(error.message);
		}
	}, [serviceId, navigate, onUpdate]);

	useEffect(() => {
		if (serviceId) {
			fetchApi('/service/' + serviceId)
				.then(setService)
				.catch((e) => console.log(e));
		}
	}, [serviceId]);

	const [serviceFromForm, setServiceFromForm] = useState({});

	const onUpdateForm = useCallback(
		(values) => {
			const valuesToSend = { ...values };
			valuesToSend.slots *= 4;
			values.profession = Number(values.profession);
			setServiceFromForm(valuesToSend);
		},
		[setServiceFromForm],
	);

	const value = useMemo(() => {
		const { price, slots } = isEmpty(serviceFromForm) ? (isEmpty(service) ? {} : service) : serviceFromForm;
		if (!price || !slots) {
			return false;
		}
		return roundTo2Dec(price / (slots >= 4 ? slots / 4 : slots));
	}, [service, serviceFromForm]);

	const inputs = useMemo(
		() =>
			[
				{
					type: 'select',
					name: 'profession',
					label: lang.columnList.profession,
					value: isNull(service?.profession)
						? String(userProfessions[0]?.id) || null
						: String(service?.profession),
					options: userProfessions.map((profession) => ({
						key: String(profession.id),
						value: String(profession.id),
						text: profession.name,
					})),
					required: true,
					silentRequired: true,
				},
				{
					type: 'autocompleteAdd',
					name: 'title',
					label: lang.columnList.title,
					placeholder: 'Coupe femme',
					required: true,
					silentRequired: true,
					value: service?.title,
					searchMatchLink: '/services?exclude=mine&title=',
				},
				{
					type: 'textArea',
					name: 'description',
					value: service?.description,
					label: lang.columnList.description,
				},
				{
					type: 'number',
					name: 'price',
					label: lang.columnList.price,
					required: true,
					silentRequired: true,
					value: service?.price,
				},
				{
					type: 'number',
					name: 'slots',
					label: lang.columnList.slots,
					legend: lang.slotsInfo,
					required: true,
					silentRequired: true,
					value: service?.slots ? (service?.slots >= 4 ? service?.slots / 4 : service?.slots) : 0,
				},
			].filter((e) => !!e),
		[lang, service, userProfessions],
	);

	return (
		<div className={classes.centeredContainer}>
			<div className={classes.taCenter}>
				<TitlePage subtitle={value && <div>{lang.infoPrice + value + '€'}</div>}>
					{lang[serviceId ? 'updateService' : 'createService']}
				</TitlePage>
				<div>
					<FormComponent
						onUpdate={onUpdateForm}
						messages={{ submit: serviceId ? lang.updateService : lang.createService }}
						loading={loading}
						sendForm={onSubmit}
						inputs={inputs}
					/>
				</div>
				<Button className={isMobile ? '' : classes.removeButton} type="danger" onClick={removeService}>
					{lang.removeService}
				</Button>
			</div>
		</div>
	);
};

export default ManageService;

export const FR = {
  titleModal:   'Lien envoyé par mail',
  contentModal: 'Un lien de réinitialisation de votre mot de passe a été envoyé par mail. Patience, vous allez être redirigé vers la page de connexion, ou cliquez sur le bouton ci-dessous.',
  goTo:         'Aller à la page de réinitialisation',
	forgotPassword: 'Mot de passe oublié ?',
	validForgot: 'Envoyer',
}
export const EN = {
  titleModal:   'Reset link sent',
  contentModal: 'Reset password link is sent to your email. Patience, you\'ll be redirect to the login page, or click on the button bellow.',
  goTo:         'Go to reset page',
	validForgot: 'Send',
	forgotPassword: 'Forgot password ?',
}

export default {
  fr: FR,
  us: EN,
}

import StatusMissionComponent from 'components/StatusMission/StatusMissionComponent';
import { statuses as IssueStatuses } from 'containers/issue/issue.enum';
import useLanguage from 'hooks/useLanguage';
import React, { useMemo } from 'react';

const IssueStatusComponent = ({
    status
}) => {
    const statuses = useLanguage('issue.statuses')

    const props = useMemo(() => {
        let eventType
        switch (status) {
            case IssueStatuses.OPEN:
                eventType = 'open'
                break
            case IssueStatuses.IN_PROGRESS:
                eventType = 'inProgress'
                break
            case IssueStatuses.WAITING_MEETING:
                eventType = 'waiting'
                break
            case IssueStatuses.MEETING_BOOKED:
                eventType = 'inProgress'
                break
            case IssueStatuses.CLOSE:
                eventType = 'finished'
                break
            default:
                eventType = 'waiting'
                break
        }
        return {
            langStatus: statuses[status],
            eventType
        }
    }, [status, statuses])
    return (
        <StatusMissionComponent
            {...props}
        />
    );
};

export default IssueStatusComponent;
import Button from 'components/Button';
import { getDayStatusesLang } from 'containers/mission/helpers';
import DeleteMissionButton from 'containers/mission/MissionPage/DeleteMissionButton';
import useLanguage from 'hooks/useLanguage';
import React, { useContext, useMemo } from 'react';
import { Store } from 'store/store';
import ContractButton from '../ContractButton';
import { dayStatuses } from 'constants/DayStatuses';
import classNames from 'classnames';
import classes from 'styles/classes.module.scss'

const StudioMissionActions = ({ mission }) => {
	const lang = useLanguage('mission')
	const dayStatusesLangs = useLanguage('dayStatuses')
	const { state: { user } } = useContext(Store)

	const dayStatusesLang = useMemo(() => getDayStatusesLang(dayStatusesLangs, user.type), [user.type, dayStatusesLangs])

	const appointmentWhosWaitingAction = useMemo(() => (mission.appointments || []).filter(appointment => {
			switch (appointment.status) {
				case dayStatuses.WAITING_ALL_CONTRACTS:
				case dayStatuses.WAITING_STUDIO_CONTRACT:
				case dayStatuses.WAITING_FUNDS:
				case dayStatuses.WAITING_STUDIO_DAYS_CONFIRMATION:
				case dayStatuses.WAITING_REVIEW:
				case dayStatuses.WAITING_STUDIO_REVIEW:
					return true
				default:
					return false
			}
	}) , [mission])
	return (
		<div className={classNames(classes.flex, classes.gap1)}>
			<div className={classNames(classes.flex, classes.columnDirection, classes.gap05)}>
				<DeleteMissionButton mission={mission} missionId={mission.id} />
				<Button type="success" link={'/mission/marketplace/' + mission.id}>
					{lang.accessProfiles}
				</Button>
				<Button type="warning" link={'/mission/edit/0/' + mission.id}>
					{lang.updateMission}
				</Button>
				<Button
					type="secondary"
					link={'/mission/edit/1/' + mission.id}
				>
					{lang.updateMissionDates}
				</Button>
			</div>
			<div className={classNames(classes.flex, classes.columnDirection, classes.gap05)}>
				{appointmentWhosWaitingAction.map((appointment, index) => (
						<ContractButton key={index}
							isMissionPage
							missionId={mission.id}
							{...appointment}
							appointment={appointment.id}
							contractDays={appointment.days}
							contractId={appointment.contract}
							dayStatusesLang={dayStatusesLang}
						/>
					))}
			</div>
		</div>
	);
};

export default StudioMissionActions;
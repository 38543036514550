import { roundTo2Dec } from 'containers/helpers';
import { useMemo } from 'react';
import classes from './Bookkeeping.module.scss';
import classNames from 'classnames';
import useLanguage from 'hooks/useLanguage';
import Typography from 'components/Typography';
import { Message } from 'semantic-ui-react';

const BookkeepingNumbers = ({ payments = [], allYearPayments = [], year = new Date().getFullYear() }) => {
	const lang = useLanguage('bookkeeping');

	const total = useMemo(
		() => roundTo2Dec(payments.reduce((prev, actual) => prev + parseFloat(actual.sum), 0)),
		[payments],
	);
	const totalYear = useMemo(
		() => roundTo2Dec(allYearPayments.reduce((prev, actual) => prev + parseFloat(actual.sum), 0)),
		[allYearPayments],
	);
	const plafondTva = 72600;
	const plafond = 34400;
	return (
		<Message floating>
			<div className={classNames(classes.mb1, classes.numbersContainer)}>
				<div>
					<Typography variant="subTitle3">{lang.periodTotal}</Typography>
					<Typography>{total}€</Typography>
				</div>
				{typeof year !== 'string' && (
					<>
						{' '}
						<div>
							<Typography variant="subTitle3">
								{lang.yearTotal} {year}
							</Typography>
							<Typography>{totalYear}€</Typography>
						</div>
						<div>
							<Typography variant="subTitle3">{lang.percentileMicro}</Typography>
							<Typography>
								{roundTo2Dec((totalYear / plafond) * 100)}% = {totalYear} / {plafond} (
								{lang.topLimit2022WithoutTVA})
							</Typography>
						</div>
					</>
				)}
			</div>
		</Message>
	);
};

export default BookkeepingNumbers;

import FormComponent from 'components/Form/Form';
import RightSide from 'components/RightSide/RightSide';
import { updateFreelance } from 'containers/onboard/helpers';
import { useStoreUtils } from 'hooks/useStoreUtils';
import { toDateInput } from 'modules/dates';
import { useMemo } from 'react';
import toast from 'components/toast';
import TitlePage from 'components/TitlePage';
import { isNull, objectToFormData } from 'containers/helpers';
import { useNavigate } from 'react-router-dom';


const FiscalInfosFreelanceUpdate = () => {
    const navigate = useNavigate()
    const { state: { language: { bookkeeping: bookkeepingLang }, user }, updateLocalUser } = useStoreUtils()
    const inputs = useMemo(() => [
        {
            type: 'date',
            name: 'since',
            label: bookkeepingLang.creationDate,
            value: toDateInput(user.since),
            required: true,
            silentRequired: true,
            fluid: true,
        },
        {
            type: 'select',
            name: 'periodicity_type',
            label: bookkeepingLang.declarationType,
            required: true,
            silentRequired: true,
            placeholder: bookkeepingLang.declarationTypeChoices.quartely,
            value: isNull(user.periodicity_type) ? null : String(user.periodicity_type),
            options: [
                {
                    key: '0',
                    value: '0',
                    text: bookkeepingLang.declarationTypeChoices.quartely
                }, {
                    key: '1',
                    value: '1',
                    text: bookkeepingLang.declarationTypeChoices.monthly
                }
            ]
        },
    ], [bookkeepingLang, user])

    const updateInformations = async (values) => {
        try {
            await updateFreelance(objectToFormData(values), user.type_id)
            updateLocalUser({
                ...user,
                ...values
            })
            toast.success(bookkeepingLang.datasUpdated)
            navigate('/bookkeeping')
        } catch (e) {
            toast.error(e.message)
        }

    }
    return (
        <RightSide>
            <TitlePage>
                {bookkeepingLang.titleForm}
            </TitlePage>
            <FormComponent
                responsive={false}
                segment={false}
                inputs={inputs}
                sendForm={updateInformations}
            />
        </RightSide>
    );
};

export default FiscalInfosFreelanceUpdate;
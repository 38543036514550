import Typography from 'components/Typography';
import useLanguage from 'hooks/useLanguage';
import classes from './MyPrice.module.scss'

const MyPrice = ({ hourlyRate }) => {
    const lang = useLanguage('marketPlace')

    return (
        <div className={classes.root} >
            <Typography variant='subTitle2' classes={{ root: classes.whiteColor }}>
                {hourlyRate * 8}€ / {lang.day}
            </Typography>
        </div>
    );
};

export default MyPrice;
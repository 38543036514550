import classNames from 'classnames';
import useIsMobile from 'hooks/useIsMobile';
import React, { useMemo } from 'react';
import ContentContainer from './ContentContainer';
import classes from './LogGrid.module.scss'
import SideBarContainer from './SideBarContainer';
import useBurgerMenu from './useBurgerMenu';

const LogGridComponent = ({
    children
}) => {
    const isMobile = useIsMobile()
    const { isOpen, setOpositeToActualOpen } = useBurgerMenu()
    const isBurgerClose = useMemo(() => isMobile && !isOpen, [isMobile, isOpen])
    const isContentVisible = useMemo(() => isMobile ? isBurgerClose : true, [isBurgerClose, isMobile])

    return (
        <div className={classNames(classes.root, isMobile && classes.mobile)}>
            <SideBarContainer
                isOpen={isOpen}
                setOpositeToActualOpen={setOpositeToActualOpen}
                isBurgerClose={isBurgerClose}
            />
            {isContentVisible && <ContentContainer >
                {children}
                </ContentContainer>}
        </div>
    );
};

export default LogGridComponent;
import RightSide from 'components/RightSide/RightSide';
import TitlePage from 'components/TitlePage';
import Typography from 'components/Typography';
import { useGodsons } from 'hooks/useGodsons';
import useLanguage from 'hooks/useLanguage';
import React, { useContext } from 'react';
import { Store } from 'store/store';
import GodsonsTable from './GodsonsTable';

const GodsonsPage = () => {
    const { state: { user } } = useContext(Store)
    const { godsons } = useGodsons()
    const lang = useLanguage('godson')

    return (
        <RightSide>
            <TitlePage>{lang.title}</TitlePage>
            <div>
                <div><Typography variant='subTitle3'>{lang.myCode}: {user.code}</Typography></div>
                {godsons.length > 0 ? <GodsonsTable godsons={godsons} /> : <Typography>
                    {lang.noGodson}
                </Typography>}
            </div>
        </RightSide>
    );
};

export default GodsonsPage;
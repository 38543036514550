import React, { useContext, useEffect, useMemo, useState } from 'react';
import { getUserInvoices } from './helpers';
import { Store } from 'store/store';
import RightSide from 'components/RightSide/RightSide';
import TitlePage from 'components/TitlePage/TitlePage';
import useLanguage from 'hooks/useLanguage';
import MyTable from 'components/MyTable/MyTable';

const InvoicesPage = () => {
	const lang = useLanguage('invoice');
	const [invoices, setInvoices] = useState([]);
	const {
		user: { email },
	} = useContext(Store).state;

	useEffect(() => {
		getUserInvoices(email)
			.then(setInvoices)
			.catch((error) => {
				console.error('Error fetching invoices:', error);
			});
	}, [email]);

	const columns = useMemo(
		() => [
			{ title: 'ID', render: (invoice) => <div>{invoice.id}</div> },
			{
				title: lang.amount,
				render: (invoice) => (
					<div>
						{invoice.amount_due / 100} {invoice.currency}
					</div>
				),
			},
			{
				title: lang.invoiceDate,
				render: (invoice) => <div>{new Date(invoice.created * 1000).toLocaleDateString('en-GB')}</div>,
			},
			{
				title: lang.link,
				render: (invoice) => (
					<a href={invoice.hosted_invoice_url} target="_blank" rel="noopener noreferrer">
						{lang.link}
					</a>
				),
			},
		],
		[lang],
	);

	console.log('invoices', invoices);

	return (
		<RightSide>
			<TitlePage>{lang.myInvoices}</TitlePage>
			<MyTable columns={columns} data={invoices} options={{ paging: false }} />
		</RightSide>
	);
};

export default InvoicesPage;

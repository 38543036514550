import MyTable from 'components/MyTable/MyTable';
import useLanguage from 'hooks/useLanguage';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import DateTooltip from 'components/DateTooltip'
import StatusMission from 'components/StatusMission';
import { useDayStatuses } from 'hooks/useDayStatuses';
import { momentFromDateString } from 'modules/dates';

const ContractMissionList = ({
    contracts = []
}) => {
    const navigate = useNavigate()
    const lang = useLanguage('contract')
    const columns = useMemo(() => [
        { title: lang.columns.id, type: 'numeric', field: 'id' },
        { title: lang.columns.freelance, type: 'string', field: 'freelanceName' },
        { title: lang.columns.dates, render: ({ dates = [], ...props }) => <DateTooltip dates={dates} /> },
        { title: lang.columns.dayCount, render: ({ dates, ...props }) => Object.keys(dates).length },
        { title: lang.columns.status, render: ({ status, ...props }) => <StatusMission status={status} /> },
    ].filter(e => e), [lang])

    const onRowClick = (cellMeta, contract) => {
        return navigate(`/mission/${contract.mission}/contract/${contract.id}?nav=1`)
    }

    const { dayStatusesLang } = useDayStatuses()
    const data = useMemo(() => {
        const getMostRecentDate = (dates = []) => Object.keys(dates).map(date => momentFromDateString(date)).sort((a, b) => b - a)[0].unix()
        const sortByRecent = (a, b) => {
            return getMostRecentDate((b.dates || []))
                -
                getMostRecentDate((a.dates || []))
        }
        return contracts
            .map((contract, index) => ({
                ...contract,
                statusLang: dayStatusesLang[contract.status],
                missionId: contract.id
            }))
            .sort(sortByRecent)
    }, [contracts, dayStatusesLang])

    const onlyStatusContract = useMemo(() => data.map(({ statusLang }) => statusLang), [data])

    const dayStatusesKeysAvailables = useMemo(
        () => [...new Set(Object
            .values(dayStatusesLang)
            .filter(key => onlyStatusContract.includes(key)))]
        , [onlyStatusContract, dayStatusesLang])
    return (
        <MyTable
            onRowClick={onRowClick}
            columns={columns}
            data={data}
            options={{
                paging: false,
            }}
            filters={{
                statusLang: { values: dayStatusesKeysAvailables, title: lang.columns.status }
            }}
        />
    );
};

export default ContractMissionList;
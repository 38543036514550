import classNames from 'classnames';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import classes from './Image.module.scss'

const Image = ({
    link = null,
    alt = 'Icon',
    src = '',
    size = 'small',
    centered = false,
    verticalCentered = false,
    fullHeight = false,
    containerClassName = '',
    className = '',
    rounded = false,
    contain = false
}) => {

    const sizeClass = useMemo(() => {
        switch (size) {
            case 'small':
                return classes.small
            case 'medium':
                return classes.medium
            case 'large':
                return classes.large
            case 'larger':
                return classes.larger
            case 'big':
                return classes.big
            default:
                return ''
        }
    }, [size])

    const ImageComponent = () => <img
        alt='alt'
        src={src}
        className={classNames(sizeClass, className, rounded && classes.rounded, contain && classes.contain)}
    />

    const ImageLink = () => link ? <Link
        className={classes.inlineBlock}
        to={link}
    >
        <ImageComponent />
    </Link> :
        <ImageComponent />

    const ImageCentered = () => centered
        ? <div className={classNames(classes.centeredContainer, !!fullHeight && classes.fullHeight, containerClassName)} >
            <ImageLink />
        </div>
        : verticalCentered
            ? <div className={classNames(classes.verticalCenteredContainer, !!fullHeight && classes.fullHeight, containerClassName)} >
                <ImageLink />
            </div>
            : <ImageLink />

    return <ImageCentered />;
};

export default Image;
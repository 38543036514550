import RightSide from 'components/RightSide/RightSide'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getFreelancesForMission } from '../../helpers'
import FreelanceFound from '../../FreelanceMarketplace/FreelanceFound'
import SearchFilters from '../../../components/SearchFilter/SearchFilters'
import useLanguage from 'hooks/useLanguage'
import classes from './FreelanceMarketplace.module.scss'
import { useMission } from 'hooks/useMission'
import MissionTitle from 'containers/mission/components/MissionTitle'
import Typography from 'components/Typography'
import classNames from 'classnames'
import { LevelTypes } from 'constants/FreelanceTypes.contant'

const FreelanceMarketplace = () => {
	const lang = useLanguage('marketPlace')
	const missionId = Number(useParams().missionId)
	const [freelances, setFreelances] = useState([])
	const [APIfreelances, setAPIFreelances] = useState([])
	const { mission, ...props } = useMission(missionId)
	const addType = freelances =>
		freelances
			.map(freelance => ({
				...freelance,
				type: LevelTypes[freelance.level]
			}))

	useEffect(() => {
		if (!isNaN(missionId)){
			getFreelancesForMission(missionId)
				.then(addType)
				.then((res) => {
					setFreelances(res)
					setAPIFreelances(res)
				})
		}
	}, [missionId])

	return (
		<RightSide>
			<MissionTitle
				subPage={lang.pageTitle}
				mission={mission}
				{...mission}
				{...props}
			/>
			<div className={classes.searchContainer}>
				{/* <div className={classes.filterContainer}>
					<SearchFilters
						freelances={APIfreelances}
						setFreelances={setFreelances}
						actualFreelances={freelances}
					/>
				</div> */}
				<div className={classNames(classes.flex, classes.flexWrap)}>
					{freelances.length === 0 && (
						<Typography variant='subTitle3'>{lang.noProfilesAvailables}</Typography>
					)}
					{freelances.map((freelance, index) => (
						<FreelanceFound
							key={index + 'freelance'}
							freelance={freelance}
							mission={mission}
						/>
					))}
				</div>
			</div>
		</RightSide>
	)
}

export default FreelanceMarketplace

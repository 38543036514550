import { fetchApi } from 'containers/helpers';

export const getAppointmentByIdApi = (appointmentId) => fetchApi(`/appointment/${appointmentId}?plateform=1`);

export const getServicesByIdsApi = (serviceIds) => fetchApi(`/services/customer?serviceIds=${serviceIds.join(',')}`);

export const getForfaitsByIdsApi = (forfaitIds: number[]) =>
	fetchApi(`/public/forfait?forfaitIds=${forfaitIds.join(',')}`);

export const cancelAppointmentApi = (appointmentId) =>
	fetchApi('/appointment/cancelAppointment/' + appointmentId, {
		method: 'PATCH',
	});

export const getAppointmentInvoiceApi = (appointmentId) => fetchApi(`/appointment/${appointmentId}/invoice`);

import { List, Checkbox } from 'semantic-ui-react'
import Button from 'components/Button'
import Typography from 'components/Typography'
import { isWeekend, dateByDateFormat, momentFromDateString } from 'modules/dates'
import classes from './AddDatesByFreelances.module.scss'
import cn from 'classnames'
import externalClasses from 'styles/classes.module.scss'
import useLanguage from 'hooks/useLanguage'
import useLocale from 'hooks/useLocale'
import { useStoreUtils } from 'hooks/useStoreUtils'
import { UserTypes } from 'constants/UserTypes'
import { isEmpty } from 'lodash'

function AddDatesByFreelances({
	freelances,
	removeDate,
}) {
	const { state: { user: { type } } } = useStoreUtils()
	const lang = useLanguage('scheduler')
	const locale = useLocale()

	return (
		<List divided className={classes.wrapperList}>
			{Object.entries(freelances).map(([freelanceId, { days, displayName }]) => {
				return Object.entries(days).map(([date, { dates }]) => {
					return (
						<List.Item key={freelanceId + date}>
							<List.Content className={classes.scheduleItem}>
								<div className={classes.header}>
									<Typography variant='text'>
										{displayName}
									</Typography>
									<Typography variant='primaryBody'>
										{dateByDateFormat(date, 'DD/MM/YYYY', locale)}
									</Typography>
								</div>
								<Button type='cancel' onClick={() => removeDate( freelanceId, date )}>
									{lang.remove}
								</Button>
							</List.Content>
						</List.Item>
					)
				})
			})}
		</List>
	)
}

export default AddDatesByFreelances

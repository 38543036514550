import RightSide from 'components/RightSide/RightSide';
import StudioPublicProfile from 'components/StudioPublicProfile';
import TitlePage from 'components/TitlePage';
import { getImageUrl } from 'containers/helpers';
import React, { useEffect, useMemo, useState } from 'react';
import { getStudioDisplayName, getStudioForFreelance } from '../helpers';
import classes from './StudioPage.module.scss'
import useLanguage from 'hooks/useLanguage'
import { upperFirst } from 'lodash';
import StudioReviews from './StudioReviews';

const StudioPublicProfileContainer = ({
    isPage = false,
    userId = false,
}) => {
    const lang = useLanguage('studio')
    const [studio, setStudio] = useState()

    useEffect(() => {
        if (userId) {
            getStudioForFreelance(userId)
                .then(setStudio)
        }
    }, [userId])

    const imageUrl = useMemo(() => studio && studio.image && getImageUrl(studio.image), [studio])
    const displayName = useMemo(() => studio && getStudioDisplayName(studio), [studio])

    const component = useMemo(() => !!studio && <div className={classes.container}>
        <StudioPublicProfile
            img={imageUrl}
            address={studio.address}
            displayName={displayName}
            rating={studio.rating}
            nRatings={studio.nRatings}
        />
        {studio.appointments && <div className={classes.contractContainer}>
            <StudioReviews
                appointments={studio.appointments}
            />
        </div>}
    </div>, [displayName, imageUrl, studio])
    return (
        !!studio ?
            isPage ?
                <RightSide>
                    <TitlePage>
                        {upperFirst(lang.studio)} {displayName}
                    </TitlePage>

                    {component}
                </RightSide>
                : component
            : null
    );
};

export default StudioPublicProfileContainer;
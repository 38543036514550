import classNames from 'classnames';
import Typography from 'components/Typography';
import { useSteps } from 'hooks/useSteps';
import React from 'react';
import classes from './SideBar.module.scss';


const LinkSubStep = ({ step: { key, status, label, done, active } = {} }) => {
	const { goToStep } = useSteps()

    return (
        <div className={classes.relative}>
            <div className={classNames(classes.path, done && classes.done, active && classes.active)} />
            <div className={classNames(
                classes.link,
                classes.subStep,
                done && classes.done,
                active && classes.active
            )}>
                <div className={classes.circleContainer}>
                    {active && <span className={classes.littleCircle} />}
                    <span className={classNames(classes.circle, done && classes.done, active && classes.active)} />
                </div>
                        <Typography 
                        {...(active ? {} : {
                            linkStyle: true,
                            onClick: () => goToStep(status)
                        })}
                        variant='body'
                        >
                            {label}
                        </Typography>
            </div>
        </div>
    );
};

export default LinkSubStep;
import FileTypes from 'constants/FileTypes'
import { createSignatureAPI, getSignatures } from 'containers/contract/helpers'
import { useEffect, useMemo, useRef, useState } from 'react'
import toast from 'components/toast'
import CreateSvgFormDocument from './CreateSvgFormDocument'
import ImageButton from './ImageButton'
import Button from './Button'
import useLanguage from 'hooks/useLanguage'
import classes from 'styles/classes.module.scss'
import classNames from 'classnames'
import Typography from 'components/Typography'

const ManageSignatures = ({
    onSign = () => { },
}) => {
    const [loading, setLoading] = useState(false)
    const lang = useLanguage('contract')
    const ref = useRef()
    const [updating, setUpdating] = useState(false)
    const [signatures, setSignatures] = useState({
        approved: null,
        signature: null,
        initials: null,
    })
    useEffect(() => {
        getSignatures()
            .then(setSignatures)
    }, [])

    const allSignaturesExists = useMemo(() =>
        signatures.approved != null
        && signatures.signature != null
        && signatures.initials != null
        , [signatures])

    const getFileTypeByStepName = (stepname) => {
        switch (stepname) {
            case 'initials':
                return FileTypes.INITIALS
            case 'approved':
                return FileTypes.APPROVED
            case 'signature':
                return FileTypes.SIGNATURE
            default:
                return false
        }
    }
    const steps = useMemo(() => [
        'initials',
        'approved',
        'signature',
    ], [])
    const step = useMemo(() =>
        steps
            .findIndex(stepName => !signatures[stepName]),
        [signatures, steps])

    const createSignature = async (base64Signature) => {
        try {
            const fileTypeName = updating || steps[step]
            const signature = await createSignatureAPI(
                getFileTypeByStepName(fileTypeName),
                base64Signature
            )
            setSignatures(signatures => ({
                ...signatures,
                [fileTypeName]: signature
            }))
            toast.success(`${lang.signature} ${updating ? lang.updated : lang.registered}`)
            return setUpdating(false)
        } catch (error) {
            toast.error(error.message)
        }
    }

    const title = useMemo(() => {
        if (updating) {
            return lang.updateIts[getFileTypeByStepName(updating)]
        }
        return lang.createIts[getFileTypeByStepName(steps[step])]
    }, [updating, steps, step, lang])

    useEffect(() => {
        if (ref && ref.current && ref.current.clear) {
            ref.current.clear()
        }
    }, [updating, step])

    const signContract = async () => {
        setLoading(true)
        try {
            await onSign()
        } catch (error) {
            console.log('error', error)
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className={classNames(classes.taCenter, classes.mb1)}>
            {!allSignaturesExists && <div className={classes.mb1}>
                 <Typography primaryColor>
                {lang.beforeSignSignature}
            </Typography>
            </div>
            }
            <div className={classes.mb1}>
                {step === -1 && !updating ?
                    <Button
                        loading={loading}
                        disabled={step !== -1 || updating}
                        onClick={signContract}>
                        {lang.signContract}
                    </Button>
                    : <CreateSvgFormDocument
                        type={getFileTypeByStepName(updating || steps[step])}
                        ref={ref}
                        title={title}
                        onFinish={createSignature}
                    />}
            </div>
            <div className={classes.mb1}>
                {Object
                    .entries(signatures)
                    .filter(e => !!e && !!e[1])
                    .map(([key, { name }], index) =>
                        <ImageButton
                            hovered={updating === key}
                            onClick={() => setUpdating(key)}
                            key={index}
                            name={name}
                            title={lang[getFileTypeByStepName(key)]}
                        />
                    )
                }
            </div>
            {updating && step !== -1 && <Button
                secondary
                onClick={() => setUpdating(false)}
            >
                {lang.writeNextElem}
            </Button>}
            {updating && step === -1 && <Button
                secondary
                onClick={() => setUpdating(false)}
            >
                {lang.stopEditing}
            </Button>}
        </div>
    )
}

export default ManageSignatures
// import { types as nt } from 'constants/NotificationConstants'
import nt from 'common/features/notification/constants/NotificationTypes'
import { UserTypes } from 'constants/UserTypes'

export const FR = {
	[nt.MISSION_CREATED]: 'Vous avez créé une nouvelle mission',
	[nt.MISSION_PROPOSAL]: '$1 vous a proposé une mission $2',
	[nt.FREELANCE_CONFIRMED]: 'La prestation $1 est confirmée.',
	[nt.STUDIO_CONFIRMED]: 'L\'offre $1 est confirmée.',
	[nt.WAITING_ALL_CONTRACTS]: (type) => type === UserTypes.STUDIO ? 'L\'intervenant pour la mission $1 a requis un pré-paiement avant de la démarrer. Accédez à la mission pour la pré-payer.' : 'Vous avez requis un pré-paiement pour la mission $1, veuillez signer le contrat.',
	[nt.WAITING_FREELANCE_CONTRACT]: 'L\'entreprise $1 a signé un contrat. Signer le votre et commencez la mission $2.',
	[nt.WAITING_STUDIO_CONTRACT]: 'Le freelance $1 a signé un contrat. Signer le votre et commencez la mission $2.',
	[nt.BANK_TRANSFER_SENT]: 'Nous examinons votre virement bancaire relatif au paiement de la mission $1. Nous reviendrons vers vous dès que possible.',
	[nt.WAITING_FUNDS]: 'Ajouter des fonds pour commencer la mission $1',
	[nt.BANK_TRANSFER_RECEIVED]: 'Nous examinons votre virement bancaire relatif au paiement de la mission $1. Nous reviendrons vers vous dès que possible.',
	[nt.CONTRACT_DELETED]: 'Le contrat pour la mission $1 a été supprimé',
	[nt.ISSUE_MISSION]: `Vous avez ouvert une demande concernant la mission $1`,
	[nt.ISSUE]: `Vous avez indique un problème (ref. $1). Nous traitons votre demande au plus vite.`,
	[nt.BOOKED]: 'Votre mission $1 avec $2 est réservée !',
	[nt.COMPLETED_REVIEW]: 'Vous avez laissé un avis pour la mission $1',
	[nt.DECLINE_PROPOSAL]: 'La proposition de la mission $1 a été refusée par $2',
	[nt.WAITING_REVIEW]: 'Laissez un avis dès maintenant.',
	[nt.WAITING_FREELANCE_REVIEW]: 'L\'entreprise $1 a laissé un avis sur la mission $2. Ajoutez votre avis pour débloquer des fonds.',
	[nt.WAITING_STUDIO_REVIEW]: 'Le freelance $1 a laissé un avis sur la mission $2. Ajoutez le vôtre pour terminer la mission',
	[nt.REVIEWED]: 'Les avis pour la mission $1 sont prêts!',
	[nt.FUNDS_UNLOCKED]: 'Les fonds pour votre mission $1 sont débloqués',
	[nt.ALLOW_SCHEDULE_MEETING]: 'Créer un meeting pour votre demande $1',
	[nt.WAITING_STUDIO_DAYS_CONFIRMATION]: "La mission $1 est terminée, confirmez la présence du professionnel sur les journées prévues.",
	[nt.WAITING_FREELANCE_DAYS_CONFIRMATION]: "La mission $1 est terminée, veuillez confirmer les dates effectuées pour la finaliser et recevoir vos fonds.",
	[nt.SEND_USER_MEETING_INFORMATION]: `Votre réunion démarre bientôt. Cliquez ici pour la rejoindre.`,
	[nt.WAITING_ADMIN_REVIEW_DAYS_CONFIRMATION]: "La confirmation de vos dates pour la mission $1 sont en cours de validation par nos équipes.",
	[nt.CONTRACT_UPDATED]: "Un nouveau contrat a été édité pour la mission $1 avec les dates confirmées lors de la finalisation de la mission. Signez le pour valider la fin de mission.",
	[nt.CANCELLED]: "Votre contrat de la mission $1 a bien été annulé.",
	[nt.NEW_REVIEW_STUDIO_CONTRACT_CONFIRMATIONS]: "Veuillez à nouveau confirmer les dates de la mission $1.",
	[nt.ASK_TO_ENTER_BANK_ACCOUNT]: "Veuillez entrer vos informations bancaires afin de recevoir vos fonds.",
	[nt.CONTRACT_FINISHED]: "La mission $1 est terminée. Vous receverez bientot votre rémunération.",
	[nt.CANCEL_PREPAYMENT]: "Vous n'avez plus besoin de payer la mission en avance car le freelance a annulé le prépaiement.",

	privateConv: 'votre conversation privée',
	read: 'Afficher les notifications lues',
	markAllAsRead: 'Marquer toutes les notifications comme lues',

	title: 'Mes notifications',
}

export const EN = {
	[nt.MISSION_CREATED]: 'You have created a new mission',
	[nt.MISSION_PROPOSAL]: '$1 sent you a proposal for mission $2',
	[nt.FREELANCE_CONFIRMED]: 'Mission $1 is confirmed.',
	[nt.STUDIO_CONFIRMED]: 'Mission $1 is confirmed',
	[nt.WAITING_ALL_CONTRACTS]: (type) => type === UserTypes.STUDIO ? `The participant for mission $1 required pre-payment before starting it. Go to the mission to pre-pay for it.` : `You have required pre-payment for mission $1. Please sign the contract.`,
	[nt.WAITING_FREELANCE_CONTRACT]: 'Studio $1 signed a contract. Sign yours to start mission $2.',
	[nt.WAITING_STUDIO_CONTRACT]: 'Freelancer $1 signed a contract. Sign yours to start mission $2.',
	[nt.WAITING_FUNDS]: 'Add funds to begin mission $1',
	[nt.BANK_TRANSFER_SENT]: 'We are reviewing your wire transfer for a $1 mission contract. We will be back as soon as possible',
	[nt.BANK_TRANSFER_RECEIVED]: `We're reviewing your bank transfer for a contract in mission $1. We'll get back as soon as possible.`,
	[nt.CONTRACT_DELETED]: 'The contrat for the mission $1 was deleted',
	[nt.BOOKED]: 'Your mission $1 with $2 is booked!',
	[nt.ISSUE_MISSION]: `You have opened an issue for the mission $1`,
	[nt.ISSUE]: `You've opened an issue (ref $1). We're treating your concern as quickly as possible`,
	[nt.COMPLETED_REVIEW]: 'You left a review for mission $1',
	[nt.WAITING_REVIEW]: 'Mission $1 is ready to be reviewed.',
	[nt.DECLINE_PROPOSAL]: 'The proposal for mission $1 was declined by $2',
	[nt.WAITING_FREELANCE_REVIEW]: 'Studio $1 has reviewed the mission $2. Add your review and unlock funds.',
	[nt.WAITING_STUDIO_REVIEW]: 'Freelancer $1 has reviewed the mission $2. Add yours to end the mission',
	[nt.REVIEWED]: 'Reviews for mission $1 are in!',
	[nt.FUNDS_UNLOCKED]: 'Funds unlocked for your mission $1',
	[nt.ALLOW_SCHEDULE_MEETING]: 'Create a meeting for your issue $1',
	[nt.WAITING_STUDIO_DAYS_CONFIRMATION]: "The $1 mission is over, please confirm the dates made to finalize it.",
	[nt.WAITING_FREELANCE_DAYS_CONFIRMATION]: "The $1 mission is complete, please confirm the dates made to finalize it and receive your funds.",
	[nt.WAITING_ADMIN_REVIEW_DAYS_CONFIRMATION]: "Your confirmation of dates for the $1 mission are being reviewed by our teams.",
	[nt.CONTRACT_UPDATED]: "A new contract has been issued for the $1 mission with the dates confirmed at the end of the mission. Please sign it to validate the end of the mission.",
	[nt.CANCELLED]: "Your $1 mission contract has been cancelled.",
	[nt.NEW_REVIEW_STUDIO_CONTRACT_CONFIRMATIONS]: "Please review again mission $1 dates in mission page.",
	[nt.ASK_TO_ENTER_BANK_ACCOUNT]: "Please enter your bank account informations to send your funds.",
	[nt.CONTRACT_FINISHED]: "Your mission $1 is finished. Your funds are on the way.",
	[nt.SEND_USER_MEETING_INFORMATION]: `You're meeting is beginning soon. Please click here to join. If we don't see you in the meeting room after a few minutes, we'll call you buy phone.`,
	[nt.CANCEL_PREPAYMENT]: "You no longer need to pay for the job in advance, as the freelance has cancelled the prepayment.",

	privateConv: 'your private discussion',
	read: 'To display read notifications',
	markAllAsRead: 'Mark all notifications as read',
}

export default {
	fr: FR,
	us: EN,
}

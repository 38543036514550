import { fetchApi } from '../helpers'
import {
	FreelanceOnboardingStatuses,
	StudioOnboardingStatuses,
} from './constants/OnboardingStatuses'
import { UserTypes } from 'constants/UserTypes'

export const isUnsubscribedUser = ({ status, type }) => {
	return type === UserTypes.STUDIO && status === StudioOnboardingStatuses.WAITING_SUBSCRIPTION
}
export const isOnboardedUser = ({ status, type }) => {
	if (type === UserTypes.STUDIO) {
		return status === StudioOnboardingStatuses.ACTIVE
	} else if (type === UserTypes.FREELANCE) {
		return status === FreelanceOnboardingStatuses.ACTIVE
	}
	return false
}

export const updateStudio = (
	body,
	studioId
) =>
	fetchApi(`/studio/${studioId}`, {
		method: 'PATCH',
		body,
		multiform: true,
	})

export const updateFreelance = (
	body,
	freelanceId
) =>
	fetchApi(`/freelance/${freelanceId}`, {
		method: 'PATCH',
		body,
		multiform: true,
	})

export const getBrands = () => fetchApi('/brands')

export const updateUserStatusAPI = (userId, status) => fetchApi(`/user/onboardStatus`, {
	method: 'PATCH',
	body: {
		status,
		userId
	}
})

export const updateUserHourlyRateApi = (userId, hourlyRate) => fetchApi(`/freelance/hourlyRate/${userId}`, {
	method: 'PATCH',
	body: {
		hourlyRate
	}
})

export const getBankAccount = () => fetchApi('/user/bankAccount')
export const createBankAccount = (body) => fetchApi('/bankAccount', { body })
export const getHourlyRateApi = (userId) => fetchApi(`/freelance/hourlyRate/${userId}`)
export const getDailyRateStatisticsApi = () => fetchApi('/statistics/dailyRate')
export const getProfessions = () => fetchApi('/professions')
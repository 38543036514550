import StyledModal from 'components/StyledModal';
import { Modal } from 'semantic-ui-react';
import Button from 'components/Button';
import classes from './BinaryModal.module.scss';
import Spacer from 'components/Spacer';

function BinaryModal({
	open,
	header,
	description,
	onCancel,
	onAction,
	cancelText,
	actionText,
	lottie = null,
	textCenter = true,
	loading = false,
	extraButtonAction = null,
	extraButtonText = null,
}) {
	return (
		<StyledModal open={open} closeOnEscape={true} closeOnDimmerClick={true} onClose={onCancel} title={header}>
			<Modal.Content>
				{!!lottie && (
					<>
						{lottie} <Spacer />
					</>
				)}
				<Modal.Description className={textCenter ? classes.taCenter : ''}>{description}</Modal.Description>
				<Spacer />
			</Modal.Content>
			<Modal.Actions className={classes.modalActions}>
				<Button type="cancelLight" onClick={onCancel} loading={loading}>
					{cancelText}
				</Button>
				{extraButtonAction && (
					<Button type="danger" onClick={extraButtonAction} loading={loading}>
						{extraButtonText}
					</Button>
				)}
				<Button type="primary" onClick={onAction} loading={loading}>
					{actionText}
				</Button>
			</Modal.Actions>
		</StyledModal>
	);
}

export default BinaryModal;

import React, { useMemo } from 'react';
import { Link } from 'react-router-dom'
import useLanguage from 'hooks/useLanguage';
import { types } from 'containers/issue/issue.enum';
import { issueTypeName } from 'containers/issue/helpers';
import valueToKey from 'util/data/valueToKey';
import { Tooltip } from '@material-ui/core';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import Button from './Button/Button';

const IssueLinkButton = ({
    type,
    elementId = null,
    isButton = false,
    containerClassname = '',
    textButton = null
}) => {
    const lang = useLanguage('issue')
    const typeName = useMemo(() => {
        switch (type) {
            case types.UPDATE_LEVEL:
                return valueToKey({ object: issueTypeName, value: types.UPDATE_LEVEL })
            case types.CONTRACT:
                return valueToKey({ object: issueTypeName, value: types.CONTRACT })
            case types.MISSION:
                return valueToKey({ object: issueTypeName, value: types.MISSION })
            case types.ONBOARD:
                return valueToKey({ object: issueTypeName, value: types.ONBOARD })
            case types.OTHER:
                return valueToKey({ object: issueTypeName, value: types.OTHER })
            default:
                return valueToKey({ object: issueTypeName, value: types.OTHER })
        }
    }, [type])
    return (isButton ?
        <Button
            className={containerClassname}
            type='secondary'
            as={Link}
            to={`/issue/create/${typeName}${elementId ? `/${elementId}` : ''}`}
        >
            {textButton || lang.reportAnIssue}
        </Button> :
        <Tooltip title={lang.reportAnIssue}>
            <Link
                className={containerClassname}
                to={`/issue/create/${typeName}${elementId ? `/${elementId}` : ''}`}>
                <QuestionMarkIcon />
            </Link>
        </Tooltip>
    );
};

export default IssueLinkButton;
import { UserTypes } from 'constants/UserTypes';
import React, { useContext } from 'react';
import { Store } from 'store/store';
import FreelanceInfoForm from './FreelanceInfoForm';
import StudioInfoForm from './StudioInfoForm';

const InfosForm = props => {
    const {state: {user: {type}}} = useContext(Store)

    return type === UserTypes.FREELANCE ? <FreelanceInfoForm {...props} /> : <StudioInfoForm {...props} />
};

export default InfosForm;
import React, { useMemo } from 'react';
import StatusGodson from './StatusGodson';
import { toDateInput } from 'modules/dates';
import MyTable from 'components/MyTable/MyTable';
import Typography from 'components/Typography';
import useLanguage from 'hooks/useLanguage';

const GodsonsTable = ({ godsons = [] }) => {
    const lang = useLanguage('godson')

    const columns = useMemo(() => [
        {
            title: lang.status, render: ({ status }) => <StatusGodson status={status} />,
        },
        {
            title: lang.createdAt, render: ({ created_at }) => toDateInput(created_at),
        },
    ], [lang])

    return (
        <div >
            <Typography variant='subTitle2' primaryColor>{lang.arrayOf}</Typography>
            <MyTable
                columns={columns}
                data={godsons}
                options={{ paging: false }}
            />
        </div>
    );
};

export default GodsonsTable;
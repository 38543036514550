import { useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { parseKeys } from '../helpers'
import { Store } from 'store/store'
import { IconButton } from '@material-ui/core'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import useNotification from 'hooks/useNotification'
import { isNull } from 'containers/helpers'
import classes from './Notification.module.scss'
import Typography from 'components/Typography'
import { isEmpty } from 'lodash'
import classNames from 'classnames'

export const NotificationComponent = ({
	keys: keysToParse = [],
	link,
	type,
	onClick = () => { },
	persistant = false,
	id,
	read,
	tryBrowser = false,
	inProgress = false,
	...props
}) => {
	const [ignore, setIgnore] = useState(false)
	const { readNotification } = useNotification()
	const navigate = useNavigate()
	const {
		state: {
			user: { type: userType },
			language: {
				notification: {
					privateConv: privateConvLang,
					...notificationLang
				},
			},
		},
	} = useContext(Store)

	if (!id && !link) return null

	const keys = String(isNull(keysToParse) ? '' : keysToParse).split(',')
	const message = notificationLang[type] ?? 'MISSION_CREATED'
	const messageToParse = parseKeys(message, keys, type, privateConvLang, userType)

	const handleClick = () => {
		readNotification(id)

		if (link.startsWith('http')) {
			window.open(link)
		} else {
			navigate(link)
		}
	}

	const endPointGesture = (textFromParams, index, fullArrLength) => {
		const isLastIndex = index === fullArrLength - 1
		const text = isLastIndex && !textFromParams.match(/[.!?]$/)
			? textFromParams + '.' : textFromParams
		const addBrAfterPointWithoutLastOne = (text) => {
			const arr = text
				.split(/([.!?])/g)
				.filter(e => e !== '')

			if (arr.length === 1 || (arr.length === 2 && isLastIndex)) {
				return text
			}

			return arr
				.reduce((prev, actual, index) => {
					return prev + actual + (
						(actual.match(/[.!?]$/) && !(isLastIndex && index === arr.length - 1))
							? '<br/>' : '')
				}
					, '')



		}
		const textToDisplay = addBrAfterPointWithoutLastOne(text)
		return textToDisplay
	}
	return (
		!ignore && (
			<div className={classes.relative}>
				<IconButton
					className={classes.removeIconContainer}
					onClick={() => readNotification(id)}
				>
					<HighlightOffIcon />
				</IconButton>
				<div
					onClick={handleClick}
					className={classNames(
						classes.root,
						classes.cardShadow,
						classes.pointer,
						read && classes.read
					)}
				>

					{messageToParse.map(
						(
							{
								type,
								message,
								keyType: { type: keyType, operator = '' } = {},
							} = {},
							key
						) =>
							<Typography
								key={key}
								classes={{ root: classes[keyType] || '' }}
								fontWeight={isEmpty(operator) ? 400 : 600}
								html={endPointGesture(operator + message, key, messageToParse.length)}
							/>
					)}
				</div>
			</div>
		)
	)
}

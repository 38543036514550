import classNames from 'classnames';
import Button from 'components/Button';
import PdfViewer from 'components/PdfViewer';
import { getFile, getFileByNameVisualisationUrl } from 'containers/contract/helpers';
import { getToken } from 'containers/helpers';
import useLanguage from 'hooks/useLanguage';
import React, { useEffect, useState } from 'react';
import classes from './InvoiceProFormat.module.scss'

const InvoiceProFormat = ({
    invoicePF,
    className
}) => {
    const lang = useLanguage('pay')
    const [isVisible, setIsVisible] = useState(false)
    const [invoicePFUrl, setInvoicePFUrl] = useState()

    useEffect(() => {
        getFile(invoicePF)
            .then(({ name }) => setInvoicePFUrl(getFileByNameVisualisationUrl(getToken(), name)))

    }, [invoicePF])
    return (
        <div className={classNames(classes.flex, classes.columnDirection, className || '')}>
            <Button
                type='black'
                onClick={() => setIsVisible(!isVisible)}
            >
                {isVisible ? lang.unseeInvoiceProFormat : lang.seeInvoiceProFormat}
            </Button>
            {isVisible && <PdfViewer file={invoicePFUrl} />}
        </div>
    );
};

export default InvoiceProFormat;
export const EN = {
	error: 'Error',
	title:                'Confirmation mail',
	mail:                 'Email address',
	submit:               'Resend confirmation mail',
	titleModal:           'Account confirmation',
	accountValidated:     'Your account is validated, you will be redirected in 5s or you can click on the button below',
	buttonToLogin:        'Go to the login page',
	closeModal:           'Close',
	modalTitle:           'Account confirmation',
	errorConfirm:         'This is not a valid account, or was previously updated',
	mailConfirmationText: 'Confirmation email sent.',
  }
  
  export const FR = {
	error: 'Erreur',
	title:                'Mail de confirmation',
	mail:                 'Adresse mail',
	submit:               'Renvoyer un mail de confirmation',
	titleModal:           'Confirmation de compte',
	modalTitle:           'Confirmation de compte',
	buttonToLogin:        'Aller sur la page de connexion',
	closeModal:           'Fermer',
	errorConfirm:         'Ce n\'est pas un compte valide ou il a été précédemment mis à jour',
	mailConfirmationText: 'Mail de confirmation envoyé.',
	accountValidated:     'Votre compte est validé, vous allez être redirigé dans 5s, sinon cliquez sur le bouton ci-dessous',
  
  }
  
  export default {
	fr: FR,
	us: EN,
  }
  
import BinaryModal from 'components/BinaryModal'
import { useMemo } from 'react'
import AddDates from 'components/AddDates'
import { hasWeekends, onlyWeekends } from 'modules/dates'
import useLanguage from 'hooks/useLanguage'

function ConfirmAddDates({
	open,
	days,
	filterWeekends,
	toggleFilterWeekends,
	removeDate,
	onSubmit,
	onCancel,
	actionTextFromParams,
	cancelTextFromParams,
	headerTextFromParams,
	updateDaysStatus,
	updateFavoriteDayStatus,
	chooseOnly = false,
	loading = false,
	showDayStatus = false,
	showFavoriteDayStatus = false,
	deleteAllDays = null
}) {
	const lang = useLanguage('scheduler').confirmAddDates
	const actionText = useMemo(() => actionTextFromParams || lang.actionText, [lang, actionTextFromParams])
	const cancelText = useMemo(() => cancelTextFromParams || lang.cancelText, [lang, cancelTextFromParams])
	const headerText = useMemo(() => headerTextFromParams || lang.headerText, [lang, headerTextFromParams])
	const sortedDateKeys = useMemo(() => Object.keys(days).sort(), [days])
	const isOnlyWeekends = onlyWeekends(sortedDateKeys)
	const includesWeekends = hasWeekends({
		start: sortedDateKeys[0],
		end: sortedDateKeys[sortedDateKeys.length - 1],
	})
	return (
		<BinaryModal
			loading={loading}
			open={open}
			header={headerText}
			description={
				<AddDates
					isOnlyWeekends={isOnlyWeekends}
					includesWeekends={includesWeekends}
					days={days}
					filterWeekends={filterWeekends}
					toggleFilterWeekends={toggleFilterWeekends}
					removeDate={removeDate}
					chooseOnly={chooseOnly}
					showDayStatus={showDayStatus}
					showFavoriteDayStatus={showFavoriteDayStatus}
					updateDaysStatus={updateDaysStatus}
					updateFavoriteDayStatus={updateFavoriteDayStatus}
				/>
			}
			onClose={onCancel}
			cancelText={cancelText}
			actionText={actionText}
			onAction={onSubmit}
			onCancel={onCancel}
			extraButtonAction={deleteAllDays}
			extraButtonText={lang.deleteAllDates}
		/>
	)
}

export default ConfirmAddDates

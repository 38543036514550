import Loader from 'components/Loader';
import PdfViewer from 'components/PdfViewer';
import { getFile, getFileByNameVisualisationUrl } from 'containers/contract/helpers';
import { getToken } from 'containers/helpers';
import React, { useEffect, useState } from 'react';
import { getInvoice } from './helpers';
import classes from 'styles/classes.module.scss'

const ContractInvoice = ({
    invoiceId,
    className
}) => {
    const [invoiceUrl, setInvoiceUrl] = useState()
    const getStudioInvoice = async (invoice) => {
        const studioInvoice = await getInvoice(invoice)
        if (studioInvoice) {
            const studioInvoiceFile = await getFile(studioInvoice.invoice)
            if (studioInvoiceFile) {
                setInvoiceUrl(getFileByNameVisualisationUrl(getToken(), studioInvoiceFile.name))
            }
        }
    }
    useEffect(() => {
        if (invoiceId) {
            getStudioInvoice(invoiceId)
        }

    }, [invoiceId])
    return (
        <div className={classes.taCenter}>
            {invoiceUrl ? <PdfViewer file={invoiceUrl} /> : <Loader />}
        </div>
        
    );
};

export default ContractInvoice;
import { dateByDateFormat } from 'modules/dates';
import { Checkbox, List, ListItem } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import useLocale from 'hooks/useLocale';
import DaysStatus from './AddDates/DaysStatus';
import classes from 'styles/classes.module.scss';
import Typography from './Typography/Typography';
import classNames from 'classnames';

const CheckDates = ({ days, setCheckedDays, checkedDays = {}, defaultDaysStatuses }) => {
	const locale = useLocale();
	const [currentDays, setCurrentDays] = useState({});

	useEffect(() => {
		setCurrentDays(
			Object.entries(days).reduce((prev, [date, { dayStatus }]) => {
				prev[date] = {
					dayStatus: checkedDays[date]?.dayStatus || 'none',
					checked: checkedDays[date]?.checked || false,
				};
				return prev;
			}, {}),
		);
	}, [days, checkedDays]);

	const onClick = useCallback(
		(date) => {
			const nextCheckDays = { ...currentDays };

			nextCheckDays[date] = {
				...nextCheckDays[date],
				checked: !nextCheckDays[date].checked,
				dayStatus: !!nextCheckDays[date].checked ? 'none' : defaultDaysStatuses[date],
			};

			Object.entries(nextCheckDays).forEach(([date, day]) => {
				if (!day.checked) delete nextCheckDays[date];
			});
			setCheckedDays(nextCheckDays);
		},
		[currentDays, setCheckedDays, defaultDaysStatuses],
	);

	const updateDayStatus = useCallback(
		(date, dayStatus) => {
			if (dayStatus === 'none') {
				const nextCheckDays = { ...currentDays };
				nextCheckDays[date] = {
					...nextCheckDays[date],
					checked: false,
				};
				Object.entries(nextCheckDays).forEach(([date, day]) => {
					if (!day.checked) delete nextCheckDays[date];
				});

				return setCheckedDays(nextCheckDays);
			}
			const nextCurrentDays = { ...checkedDays };

			nextCurrentDays[date] = { ...nextCurrentDays[date], checked: true, dayStatus };

			setCheckedDays(nextCurrentDays);
		},
		[currentDays, checkedDays, setCheckedDays],
	);

	return (
		<List dense sx={{ width: '100%' }}>
			{Object.entries(currentDays).map(([date, { dayStatus, checked }], index) => {
				const labelId = `checkbox-list-secondary-label-${index}`;
				return (
					<ListItem key={date} className={classNames(classes.flex, classes.flexWrap)}>
						<Typography>{dateByDateFormat(date, 'DD/MM/YYYY', locale)}</Typography>
						<DaysStatus
							date={date}
							hidden={!(days[date].dayStatus === 'day')}
							update={(dayStatus) => updateDayStatus(date, dayStatus)}
							dayStatus={dayStatus}
							defaultDayStatus={defaultDaysStatuses[date]}
							noneDaysStatus={true}
						/>
						<Checkbox
							edge="end"
							onChange={() => onClick(date)}
							checked={checked}
							inputProps={{ 'aria-labelledby': labelId }}
						/>
					</ListItem>
				);
			})}
		</List>
	);
};

export default CheckDates;

import Button from 'components/Button'
import { useContext } from 'react'
import { Store } from 'store/store'
import signUpModes from '../constants/signUpModes'

function ChooseUserTypeForm({ onClick }) {

	const { state: { language: { login:
		{
			chooseUserTypeFreelance: chooseUserTypeFreelanceLang,
			chooseUserTypeStudio: chooseUserStudioLang,
		},
	},
	}
	} = useContext(Store)
	return (
		<div>
			<div style={{ width: '100%' }}>
				<Button style={{ width: '100%' }} type='secondary' onClick={() => onClick(signUpModes.freelance)}>
					{chooseUserTypeFreelanceLang}
				</Button>
			</div>
			<div style={{ marginTop: '1rem', width: '100%' }}>
				<Button style={{ width: '100%' }} onClick={() => onClick(signUpModes.studio)}>
					{chooseUserStudioLang}
				</Button>
			</div>
		</div>
	)
}

export default ChooseUserTypeForm
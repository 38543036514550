import { fetchApi } from 'containers/helpers';

export const subscribeApi = (paymentMethodId, priceId) =>
	fetchApi('/stripe/subscription/payment', {
		method: 'PATCH',
		body: {
			paymentMethodId,
			priceId,
		},
	});

export const getPriceOfSubscriptionApi = (priceId) => fetchApi(`/stripe/subscription/${priceId}`);

import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import useLanguage from 'hooks/useLanguage'
import { createIssue, getIssueTypes, issueTypeName } from 'containers/issue/helpers'
import { useNavigate, useParams } from 'react-router-dom'
import toast from 'components/toast'
import { Store } from 'store/store'
import RightSide from 'components/RightSide/RightSide'
import CreateLevelUpIssue from './CreateLevelUpIssue'
import CreateContractIssue from './CreateContractIssue'
import CreateMissionIssue from './CreateMissionIssue'
import CreateOnboardIssue from './CreateOnboardIssue'
import CreateGeneralIssue from './CreateGeneralIssue'
import TitlePage from 'components/TitlePage'

function CreateIssue() {
	const { issueType: issueTypeFromParams, elemId: elemIdFromParams } = useParams()
	const { state: { user } } = useContext(Store)
	const navigate = useNavigate()
	const issueTypes = useMemo(() => getIssueTypes(user.type), [user])
	const [issueType, setIssueType] = useState(issueTypeName[issueTypeFromParams])
	useEffect(() => {
		if (issueTypeFromParams && issueTypeName[issueTypeFromParams]) {
			setIssueType(issueTypeName[issueTypeFromParams])
		}
	}, [issueTypeFromParams])
	const lang = useLanguage('issue.create')

	const onSubmit = useCallback(async (values) => {
		try {
			await createIssue(values)
			toast.success(lang.success)
			navigate('/issues')
		} catch (error) {
			toast.error(lang.failure)

		}
	}, [lang, navigate])

	const component = useMemo(() => {
		const props = { onSubmit, lang }
		switch (issueType) {
			case issueTypes.MISSION:
				return <CreateMissionIssue {...props} missionId={Number(elemIdFromParams)} issueTypes={issueTypes} />
			case issueTypes.CONTRACT:
				return <CreateContractIssue {...props} contractId={Number(elemIdFromParams)} issueType={issueTypes.CONTRACT} />
			case issueTypes.UPDATE_LEVEL:
				return <CreateLevelUpIssue {...props} issueType={issueTypes.UPDATE_LEVEL} />
			case issueTypes.ONBOARD:
				return <CreateOnboardIssue {...props} stepId={Number(elemIdFromParams)} issueType={issueTypes.ONBOARD} />
			case issueTypes.OTHER:
				return <CreateGeneralIssue {...props} issueType={issueTypes.OTHER} />
			default:
				return <CreateGeneralIssue {...props} issueType={issueTypes.OTHER} />
		}
	}, [elemIdFromParams, issueType, issueTypes, lang, onSubmit])

	return (
		<RightSide>
			<TitlePage>{lang.title}</TitlePage>
			{component}
		</RightSide>
	)
}

export default CreateIssue

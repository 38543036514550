import StudioCalendar from '../components/StudioCalendar'
import RightSide from 'components/RightSide/RightSide'
import TitlePage from 'components/TitlePage'
import { UserTypes } from 'constants/UserTypes'
import useLanguage from 'hooks/useLanguage'
import { useContext } from 'react'
import { Store } from 'store/store'
import Button from 'components/Button'
import SlotsFreelanceCalendar from '../components/SlotsFreelanceCalendar'

const CalendarPage = () => {
	const lang = useLanguage('mission')
	const { state: { user: { type } } } = useContext(Store)

	return (
		<RightSide>
			<TitlePage
				subtitle={
					type === UserTypes.STUDIO
						? <Button link={'/mission/create/0'}>{lang.createMission}</Button>
						: null
				}
			>{lang.myCalendar}</TitlePage>
			{type === UserTypes.FREELANCE ? (
				<SlotsFreelanceCalendar />
			) : (
				<StudioCalendar />
			)}
		</RightSide>
	)
}

export default CalendarPage

import { IconButton, Tooltip } from '@material-ui/core';
import { intensityTypes } from 'constants/badgeIntensity.constant';
import { getImageUrl } from 'containers/helpers';
import React, { useContext, useMemo } from 'react';
import { Store } from 'store/store';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import classes from './BadgeComponent.module.scss'
const BadgeComponent = ({
    intensity,
    name,
    increment = null,
    decrement = null,
}) => {
    const { state: { badges } } = useContext(Store)
    const src = useMemo(() => Object.keys(badges).length > 0 && getImageUrl(badges[name][intensity]), [badges, name, intensity])
    return (
        src && <div>
            <Tooltip title={name + ' - ' + Object.keys(intensityTypes)[intensity]}>
                    <img
                    className={classes.imageAligned}
                        src={src}
                        alt={"Badge " + name}
                        style={{ width: 50, height: 50 }}
                    />
            </Tooltip>
            <div>
                {!!decrement
                    && <Tooltip title={intensity === 0 ? "Supprimer le badge" : "Réduire le badge d'une intensité"}>
                        <IconButton onClick={decrement}>
                            <RemoveCircleIcon {...(intensity === 0 ? { className: classes.remove } : {})} />
                        </IconButton></Tooltip>}
                {!!increment
                    && Object.keys(intensityTypes).length !== intensity + 1
                    && <Tooltip title="Augmenter le badge d'une intensité">
                        <IconButton onClick={increment}><AddCircleIcon /></IconButton>
                    </Tooltip>}
            </div>
        </div>
    );
};

export default BadgeComponent;
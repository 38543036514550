export const onboardingStatuses = {
	WAITING_EMAIL_VALIDATION: 0,
	WAITING_FISCAL: 1,
	WAITING_STRIPE: 2,
	WAITING_INFORMATIONS: 3,
	WAITING_ADDRESS: 4,
	WAITING_MEETING_PLAN: 5,
	WAITING_FISCAL_REVIEW: 6,
	WAITING_MEETING: 7,
	WAITING_ONBOARD_VALIDATION: 8,
	ACTIVE: 9,
	WAITING_SUBSCRIPTION: 10,
};

export const StudioOnboardingStatuses = {
	WAITING_EMAIL_VALIDATION: onboardingStatuses.WAITING_EMAIL_VALIDATION,
	WAITING_FISCAL: onboardingStatuses.WAITING_FISCAL,
	WAITING_INFORMATIONS: onboardingStatuses.WAITING_INFORMATIONS,
	WAITING_ADDRESS: onboardingStatuses.WAITING_ADDRESS,
	WAITING_FISCAL_REVIEW: onboardingStatuses.WAITING_FISCAL_REVIEW,
	ACTIVE: onboardingStatuses.ACTIVE,
	WAITING_SUBSCRIPTION: onboardingStatuses.WAITING_SUBSCRIPTION,
};

export const FreelanceOnboardingStatuses = {
	WAITING_EMAIL_VALIDATION: onboardingStatuses.WAITING_EMAIL_VALIDATION,
	WAITING_FISCAL: onboardingStatuses.WAITING_FISCAL,
	WAITING_STRIPE: onboardingStatuses.WAITING_STRIPE,
	WAITING_INFORMATIONS: onboardingStatuses.WAITING_INFORMATIONS,
	WAITING_ADDRESS: onboardingStatuses.WAITING_ADDRESS,
	WAITING_MEETING_PLAN: onboardingStatuses.WAITING_MEETING_PLAN,
	WAITING_MEETING: onboardingStatuses.WAITING_MEETING,
	WAITING_ONBOARD_VALIDATION: onboardingStatuses.WAITING_ONBOARD_VALIDATION,
	ACTIVE: onboardingStatuses.ACTIVE,
};

/* eslint-disable import/no-anonymous-default-export */
import confirmAccountLanguage from './confirmAccount.language';
import datesFormLanguage from './datesForm.language';
import reviewConfirmedDatesLanguage from './reviewConfirmedDates.language';
import formLanguage from './form.language';
import headerLanguage from './header.language';
import logInLanguage from './logIn.language';
import passwordLanguage from './password.language';
import profileLanguage from './profile.language';
import rightSideLanguage from './rightSide.language';
import titleLanguage from './title.language';
import sideBarLanguage from './sideBar.language';
import forgotPasswordLanguage from './forgotPassword.language';
import onboardLanguage from './onboard.language';
import subscriptionPaymentLanguage from './subscriptionPayment.language';
import starterPayLanguage from './starterPay.language';
import bookkeepingLanguage from './bookkeeping.language';
import notificationLanguage from './notification.language';
import reviewLanguage from './review.language';
import faqLanguage from './faq.language';
import issueLanguage from './issue.language';
import addressFormLanguage from './addressForm.language';
import godsonLanguage from './godson.language';
import cookieLanguage from './cookie.language';
import meetingLanguage from './meeting.language';
import missionLanguage from './mission.language';
import dayStatusesLanguage from './dayStatuses.language';
import contractLanguage from './contract.language';
import contractDatesLanguage from './contractDates.language';
import marketPlace from './marketPlace.language';
import toastLanguage from './toast.language';
import daysCountReductionLanguage from './daysCountReduction.language';
import settingsLanguage from './settings.language';
import freelanceSelectedLanguage from './freelanceSelected.language';
import schedulerLanguage from './scheduler.language';
import calendarLanguage from './calendar.language';
import payLanguage from './pay.language';
import createMissionLanguage from './createMission.language';
import studioLanguage from './studio.language';
import didactielLanguage from './didactiel.language';
import freelanceLanguage from './freelance.language';
import tableLanguage from './table.language';
import dateLanguage from './date.language';
import invoiceLanguage from './invoice.language';
import prepaymentLanguage from './prepayment.language';
import servicesLanguage from './services.language';
import weeklyAvailabilityLanguage from './weeklyAvailability.language';
import appointmentLanguage from './appointment.language';
import premiumLanguage from './premium.language';

export const getLanguage = (value = 'fr') => ({
	login: logInLanguage[value],
	title: titleLanguage[value],
	confirmAccount: confirmAccountLanguage[value],
	form: formLanguage[value],
	password: passwordLanguage[value],
	profile: profileLanguage[value],
	rightSide: rightSideLanguage[value],
	header: headerLanguage[value],
	forgotPassword: forgotPasswordLanguage[value],
	onboard: onboardLanguage[value],
	subscriptionPayment: subscriptionPaymentLanguage[value],
	starterPay: starterPayLanguage[value],
	bookkeeping: bookkeepingLanguage[value],
	notification: notificationLanguage[value],
	review: reviewLanguage[value],
	sideBar: sideBarLanguage[value],
	faq: faqLanguage[value],
	issue: issueLanguage[value],
	addressForm: addressFormLanguage[value],
	godson: godsonLanguage[value],
	cookie: cookieLanguage[value],
	meeting: meetingLanguage[value],
	mission: missionLanguage[value],
	dayStatuses: dayStatusesLanguage[value],
	contract: contractLanguage[value],
	contractDates: contractDatesLanguage[value],
	marketPlace: marketPlace[value],
	toast: toastLanguage[value],
	datesForm: datesFormLanguage[value],
	daysCountReduction: daysCountReductionLanguage[value],
	settings: settingsLanguage[value],
	freelanceSelected: freelanceSelectedLanguage[value],
	scheduler: schedulerLanguage[value],
	calendar: calendarLanguage[value],
	pay: payLanguage[value],
	reviewConfirmedDates: reviewConfirmedDatesLanguage[value],
	createMission: createMissionLanguage[value],
	studio: studioLanguage[value],
	didactiel: didactielLanguage[value],
	freelance: freelanceLanguage[value],
	table: tableLanguage[value],
	date: dateLanguage[value],
	invoice: invoiceLanguage[value],
	prepayment: prepaymentLanguage[value],
	services: servicesLanguage[value],
	weeklyAvailability: weeklyAvailabilityLanguage[value],
	appointment: appointmentLanguage[value],
	premium: premiumLanguage[value],
});

export default {
	fr: getLanguage(),
	us: getLanguage('us'),
};

import useLanguage from 'hooks/useLanguage'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Store } from 'store/store'
import { useStoreUtils } from 'hooks/useStoreUtils'
import BinaryModal from 'components/BinaryModal'
import Typography from 'components/Typography'
import { isEmpty } from 'lodash'

const Didactiel = ({
    didactielType: didactielTypeFromParams,
    isMobile: isMobileDidactiel,
    lottie = null
}) => {
    const didactielType = useMemo(() => didactielTypeFromParams + (
        isMobileDidactiel
            ? "_mobile"
            : ""
    ),
        [didactielTypeFromParams, isMobileDidactiel]
    )
    const didactielLang = useLanguage('didactiel')
    const { state: { user: { type }, didactiels } } = useContext(Store)
    const lang = useMemo(() => didactielLang[type][didactielType], [type, didactielLang, didactielType])
    const [activeDidactiel, setActiveDidactiel] = useState(false)
    const { updateDidactielStatus } = useStoreUtils()
    const removeDidactiel = useCallback(() => {
        updateDidactielStatus(didactielType)
    }, [updateDidactielStatus, didactielType])

    const closeDidactiel = () => setActiveDidactiel(false)

    useEffect(() => {
        if (isEmpty(didactiels)) {
            return
        }
        if (!didactiels[didactielType]) {
            return setActiveDidactiel(true)
        }
        return setActiveDidactiel(false)
    }, [didactielType, didactiels])

    if (!lang) {
        return null
    }

    return (
        <BinaryModal
            open={activeDidactiel}
            header={lang.header || didactielLang.header}
            description={<Typography
                html={lang.description}
            />}
            lottie={lottie}
            onAction={removeDidactiel}
            onCancel={closeDidactiel}
            onClose={closeDidactiel}
            actionText={didactielLang.understood}
            cancelText={didactielLang.remindMeLater}
        />
    );
};

export default Didactiel;
import NewPageLinkButton from 'components/NewPageLinkButton'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import DeleteContractButton from './DeleteContractButton'
import {
	getFile,
	getFileByNameVisualisationUrl,
	signContractAPI,
} from '../helpers'
import { getToken } from 'containers/helpers'
import PdfViewer from 'components/PdfViewer'
import { UserTypes } from 'constants/UserTypes'
import ManageSignatures from 'components/ManageSignatures'
import toast from 'components/toast'
import { dayStatuses } from 'constants/DayStatuses'
import { Store } from 'store/store';
import { Chip } from '@material-ui/core'
import IssueLinkButton from 'components/IssueLinkButton'
import { types } from 'containers/issue/issue.enum'
import useLanguage from 'hooks/useLanguage'
import classes from './Contract.module.scss'
import classNames from 'classnames'
import InvoiceProFormat from 'containers/payment/Pay/InvoiceProFormat'

const Contract = ({
	contract,
	mission
}) => {
	const lang = useLanguage('contract')
	const { state: { user } } = useContext(Store)

	const getContractUrl = useCallback(async (contract) => {
		if (!contract) {
			return false
		}
		const contractFileId =
			user.type === UserTypes.FREELANCE
				? contract.freelance_contract
				: contract.studio_contract
		const { name } = await getFile(contractFileId)
		setContractUrl(getFileByNameVisualisationUrl(getToken(), name))
	}, [user.type])

	const [contractUrl, setContractUrl] = useState()

	useEffect(() => {
		getContractUrl(contract)
	}, [contract])

	const signContract = useCallback(async () => {
		try {
			await signContractAPI(contract.id)
			toast.success(lang.contractSigned)
		} catch (error) {
			toast.error(error.message)
		}
	}, [contract, lang])

	const alreadySignedForMe = useMemo(() => {
		if (contract) {
			if (user.type === UserTypes.FREELANCE) {
				if (contract.status > dayStatuses.WAITING_FREELANCE_CONTRACT) {
					return true
				}
			} else {
				if (
					contract.status > dayStatuses.WAITING_ALL_CONTRACTS &&
					contract.status !== dayStatuses.WAITING_STUDIO_CONTRACT
				) {
					return true
				}
			}
		}
		return false
	}, [contract, user.type])

	const canDeleteContract = useMemo(() => {
		if (contract) {
			if (user.type === UserTypes.FREELANCE) {
				if (contract.status < dayStatuses.WAITING_STUDIO_CONTRACT) {
					return true
				}
			} else {
				if (contract.status < dayStatuses.BOOKED) {
					return true
				}
			}
		}
		return false
	}, [contract, user.type])

	const alreadySignedByOther = useMemo(() => {
		if (contract) {
			if (user.type === UserTypes.FREELANCE
				 && contract.status < dayStatuses.BOOKED){
					 return false
				 }
			if (contract.status > dayStatuses.WAITING_STUDIO_CONTRACT) {
				return true
			}
			if (user.type === UserTypes.FREELANCE) {
				if (contract.status === dayStatuses.WAITING_FREELANCE_CONTRACT) {
					return true
				}
			} else {
				if (contract.status === dayStatuses.WAITING_STUDIO_CONTRACT) {
					return true
				}
			}
		}
		return false
	}, [contract, user.type])

	return (
		<div>
			<div className={classNames(classes.mb1, classes.taCenter, classes.buttonContainer)}>
				<IssueLinkButton isButton type={types.CONTRACT} elementId={contract.id} />
				{canDeleteContract && (
					<DeleteContractButton contractId={contract.id} />
				)}
				<NewPageLinkButton href={contractUrl}>
					{lang.seeFullPage}
				</NewPageLinkButton>
			</div>
			{!alreadySignedForMe && <ManageSignatures onSign={signContract} />}
			{alreadySignedByOther &&
			<div className={classes.chipContainer}>
				<Chip
					label={`${lang.contractHaveBeenSigned} ${user.type === UserTypes.FREELANCE ? lang.studio : lang.freelance}`}
					color="primary" />
			</div>
			}
			{!!(contract?.studio_invoice_pf) && <InvoiceProFormat 
				className={classNames(classes.flex, classes.verticalCenteredContainer, classes.mb1)}
				invoicePF={contract.studio_invoice_pf}
			/>}
			<div className={classNames(classes.flex, classes.horizontalCenteredContainer, classes.taCenter, classes.flexWrap, classes.relative)}>
				<PdfViewer file={contractUrl} />
			</div>
		</div>
	)
}

export default Contract;

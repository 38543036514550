import JwtDecode from 'jwt-decode'
import { useContext, useEffect, useMemo, useState } from 'react'
import Loader from 'components/Loader'
import { Store } from 'store/store'
import { useNavigate, useParams } from 'react-router-dom'
import UnLogGrid from 'components/UnLogGrid/UnLogGrid'
import { updateUser } from 'containers/helpers'
import { parseEncodeObject } from '../../helpers'
import { getTokensFromGoogleToken } from './helpers'
import { useStoreUtils } from 'hooks/useStoreUtils'
import toast from 'components/toast'

const GoogleConnect = () => {
	const { token } = useParams()
	const navigate = useNavigate()
	const { state } = useContext(Store)
	const [loading, setLoading] = useState(true)
	const { loginInApp } = useStoreUtils()
	const { login: copy } = state.language

	const getUserAgreement = () => (
		<label>
			{copy.termsAndConditionsAgreement}
			<a href='https://www.klack.fr/confidentialite/' target='_blank' rel="noreferrer">
				{copy.termsAndConditions}
			</a>
		</label>
	)

	const inputs = useMemo(() => [
		{
			type: 'text',
			name: 'firstname',
			placeholder: copy.firstname,
			required: true,
			silentRequired: true,
			fluid: true,
		},
		{
			type: 'text',
			name: 'lastname',
			placeholder: copy.lastname,
			required: true,
			silentRequired: true,
			fluid: true,
		},
		{
			type: 'checkbox',
			name: 'userAgreement',
			label: getUserAgreement(),
			placeholder: copy.userAgreementPlaceholder,
			labelRight: true,
			required: true,
		},
	], [copy])

	const sendForm = async (values) => {
		// try {
		// 	const token = parseEncodeObject(params.token)
		// 	const { id } = JwtDecode(token.token.token)
		// 	await updateUser(values)
		// 	const path = JSON.parse(localStorage.getItem('path'))
		// 	if (path) localStorage.removeItem('path')
		// 	path ? navigate(path) : navigate('/home')
		// 	return
		// } catch (e) {
		// 	console.error(e)
		// }
	}

	const tryToLogin = async () => {
		try {
			const tokens = await getTokensFromGoogleToken(token)
			loginInApp(tokens)
			setLoading(false)
			const path = JSON.parse(localStorage.getItem('path'))
			if (path) localStorage.removeItem('path')
			return path ? navigate(path) : navigate('/')
		} catch (e) {
			toast.error(copy.googleConnectUnfunctional)
			setLoading(false)
			return navigate('/')
		}
	}

	useEffect(() => {
		if (token && token !== '') {
			tryToLogin(token)
		}

	}, [token])

	return (
		loading ? (
			<Loader />
		) : (
			<UnLogGrid
				inputs={inputs}
				sendForm={sendForm}
				page='additonnalInfo'
				message={copy.addInfoTitle}
				submitMessage={copy.addInfo}
			/>
		)
	)
}

export default GoogleConnect

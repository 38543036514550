import { fetchApi } from 'containers/helpers'

export const getFreelancesForMission = (missionId) =>
	fetchApi('/freelances/search/' + missionId)

export const getFreelanceForMission = (freelanceId, missionId) =>
	fetchApi(`/freelance/search/${freelanceId}/mission/${missionId}`)

export const updateWorkingPropositionStatus = (
	missionId,
	dates,
	status,
	freelanceUserId
) =>
	fetchApi('/availables/status/' + missionId, {
		method: 'PATCH',
		body: {
			dates,
			status,
			freelanceUserId,
		},
	})

export const createProposals = (
	mission,
	dates,
	user
) =>
	fetchApi('/proposals', {
		method: 'POST',
		body: {
			dates,
			mission,
			user,
		},
	})

export const declineProposalsAPI = (
	mission,
	dates,
	user
) =>
	fetchApi('/proposals', {
		method: 'DELETE',
		body: {
			dates,
			mission,
			user,
		},
	})

export const confirmProposalsAPI = (
	mission,
	dates,
	user
) =>
	fetchApi('/proposals/confirmed', {
		method: 'PUT',
		body: {
			dates,
			mission,
			user,
		},
	})

export const getDatesPrice = (freelanceId, dates) => fetchApi('/dates/price', {
	body: {
		freelanceId,
		dates//unix dates
	}
})

export const getLevels = () => fetchApi('/levels')

export const getFreelanceBadges = (userId) => fetchApi('/userBadges/' + userId)

export const getDaysCountReductions = () => fetchApi('/days/reductions')

export const getPercentile = (reductionPercentile) => {
	return reductionPercentile * 100 + '%'
}
export const getUserProposalsForMissionApi = (missionId) => fetchApi(`/proposals/user/${missionId}`)

export const getMissionProposalsApi = (missionId) => fetchApi(`/proposals/${missionId}`)

export const getMissionAppointmentsApi = (missionId) => fetchApi(`/appointments/${missionId}`)

export const getFreelanceAppointmentByMissionIdApi = (missionId) => fetchApi(`/appointments/freelance/${missionId}`)
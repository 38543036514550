import MyTable from 'components/MyTable/MyTable';
import useLanguage from 'hooks/useLanguage';
import React, { useContext, useMemo } from 'react';
import DateTooltip from 'components/DateTooltip';
import StatusMission from 'components/StatusMission';
import { useDayStatuses } from 'hooks/useDayStatuses';
import { momentFromDateString } from 'modules/dates';
import { roundTo2Dec } from 'containers/helpers';
import { Store } from 'store/store';
import { UserTypes } from 'constants/UserTypes';
import { isEmpty } from 'lodash';
import { getFee, getFeePourcentage } from 'util/data/styleep.js';

const AppointmentTablePrices = ({ appointments = [] }) => {
	const {
		state: { user },
	} = useContext(Store);

	const lang = useLanguage('contract');
	const columns = useMemo(
		() =>
			[
				{ title: lang.columns.id, type: 'numeric', field: 'id' },
				user.type === UserTypes.STUDIO && {
					title: lang.columns.freelance,
					type: 'string',
					field: 'freelanceName',
				},
				{ title: lang.columns.dates, render: ({ days = [], ...props }) => <DateTooltip dates={days} /> },
				{ title: lang.columns.dayCount, render: ({ days, ...props }) => Object.keys(days).length },
				{ title: lang.columns.status, render: ({ status, ...props }) => <StatusMission status={status} /> },
				{
					title: lang.columns.price,
					render: ({ price, tva: hasTva, ...props }) => {
						if (isEmpty(price)) return '0€';

						const tva = hasTva ? 1.2 : 1;
						if (user.type === UserTypes.STUDIO) {
							return roundTo2Dec(price.price) + '€ HT (' + roundTo2Dec(price.price * tva) + '€ TTC)';
						}

						const priceWithTva = price.price * (user.tva_number ? tva : 1);
						const fee = getFee(priceWithTva);
						return props.contract
							? roundTo2Dec(priceWithTva) +
									` - ${getFeePourcentage(fee, priceWithTva)}% (commission Styleep) = ` +
									roundTo2Dec(priceWithTva - fee) +
									'€'
							: roundTo2Dec(priceWithTva) + '€';
					},
				},
			].filter((e) => e),
		[lang, user],
	);

	const { dayStatusesLang } = useDayStatuses();
	const data = useMemo(() => {
		const getMostRecentDate = (days = []) =>
			Object.keys(days)
				.map((date) => momentFromDateString(date))
				.sort((a, b) => b - a)[0]
				.unix();
		const sortByRecent = (a, b) => {
			return getMostRecentDate(b.days || []) - getMostRecentDate(a.days || []);
		};
		return appointments
			.map((contract, index) => ({
				...contract,
				statusLang: dayStatusesLang[contract.status],
				missionId: contract.id,
			}))
			.sort(sortByRecent);
	}, [appointments, dayStatusesLang]);

	const onlyStatusContract = useMemo(() => data.map(({ statusLang }) => statusLang), [data]);

	const dayStatusesKeysAvailables = useMemo(
		() => [...new Set(Object.values(dayStatusesLang).filter((key) => onlyStatusContract.includes(key)))],
		[onlyStatusContract, dayStatusesLang],
	);
	return (
		<MyTable
			columns={columns}
			data={data}
			options={{
				paging: false,
			}}
			filters={{
				statusLang: { values: dayStatusesKeysAvailables, title: lang.columns.status },
			}}
		/>
	);
};

export default AppointmentTablePrices;

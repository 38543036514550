import BinaryModal from 'components/BinaryModal';
import Button from 'components/Button';
import toast from 'components/toast';
import { dayStatuses } from 'constants/DayStatuses';
import { createContractApi, removeContractApi } from 'containers/mission/helpers';
import useLanguage from 'hooks/useLanguage';
import { useMission } from 'hooks/useMission';
import { useCallback, useContext, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Store } from 'store/store';
import PrepaymentModal from './PrepaymentModal';
import classNames from 'classnames';
import classes from 'styles/classes.module.scss';

export const PrepaymentButton = ({
	id: appointmentId,
	studio_name: studioName,
	contract: contractId,
	status,
	price = null,
	daysRangeText,
}) => {
	const missionId = Number(useParams().missionId) || 0;
	const {
		state: { user },
	} = useContext(Store);
	const [loading, setLoading] = useState(false);
	const lang = useLanguage('contract');
	const langPrepayment = useLanguage('prepayment');
	const navigate = useNavigate();
	const [openModal, setOpenModal] = useState(false);
	const createContract = useCallback(async () => {
		setLoading(true);

		try {
			const contract = await createContractApi(appointmentId, user.id);

			toast.success(lang.contractCreated);
			navigate(`/mission/${missionId}/contract/${contract.id}`);
		} catch (err) {
			toast.error(err.message);
		} finally {
			setLoading(false);
			setOpenModal(false);
		}
	}, [appointmentId, user.id, lang.contractCreated, navigate, missionId]);

	const removeContract = useCallback(async () => {
		setLoading(true);

		try {
			await removeContractApi(contractId);
			toast.success(lang.contractRemoved);
			navigate(`/mission/page/${missionId}`);
		} catch (err) {
			toast.error(err.message);
		} finally {
			setLoading(false);
			setOpenModal(false);
		}
	}, [navigate, lang, missionId, contractId]);
	const visible = useMemo(() => (status ? status < dayStatuses.BOOKED : true), [status]);

	return (
		<>
			{visible && (
				<Button type={contractId ? 'danger' : 'primary'} onClick={() => setOpenModal(true)}>
					{contractId ? lang.notUsePrepaymentMethod : lang.usePrepaymentMethod} {daysRangeText}
				</Button>
			)}

			{contractId ? (
				<BinaryModal
					open={openModal}
					onCancel={() => setOpenModal(false)}
					textCenter
					header={langPrepayment.cancelModalTitle}
					cancelText={langPrepayment.cancelModalCancelText}
					actionText={langPrepayment.cancelModalActionText}
					description={langPrepayment.cancelModalDescription}
					onAction={removeContract}
					loading={loading}
				/>
			) : (
				<PrepaymentModal
					open={openModal}
					onAction={createContract}
					onClose={() => setOpenModal(false)}
					loading={loading}
					price={price.price}
				/>
			)}
		</>
	);
};

const PrepaymentListButton = () => {
	const missionId = Number(useParams().missionId) || 0;
	const {
		mission: { appointments },
	} = useMission(missionId);
	const lang = useLanguage('mission');

	const getDaysRangeText = useCallback((days) => {
		if (!days) return '';
		const daysKeys = Object.keys(days).sort();
		return `- ${daysKeys[0]} ${daysKeys.length > 1 ? lang.to + ' ' + daysKeys[daysKeys.length - 1] : ''}`;
	}, [lang]);

	return (
		<div className={classNames(classes.flex, classes.columnDirection, classes.gap05)}>
			{Object.entries(appointments || {}).map(([key, appointment], index) => (
				<PrepaymentButton key={index} {...appointment} daysRangeText={getDaysRangeText(appointment.days)} />
			))}
		</div>
	);
};

export default PrepaymentListButton;

import classNames from 'classnames';
import { dayStatuses } from 'constants/DayStatuses';
import { UserTypes } from 'constants/UserTypes';
import React, { useContext, useMemo } from 'react';
import { Store } from 'store/store';
import classes from './ContractsStatusCount.module.scss'
import useLanguage from 'hooks/useLanguage'
const DayCount = ({
    days = []
}) => {
    const lang = useLanguage('mission')
    const { state: { user: { type } } } = useContext(Store)
    const bookedDays = useMemo(() => days.filter(({ status }) => status >= dayStatuses.BOOKED).length, [days])
    const daysLength = useMemo(() => days.length, [days])

    const countLang = useMemo(() => {
        if (type === UserTypes.FREELANCE) {
            return daysLength
        }
        return `${bookedDays} / ${daysLength} ${lang.bookedDays}`
    }, [type, bookedDays, daysLength, lang])
    const className = useMemo(() => {
        if (type === UserTypes.FREELANCE) {
            return ''
        }
        if (bookedDays === daysLength) {
            return classes.finished
        } else if (bookedDays === 0) {
            return classes.open
        } else if (bookedDays <= (0.5 * daysLength)) {
            return classes.inProgress
        }
        return classes.waiting
    }, [bookedDays, daysLength, type])
    return (
        <div className={classNames(classes.root, className)}>
            {countLang}
        </div>
    );
};

export default DayCount;
import { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import { Popover } from 'antd';
import { createManySlotsAPI, getSlotsApi, deleteMultipleSlotsApi, getProposalsApi } from 'containers/scheduler/helpers';
import toast from 'components/toast';
import { appointmentsToEvents, date2unix, proposalsByMissionsToEvents } from 'modules/dates';
import { socket, removeEvent } from 'socket';
import Button from 'components/Button';
import useLanguage from 'hooks/useLanguage';
import { Store } from 'store/store';
import dayStatusEventProp from './dayStatusEventProp';
import useIsMobile from 'hooks/useIsMobile';
import Didactiel from 'components/Didactiel';
import * as animationJson from 'animations/calendar.json';
import LottieControl from 'components/LottieControl';
import useLottie from 'hooks/useLottie';
import SelectableScheduler from 'containers/scheduler/SelectableScheduler';
import { getDayStatusesLang, getFreelanceAppointmentsApi } from 'containers/mission/helpers';
import { isEmpty } from 'lodash';
import { EventTypes } from 'constants/EventTypes.constant';
import moment from 'moment-timezone';

const SlotsFreelanceCalendar = () => {
	const lang = useLanguage('mission');
	const appLang = useLanguage('appointment');
	const dayStatusesLangs = useLanguage('dayStatuses');
	const calLang = useLanguage('calendar');
	const [loading, setLoading] = useState(true);
	const [events, setEvents] = useState([]);
	const [days, setDays] = useState({});
	const { user, socketConnected } = useContext(Store).state;
	const dayStatusesLang = useMemo(
		() => getDayStatusesLang(dayStatusesLangs, user.type),
		[user.type, dayStatusesLangs],
	);

	const init = useCallback(async () => {
		try {
			const days = await getSlotsApi();
			const proposals = await getProposalsApi();
			const appointments = await getFreelanceAppointmentsApi();

			setDays(days);
			setEvents(
				[
					...(!isEmpty(appointments)
						? appointmentsToEvents(appointments, dayStatusesLang, lang, user.type, true)
						: []),
					...(!isEmpty(proposals) ? proposalsByMissionsToEvents(proposals, dayStatusesLang, true) : []),
				].filter(
					({ start, eventType }) =>
						!(
							[EventTypes.OPEN, EventTypes.PROPOSAL].includes(eventType) &&
							moment(start).isBefore(moment())
						),
				),
			);
		} catch (e) {
			toast.error(e.message);
		} finally {
			setLoading(false);
		}
	}, [dayStatusesLang, lang, user]);

	useEffect(() => {
		init();
	}, [init]);

	useEffect(() => {
		if (socketConnected && socket) {
			socket.on(`missions updated`, () => {
				init();
			});
			socket.on(`appointment updated`, () => {
				init();
			});
		}
		return () => {
			if (socketConnected && socket) {
				removeEvent(`missions updated`);
				removeEvent(`appointment updated`);
			}
		};
	}, [socketConnected, init]);

	const pushDates = useCallback(
		async (daysToAdd) => {
			const formatedDays = Object.entries(daysToAdd).reduce((acc, [key, { dates, ...rest }]) => {
				acc[key] = {
					...rest,
					dates: dates.map((date) => date2unix(date)),
				};

				return acc;
			}, {});

			setLoading(true);
			try {
				await createManySlotsAPI(formatedDays);
				toast(calLang.datesAdded(Object.keys(formatedDays).length));
				setDays({ ...days, ...daysToAdd });
			} catch (e) {
				toast.error(e.message);
			} finally {
				setLoading(false);
			}
		},
		[calLang, days, setLoading],
	);

	const deleteDates = useCallback(
		async (daysToRemoveKeys) => {
			const nextDays = { ...days };

			const daysToDelete = Object.entries({ ...days }).reduce((acc, [key, value]) => {
				if (daysToRemoveKeys.find((keyFormDaysToRemove) => keyFormDaysToRemove === key)) {
					acc[key] = value;
					delete nextDays[key];
				}

				return acc;
			}, {});

			const formatedDays = Object.entries(daysToDelete).reduce((acc, [key, { dates, ...rest }]) => {
				acc[key] = {
					...rest,
					dates: dates.map((date) => date2unix(date)),
				};

				return acc;
			}, {});

			setLoading(true);

			try {
				const { current, deleted } = await deleteMultipleSlotsApi(formatedDays);
				setDays(current);
				toast(calLang.datesRemoved(Object.keys(deleted).length));
			} catch (e) {
				toast.error(e.message);
			} finally {
				setLoading(false);
			}
		},
		[days, calLang, setLoading],
	);

	const EventWrapper = (props) => {
		const {
			children,
			event: { id, title, eventType },
		} = props;
		return (
			<Popover
				title={title}
				trigger="click"
				content={
					<Button
						link={(eventType === EventTypes.APPOINTMENT_BTOC ? '/appointment/' : '/mission/page/') + id}
					>
						{eventType === EventTypes.APPOINTMENT_BTOC
							? appLang.accessToAppointmentPage
							: lang.accessToMissionPage}
					</Button>
				}
			>
				{children}
			</Popover>
		);
	};

	const components = {
		eventWrapper: EventWrapper,
	};

	const isMobile = useIsMobile();
	const { width, height } = useLottie({
		width: 600,
		height: 500,
	});
	return (
		<>
			<Didactiel
				didactielType={'calendar'}
				isMobile={isMobile}
				lottie={<LottieControl width={width} height={height} animationData={animationJson} />}
			/>
			<SelectableScheduler
				monthOnly={false}
				moreComponents={components}
				eventPropGetter={dayStatusEventProp}
				events={events}
				deleteDates={deleteDates}
				scheduledDates={days}
				pushDates={pushDates}
				loading={loading}
				showDayStatus={true}
			/>
		</>
	);
};

export default SlotsFreelanceCalendar;

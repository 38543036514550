import RightSide from 'components/RightSide/RightSide';
import TitlePage from 'components/TitlePage';
import useLanguage from 'hooks/useLanguage';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
	cancelAppointmentApi,
	getAppointmentByIdApi,
	getForfaitsByIdsApi,
	getServicesByIdsApi,
} from './helpers';
import MenuNavigationComponent from 'containers/mission/components/MenuNavigationComponent';
import SingleAppointmentCalendar from 'containers/calendar/components/SingleAppointmentCalendar';
import ServicesTablePrices from './components/ServicesTablePrices';
import { isEmpty } from 'lodash';
import IssuesByUserAppointment from 'containers/issue/components/IssueByAppointment';
import { Store } from 'store/store';
import CustomerPublicProfileContainer from 'containers/customer/CustomerPage/CustomerPublicProfileContainer';
import { getCustomerDisplayName } from 'containers/customer/helpers';
import AppointmentButton from './components/AppointmentButton';
import toast from 'components/toast';
import { dayStatuses } from 'constants/DayStatuses';
import AppointmentReviews from 'containers/review/components/AppointmentReviews';

const AppointmentPage = () => {
	const {
		state: { user: me },
	} = useContext(Store);
	const lang = useLanguage('appointment');
	const appointmentId = Number(useParams().appointmentId);
	const [appointment, setAppointment] = useState({});
	const [services, setServices] = useState([]);
	const navigate = useNavigate();

	console.log('appointment', appointment)

	const customerName = useMemo(
		() => (appointment?.users?.customer ? getCustomerDisplayName({ ...appointment.users.customer }) : ''),
		[appointment],
	);

	const servicesOrForfaits = useCallback(async () => {
		const { services: servicesIds, forfaits: forfaitsIds } = appointment;

		try {
			const services = !isEmpty(servicesIds) ? await getServicesByIdsApi(servicesIds) : [];
			const forfaits = !isEmpty(forfaitsIds) ? await getForfaitsByIdsApi(forfaitsIds) : [];
			setServices([...services, ...forfaits]);
		} catch (err) {
			toast.error(err.message);
		}
	}, [appointment]);

	useEffect(() => {
		servicesOrForfaits();
	}, [servicesOrForfaits]);

	useEffect(() => {
		if (!appointmentId) return;
		getAppointmentByIdApi(appointmentId).then(setAppointment);
	}, [appointmentId]);

	const cancelAppointment = useCallback(
		async (id: number) => {
			try {
				await cancelAppointmentApi(id);
				toast.success(lang.appointmentCanceled);
				navigate('/calendar');
			} catch (err) {
				toast.error(lang.appointmentCanceledError);
			}
		},
		[navigate, lang],
	);

	const components = useMemo(
		() =>
			[
				{
					title: lang.calendar,
					component: appointment && (
						<SingleAppointmentCalendar appointment={appointment} cancelAppointment={cancelAppointment} />
					),
				},
				!isEmpty(services) && {
					title: lang.servicesList,
					component: <ServicesTablePrices services={services} />,
				},
				{
					title: lang.issues,
					component: <IssuesByUserAppointment user={me.id} appointment={appointmentId} />,
				},
				appointment.status === dayStatuses.FINISHED && {
					title: lang.review,
					component: <AppointmentReviews appointmentId={appointmentId} />,
				},
				{
					title: lang.customerDetails,
					component: <CustomerPublicProfileContainer userId={appointment.customer} />,
				},
			].filter((e) => !!e),
		[services, appointment, me, lang, appointmentId, cancelAppointment],
	);

	return (
		<RightSide>
			<TitlePage
				subtitle={appointment?.users?.customer?.address || ''}
			>{`${lang.title} ${customerName}`}</TitlePage>
			<AppointmentButton {...appointment} cancelAppointment={cancelAppointment} />
			<MenuNavigationComponent components={components} />
		</RightSide>
	);
};

export default AppointmentPage;

export const FR = {
	title: 'Ma boutique',
	subtitle: 'Gérez vos prestations',
	columnList: {
		profession: 'Métier',
		forfait: 'Forfait',
		title: 'Titre',
		description: 'Description',
		time: 'Heures',
		price: 'Prix TTC',
		slots: "Nombre d'heures",
	},
	slotsInfo: "Si il s'agit par exemple de 30 minutes, mettez 0,5. Si 1h30, mettez 1,5",
	createService: 'Créer la prestation',
	createForfait: 'Créer le forfait',
	updateService: 'Modifier la prestation',
	updateForfait: 'Modifier le forfait',
	infoPrice: 'Votre taux horaire sera donc de ',
	removeService: 'Supprimer cette prestation',
	removeForfait: 'Supprimer ce forfait',
	activePrepayment: 'Activer le prépaiement pour toutes les prestations',
	unactivePrepayment: 'Désactiver le prépaiement pour toutes les prestations',
	servicesInfo: 'Selectionnez les services qui composent ce forfait',
	services: 'Prestations',
	yes: 'Oui',
	no: 'Non',
};

export const EN = {
	title: 'My store',
	subtitle: 'Manage your services',
	yes: 'Yes',
	no: 'No',
	columnList: {
		profession: 'Profession',
		forfait: 'Forfait',
		title: 'Title',
		description: 'Description',
		time: 'Hours',
		price: 'Price',
	},
	slotsInfo: 'If 30 minutes --> 0,5. If 1h30, --> 1,5',
	createService: 'Create new service',
	createForfait: 'Create new forfait',
	updateService: 'Update service',
	updateForfait: 'Update forfait',
	infoPrice: 'Your hourly rate will be so of ',
	removeService: 'Remove this service',
	removeForfait: 'Delete this forfait',
	activePrepayment: 'Active pre-payment for all services',
	unactivePrepayment: 'Unactive pre-payment for all services',
	servicesInfo: 'Select services which compose this forfait',
	services: 'Services',
};

export default {
	fr: FR,
	us: EN,
};

import Typography from 'components/Typography'
import classNames from 'classnames';
import React, { useCallback, useMemo } from 'react';
import classes from './MenuNavigationComponent.module.scss'
import { useLocation, useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { urlParametersToJson } from 'containers/helpers';


const MenuNavigationComponent = ({
    components = [{ title: '', component: '' }]
}) => {
    const { pathname, search } = useLocation()
    const navigate = useNavigate()

	const menu = useMemo(() => components.map(({ title }) => ({ title })), [components])

    const index = useMemo(() => {
        if (isEmpty(search)) {
            return 0
        }
        const indexFromParams = Number(urlParametersToJson(search).nav || 0)
        if (indexFromParams >= 0) {
            if (indexFromParams < menu.length - 1) {
                return indexFromParams
            }
            return menu.length - 1
        }
        return 0
    }, [search, menu])

    const updateUrl = useCallback((index) => {
        return navigate(`${pathname}?nav=${index}`)
    }, [navigate, pathname])

    const activeComponent = useMemo(() => components[index].component, [components, index])

    return (
        <div className={classes.root}>
            <div className={classes.menuContainer}>
                {menu.map(({ title, isActive = false }, key) =>
                    <div
                        key={key}
                        onClick={() => updateUrl(key)}
                        className={classNames(classes.centeredContainer, classes.itemContainer, key === index && classes.active)}>
                        <Typography
                            classes={{ root: classes.item }}
                        >
                            {title}
                        </Typography>
                    </div>)}
            </div>
            <div className={classes.componentContainer}>
                {activeComponent}
            </div>
        </div>
    );
};

export default MenuNavigationComponent;
export const FR = {
	modalTypes: {
        schedule: {
			header: 'Confirmer la réunion',
			description: `Confirmer la réunion pour $1 ?`,
			cancelButtonText: 'Annuler',
			actionButtonText: 'Programmer',
		},
		cancel: {
			header: 'Annuler la réunion',
			description: `Êtes-vous sûr de vouloir annuler la réunion de $1 ?`,
			cancelButtonText: 'Garder la réunion',
			actionButtonText: 'Annuler la réunion',
		},
		reschedule: {
			header: 'Reprogrammer la réunion',
			description: `Êtes-vous sûr de vouloir reprogrammer une réunion pour le $1 ?`,
			cancelButtonText: 'Conserver la réunion actuelle',
			actionButtonText: 'Reprogrammer',
		},
    },
    meetingScheduled: 'Votre meeting est planifié le $1.',
    updateMeeting:'Actualiser votre date de meeting',
    stopUpdateMeeting:"Arrêter l'actualisation du meeting",
    meetingLink: 'Lien vers la réunion',
}

export const EN = {
    modalTypes: {
        schedule: {
			header: 'Confirm meeting',
			description: `Confirm meeting for $1 ?`,
			cancelButtonText: 'Cancel',
			actionButtonText: 'Confirm',
		},
		cancel: {
			header: 'Cancel the meeting',
			description: `Are your sure to cancel meeting of $1 ?`,
			cancelButtonText: 'Keep the meeting',
			actionButtonText: 'Cancel the meeting',
		},
		reschedule: {
			header: 'Reschedule the meeting',
			description: `Are you sure to reschedule the meeting of $1 ?`,
			cancelButtonText: 'Keep actuel meeting',
			actionButtonText: 'Reschedule',
		},
    },
    meetingScheduled: 'Your meeting is scheduled to $1.',
    updateMeeting:'Update meeting time',
    stopUpdateMeeting:"Stop meeting update",
    meetingLink: 'Meeting link',
}

export default {
	fr: FR,
	us: EN,
}
  
import FormComponent from 'components/Form/Form';
import { isEmpty } from 'lodash';
import { dateToAnotherTime, unix2moment } from 'modules/dates';
import { useCallback, useMemo } from 'react';

const FavoriteDayStatus = ({
	onUpdate,
	favoriteDayStatus,
	hidden = false,
	disabled = false,
	dayStatus,
	currentDay,
}) => {
	const inputs = useMemo(
		() => [
			{
				type: 'time',
				name: 'start',
				value: !isEmpty(favoriteDayStatus) ? unix2moment(favoriteDayStatus.start).format('HH:mm') : '9:00',
			},
			{
				type: 'time',
				name: 'end',
				value: !isEmpty(favoriteDayStatus) ? unix2moment(favoriteDayStatus.end).format('HH:mm') : '19:00',
			},
		],
		[favoriteDayStatus],
	);

	const handleUpdate = useCallback(
		(values) => {
			const { start, end } = values;

			onUpdate({
				start: dateToAnotherTime(currentDay, Number(start.split(':')[0]), Number(start.split(':')[1])).unix(),
				end: dateToAnotherTime(currentDay, Number(end.split(':')[0]), Number(end.split(':')[1])).unix(),
			});
		},
		[currentDay, onUpdate],
	);

	return (
		!hidden && <FormComponent sideInputs inputs={inputs} button={false} onUpdate={handleUpdate} responsive={true} />
	);
};

export default FavoriteDayStatus;

export const FR = {
	confirmedDates: `Dates confirmées`,
	declineDate: `Refuser`,
	declineConfirmedDateHeader: 'Êtes-vous certain de vouloir refuser la date',	
	declineConfirmedDateDescription: (date) => `Refuser la date confirmée: ${date}`,
	decline: 'Refuser',
	leaveDateConfirmed: 'Laisser la date comme confirmée',
	declineDateSuccess: (date) => `La date ${date} a bien été refusée`	
}

export const EN = {
	confirmedDates: `Confirmed Dates`,
	declineDate: `Decline Date`,
	declineConfirmedDateHeader: 'Confirm declining date',
	declineConfirmedDateDescription: (date) => `Decline confirmed date: ${date}`,
	decline: 'Decline',
	leaveDateConfirmed: 'Leave date confirmed',
	declineDateSuccess: (date) => `You've succesfully declined date ${date}`
}

export default {
	fr: FR,
	us: EN,
}
import Typography from 'components/Typography';
import useLanguage from 'hooks/useLanguage';
import { toDateInput } from 'modules/dates';
import React, { useMemo } from 'react';
import classes from '../../styles/classes.module.scss'

const DatesFromMissionDays = ({
    startDate,
    endDate,
    daysCount = null,
    noTypo = false
}) => {
    const lang = useLanguage('mission')
    const daysCountLang = useMemo(() => daysCount + ' ' + (daysCount > 1 ? lang.days : lang.day), [daysCount, lang])

    return (
        noTypo ? `${toDateInput(startDate)} ${lang.to} ${toDateInput(endDate)}${daysCount != null ? ` - ${daysCountLang}` : ''}`
            : <Typography italic variant='subTitle2' fontWeight={300} classes={{ root: classes.noMargin }}>
                {toDateInput(startDate)} {lang.to} {toDateInput(endDate)}{daysCount != null ? ` - ${daysCountLang}` : ''}
            </Typography>
    );
};

export default DatesFromMissionDays;
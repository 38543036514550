import { useState, useEffect, useContext, useCallback, useMemo } from 'react'
import Button from 'components/Button'
import Spacer from 'components/Spacer'
import { useParams, useNavigate } from 'react-router-dom'
import DetailsForm from '../components/DetailsForm'
import DatesForm from '../components/DatesForm'
import createSteps from '../constants/createSteps'
import errors from '../constants/errors'
import editModes from '../constants/editModes'
import useLanguage from 'hooks/useLanguage'
import {
	getMission,
	getLatestMissions,
	createMissionAPI,
	updateMission,
	updateMissionDays,
} from '../helpers'
import toast from 'components/toast'
import { Store } from 'store/store'
import BinaryModal from 'components/BinaryModal'
import RightSide from 'components/RightSide/RightSide'
import { pick } from 'lodash'
import TitlePage from 'components/TitlePage'
import Didactiel from 'components/Didactiel'
import useIsMobile from 'hooks/useIsMobile'
import * as animationJson from 'animations/calendar.json'
import LottieControl from 'components/LottieControl'
import useLottie from 'hooks/useLottie'
import classes from 'styles/classes.module.scss'
import { date2unix } from 'modules/dates'

function CreateMission() {
	const navigate = useNavigate()
	const lang = useLanguage('mission.create')
	const { step, mode, missionId } = useParams()
	const [error, setError] = useState(false)
	const [loading, setLoading] = useState(false)
	const [editingMission, setEditingMission] = useState(false)
	const [previousMissions, setPreviousMissions] = useState([])
	const [deleteDatesModalOpen, setDeleteDatesModalOpen] = useState(false)
	const {
		dispatch,
		state: { createMissionForm },
	} = useContext(Store)
	const [days, setDays] = useState({})

	const daysLength = useMemo(() => Object.keys(days).length, [days])
	
	useEffect(() => {
		const init = async () => {
			setLoading(true)
			try {
				if (mode === editModes.edit) {
					const mission = await getMission(missionId)
					setEditingMission(mission)
					setDays(mission.days)
				} else if (mode === editModes.create) {
					const missions = await getLatestMissions(3)
					setPreviousMissions(missions)
				}
			} catch (e) {
				toast.error(e)
			} finally {
				setLoading(false)
			}
		}

		init()
	}, [mode, missionId, step])

	const onSubmitDatesForm = useCallback(async () => {
		if (
			mode !== editModes.edit &&
			(!createMissionForm.title.length ||
				!createMissionForm.description.length)
		) {
			setError(errors.DETAILS)
			navigate('/mission/create/0')
			return
		}

		const formatedDays = Object.entries(days).reduce((acc, [key, { dates, ...rest }]) => {
			acc[key] = {
				...rest,
				dates: dates.map(date => date2unix(date))
			}

			return acc;
		}, {})

		setLoading(true)
		try {
			if (mode === editModes.create) {
				const { id } = await createMissionAPI({
					...createMissionForm,
					days: formatedDays
				})
				dispatch({ type: 'CREATE_MISSION__FORM_DATA__UNSET' })
				toast.success(lang.newMissionCreated)
				navigate(`/mission/page/${id}`)
			} else if (mode === editModes.edit) {
				const { deleted, added } = await updateMissionDays(
					missionId,
					formatedDays
				)
				dispatch({ type: 'CREATE_MISSION__FORM_DATA__UNSET' })
				if (added + deleted > 0) {
					toast.success(
						lang.missionModified({ nDatesDeleted: deleted, nDatesAdded: added })
					)
				}
				navigate(`/mission/page/${missionId}`)
			}
		} catch (e) {
			toast.error(e.message)
		} finally {
			setLoading(false)
		}
	}, [createMissionForm, dispatch, missionId, mode, days, lang, navigate])

	const onSubmitDetailsForm = async (form) => {
		if (error && error === errors.DETAILS) {
			setError(null)
		}
		//update title + description
		if (
			mode === editModes.edit &&
			(form.description !== editingMission.description ||
				form.title !== editingMission.title)
		) {
			await updateMission(missionId, form)
			toast(lang.titleAndDescriptionModified)
		}

		dispatch({ type: 'CREATE_MISSION__FORM_DATA__SET', payload: form })
		navigate(
			editingMission
				? `/mission/edit/1/${missionId}`
				: '/mission/create/1'
		)
	}

	const onAddDates = useCallback((dayKeysToAdd) => {
		setDays({ ...days, ...dayKeysToAdd })
	}, [days])

	const onDeleteDates = useCallback((daysToRemoveKeys) => {
		const nextDays = { ...days };
		daysToRemoveKeys.forEach(key => {
			delete nextDays[key];
		})

		setDays({ ...nextDays })
	}, [days])

	const closeDeleteDatesModal = () => setDeleteDatesModalOpen(false)
	const openDeleteDatesModal = () => setDeleteDatesModalOpen(true)

	const resetDates = () => {
		setDays({})
		closeDeleteDatesModal()
	}

	const showMessage = () => {
		switch (error) {
			case errors.DETAILS:
				toast.error(
					lang.noDetails
				)
				break
			case errors.DatesForm:
				toast.error(
					lang.noDates
				)
				break
			default:
				break
		}
	}
	const isMobile = useIsMobile()
	const { width, height } = useLottie({
		width: 600,
		height: 500
	})
	return (
		<RightSide>
			{!!!loading && (
				<>
					{step === createSteps.dates &&
						<Didactiel
							didactielType={'create_mission_calendar'}
							isMobile={isMobile}
							lottie={<LottieControl
								width={width}
								height={height}
								animationData={animationJson} />} />}
					<TitlePage>
						{editingMission ? lang.modify : lang.create} une mission
					</TitlePage>
					{error && showMessage()}
					{step === createSteps.form ? (
						<DetailsForm
							templates={previousMissions}
							initForm={
								editingMission
									? pick(editingMission, [
										'title',
										'description',
									])
									: createMissionForm
							}
							onSubmit={onSubmitDetailsForm}
						/>
					) : step === createSteps.dates ? (
						<>
							<Button
								type='cancelLight'
								onClick={() => navigate(-1)}
							>
								{'<'}
							</Button>
							<DatesForm
								mission={editingMission}
								onClickDeleteAll={openDeleteDatesModal}
								onDeleteDates={onDeleteDates}
								onAddDates={onAddDates}
								days={days}
								onSubmit={onSubmitDatesForm}
								showDayStatus
								showFavoriteDayStatus
							/>
							<Spacer />
							<Button
								className={classes.fixedBottom}
								onClick={onSubmitDatesForm}
								disabled={daysLength === 0}
								positive={true}
							>
								{editingMission
									? lang.modifyDates
									: lang.createNewMission}
							</Button>
						</>
					) : null}
					<BinaryModal
						open={deleteDatesModalOpen}
						header={lang.confirmDeleteAllDates}
						description={lang.thisWillDelete(daysLength)}
						onCancel={closeDeleteDatesModal}
						onAction={resetDates}
						cancelText={lang.cancel}
						actionText={lang.delete}
					/>
				</>
			)}
		</RightSide>
	)
}

export default CreateMission

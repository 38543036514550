import { fetchApi } from '../../helpers';

export const createUser = ({ email, password, firstname, lastname, company, phone, position }) =>
    fetchApi('/user', {
        body: {
            email,
            password,
            firstname,
            lastname,
            company,
            phone,
            position,
        },
    });

export const createStudio = ({
    studio_name,
    email,
    password,
    firstname,
    lastname,
    phone
}) =>
    fetchApi('/studio', {
        body: {
            studio_name,
            email,
            password,
            firstname,
            lastname,
            phone
        },
    });

export const createFreelance = ({
    email,
    password,
    firstname,
    lastname,
    godfather,
    phone
}) =>
    fetchApi('/freelance', {
        body: {
            email,
            password,
            firstname,
            lastname,
            godfather,
            phone
        },
    });

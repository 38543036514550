export const FR = {
	warning: 'Styleep fonctionne avec les cookies, veuillez activer les cookies puis rechargez la page pour y accéder.',
}

export const EN = {
	warning: 'Styleep works with cookies. Please active them and reload page.',
}

export default {
	fr: FR,
	us: EN,
}
  
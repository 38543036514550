import DatesFromMissionDays from 'components/DatesFromMissionDays';
import TitlePage from 'components/TitlePage';
import useLanguage from 'hooks/useLanguage';
import { upperFirst } from 'lodash';
import React, { useContext, useMemo } from 'react';
import classes from './MissionTitle.module.scss'
import classNames from 'classnames';
import { Store } from 'store/store';
import { UserTypes } from 'constants/UserTypes';
import GroupedDaysStatus from '../GroupedDaysStatus/GroupedDaysStatus';

const MissionTitle = ({
	mission,
    title,
    startDate,
    endDate,
    daysCount,
    subPage = null,
    studio_name = '',
}) => {
    const { user } = useContext(Store).state

    const lang = useLanguage('mission')
    const subtitle = useMemo(() => <div className={classNames(classes.flex, classes.subtitle)}>
        <DatesFromMissionDays
            startDate={startDate}
            endDate={endDate}
            daysCount={daysCount}
        />
		<GroupedDaysStatus
			proposalsLength={mission.proposalsLength}
			appointmentsLength={mission.appointmentsLength}
			openDaysLength={mission.openDaysLength}
		/>
        {/* <StatusMission status={status} mission={mission} /> */}
    </div>, [startDate, endDate, daysCount, mission])
    return (
        <div className={classes.root}>
            <TitlePage subtitle={subtitle}>
                {subPage ? `${subPage} > ` : null}{upperFirst(lang.mission)}{
                    user.type === UserTypes.FREELANCE
                        ? ` ${lang.ofStudio} ${upperFirst(studio_name)}`
                        : ''
                } - {upperFirst(title)}
            </TitlePage>
        </div>
    );
};

export default MissionTitle;
export const types = {
	UPDATE_LEVEL: 0,
	CONTRACT: 1,
	MISSION: 2,
	ONBOARD: 3,
	REPAYMENT: 4,
	APPOINTMENT: 5,
	CONTRACT_CONFIRMED_DATES_MISMATCH: 45,
	OTHER: 909,
};

export const FreelanceIssueTypes = {
	UPDATE_LEVEL: types.UPDATE_LEVEL,
	CONTRACT: types.CONTRACT,
	MISSION: types.MISSION,
	ONBOARD: types.ONBOARD,
	REPAYMENT: types.REPAYMENT,
	OTHER: types.OTHER,
};

export const StudioIssueTypes = {
	UPDATE_LEVEL: types.UPDATE_LEVEL,
	CONTRACT: types.CONTRACT,
	MISSION: types.MISSION,
	ONBOARD: types.ONBOARD,
	REPAYMENT: types.REPAYMENT,
	OTHER: types.OTHER,
};

export const priorities = {
	LOW: 0,
	MEDIUM: 1,
	HIGH: 2,
	MAX: 3,
};

export const statuses = {
	OPEN: 0,
	IN_PROGRESS: 1,
	WAITING_MEETING: 2,
	MEETING_BOOKED: 3,
	CLOSE: 4,
};

export const IssueLabels_FR = {
	[statuses.CLOSE]: 'Fermé',
	[statuses.OPEN]: 'Ouverte',
	[statuses.WAITING_MEETING]: 'Programmez un meeting',
	[statuses.MEETING_BOOKED]: 'Meeting programmé',
	[statuses.IN_PROGRESS]: 'En course',
};

import { useContext } from 'react'
import {
  CardElement, IbanElement,
} from '@stripe/react-stripe-js'
import { Input } from 'semantic-ui-react'
import { Store } from 'store/store'
import TransferIndications from './TransferIndications'
import classes from './Payment.module.scss'
import classNames from 'classnames'
import Typography from 'components/Typography'

const CheckoutForm = ({
  disabled,
  children,
  onChange,
  onFocus,
  saveCardTextLang,
  paymentType,
  sepaAuthorizationLang,
  setFirstName,
  setLastName,
  reference,
  price,
  currency,
  invoicePF
}) => {
  const handleChangeFirstName = event => {
    setFirstName(event.target.value)
  }
  const handleChangeLastName = event => {
    setLastName(event.target.value)
  }
  const {
    state: {
      language: {
        starterPay: {
          firstname: firstnameLang,
          lastname: lastnameLang,
        },
      },
    },
  } = useContext(Store)



  return (
    <div>{paymentType === 'bankTransfer' ? <TransferIndications
      reference={reference}
      price={price}
      currency={currency}
      invoicePF={invoicePF}

    /> : <>

      <Input className={classes.nameInput} placeholder={firstnameLang} onChange={handleChangeFirstName} />

      <Input placeholder={lastnameLang} onChange={handleChangeLastName} />
      {paymentType === 'card' ? <CardElement
        onChange={onChange}
        onFocus={onFocus}
        className={classNames(classes.vaMiddle,
          classes.cardElement,
          classes.cardContainer
        )}
        options={{
          style: {
            base: {
              fontSize: '16px',
              color: '#424770',
              '::placeholder': {
                color: '#aab7c4',
              },
            },
            invalid: {
              color: '#9e2146',
            },
          },
        }}
      /> :
        <>
          <IbanElement
            onChange={onChange}
            className={classNames(classes.vaMiddle,
              classes.cardElement,
              classes.sepaContainer
            )}
            options={{
              supportedCountries: ['SEPA'],
              placeholderCountry: 'DE',
              // Elements can use a placeholder as an example IBAN that reflects
              // the IBAN format of your customer's country. If you know your
              // customer's country,  we recommend that you pass it to the Element as the
              // placeholderCountry.
              style: {
                base: {
                  color: '#32325d',
                  fontSize: '16px',
                  '::placeholder': {
                    color: '#aab7c4',
                  },
                  ':-webkit-autofill': {
                    color: '#32325d',
                  },
                },
                invalid: {
                  color: '#fa755a',
                  iconColor: '#fa755a',
                  ':-webkit-autofill': {
                    color: '#fa755a',
                  },
                },
              },
            }}

          />
        </>}</>}
      {children}
      {paymentType === 'sepa_debit' && <div className={classes.mandateAcceptanceContainer}>
        <Typography classes={{ root: classes.mandateAcceptance }}>
          {sepaAuthorizationLang}
        </Typography>
      </div>
      }
    </div>
  )
}

export default CheckoutForm

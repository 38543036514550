import { dayStatuses } from 'constants/DayStatuses';
import { useMemo, useContext } from 'react';
import Button from 'components/Button';
import ReviewButton from 'containers/review/components/ReviewButton';
import { Store } from 'store/store';
import classes from '../AppointmentPage.module.scss';
import useLanguage from 'hooks/useLanguage';

const AppointmentButton = ({
	id,
	dates, //[123214,4214214,24124124,2144214]
	status,
	user: userId,
	displayName,
	price,
	cancelAppointment,
	isAppointmentPage = false,
	days,
}) => {
	const lang = useLanguage('appointment');
	const {
		state: {
			user: { type },
		},
	} = useContext(Store);

	const freelanceButtonFromStatus = useMemo(() => {
		const actionButton = () => {
			switch (status) {
				case dayStatuses.CONFIRMED:
				case dayStatuses.WAITING_ALL_CONTRACTS:
				case dayStatuses.WAITING_FREELANCE_CONTRACT:
				case dayStatuses.WAITING_STUDIO_CONTRACT:
				case dayStatuses.WAITING_FUNDS:
				case dayStatuses.WAITING_TEAM_FUNDS_RECEIVED:
				case dayStatuses.BOOKED:
					return (
						<Button type="danger" onClick={() => cancelAppointment(id)}>
							{lang.cancelAppointment}
						</Button>
					);
				case dayStatuses.WAITING_FREELANCE_DAYS_CONFIRMATION:
				case dayStatuses.WAITING_STUDIO_DAYS_CONFIRMATION:
				case dayStatuses.WAITING_REVIEW:
					return null;
				case dayStatuses.WAITING_FREELANCE_REVIEW:
					return <ReviewButton appointment={id} status={status} type={type} />;
				case dayStatuses.WAITING_STUDIO_REVIEW:
				case dayStatuses.REVIEWED_DAYS:
				case dayStatuses.WAITING_UNLOCK_FUNDS:
				case dayStatuses.CANCELLED:
				case dayStatuses.FINISHED:
					return null;
				default:
					return null;
			}
		};

		const actionButtonComponent = actionButton();
		return <div className={classes.actionContainer}>{actionButtonComponent}</div>;
	}, [status, lang, type, cancelAppointment, id]);

	return freelanceButtonFromStatus;
};

export default AppointmentButton;

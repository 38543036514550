import { fetchApi } from '../../helpers';

export const getCustomerCards = () => fetchApi('/stripe/subscription/getCards');

export const pay = (paymentType, paymentMethodId, contract, amount) =>
	fetchApi('/stripe/singlePayment', {
		method: 'PATCH',
		body: {
			contract,
			amount: amount,
			paymentType: paymentType,
			paymentMethodId: paymentMethodId,
		},
	});

export const getStudioPriceFromContract = ({ studio_price }) => {
	return studio_price;
};

export const sentBankTransfer = (contractId) =>
	fetchApi('/contract/transfer/' + contractId, {
		method: 'PATCH',
	});

export const getSetupIntentApi = (receiverId: number, paymentMethodId: string) =>
	fetchApi(`/stripe/setupIntent`, {
		method: 'POST',
		body: {
			receiverId,
			paymentMethodId,
		},
	});

export const prepaymentApi = (price, contract, paymentMethodId) =>
	fetchApi('/stripe/prepayment/b2b', {
		method: 'POST',
		body: {
			price,
			contract,
			paymentMethodId,
		},
	});

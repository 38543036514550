import { unix } from 'moment'

const isWeekend = (_date) => {
	let date = _date
	let dayOfWeek = null
	if (typeof(date) === 'object') {
		dayOfWeek = date.getDay()
	} else {
		dayOfWeek = unix(date).day()	
	}
	return dayOfWeek === 0 || dayOfWeek === 6
}

export default isWeekend
import { useMemo } from 'react'
import useIsMobile from './useIsMobile'

function useLottie({
    width: widthFromImage,
    height: heightFromImage
}) {
    const isMobile = useIsMobile()
    const standartSizes = useMemo(() => isMobile
        ? {
            width: 300,
            height: 350
        } : {
            width: 500,
            height: 500
        }, [isMobile])

    const props = useMemo(() => {
        let width = widthFromImage
        let height = heightFromImage
        while (width > standartSizes.width || height > standartSizes.height) {
            width = width * 0.95
            height = height * 0.95
        }
        return {
            width,
            height
        }

    }, [standartSizes, widthFromImage, heightFromImage])

    return props
}

export default useLottie
import { godfatherStatuses } from 'constants/godfather.constant';
import React, { useContext } from 'react';
import { Store } from 'store/store';

const StatusGodson = ({ status }) => {
    const { state: { language: { godson: godsonLang } } } = useContext(Store)

    switch (status) {
        case godfatherStatuses.WAITING_FIRST_MISSION:
            return godsonLang.statuses[godfatherStatuses.WAITING_FIRST_MISSION]
        case godfatherStatuses.FIRST_MISSION_DONE:
            return godsonLang.statuses[godfatherStatuses.FIRST_MISSION_DONE]
        case godfatherStatuses.PAYMENT_DONE:
            return godsonLang.statuses[godfatherStatuses.PAYMENT_DONE]
        default:
            return false
    }
};

export default StatusGodson;
import React from 'react';
import classes from './UnAuthLayout.module.scss'

const UnAuthLayout = ({ children }) => {
    return (
        <div className={classes.container} >
            {children}
        </div>
    );
};

export default UnAuthLayout;
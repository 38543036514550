import { Modal } from 'semantic-ui-react'
import classes from './StyledModal.module.scss'
import CloseIcon from '@mui/icons-material/Close';
import Typography from 'components/Typography'
import Button from 'components/Button'
import { useEffect } from 'react'
import './StyledModal.scss'
import { IconButton } from '@material-ui/core'

function StyledModal({
	open,
	closeOnEscape,
	onClose,
	closeOnDimmerClick,
	title,
	children,
	button = null,
	button2 = null,
	onClick2 = () => {},
	onClick = () => {}
}) {
	const downHandler = ({ key }) => {
		if (open) {
			switch (key) {
				case 'Enter':
					return onClick()
				default:
			}
		}
	}
	useEffect(() => {
		window.addEventListener('keydown', downHandler)
		return () => {
			window.removeEventListener('keydown', downHandler)
		}
	}, [])
	return (
		<Modal
			{...{
				open,
				closeOnEscape,
				onClose,
				closeOnDimmerClick,
			}}
			closeIcon={false}
			className={classes.container}
		>
			<Modal.Header className={classes.header}>
				<Typography variant='subTitle1'>
					{title}
				</Typography>
				<IconButton className={classes.closeIcon} onClick={onClose}>
					<CloseIcon />
				</IconButton>
			</Modal.Header>
			<Modal.Content>
				<Modal.Description >
					{children}
				</Modal.Description>
			</Modal.Content>
			{!!button2 ? <div className={classes.flex}>
				{!!button && <Button onClick={onClick}>{button}</Button>}
				{!!button2 && <Button onClick={onClick2}>{button2}</Button>}
			</div> :
				!!button && <Button onClick={onClick}>{button}</Button>
			}
		</Modal>
	)
}

export default StyledModal

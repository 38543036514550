import React, { useContext } from 'react';
import { Store } from 'store/store';
import classes from 'styles/classes.module.scss'
import classNames from 'classnames';
import InfoProfileCard from 'components/InfoProfileCard';
import Button from 'components/Button';

const StudioProfile = () => {
    const { state: {
        language: { profile: profileLang },
        user
    } } = useContext(Store)
    return (
        <div>
            <div className={classNames(classes.flex, classes.flexWrap, classes.gap1)}>
                <Button link={'/user/update'} type="warning">{profileLang.updateInfoButton}</Button>
            </div>
            <InfoProfileCard {...user} denomination />
        </div>
    );
};

export default StudioProfile;
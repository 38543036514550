import { TimePicker } from 'antd';
import useLocale from 'hooks/useLocale';
import { Form } from 'semantic-ui-react';
import classes from './WeeklyAvailability.module.scss';
import { useCallback, useEffect, useState } from 'react';
import useLanguage from 'hooks/useLanguage';
import localeFr from 'antd/es/date-picker/locale/fr_FR';
import localeEn from 'antd/es/date-picker/locale/en_US';
import { fetchApi } from 'containers/helpers';
import Input from 'components/Form/Input';
import toast from 'components/toast';
import externalClasses from 'styles/classes.module.scss';
import dayjs from 'dayjs';
import { isEmpty, pick } from 'lodash';
import Typography from 'components/Typography';

localeFr.lang.locale = 'fr';
localeEn.lang.locale = 'en';

const WeeklyAvailability = () => {
	const [customise, setCustomise] = useState(false);
	const lang = useLanguage('weeklyAvailability');

	const [days, setDays] = useState({
		monday: ['9:00', '18:00'],
		tuesday: ['9:00', '18:00'],
		wednesday: ['9:00', '18:00'],
		thursday: ['9:00', '18:00'],
		friday: ['9:00', '18:00'],
		saturday: ['9:00', '18:00'],
		sunday: ['9:00', '18:00'],
	});
	const locale = useLocale();

	useEffect(() => {
		fetchApi(`/weeklyAvailability/freelance`)
			.then((weeklyAvailability) => {
				if (isEmpty(weeklyAvailability)) return;
				setDays(pick(weeklyAvailability, Object.keys(days)));
			})
			.catch((e) => {});
	}, []);

	const handleSubmit = useCallback(async () => {
		try {
			const weeklyAvailability = await fetchApi('/weeklyAvailability/freelance', {
				method: 'PATCH',
				body: days,
			});

			setDays(pick(weeklyAvailability, Object.keys(days)));
			toast.success(lang.updatedSuccessfully);
		} catch (err) {
			toast.error(lang.updatedError);
		}
	}, [days, lang]);

	const handleChange = useCallback(
		(e, day = null) => {
			if (!e || !e.length) return;

			const [start, end] = e;
			const formattedStart = start.format('HH:mm');
			const formattedEnd = end.format('HH:mm');

			if (customise) {
				setDays({
					...days,
					[day]: [formattedStart, formattedEnd],
				});
			} else {
				const newDays = Object.keys(days).reduce(
					(acc, currentDay) => ({
						...acc,
						[currentDay]: [formattedStart, formattedEnd],
					}),
					{},
				);

				setDays(newDays);
			}
		},
		[days, customise],
	);

	return (
		<div className={externalClasses.mb2}>
			<Typography variant="subTitle1">{lang.openingHours}</Typography>
			<Form>
				<Input
					type="checkbox"
					label={lang.customise}
					name="customise"
					onChange={(e) => setCustomise(e.checked)}
					value={String(customise)}
				/>

				{customise ? (
					Object.entries(days).map(([key, day]) => (
						<Form.Field key={key}>
							<label className={classes.label}>{lang[key]}</label>
							<TimePicker.RangePicker
								locale={locale === 'fr' ? localeFr : localeEn}
								className={classes.input}
								name={key}
								value={[dayjs(day[0], 'HH:mm'), dayjs(day[1], 'HH:mm')]}
								onChange={(e) => handleChange(e, key)}
							/>
						</Form.Field>
					))
				) : (
					<Form.Field>
						<label className={classes.label}>{lang.days}</label>
						<TimePicker.RangePicker
							locale={locale === 'fr' ? localeFr : localeEn}
							className={classes.input}
							name="days"
							value={[dayjs(days['monday'][0], 'HH:mm'), dayjs(days['monday'][1], 'HH:mm')]}
							onChange={(e) => handleChange(e, 'monday')}
						/>
					</Form.Field>
				)}

				<Form.Button className={classes.button} type="submit" onClick={handleSubmit} positive={true}>
					{lang.save}
				</Form.Button>
			</Form>
		</div>
	);
};

export default WeeklyAvailability;

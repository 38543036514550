import classes from './StudioPublicProfile.module.scss'
import useLanguage from 'hooks/useLanguage'
import Typography from 'components/Typography'
import StarRating from 'components/StarRating/StarRating'
import { getDateFormatted } from 'modules/dates'
import classNames from 'classnames'

const StudioPublicProfile = ({
    img,
    displayName,
    address,
    rating,
    nRatings,
    created_at
}) => {
    const profileLang = useLanguage('profile')

    return (<div className={classes.container}>
        <div className={classes.topContainer}>
            <div
                style={{
                    background: `url(${img || '/assets/default-img.png'}`,
                }}
                className={classes.img}
            />
            <div className={classes.freelanceInfosContainer}>
                <div>
                    <div className={classes.inlineBlock}>
                        <Typography
                            classes={{ root: classNames(classes.name, classes.inlineBlock) }}
                            primaryColor
                            variant='subTitle2'
                        >
                            {displayName}
                        </Typography>
                    </div>
                    <div className={classes.ratingLine}>
                        <StarRating size='little' rating={rating} />
                        <span className={classes.ratingsCount}>
                            ({nRatings || 0})
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div className={classes.bottomContainer}>
            <div className={classNames(classes.padding1, classes.fullWidth, classes.bottomContainerContent)}>
                <div className={classes.badgesContainer}>
                    <Typography primaryColor>{address}</Typography>
                </div>
                <Typography italic >{profileLang.memberSince} {getDateFormatted(created_at)}</Typography>
            </div>
        </div>
    </div>)
}
export default StudioPublicProfile;
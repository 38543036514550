import MyTable from 'components/MyTable/MyTable';
import useLanguage from 'hooks/useLanguage';
import React, { useContext, useMemo } from 'react';
import { getFreelanceMissionStatus, transformMissionsToContracts } from '../helpers';
import DateTooltip from 'components/DateTooltip'
import { pick } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { Store } from 'store/store';
import { UserTypes } from 'constants/UserTypes';
import ContractsStatusCount from './ContractsStatusCount';
import DayCount from './DayCount';
import moment from 'moment-timezone';
import { useDayStatuses } from 'hooks/useDayStatuses';
import StatusMission from 'components/StatusMission';

const MissionListComponent = ({
    missions = []
}) => {
    const navigate = useNavigate()
    const lang = useLanguage('mission')
    const { state: { user: { type } } } = useContext(Store)

    const columns = useMemo(() => [
        process.env.NODE_ENV === 'development' && { title: lang.columns.id, type: 'numeric', field: 'missionId' },
        { title: lang.columns.title, type: 'string', field: 'title' },
        { title: lang.columns.description, type: 'string', field: 'description' },
        { title: lang.columns.dates, render: ({ dates = [], ...props }) => <DateTooltip dates={dates} /> },
        { title: lang.columns.dayCount, render: ({ days, ...props }) => <DayCount days={Object.keys(days)} /> },
        { title: lang.columns.status, render: ({ status, ...rest }) => <StatusMission status={status} mission={rest} /> },
        type === UserTypes.STUDIO ? {
            title: lang.columns.contractSignedCount,
            render: ({ contracts = [], ...props }) => <ContractsStatusCount contracts={contracts} />
        } : null,
    ].filter(e => e), [lang, type])

    const { dayStatusesLang } = useDayStatuses()

    const data = useMemo(() => {
        const getMostRecentDate = (dates = []) => new Date(dates.sort((a, b) => new Date(b).getTime() - new Date(a).getTime())[0]).getTime()
        const sortByRecent = (a, b) =>
            getMostRecentDate((Object.keys(b.days) || []).map(key => key))
            -
            getMostRecentDate((Object.keys(a.days) || []).map(key => key))

        if (type === UserTypes.FREELANCE) {
            return transformMissionsToContracts(missions)
                .filter(({ days }) => !!days && Object.keys(days).length > 0)
                .sort(sortByRecent)
                .map((mission, index) => ({
                    ...(pick(mission, [
                        'title',
                        'description',
                        'days',
                        'contractId',
						'contract',
						'appointments',
						'proposals'
                    ])),
                    status: mission.status,
                    statusLang: dayStatusesLang[getFreelanceMissionStatus(mission)],
                    dates: (Object.keys(mission.days) || []).map((date) => moment(date, 'DD/MM/YYYY')),
                    id: index,
                    missionId: mission.id
                }))
        }

        return missions
            .filter(({ days }) => !!days && Object.keys(days).length > 0)
            .map((mission, index) => ({
                ...(pick(mission, [
                    'id',
                    'title',
                    'description',
                    'contracts',
                    'days'
                ])),
                statusLang: dayStatusesLang[getFreelanceMissionStatus(mission)],
                dates: (Object.keys(mission.days) || []).map(date => moment(date, 'DD/MM/YYYY')),
                missionId: mission.id
            }))
            .sort(sortByRecent)
    }, [type, missions, dayStatusesLang])

    // const onlyStatusMission = useMemo(() => data.map(({ statusLang }) => statusLang), [data])

    // const dayStatusesKeysAvailables = useMemo(
    //     () => [...new Set(Object
    //         .values(dayStatusesLang)
    //         .filter(key => onlyStatusMission.includes(key)))]
    //     , [onlyStatusMission])

    const onRowClick = (cellMeta, mission) => {
        navigate(
            // mission.contractId
                // ? `/mission/${mission.missionId}/contract/${mission.contractId}`
                '/mission/page/' + mission.missionId
        )
    }

    return (
        <MyTable
            onRowClick={onRowClick}
            columns={columns}
            data={data}
            options={{
                paging: false,
            }}
            // filters={{
            //     statusLang: { values: dayStatusesKeysAvailables, title: lang.columns.status }
            // }}
        />
    );
};

export default MissionListComponent;
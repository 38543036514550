import classNames from 'classnames';
import RightSide from 'components/RightSide/RightSide';
import Typography from 'components/Typography';
import SingleMissionCalendar from 'containers/calendar/components/SingleMissionCalendar';
import { useMission } from 'hooks/useMission';
import React, { useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import MissionTitle from '../components/MissionTitle';
import ContractButton from '../components/ContractButton';
import classes from '../MissionPage/MissionPage.module.scss';
import AppointmentReviews from 'containers/review/components/AppointmentReviews';
import MenuNavigationComponent from '../components/MenuNavigationComponent';
import useLanguage from 'hooks/useLanguage';
import IssuesByContract from 'containers/issue/components/IssueByContract';
import Contract from 'containers/contract/Contract';
import { Store } from 'store/store';
import { UserTypes } from 'constants/UserTypes';
import StudioPublicProfileContainer from 'containers/studio/StudioPage/StudioPublicProfileContainer';
import Didactiel from 'components/Didactiel';
import useIsMobile from 'hooks/useIsMobile';
import ContractInvoice from '../components/ContractInvoice';
import { dayStatuses } from 'constants/DayStatuses';

const MissionContractPage = () => {
    const { user } = useContext(Store).state
    const lang = useLanguage('mission')
    const params = useParams()
    const [missionId, contractId] = useMemo(() => [params.missionId, params.contractId].map(Number), [params])
    const { mission, contract, isReviewVisible, days, ...props } = useMission(missionId, contractId)

    const components = useMemo(() => [
        {
            title: lang.calendar,
            component: mission &&
                <SingleMissionCalendar
                    mission={mission}
                    contract={contract}
                />,
        },
        contract && ((user.type === UserTypes.STUDIO && contract.studio_contract) || (user.type === UserTypes.FREELANCE && contract.freelance_contract)) && {
            title: lang.myContract,
            component: contract && <Contract contract={contract} />
        },
        user.type === UserTypes.STUDIO
        && contract
        && contract.status >= dayStatuses.WAITING_REVIEW
        && {
            title: lang.myInvoice,
            component: <ContractInvoice
                invoiceId={contract.studio_invoice}
            />
            ,
        },
        isReviewVisible && {
            title: lang.review,
            component: <AppointmentReviews appointmentId={contract?.appointment_id} />
        },
        {
            title: lang.myMissionIssues,
            component: <IssuesByContract contractId={contractId} />
            ,
        },
        user.type === UserTypes.FREELANCE && {
            title: lang.studioDetails,
            component: <StudioPublicProfileContainer
                userId={mission.user}
            />
            ,
        },
    ].filter(e => !!e),
        [mission, lang, contractId, isReviewVisible, contract, user])
    const isMobile = useIsMobile()
    return (
        <RightSide>
            <Didactiel didactielType={"sign_contract"} isMobile={isMobile} />
            <MissionTitle
                mission={mission}
                {...mission}
                {...props}
            >
            </MissionTitle>
            <div className={classNames(classes.flex, classes.flexWrap, classes.mb3)}>
                <Typography primaryColor classes={{ root: classes.descriptionContainer }}>
                    {mission.description}
                </Typography>
                {contract && <ContractButton
                    dayStatusesLang={props.dayStatusesLang}
                    contractId={contract.id}
                    missionId={missionId}
                    {...contract}
                />}
            </div>
            <MenuNavigationComponent
                components={components}
            />
        </RightSide>
    );
};

export default MissionContractPage;
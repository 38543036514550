export const FR = {
    filterBy: 'Filtrer par',
    selectSome: 'Sélectionner un',
}

export const EN = {
    filterBy: 'Filter by',
    selectSome: 'Select one',
}

export default {
    fr: FR,
    us: EN,
}

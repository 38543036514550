import Button from 'components/Button/Button';
import ExternalLink from 'components/ExternalLink';
import ExternalScheduler from 'components/ExternalScheduler';
import Typography from 'components/Typography'
import { getAllOtherUsersStartTime_api, getUserMeeting_api } from 'containers/meeting/helpers';
import useLanguage from 'hooks/useLanguage';
import { useSockets } from 'hooks/useSockets';
import { timeDateFormat } from 'modules/dates';
import React, { useEffect, useMemo, useState } from 'react';
import { socket, removeEvent } from 'socket';
import classes from '../Onboarding/Onboarding.module.scss'

const MeetingScheduled = ({ setLoading = () => { } }) => {
    const [updateMode, setUpdateMode] = useState(false)
    const lang = useLanguage('meeting')
    const [scheduledTime, setScheduledTime] = useState(null)
    const init = async () => {
        try {
            const scheduledTimes = await getUserMeeting_api()

            if (scheduledTimes.length) {
                setScheduledTime(scheduledTimes[0])
            }
        } catch (e) {
            console.error(e)
            alert(e.message)
        }
    }

    useEffect(() => {
        if (!updateMode) {
            init()
        }
    }, [updateMode])
    const { socketConnected } = useSockets()

    useEffect(() => {
        if (scheduledTime && scheduledTime.id) {
            if (socketConnected && socket) {
                socket.emit('join_meeting', scheduledTime.id);
                socket.on(`meeting updated`, () => {
                    init()
                });
            }
            return () => {
                if (socketConnected && socket) {
                    socket.emit('leave_meeting', scheduledTime.id);
                    removeEvent(`meeting updated`);
                }
            };
        }
    }, [scheduledTime, socketConnected])

    const scheduledStartTime = useMemo(() => scheduledTime ? timeDateFormat(scheduledTime.start) : '', [scheduledTime])
    return (updateMode ? <div>
        <Button className={classes.mb2} onClick={() => setUpdateMode(!updateMode)} >{lang.stopUpdateMeeting}</Button>
        <ExternalScheduler
            getBlockedTimes={getAllOtherUsersStartTime_api}
            getScheduledTimes={getUserMeeting_api}
        />
    </div> : <div>
        <Typography primaryColor variant='subTitle1'>{lang.meetingScheduled.replace('$1', scheduledStartTime)}<br /><br /></Typography>
        {scheduledTime && scheduledTime.link && <Typography primaryColor ><ExternalLink link={scheduledTime.link} text={lang.meetingLink} /><br/></Typography>}
        <Button className={classes.mt2} onClick={() => setUpdateMode(!updateMode)} >{lang.updateMeeting}</Button>
    </div>
    );
};

export default MeetingScheduled;
import { useCallback, useState } from 'react'
import Loader from 'components/Loader'
import Button from 'components/Button'
import classes from '../components/Issue.module.scss'

function CreateLevelUpIssue({
    onSubmit: onSubmitFromParams,
    lang,
    issueType,
}) {
    const [loading, setLoading] = useState(false)

    const onSubmit = useCallback((values) => {
        setLoading(true)
        const toSubmit = {
            type: issueType,
            title: 'Je veux augmenter mon level',
            description:'Je veux augmenter mon level',
        }

        return onSubmitFromParams(toSubmit)


    }, [issueType, onSubmitFromParams])


    return (loading ? <Loader /> :
            <Button onClick={onSubmit}>{lang.levelUp.buttonTitle}</Button>
    )
}

export default CreateLevelUpIssue

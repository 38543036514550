import Button from 'components/Button/Button';
import FormComponent from 'components/Form/Form';
import TitlePage from 'components/TitlePage/TitlePage';
import toast from 'components/toast/toast';
import { fetchApi, roundTo2Dec } from 'containers/helpers';
import useLanguage from 'hooks/useLanguage';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classes from './ManageService.module.scss';
import useIsMobile from 'hooks/useIsMobile';

const ManageForfait = ({ forfaitId = null, onUpdate = () => {}, services: servicesFromParams = [] }) => {
	const services = useMemo(() => servicesFromParams.filter((e) => !!!e.forfait), [servicesFromParams]);
	const isMobile = useIsMobile();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const lang = useLanguage('services');
	const [forfait, setForfait] = useState();
	const onSubmit = useCallback(
		async (values) => {
			try {
				setLoading(true);
				values.slots *= 4;
				const forfaitReceived = await fetchApi(`/forfait${forfaitId ? `/${forfaitId}` : ''}`, {
					method: forfaitId ? 'PATCH' : 'POST',
					body: values,
				});
				setLoading(false);
				onUpdate();
				forfaitId
					? setForfait((s) => ({ ...s, ...forfaitReceived }))
					: navigate('/service/forfait/' + forfaitReceived.id);
			} catch (error) {
				setLoading(false);
				toast.error(error.message);
			}
		},
		[forfaitId, navigate, onUpdate],
	);

	const removeForfait = useCallback(async () => {
		try {
			setLoading(true);
			await fetchApi(`/forfait/${forfaitId}`, { method: 'DELETE' });
			setLoading(false);
			onUpdate();
			navigate('/services');
		} catch (error) {
			setLoading(false);
			toast.error(error.message);
		}
	}, [forfaitId, navigate, onUpdate]);

	useEffect(() => {
		if (forfaitId) {
			fetchApi('/forfait/' + forfaitId)
				.then(setForfait)
				.catch((e) => console.log(e));
		}
	}, [forfaitId]);

	const [forfaitFromForm, setForfaitFromForm] = useState({});

	const onUpdateForm = useCallback(
		(values) => {
			const valuesToSend = { ...values };
			valuesToSend.slots *= 4;
			setForfaitFromForm(valuesToSend);
		},
		[setForfaitFromForm],
	);

	const value = useMemo(() => {
		const { price, slots } = isEmpty(forfaitFromForm) ? (isEmpty(forfait) ? {} : forfait) : forfaitFromForm;
		if (!price || !slots) {
			return false;
		}
		return roundTo2Dec(price / (slots >= 4 ? slots / 4 : slots));
	}, [forfait, forfaitFromForm]);

	const inputs = useMemo(
		() =>
			[
				{
					type: 'autocomplete',
					name: 'services',
					label: lang.services,
					value: forfait?.services || null,
					required: true,
					silentRequired: true,
					fluid: true,
					options: services,
					info: lang.servicesInfo,
					multi: true,
					idForExtern: true,
				},
				{
					type: 'text',
					name: 'title',
					label: lang.columnList.title,
					placeholder: 'Coupe femme + brushing',
					value: forfait?.title || null,
					// info: forfaitFromForm?.services?.map((id) => services.find((s) => s.id === id).title).join(' + ') || '',
					required: true,
					silentRequired: true,
				},
				{
					type: 'number',
					name: 'price',
					label: lang.columnList.price,
					required: true,
					silentRequired: true,
					// info: forfaitFromForm?.services?.map((id) => services.find((s) => s.id === id).price).reduce((prev, actual) => prev + actual, 0),
					value: forfait?.price || null,
				},
				{
					type: 'number',
					name: 'slots',
					label: lang.columnList.slots,
					legend: lang.slotsInfo,
					required: true,
					silentRequired: true,
					// info: forfaitFromForm?.services?.map((id) => services.find((s) => s.id === id).slots).reduce((prev, actual) => prev + actual, 0),
					value: (forfait?.slots || 0) / 4,
				},
			].filter((e) => !!e),
		[lang, forfait, services /*, forfaitFromForm*/],
	);

	return (
		<div className={classes.centeredContainer}>
			<div className={classes.taCenter}>
				<TitlePage subtitle={value && <div>{lang.infoPrice + value + '€'}</div>}>
					{lang[forfaitId ? 'updateForfait' : 'createForfait']}
				</TitlePage>
				<div>
					<FormComponent
						onUpdate={onUpdateForm}
						messages={{ submit: lang[forfaitId ? 'updateForfait' : 'createForfait'] }}
						loading={loading}
						sendForm={onSubmit}
						inputs={inputs}
					/>
				</div>
				<Button className={isMobile ? '' : classes.removeButton} type="danger" onClick={removeForfait}>
					{lang.removeForfait}
				</Button>
			</div>
		</div>
	);
};

export default ManageForfait;

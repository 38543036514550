import { Grid } from '@material-ui/core'
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { useEffect, useState } from 'react'
import { Loader } from 'semantic-ui-react'
import './Calendar.scss'
import moment from 'moment-timezone'
import 'moment/locale/fr'
import useLanguage from 'hooks/useLanguage'

const localizer = momentLocalizer(moment)

const defaultStyle = {
	width: '100%',
	height: '100%',
}

const Calendar = ({
	defaultDate,
	defaultView,
	parseEvent,
	events,//: _eventsWithTimezone,
	views,
	min,
	max,
	step,
	onSelecting,
	onSelectEvent,
	eventPropGetter,
	timeslots,
	components,
	dontParseEvents,
	selected,
	onSelectSlot,
	onRangeChange,
	loading,
	selectable,
	style,
	monthOnly = false,
	schedulerType = 'scheduler'
}) => {
	// Function reparses all days to floor, and offsets with d3's timeDay.offset
	// as it guarantees a 24 hour difference (event during Daylight Saving's Time)
	// const events = useMemo(() => dontParseEvents ? _eventsWithTimezone : _eventsWithTimezone.map(event => {
	// 	// if (type === UserTypes.FREELANCE)
	// 	// 	return onlyAverageDay(event);
	// 	return (event)
	// }), [_eventsWithTimezone, dontParseEvents])

	const [currentDate, setCurrentDate] = useState(defaultDate ? defaultDate : new Date())

	const onNavigate = (date) => {
		setCurrentDate(moment(date).toDate())
	}

	useEffect(() => {
		setCurrentDate(defaultDate)
	}, [defaultDate])

	const lang = useLanguage('calendar')

	return (
		<div className={loading ? 'calendar-loading' : ''}>
			{loading && (
				<Loader className='calendar-loader' active size='big' />
			)}
			<Grid
				container
				className='calendar-container page-container border-radius-5 shadow bg-white'
			>
				<BigCalendar
					className={schedulerType}
					onNavigate={onNavigate}
					showMultiDayTimes={true}
					messages={{
						next: lang.next,
						previous: lang.previous,
						today: lang.today,
						month: lang.month,
						week: lang.week,
						day: lang.day,
						noEventsInRange: lang.noEventsInRange,
					}}
					date={currentDate}
					defaultDate={defaultDate}
					onSelectEvent={onSelectEvent}
					eventPropGetter={eventPropGetter}
					culture={lang.culture}
					onRangeChange={onRangeChange}
					onSelecting={onSelecting}
					step={step}
					components={components}
					views={monthOnly ? ['month'] : undefined}
					min={min}
					max={max}
					timeslots={timeslots}
					defaultView={defaultView}
					selectable={selectable}
					onSelectSlot={onSelectSlot}
					selected={selected}
					localizer={localizer}
					events={events}
					style={style || defaultStyle}
				/>
			</Grid>
		</div>
	)
}

export default Calendar

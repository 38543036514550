import { isNumber, isString } from "lodash"
import { useCallback, useEffect, useState } from "react"
import { noSpaceRegex } from "./helpers"

export const useTableHeader = (rows, setRows) => {
    const filterBySearch = (value, rows) => {
        const newValue = String(value)
            .toLocaleLowerCase()
            .replace(noSpaceRegex, '')

        if (newValue) {
            const searchableRows = rows.map(row => Object.entries(row)
                .reduce((prev, [key, value]) =>
                    isString(value) || isNumber(value) ? [
                        ...prev,
                        String(value)
                            .toLocaleLowerCase()
                            .replace(noSpaceRegex, '')
                    ] : prev, [])
            )
            const filteredRows = rows
                .filter((row, index) =>
                    searchableRows[index]
                        .find(rowValue => rowValue.includes(newValue))
                )
            return filteredRows
        }

        return rows

    }

    const filterByFilter = (valueObj, rows) => {
        const key = Object.keys(valueObj)[0]
        const values = valueObj[key]

        if (!values || values.length === 0) {
            return rows
        }


        const newValues = values.map(
            value => String(value)
                .toLocaleLowerCase()
                .replace(noSpaceRegex, '')
        )

        const filteredRows = rows
            .filter((row, index) => {
                const rowToCompare = row[key]
                    .toLocaleLowerCase()
                    .replace(noSpaceRegex, '')
                return !!newValues.find(newValue => rowToCompare.includes(
                    newValue
                        .toLocaleLowerCase()
                        .replace(noSpaceRegex, '')
                ))
            }
            )
        return filteredRows
    }

    const [filters, setFilters] = useState({
        search: '',
        filter: null
    })


    const updateWithFilters = useCallback(() => {
        let newRows = [...rows]
        if (filters.search) {
            newRows = filterBySearch(filters.search, newRows)
        }
        if (filters.filter) {
            newRows = filterByFilter(filters.filter, newRows)
        }

        setRows(newRows)
    }, [filters, rows, setRows])

    useEffect(() => {
        updateWithFilters()
    }, [filters, rows])

    const updateFilters = (type, value) => {
        setFilters(filters => ({
            ...filters,
            [type]: value
        }))
    }

    return {
        updateFilters
    }
}
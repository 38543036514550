const jsonHeaders = {
	Accept: 'application/json',
	'Content-Type': 'application/json',
}

const headers = {
	'Access-Control-Request-Headers': '*',
	'Access-Control-Request-Method': '*',
	'Access-Control-Allow-Origin': '*',
}

const getHeaders = (multiform = false) => ({
	...headers,
	...(multiform ? {} : jsonHeaders),
	'x-refresh-token': localStorage.getItem('refreshToken'), // await SecureStore.getItemAsync('refreshToken', {}),
	'x-access-token': localStorage.getItem('token'), // await SecureStore.getItemAsync('userToken', {}),
	'x-language': localStorage.getItem('language'),
})

export default getHeaders
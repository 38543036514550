import Typography from 'components/Typography';
import useLanguage from 'hooks/useLanguage';
import React, { useMemo } from 'react';
import LastMissionItem from './LastMissionItem';
import classes from './LastMission.module.scss'

const LastMissions = ({
    appointments = [{
        title: '',
        dates: [],
        status: '',
    }]
}) => {
    const lang = useLanguage('freelanceSelected')

    const rows = useMemo(() => appointments
        .slice(0, 3),
        [appointments]
    )
    return (
        <div>
            <Typography primaryColor variant='subTitle2' classes={{root: classes.taCenter}}>{lang.lastMissions}</Typography>
            <div className={classes.inlineBlock}>
            {rows.map((appointment, key) => <LastMissionItem
                key={key}
                {...appointment} />
            )}
            </div>
        </div>
    );
};

export default LastMissions;
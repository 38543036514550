export const FR = {
	title: "M'abonner pour débloquer ma boutique",
}

export const EN = {
	title: 'Register to unlock my store',
}

export default {
	fr: FR,
	us: EN,
}

export const roles = {
	SUPERADMIN: 1,
	ADMIN:      2,
	USER:       0,
  }
  
  export const plans = {
	UNLIMITED:    3,
	PREMIUM:      2,
	PROFESSIONAL: 1,
	FREEMIUM:     0,
  }
  
  export const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY
  export default {
	roles,
	plans,
	stripePublicKey,
  }
  
import { useState, useEffect, useMemo, useCallback, useContext } from 'react'
import { Message } from 'semantic-ui-react'
import BinaryModal from 'components/BinaryModal'
import Calendar from 'components/Calendar/Calendar'
import DisabledDates from 'components/Calendar/DisabledDates'
import isWeekend from 'common/utils/date/isWeekend'
import {
	scheduleMeeting_api,
	cancelMeeting_api,
} from 'containers/meeting/helpers'
import moment from 'moment-timezone'
import useLanguage from 'hooks/useLanguage'
import { timeDateFormat } from 'modules/dates'
import Button from 'components/Button'
import './ExternalScheduler.css'
import classes from './ExternalScheduler.module.scss'
import toast from 'components/toast'
import { Store } from 'store/store'
import { useSockets } from 'hooks/useSockets'
import { removeEvent, socket } from 'socket'

function ExternalScheduler({
	onChangeMeeting,
	getBlockedTimes,
	getScheduledTimes,
	elementId,
}) {
	const [scheduledTime, setScheduledTime] = useState(null)
	const [proposedTime, setProposedTime] = useState(null)
	const [blockedTimes, setBlockedTimes] = useState({})
	const [scheduleMode, setScheduleMode] = useState('schedule')
	const [modalOpen, setModalOpen] = useState(false)
	const { id: userId } = useContext(Store).state.user
	const { socketConnected } = useSockets()

	const init = useCallback(async () => {
		try {
			const blockedTimes = await getBlockedTimes()
			const scheduledTimes = await getScheduledTimes()
			if (scheduledTimes.length) {
				setScheduleMode('reschedule')
				setScheduledTime(scheduledTimes[0])
			}

			if (blockedTimes.length) {
				setBlockedTimes(blockedTimes)
			}
		} catch (e) {
			console.error(e)
			alert(e.message)
		}
	}, [getBlockedTimes, getScheduledTimes])

	useEffect(() => {
		if (socketConnected && socket) {
			socket.emit('join_meetings');
			socket.on(`meeting updated`, () => {
				init()
			});
		}
		return () => {
			if (socketConnected && socket) {
				socket.emit('leave_meetings');
				removeEvent(`meeting updated`);
			}
		};
	}, [socketConnected])

	useEffect(() => {
		init()
	}, [userId])

	const now = new Date()
	const _9AM = new Date(2020, 1, 0, 9, 0, 0)
	const _7PM = new Date(2020, 1, 0, 19, 0, 0)

	const disableDates = (date) => {
		// only dates after today
		if (date < now) {
			return true
		}

		//only on non blocked dates
		let i = 0
		while (blockedTimes.length > i) {
			const { start } = blockedTimes[i]
			if (moment(start).isSame(date)) {
				return true
			}
			i++
		}

		//only weekdays
		if (isWeekend(date)) {
			return true
		}

		// only business hours
		const hour = date.getHours()
		if (hour >= 13 && hour < 14) {
			return true
		}
		return false
	}

	const onSelectSlot = (e) => {
		if (scheduledTime) {
			setScheduleMode('reschedule')
		}
		if (!disableDates(e.slots[0])) {
			setProposedTime({
				start: e.slots[0],
				end: e.slots[1],
			})
			setModalOpen(true)
		}
	}

	const scheduleMeeting = useCallback(() => {
		const { start, end } = proposedTime
		setModalOpen(false)
		scheduleMeeting_api({
			userId,
			start,
			end,
			elementId,
		}).then((res) => {
			setScheduledTime({
				id: res.id,
				...proposedTime,
			})
			setProposedTime(null)
			if (onChangeMeeting) {
				onChangeMeeting(res.id)
			}
		}).catch(error => {
			toast.error(error.message)
		})
	}, [elementId, onChangeMeeting, proposedTime, userId])

	const cancelScheduledMeeting = useCallback(async () => {
		setModalOpen(false)
		setScheduleMode('schedule')
		try {
			await cancelMeeting_api({ meetingId: scheduledTime.id })
			setScheduledTime(null)
			if (onChangeMeeting) {
				onChangeMeeting(null)
			}
		} catch (e) {
			console.error(e)
		}
	}, [onChangeMeeting, scheduledTime])

	const rescheduleMeeting = useCallback(async () => {
		await cancelScheduledMeeting()
		scheduleMeeting()
	}, [cancelScheduledMeeting, scheduleMeeting])

	const cancelProposedMeeting = () => {
		setModalOpen(false)
		setProposedTime(null)
	}

	function onClickEvent(e) {
		setModalOpen(true)
		setScheduleMode('cancel')
	}

	function closeModal() {
		setModalOpen(false)
	}

	const proposedStartTime = useMemo(
		() => (proposedTime ? timeDateFormat(proposedTime.start) : ''),
		[proposedTime]
	)

	const scheduledStartTime = useMemo(
		() => (scheduledTime ? timeDateFormat(scheduledTime.start) : ''),
		[scheduledTime]
	)

	const lang = useLanguage('meeting')

	const modalTypes = useMemo(
		() => ({
			schedule: {
				...lang.modalTypes.schedule,
				description: lang.modalTypes.schedule.description.replace(
					'$1',
					proposedStartTime
				),
				onAction: scheduleMeeting,
				onCancel: cancelProposedMeeting,
			},
			cancel: {
				...lang.modalTypes.cancel,
				description: lang.modalTypes.cancel.description.replace(
					'$1',
					scheduledStartTime
				),
				onAction: cancelScheduledMeeting,
				onCancel: closeModal,
			},
			reschedule: {
				...lang.modalTypes.reschedule,
				description: lang.modalTypes.reschedule.description.replace(
					'$1',
					proposedStartTime
				),
				onAction: rescheduleMeeting,
				onCancel: cancelProposedMeeting,
			},
		}),
		[
			cancelScheduledMeeting,
			lang,
			proposedStartTime,
			rescheduleMeeting,
			scheduleMeeting,
			scheduledStartTime,
		]
	)

	const EventWrapper = (props) => {
		return <div onClick={onClickEvent}>{props.children}</div>
	}

	const components = {
		eventWrapper: EventWrapper,
		timeSlotWrapper: DisabledDates(disableDates),
	}

	const {
		header,
		description,
		onCancel,
		onAction,
		cancelButtonText,
		actionButtonText,
	} = modalTypes[scheduleMode]

	const eventPropGetter = () => ({
		className: classes.event,
	})

	return (
		<>
			{scheduledTime ? (
				<Message floating>
					{lang.meetingScheduled.replace('$1', scheduledStartTime)}
					{!!scheduledTime.link && (
						<div>
							<Button external link={scheduledTime.link}>
								{lang.meetingLink}
							</Button>
						</div>
					)}
				</Message>
			) : null}
			<Calendar
				defaultDate={scheduledTime ? Date.parse(scheduledTime.start) : undefined}
				eventPropGetter={eventPropGetter}
				selectable={true}
				popup={true}
				components={components}
				timeslots={1}
				step={30}
				views={{ week: true }}
				min={_9AM}
				max={_7PM}
				dontParseEvents={true}
				events={scheduledTime ? [scheduledTime] : []}
				onSelectSlot={onSelectSlot}
				defaultView='week'
				schedulerType='externalScheduler'
			/>
			<BinaryModal
				onClose={onCancel}
				open={modalOpen}
				header={header}
				description={description}
				onCancel={onCancel}
				onAction={onAction}
				cancelText={cancelButtonText}
				actionText={actionButtonText}
			/>
		</>
	)
}

export default ExternalScheduler

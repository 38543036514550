import Loader from 'components/Loader';
import { fetchApi, urlParametersToJson } from 'containers/helpers';
import React from 'react';
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useLocation, useNavigate } from 'react-router-dom/dist';


const Marketing = () => {
    const params = useParams()
    const { search } = useLocation()
    const navigate = useNavigate();

    useEffect(() => {
        const { campaignKey, email } = params || {}
        if (campaignKey && email) {
            fetchApi(`/clickEmail/${campaignKey}/${email}`)
                .then(response => {
                    console.log('response', response)
                    const paramsGetted = urlParametersToJson(search)
                    let redirectTo = '/'
                    if (paramsGetted && paramsGetted.redirect) {
                        redirectTo = paramsGetted.redirect
                    }
                    navigate(redirectTo)

                })
        }
    }, [params, search])
    return (
        <Loader />
    );
};

export default Marketing;
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@mui/material';
import { upperFirst } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import './HeaderTable.scss'
import Typography from 'components/Typography';
import classNames from 'classnames';
import classes from './Filters.module.scss'
import useLanguage from 'hooks/useLanguage';

const FiltersTable = ({
    className = '',
    filters = {},
    updateFilters = () => { }
}) => {
    const lang = useLanguage('table')
    const key = useMemo(() => Object.keys(filters)[0], [filters])
    const values = useMemo(() => (filters[key] || { values: [] }).values || [], [key, filters])
    const title = useMemo(() => (filters[key] || { title: '' }).title || '', [filters, key])
    const selectValue = useCallback((event, newValue) => {
        updateFilters({ [key]: newValue });
    }, [key])

    return (
        <div className={classNames(classes.flex, classes.filterContainer)}>
            <Typography classes={{ root: classes.alignSelf }}>
                {lang.filterBy + ' ' + upperFirst(title)}
            </Typography>
            <Autocomplete
                className={'autocomplete' + className}
                multiple
                onChange={selectValue}
                disablePortal
                id="filter-table"
                options={values}
                sx={{ width: 250 }}
                renderInput={(params) => <TextField
                    {...params}
                    variant="standard"
                    placeholder={lang.selectSome + ' ' + title}
                />}
            />
        </div>
    );
};

export default FiltersTable;
export const FR = {
	missionTitle: 'Titre de la mission',
	title: 'Titre',
	missionDescription: 'Description de la mission',
	descriptionPlaceholder: 'Décrivez les détails de la mission: horaires, qualités recherchées, ambiance, ...',
	maximum: 'Maximum 500 caractères.',
	copyFromPreviousMission: 'copier les données d\'une mission précédente',
	nextStep: 'Étape suivante',
	startDate: 'Heure de début de journée favorite',
	endDate: 'Heure de fin de journée favorite',
}

export const EN = {
	missionTitle: 'Mission title',
	title: 'Title',
	missionDescription: 'Mission description',
	descriptionPlaceholder: 'Describe mission details: worktime, freelance personality, mood, ...',
	maximum: 'Maximum 500 chars.',
	copyFromPreviousMission: 'copy datas from previous mission',
	nextStep: 'Next step',
	startDate: 'Favorite start work day time',
	endDate: 'Favorite end work day time',
}

export default {
	fr: FR,
	us: EN,
}

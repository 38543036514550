import Typography from 'components/Typography'
import useLanguage from 'hooks/useLanguage';
import React from 'react';

const ReviewDocuments = () => {
    const lang = useLanguage('onboard')
    return (
        <Typography
            variant='subTitle2'
            html={lang.reviewingDocsHelp}
            primaryColor />
    );
};

export default ReviewDocuments;
import useLanguage from 'hooks/useLanguage'
import Typography from 'components/Typography'
import classes from './CookieWarning.module.scss'

const CookieWarning = () => {
    const lang = useLanguage('cookie')
    return (
        <div className={classes.root} >
            <Typography variant='subTitle1'>
                {lang.warning}
            </Typography>
        </div>
    )
}

export default CookieWarning

import {useState} from 'react'

const useBurgerMenu = () => {
    const [isOpen, setIsOpen] = useState(false)

    const setOpositeToActualOpen = () => setIsOpen(isOpen => !isOpen)

    return {
        isOpen,
        setIsOpen,
        setOpositeToActualOpen
    }

}

export default useBurgerMenu
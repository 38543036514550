import React from 'react';
import { useParams } from 'react-router-dom';
import StudioPublicProfileContainer from './StudioPublicProfileContainer'

const StudioPage = () => {
    const userId = Number(useParams().userId)
    return (
        <StudioPublicProfileContainer
            userId={userId}
            isPage
        />
    );
};

export default StudioPage;
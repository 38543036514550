import CookieWarning from './components/CookieWarning/CookieWarning'
import { Routes } from './routes/Routes/Routes'

export const Application = () => {
    console.log(Intl.DateTimeFormat().resolvedOptions().timeZone)
    const app = () => {
        try {
            if (localStorage) {
                return <Routes />
            }
        } catch (err) {
            return <CookieWarning />
        }
        return <CookieWarning />
    }

    return app()
}

import setLoggedInCookie from "./setLoggedInCookie"
import history from "./history"

const logOut = async () => {
	setLoggedInCookie(0)
	localStorage.removeItem('token')
	localStorage.removeItem('tokenExpiration')
	localStorage.removeItem('refreshToken')
	localStorage.removeItem('refreshTokenExpiration')
	localStorage.removeItem('profile')
	// console.log('socket disconnect before')
	// socketRooms.map((room) =>
	// 	socket.removeListener(room, (timestamp) => {
	// 		console.log(timestamp)
	// 	})
	// )
	// socket.emit('forceDisconnect')
	return history.push('/')
}

export default logOut
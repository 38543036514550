import Lottie from "lottie-react";

const LottieControl = ({
  animationData,
  loop = true,
  autoplay = true,
  height = 400,
  width = 400,
  isComplete = () => { },
}) => {

  return <Lottie
    animationData={animationData.default}
    loop={loop}
    autoplay={autoplay}
    onComplete={isComplete}
    style={{
      height,
      width,
      margin: 'auto'
    }} />
}

export default LottieControl

import useLanguage from 'hooks/useLanguage';
import React from 'react';
import classes from './Form.module.scss'

const InvalidEmailComponent = () => {
    const {invalidEmail: invalidEmailLang} = useLanguage('form')
    return (
        <div className={classes.invalidEmailText}>
            {invalidEmailLang}
        </div>
    );
};

export default InvalidEmailComponent;
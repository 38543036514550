import React from 'react';
import classes from './UnAuthLayout.module.scss'
import Typography from 'components/Typography'

const AuthFormHeader = ({ page, copy }) => {
    const title = page === 'login' ? copy.logIn : copy.signUp
    const link = page === 'login' ? copy.signUp : copy.logIn
    const nav = page === 'login' ? '/signup' : '/login'

    return (
        <div className={classes.authFormHeader}>
            <div>
                <Typography variant='subTitle1' classes={{ root: classes.confirmAccountText }}>
                    {title}
                </Typography>
            </div>
            <div>
                <Typography
                    variant='subTitle3'
                    link={nav}
                    classes={{ root: classes.confirmAccountText }}
                >
                    {link}
                </Typography>
            </div>
        </div>
    )
}

export default AuthFormHeader;
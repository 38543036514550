import { useEffect, useState, useContext, useCallback } from 'react'
import Loader from 'components/Loader'
import { getMissionReviews } from '../helpers'
import ReviewWrapper from './ReviewWrapper'
import { UserTypes } from 'constants/UserTypes'
import { Store } from 'store/store'
import useLanguage from 'hooks/useLanguage'
import Typography from 'components/Typography'
import DatesFromMissionDays from 'components/DatesFromMissionDays'
import { getStartAndEndDate } from 'modules/dates'
import Divider from 'components/Divider/Divider'
import moment from 'moment'
import { isEmpty } from 'lodash'

function MissionReviews({ appointments }) {
	const [loading, setLoading] = useState(false)
	const { state: { user: { type } } } = useContext(Store)
	const lang = useLanguage('review')
	const [appointmentsReviews, setAppointmentsReviews] = useState({})

	const convertDataToAppointmentsReviewsArr = ({ reviewsForMe, reviewsILeft }, appointments) => {
		try {
			const getReviewsByAppointment = data => data.reduce((prev, actual) =>
			({
				...prev,
				[actual.appointment]:
					[...(prev[actual.appointment] ? prev[actual.appointment] : []), {
						...actual,
						...(appointments.find(appointments => appointments.id === actual.appointment) || {})
					}]
			}), {})
			const reviewsForMeByAppointment = getReviewsByAppointment(reviewsForMe)
			const reviewsILeftByAppointment = getReviewsByAppointment(reviewsILeft)
			const fullAppointmentsKeys = [...new Set([...Object.keys(reviewsForMeByAppointment), ...Object.keys(reviewsILeftByAppointment)])]
			return fullAppointmentsKeys
				.reduce((prev, key) => ({
					...prev,
					[key]: {
						reviewsILeft: reviewsILeftByAppointment[key],
						reviewsForMe: reviewsForMeByAppointment[key]
					}
				}), {})
		} catch (error) {
			console.log('error', error)
		}

	}

	useEffect(() => {
		if (!isEmpty(appointments)) {
			setLoading(true)
			getMissionReviews(appointments)
				.then(res => {
					setAppointmentsReviews(convertDataToAppointmentsReviewsArr(res, appointments))
				})
				.catch(e => e)
				.finally(e => setLoading(false))
		}
	}, [appointments])

	const injectLang = reviews => (reviews || []).map(review => ({ ...review, text: lang.questions[review.text_key] }))

	const getAppointmentActorName = useCallback(appointmentsId =>
		appointments.find(appointments =>
			appointments.id === Number(appointmentsId))[
		type === UserTypes.STUDIO ? 'freelanceName' : 'studio_name'
		], [appointments, type])
	const getAppointmentDates = useCallback(appointmentsId => {
		const { days } = appointments.find(appointments => appointments.id === Number(appointmentsId))
		const dates = Object.keys(days)
		const { startDate, endDate } = getStartAndEndDate(dates.map(date => moment(date, 'DD/MM/YYYY').toDate()))
		return <DatesFromMissionDays
			startDate={startDate}
			endDate={endDate}
			noTypo
		/>

	}, [appointments])

	return isEmpty(appointmentsReviews) ? false : (
		<>
			<h1>{lang.reviews}</h1>
			{
				Object
					.entries(appointmentsReviews)
					.map(([key, data], index) =>
						<div key={index}>
							{index !== 0 && <Divider />}
							<div>
								<Typography variant='subTitle1'>{lang.reviewOfMission} {lang.with} {getAppointmentActorName(key)} {lang.from} {getAppointmentDates(key)}</Typography>
								{!!data.reviewsILeft && <ReviewWrapper title={lang.myReview} reviews={injectLang(data.reviewsILeft)} />}
								{!!data.reviewsForMe?.length && (
									<ReviewWrapper
										title={`${type === UserTypes.FREELANCE ? lang.reviewFromStudio : lang.reviewFromFreelance}`}
										reviews={injectLang(data.reviewsForMe)}
									/>
								)}
							</div>
						</div>

					)
			}
		</>
	)
}

export default MissionReviews

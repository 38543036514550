import jwtDecode from 'jwt-decode'
import setLoggedInCookie from './setLoggedInCookie'

const saveTokens = ({ token, refreshToken }) => {
	const user = jwtDecode(token.token)
	if (user) {
		setLoggedInCookie(1, token.expiresIn)
		localStorage.setItem('token', token.token)
		localStorage.setItem('tokenExpiration', refreshToken.expiresIn)
		localStorage.setItem('refreshToken', refreshToken.token)
		localStorage.setItem('refreshTokenExpiration', token.expiresIn)
		localStorage.setItem('profile', JSON.stringify(user))
		return token
	}
	throw new Error('Token is not readeable.')
}

export default saveTokens
export const FR = {
    missionDatesTitle: `Dates selectionnées`,
    deleteAll: 'Supprimer toutes les dates',
    delete: 'Supprimer cette date',
    noDate: 'Aucune date.'
}

export const EN = {
    missionDatesTitle: `Dates selected`,
    deleteAll: 'Delete all dates',
    delete: 'Delete this date',
    noDate: 'No date.'
}

export default {
    fr: FR,
    us: EN,
}
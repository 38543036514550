import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import React, { useContext } from 'react';
import { Store } from 'store/store';
const RadiusInput = ({
    onSelect = () => { },
    initialRadius = ''
}) => {
    const [radius, setRadius] = React.useState(initialRadius || '')
    const {state:{ language: {addressForm: addressFormLang}}} = useContext(Store)
    const handleChange = (event) => {
        setRadius(event.target.value)
        onSelect(event.target.value)
    };
    const radiuses = [1, 2, 3, 4, 5, 6, 8, 10, 13, 15, 20, 25, 35, 50, 100, 500]
        .map(distance => ({ value: distance, text: String(distance) + ' km' + (distance > 1 ? 's' : '') }))
    return (
        <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">{addressFormLang.perimeterSearch}</InputLabel>
            <Select
                placeholder='10 kms'
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={radius}
                label={addressFormLang.perimeter}
                onChange={handleChange}
            >{radiuses.map(({ value, text }, index) =>
                <MenuItem key={index} value={value}>{text}</MenuItem>
            )}
            </Select>
        </FormControl>
    );
};

export default RadiusInput;
import RightSide from 'components/RightSide/RightSide';
import TitlePage from 'components/TitlePage';
import React, { useEffect, useState } from 'react';
import useLanguage from 'hooks/useLanguage';
import LanguageSelection from 'components/LanguageSelection';
import Button from 'components/Button';
import classes from 'styles/classes.module.scss';
import classNames from 'classnames';
import { getSubscriptionEndApi, unsubscribeApi } from './helpers';
import toast from 'components/toast';
import { socket } from 'socket';
import { useNavigate } from 'react-router-dom';
import Typography from 'components/Typography';
import { useStoreUtils } from 'hooks/useStoreUtils';

const Settings = () => {
	const { state, dispatch, isPremium, reloadUser } = useStoreUtils();
	const lang = useLanguage('settings');
	const profileLang = useLanguage('profile');
	const {
		stripe: stripeSocket,
		socketConnected,
		user: { firstname, lastname, email, phone },
	} = state;
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const [subscriptionEnd, setSubscriptionEnd] = useState();

	const handleUnsubscribe = async () => {
		try {
			setLoading(true);
			await unsubscribeApi();
			toast.success(profileLang.desactivateSuccess);
		} catch (err) {
			toast.error(err.message);
		}
	};

	const getSubEnd = async () => {
		try {
			const result = await getSubscriptionEndApi();
			if (result.subscriptionEnd) {
				setSubscriptionEnd(result.subscriptionEnd);
			}
		} catch (e) {
			console.error(e);
		}
	};

	useEffect(() => {
		getSubEnd();
	}, []);

	useEffect(() => {
		if (socketConnected && socket && stripeSocket) {
			if (stripeSocket.status === 'unsubscribed') {
				setLoading(false);
				getSubEnd();
				reloadUser();
				return dispatch({ type: 'STRIPE/RESET' });
			}
		}
	}, [stripeSocket, socketConnected, dispatch, navigate, reloadUser]);

	return (
		<RightSide>
			<TitlePage>{lang.title}</TitlePage>
			<div className={classNames(classes.flex, classes.flexWrap)}>
				<LanguageSelection />
				<Button link={'/user/update'} type="warning">
					{profileLang.updateInfoButton}
				</Button>
				{subscriptionEnd ? (
					<Typography variant="body1" className={classes.marginBottom}>
						{profileLang.subscriptionEnd(true, subscriptionEnd)}
					</Typography>
				) : isPremium ? (
					<Button onClick={handleUnsubscribe} type="delete" external>
						{profileLang.unsubscribe}
					</Button>
				) : (
					<Button link={'/subscription'}>{profileLang.subscribe}</Button>
				)}
				<Button
					link={`mailto:contract@styleep.com?subject=${lang.mailRemoveSubject}&body=${lang.mailRemoveBody(
						phone,
						email,
						firstname + ' ' + lastname,
					)}`}
					type="danger"
					external
				>
					{profileLang.cancel}
				</Button>
			</div>
		</RightSide>
	);
};

export default Settings;

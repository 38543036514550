import FileTypes from "constants/FileTypes"

export const FR = {
	myInvoice: 'Ma facture',
	clearCanvas: 'Recommencer ma signature',
	beforeSignSignature: 'Notre outil de signature éléctronique vous guide dans les étapes de signature du contrat',
	contract: 'contrat',
	contracts: 'contrats',
	noContracts: 'Pas de contrat',
	columns: {
		id: 'ID',
		freelance: 'Freelance',
		dates: 'Dates',
		dayCount: 'Nombre de jours',
		status: 'Statut',
		price: 'Rémunération'
	},
	createContractWith: (studioName) => `Créer un contract avec l'entreprise ${studioName}`,
	deleteContractWith: (studioName) => `Supprimer un contract avec l'entreprise ${studioName}`,
	usePrepaymentMethod: 'Demander un pré-paiement de la mission',
	notUsePrepaymentMethod: 'Ne plus utiliser la mèthode de pré-paiement',
	createContract: 'Créer un contrat',
	signContract: 'Signer le contrat',
	writeNextElem: 'Écrire l\'élément suivant',
	stopEditing: 'Arrêter l\'édition',
	youCannotRegisterEmptyElement: 'Vous ne pouvez pas enregistrer d\'élément vide.',
	contractSigned: 'Contrat signé',
	seeFullPage: 'Voir le contrat en plein écran',
	contractDeleted: 'Contrat supprimé avec succès. Toutes vos dates ont été mises à jour.',
	deleteContract: 'Supprimer le contrat',
	modalContractDeleteDescription: 'sdfsadfasdf',
	modalContractCreateDescription: 'sdfasdfaf',
	contractHaveBeenSigned: 'Le contrat a été signé par le',
	freelance: 'freelance',
	studio: "entreprise d'accueil",
	signature: 'Signature',
	updated: 'modifiée',
	registered: 'enregistrée',
	cancel: 'Fermer',
	[FileTypes.INITIALS]: 'Initiales',
	[FileTypes.APPROVED]: 'Lu et approuvé',
	[FileTypes.SIGNATURE]: 'Signature',
	updateIts: {
		[FileTypes.INITIALS]: 'Modifier mes initiales',
		[FileTypes.APPROVED]: 'Modifier mon lu et approuvé',
		[FileTypes.SIGNATURE]: 'Modifier ma signature',
	},
	createIts: {
		[FileTypes.INITIALS]: 'Créer mes initiales',
		[FileTypes.APPROVED]: 'Créer mon lu et approuvé',
		[FileTypes.SIGNATURE]: 'Créer ma signature',
	},
	buttonIts: {
		[FileTypes.INITIALS]: 'Parapher et passer à l\'étape suivante',
		[FileTypes.APPROVED]: 'J\'ai lu et j\'approuve le présent contrat',
		[FileTypes.SIGNATURE]: 'Apposer ma signature',
	},
	fileText: {
		[FileTypes.APPROVED]: 'Lu et approuvé',
		[FileTypes.INITIALS]: 'Mes initiales: JK',
		[FileTypes.SIGNATURE]: 'Amy Winehouse',
	},
	writeInCursive: 'Écrire en cursive',
	drawSignature: 'Dessiner ma signature',
	enablePrepayment: 'Activé le prépaiement',
	prepaymentDescription: 'Le prépaiement est utile si vous souhaitez que Styleep stocke votre argent et vous le reverse à la fin de la mission. Une taxe de 6% sera appliquée sur le montant total de la mission.',
	disablePrepayment: 'Désactivé le prépaiement',
	
}

export const EN = {
	myInvoice: 'My invoice',
	clearCanvas: 'Restart from beginning my signature',
	beforeSignSignature: 'Our electronic signature tool guide you which each step of contract acceptation',
	contract: 'contract',
	contracts: 'contracts',
	noContracts: 'No contact',
	columns: {
		id: 'ID',
		freelance: 'Freelance',
		dates: 'Dates',
		dayCount: 'Number of days',
		status: 'Status',
		price: 'Amount'
	},
	createContractWith: (studioName) => `Create contract with ${studioName} studio`,
	deleteContractWith: (studioName) => `Delete contract with ${studioName} studio`,
	usePrepaymentMethod: 'Use the prepayment method',
	notUsePrepaymentMethod: 'Use the prepayment method',
	createContract: 'Create contract',
	signContract: 'Sign contract',
	writeNextElem: 'Write next elem',
	stopEditing: 'Stop editing',
	youCannotRegisterEmptyElement: 'You cannot register empty element.',
	contractSigned: 'Contract signed',
	seeFullPage: 'See contract in full screen',
	contractDeleted: 'Contract deleted with success. All your dates have been correctly updated.',
	deleteContract: 'Delete the contract',
	modalContractDeleteDescription: 'sdfsadfasdf',
	modalContractCreateDescription: 'sdfasdfaf',
	contractHaveBeenSigned: 'Contract have been signed by',
	freelance: 'freelance',
	studio: 'studio',
	signature: 'Signature',
	updated: 'updated',
	cancel: 'Cancel',
	registered: 'registered',
	[FileTypes.INITIALS]: 'Initials',
	[FileTypes.APPROVED]: 'Read and approved',
	[FileTypes.SIGNATURE]: 'Signature',
	updateIts: {
		[FileTypes.INITIALS]: 'Update my initials',
		[FileTypes.APPROVED]: 'Update my read and approved',
		[FileTypes.SIGNATURE]: 'Update my signature',
	},
	createIts: {
		[FileTypes.INITIALS]: 'Create my initials',
		[FileTypes.APPROVED]: 'Create my read and approved',
		[FileTypes.SIGNATURE]: 'Create my signature',
	},
	fileText: {
		[FileTypes.APPROVED]: 'Read and approved',
		[FileTypes.SIGNATURE]: 'Amy Winehouse',
		[FileTypes.INITIALS]: 'My initials: JK',
	},
	writeInCursive: 'Write in cursive',
	drawSignature: 'Draw my signature',
	enablePrepayment: 'Enable prepayment',
	prepaymentDescription: 'Prepayment is useful if you want Styleep to store your money and give it back to you at the end of the mission. A 6% tax will be applied on the total amount of the mission.',
	disablePrepayment: 'Disable prepayment',
}

export default {
	fr: FR,
	us: EN,
}

export const FR = {
    title: 'Profil sélectionné',
    allDaysAvailables: 'Ce freelance est disponible pour l\'ensemble de la mission',
    on: 'sur',
    day: 'jour',
    missionAvailables: 'de mission sont disponibles pour ce freelance.',
    visitedStudios: 'Entreprises visités',
    lastMissions: 'Dernières missions',
    sendProposal: 'Envoyer la proposition de mission au freelance',
    datasAlreadyUpdated: 'les données ont déjà été actualisées',
    proposalSent: `Proposition envoyée !`,
    goBack: 'Retour au choix des profils',
    reinitializeDates: 'Réinitialiser les dates',
}

export const EN = {
    title: 'Selected profile',
    allDaysAvailables: 'All mission days are availables for this freelance.',
    on: 'on',
    day: 'day',
    missionAvailables: 'of mission are availables for this freelance.',
    visitedStudios: 'Studios visited',
    lastMissions: 'Last missions',
    sendProposal: 'Send mission proposal to freelance',
    datasAlreadyUpdated: 'datas have already been updated',
    proposalSent: `Proposal sent !`,
    goBack: 'Go back to search profiles page',
    reinitializeDates: 'Reinitialize dates',
}

export default {
    fr: FR,
    us: EN,
}

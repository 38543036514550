import classNames from 'classnames'
import { useMemo } from 'react'
import classes from './StarRating.module.scss'

function StarRating({ rating, size = 'normal', }: { rating: number, size: string }) {
	// const renderId = String(Math.random()).substr(3, 5)
	const array = [0, 1, 2, 3, 4]

	const { width, height } = useMemo(() => {
		const width = 32
		const height = 34
		switch (size) {
			case 'little':
				return { width: width / 2, height: height / 2 }
			case 'normal':
				return { width, height }
			default:
				return { width, height }
		}
	}, [size])

	return (
		<div className={classes.root}>
			{array.map((s, i) => {
				return (
					<div className={
						classNames(
							classes.starWrapper,
							size === 'little' && classes.little
						)
					} key={i}>
						<svg
							width={width}
							height={height}
							viewBox="0 0 32 34"
							fill='white'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path
								id='star'
								d='M27.65 12.1392L20.0366 10.9635L16.6332 3.63255C16.5402 3.43183 16.3873 3.26935 16.1984 3.17058C15.7246 2.92207 15.1489 3.12916 14.912 3.63255L11.5086 10.9635L3.89518 12.1392C3.68528 12.171 3.49337 12.2762 3.34644 12.4355C3.16881 12.6294 3.07093 12.8904 3.0743 13.161C3.07768 13.4317 3.18203 13.6898 3.36443 13.8787L8.87284 19.5848L7.57145 27.6422C7.54093 27.8296 7.56045 28.0224 7.6278 28.1987C7.69515 28.3749 7.80762 28.5276 7.95248 28.6393C8.09733 28.7511 8.26876 28.8175 8.44734 28.8311C8.62591 28.8446 8.80448 28.8047 8.96279 28.7159L15.7726 24.9118L22.5824 28.7159C22.7683 28.821 22.9842 28.8561 23.1911 28.8178C23.7128 28.7223 24.0637 28.1966 23.9737 27.6422L22.6723 19.5848L28.1807 13.8787C28.3307 13.7226 28.4296 13.5187 28.4596 13.2957C28.5406 12.7381 28.1747 12.222 27.65 12.1392Z'
								stroke='#D5C4FA'
								x={0}
								y={0}
								width={width}
								height={height}
								fill={i < rating ? '#d5c4fa' :'none'}
							/>
							{/* <clipPath id={`myClip-${i}-${renderId}`} x={0} y={0}>
								<path
									id='star'
									d='M27.65 12.1392L20.0366 10.9635L16.6332 3.63255C16.5402 3.43183 16.3873 3.26935 16.1984 3.17058C15.7246 2.92207 15.1489 3.12916 14.912 3.63255L11.5086 10.9635L3.89518 12.1392C3.68528 12.171 3.49337 12.2762 3.34644 12.4355C3.16881 12.6294 3.07093 12.8904 3.0743 13.161C3.07768 13.4317 3.18203 13.6898 3.36443 13.8787L8.87284 19.5848L7.57145 27.6422C7.54093 27.8296 7.56045 28.0224 7.6278 28.1987C7.69515 28.3749 7.80762 28.5276 7.95248 28.6393C8.09733 28.7511 8.26876 28.8175 8.44734 28.8311C8.62591 28.8446 8.80448 28.8047 8.96279 28.7159L15.7726 24.9118L22.5824 28.7159C22.7683 28.821 22.9842 28.8561 23.1911 28.8178C23.7128 28.7223 24.0637 28.1966 23.9737 27.6422L22.6723 19.5848L28.1807 13.8787C28.3307 13.7226 28.4296 13.5187 28.4596 13.2957C28.5406 12.7381 28.1747 12.222 27.65 12.1392Z'
									stroke='#D5C4FA'
									width={width}
									height={height}
								/>
							</clipPath> */}
						</svg>
						{/* <div
							className={classNames(classes.clippedBox, size === 'little' && classes.little)}
							style={{
								clipPath: `url(#myClip-${i}-${renderId})`,
								width: Math.floor(rating) === i ? `${(rating % 1) * 100}%` : Math.floor(rating) > i ? '100%' : '0%'
							}}
						/> */}
					</div>
				)
			})}
		</div>
	)
}

export default StarRating
export const FR = {
	studio: 'entreprise',
	reviewsTitle: 'Avis sur l\'entreprise de beauté laissés par les freelances',
}

export const EN = {
	studio: 'studio',
	reviewsTitle: 'Reviews by freelances about studio',
}

export default {
	fr: FR,
	us: EN,
}
  
import React, { useMemo, useEffect, useState } from 'react'
import { Form } from 'semantic-ui-react'
import { DatePicker } from 'antd'
// import 'antd/dist/antd.css'
import useLocale from 'hooks/useLocale'
import moment from 'moment-timezone'
import frLocaleMoment from 'moment/locale/fr'
import enLocaleMoment from 'moment/locale/en-gb'
import localeFr from 'antd/es/date-picker/locale/fr_FR'
import localeEn from 'antd/es/date-picker/locale/en_US'

import classes from './RecurrenceForm.module.scss'
import useLanguage from 'hooks/useLanguage'
import { upperFirst } from 'lodash'

localeFr.lang.locale = 'fr'
localeEn.lang.locale = 'en'

const { RangePicker } = DatePicker

const RecurrenceForm = ({
	onChangeRecurrence = () => { },
	editMode
}) => {
	const locale = useLocale()
	const lang = useLanguage('scheduler')

	useEffect(() => {
		if (locale === 'fr') {
			moment.locale('fr', frLocaleMoment)
		} else {
			moment.locale('en', enLocaleMoment)
		}
	}, [locale])
	
	const [repeatsEvery, setRepeatsEvery] = useState()
	const [currentRange, setCurrentRange] = useState()
	const [daysAvailable, setDaysAvailable] = useState([])
	const handleChange = (e, { name, value }) => {
		if (!value) {
			// is input from date picker
			setCurrentRange(e)
		} else {
			switch (name) {
				case 'repeatsEvery':
					return setRepeatsEvery(value)
				case 'currentRange':
					return setCurrentRange(value)
				case 'daysAvailable':
					return setDaysAvailable(value)
				default:
					break;
			}
		}
	}

	const formValid = () => {
		return (
			currentRange &&
			currentRange.length === 2 &&
			daysAvailable.length > 0 &&
			repeatsEvery !== undefined
		)
	}

	const submit = () => {
		return onChangeRecurrence({
			start: currentRange[0],
			end: currentRange[1],
			onDays: daysAvailable,
			repeats: repeatsEvery,
		})
	}

	const disabledDate = (current) => {
		if (current < moment()) {
			return true
		}
		if (!currentRange || currentRange.length === 0) {
			return false
		}

		const tooLate = currentRange[0] && current.diff(currentRange[0], 'days') > 180
		const tooEarly = currentRange[1] && currentRange[1].diff(current, 'days') > 180
		return tooEarly || tooLate
	}

	const copy = useMemo(() => lang.copy, [lang])

	const days = useMemo(() => [
		{ key: 0, value: 1, text: lang.monday },
		{ key: 1, value: 2, text: lang.tuesday },
		{ key: 2, value: 3, text: lang.wedensday },
		{ key: 3, value: 4, text: lang.thursday },
		{ key: 4, value: 5, text: lang.friday },
		{ key: 5, value: 6, text: lang.saturday },
		{ key: 6, value: 0, text: lang.sunday },
	], [lang])

	const repeatsEveryOptions = useMemo(() => [
		{ key: 0, value: 1, text: upperFirst(lang.week) },
		{ key: 1, value: 2, text: `2 ${upperFirst(lang.week)}s` },
		{ key: 2, value: 3, text: `3 ${upperFirst(lang.week)}s` },
		{ key: 3, value: 4, text: `4 ${upperFirst(lang.week)}s` },
	], [lang])

	const { daysLabel, actionButton } = useMemo(() => copy[editMode], [copy, editMode])

	return (
		<Form>
			<Form.Field required={true}>
				<label className={classes.label}>{lang.firstDateLastDate}</label>
				<RangePicker
					locale={locale === 'fr' ? localeFr : localeEn}
					className={classes.input}
					disabledDate={disabledDate}
					name='currentRange'
					value={currentRange}
					onChange={handleChange}
				/>
			</Form.Field>
			<Form.Field required={true}>
				<label className={classes.label}>{daysLabel}</label>
				<Form.Dropdown
					className={classes.input}
					value={daysAvailable}
					name='daysAvailable'
					onChange={handleChange}
					placeholder={lang.chooseDays}
					fluid
					multiple={true}
					options={days}
				/>
			</Form.Field>
			<Form.Field required={true}>
				<label className={classes.label}>{lang.repeatsEvery}</label>
				<Form.Dropdown
					className={classes.input}
					value={repeatsEvery}
					name='repeatsEvery'
					onChange={handleChange}
					placeholder={lang.chooseRepetition}
					fluid
					multiple={false}
					options={repeatsEveryOptions}
				/>
			</Form.Field>
			<Form.Button
				className={classes.button}
				type='submit'
				onClick={submit}
				positive={true}
				disabled={!formValid()}
			>
				{actionButton}
			</Form.Button>
		</Form>
	);
};

export default RecurrenceForm

import { useEffect, useContext, useState, useCallback } from 'react';
import PaymentComponent from './PaymentComponent';
import { Store } from 'store/store';
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js';
import { getCustomerCards, getSetupIntentApi, prepaymentApi, sentBankTransfer } from './helpers';
import { stripePublicKey } from 'helpers';
import { useNavigate } from 'react-router-dom';
import toast from 'components/toast';
import useLanguage from 'hooks/useLanguage';
import Loader from 'components/Loader';
import StyledModal from 'components/StyledModal';
import { upperFirst } from 'lodash';
import { socket } from 'socket';
import Didactiel from 'components/Didactiel';

const PayComponent = ({ price, onSuccessUrl = '/', contract }) => {
	const navigate = useNavigate();
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const { state, dispatch } = useContext(Store);
	const [cards, setCards] = useState([]);
	const [card, setCard] = useState();
	const [loading, setLoading] = useState(false);
	const [errorPayment, setErrorPayment] = useState(null);
	const stripe = useStripe();
	const elements = useElements();
	const [paymentType, setPaymentType] = useState('card');
	const [sepa, setSepa] = useState(false);
	const [modal] = useState(false);
	const { stripe: stripeSocket, socketConnected } = state;
	const lang = useLanguage('pay');

	const getCards = async () => {
		try {
			const cards = await getCustomerCards();
			return cards;
		} catch (e) {
			console.log(e.message);
			return [];
		}
	};

	const success = useCallback(() => {
		toast(lang.success);
		navigate(onSuccessUrl);
	}, [lang.success, navigate, onSuccessUrl]);

	useEffect(() => {
		if (socketConnected && socket && stripeSocket) {
			if (stripeSocket.status === 'success') {
				success();
				setLoading(false);

				return dispatch({ type: 'STRIPE/RESET' });
			} else if (stripeSocket.status === 'not payed') {
				console.log('stripeSocket', stripeSocket);
				setErrorPayment(stripeSocket.message);
				setLoading(false);
				return dispatch({ type: 'STRIPE/RESET' });
			}
		}
	}, [stripeSocket, socketConnected, success]);

	useEffect(() => {
		getCards()
			.then((data) => {
				setCards(data.cards);
				dispatch({ type: 'LOADING_OFF' });
			})
			.catch((e) => {
				console.log('e', e);
				//  navigate('/home')
			});
	}, []);

	const [error, setError] = useState(lang.card.errorDefault);
	const onChange = ({ complete, error }) => {
		if (!complete) {
			if (paymentType === 'sepa_debit') {
				setSepa(true);
			}
			return setError(lang.card.incomplete);
		} else if (error) {
			setSepa(false);
			return setError(lang.card.invalid);
		}
		setSepa(true);
		setError(null);
		process.env.NODE_ENV !== 'production' && console.log(error);
	};

	const onFocus = () => {
		setCard(null);
	};

	//paiement avec une carte enregistrée
	const payWithRegisteredCard = useCallback(
		async (event) => {
			event.preventDefault();
			if (!stripe || !elements) {
				return;
			}
			setLoading(true);
			try {
				await prepaymentApi(price, contract, card.id);
				success();
			} catch (e) {
				process.env.NODE_ENV !== 'production' && console.log('error:', e.message);
				setErrorPayment(e.message);
				setLoading(false);
			}
		},
		[card, contract, elements, price, stripe, success],
	);

	//paiement avec l'élément stripe
	const payWithElement = useCallback(
		async (event) => {
			setCard(null);
			event.preventDefault();
			if (!stripe || !elements) {
				return;
			}

			try {
				setLoading(true);
				const cardElement = elements.getElement(CardElement);
				const { error, paymentMethod } = await stripe.createPaymentMethod({
					type: 'card',
					billing_details: {
						name: `${firstName} ${lastName}`,
						email: 'klack@test.io',
					},
					card: cardElement,
				});

				if (error) return setError(error.message);
				const setupIntent = await getSetupIntentApi(contract.studio, paymentMethod.id);
				const { status, clientSecret } = setupIntent;

				if (status === 'error') {
					throw setupIntent;
				}

				if (status === '3dSecure') {
					const { error } = await stripe.confirmCardSetup(clientSecret, {
						payment_method: paymentMethod.id,
					});
					if (error) {
						throw new Error(error.message);
					}
				}

				await prepaymentApi(price, contract, paymentMethod.id);
				success();
			} catch (e) {
				process.env.NODE_ENV !== 'production' && console.log('error:', e.message);
				setErrorPayment(e.message);
				setLoading(false);
			}
		},
		[contract, elements, firstName, lastName, stripe, success, price],
	);

	const bankTransferSent = useCallback(async () => {
		try {
			await sentBankTransfer(contract.id);
			toast(lang.bankTransferSentMessage);
			navigate(onSuccessUrl);
		} catch (error) {
			console.log('error', error);
		}
	}, [contract, lang, navigate, onSuccessUrl]);

	const changePaymentType = (type) => {
		setPaymentType(type);
		setCard();
	};

	const redirectToHome = () => navigate('/');

	return (
		<>
			{' '}
			{paymentType === 'bankTransfer' && <Didactiel didactielType={'bank_transfer'} />}
			{price ? (
				<PaymentComponent
					setFirstName={setFirstName}
					setLastName={setLastName}
					payWithElement={payWithElement}
					subscriptionPaymentLanguage={state.language.subscriptionPayment}
					card={card}
					setCard={setCard}
					cards={cards}
					disabled={!stripe || !elements}
					error={error}
					onChange={onChange}
					onFocus={onFocus}
					payWithRegisteredCard={payWithRegisteredCard}
					loading={loading}
					price={{
						price: price,
						currency: '€',
					}}
					errorPayment={errorPayment}
					campaign={state.campaign}
					paymentType={paymentType}
					changePaymentType={changePaymentType}
					sepa={sepa}
					setSepa={setSepa}
					starterPayLanguage={state.language.starterPay}
					bankTransferSent={bankTransferSent}
					reference={contract.reference}
					invoicePF={contract.studio_invoice_pf}
				/>
			) : (
				<Loader />
			)}
			<StyledModal
				onClose={redirectToHome}
				open={modal}
				title={lang.modalTitle}
				onClick={redirectToHome}
				button={lang.modalButtonText}
			>
				{upperFirst(lang.modalDescription)}
			</StyledModal>
		</>
	);
};
const stripePromise = loadStripe(stripePublicKey);
const Wrapper = (props) => {
	return (
		<Elements stripe={stripePromise}>
			<PayComponent {...props} />
		</Elements>
	);
};

export default Wrapper;

import EmailValidationHelp from './EmailValidationHelp';
import UploadFiscalDocs from './UploadFiscalDocs';
import { FreelanceOnboardingStatuses, StudioOnboardingStatuses } from '../constants/OnboardingStatuses';
import { UserTypes } from 'constants/UserTypes';
import AddAddress from './AddAddress';
import InfosForm from './InfosForm';
import { useMemo } from 'react';
import ScheduleOnboardMeeting from './ScheduleOnboardMeeting';
import MeetingScheduled from './MeetingScheduled';
import StripeOnboard from './StripeOnboard';
import ReviewDocuments from './ReviewDocuments.jsx';

const useOnboardingSteps = ({ lang, status, type }) => {
	const myOnboardingStatuses = useMemo(
		() => (type === UserTypes.STUDIO ? StudioOnboardingStatuses : FreelanceOnboardingStatuses),
		[type],
	);
	const values = useMemo(() => Object.values(myOnboardingStatuses), [myOnboardingStatuses]);
	const steps = useMemo(
		() =>
			type === UserTypes.STUDIO
				? [
						{
							title: lang.obStepTitles[StudioOnboardingStatuses.WAITING_EMAIL_VALIDATION],
							complete: status > StudioOnboardingStatuses.WAITING_EMAIL_VALIDATION,
							current: status === StudioOnboardingStatuses.WAITING_EMAIL_VALIDATION,
							status: StudioOnboardingStatuses.WAITING_EMAIL_VALIDATION,
							stepIndex: values.indexOf(StudioOnboardingStatuses.WAITING_EMAIL_VALIDATION),
							content: <EmailValidationHelp />,
						},
						{
							title: lang.obStepTitles[StudioOnboardingStatuses.WAITING_FISCAL],
							complete: status > StudioOnboardingStatuses.WAITING_FISCAL,
							current: status === StudioOnboardingStatuses.WAITING_FISCAL,
							status: StudioOnboardingStatuses.WAITING_FISCAL,
							stepIndex: values.indexOf(StudioOnboardingStatuses.WAITING_FISCAL),
							content: <UploadFiscalDocs />,
						},
						{
							title: lang.obStepTitles[StudioOnboardingStatuses.WAITING_STRIPE],
							complete: status > StudioOnboardingStatuses.WAITING_STRIPE,
							current: status === StudioOnboardingStatuses.WAITING_STRIPE,
							status: StudioOnboardingStatuses.WAITING_STRIPE,
							stepIndex: values.indexOf(StudioOnboardingStatuses.WAITING_STRIPE),
							content: <StripeOnboard />,
						},
						{
							title: lang.obStepTitles[StudioOnboardingStatuses.WAITING_INFORMATIONS],
							complete: status > StudioOnboardingStatuses.WAITING_INFORMATIONS,
							current: status === StudioOnboardingStatuses.WAITING_INFORMATIONS,
							status: StudioOnboardingStatuses.WAITING_INFORMATIONS,
							stepIndex: values.indexOf(StudioOnboardingStatuses.WAITING_INFORMATIONS),
							content: <InfosForm />,
						},
						{
							title: lang.obStepTitles[StudioOnboardingStatuses.WAITING_ADDRESS],
							complete: status > StudioOnboardingStatuses.WAITING_ADDRESS,
							current: status === StudioOnboardingStatuses.WAITING_ADDRESS,
							status: StudioOnboardingStatuses.WAITING_ADDRESS,
							stepIndex: values.indexOf(StudioOnboardingStatuses.WAITING_ADDRESS),
							content: <AddAddress />,
						},
						{
							title: lang.obStepTitles[StudioOnboardingStatuses.WAITING_FISCAL_REVIEW][type],
							complete: status > StudioOnboardingStatuses.WAITING_FISCAL_REVIEW,
							current: status === StudioOnboardingStatuses.WAITING_FISCAL_REVIEW,
							status: StudioOnboardingStatuses.WAITING_FISCAL_REVIEW,
							stepIndex: values.indexOf(StudioOnboardingStatuses.WAITING_FISCAL_REVIEW),
							content: <ReviewDocuments />
						}
				  ]
				: type === UserTypes.FREELANCE
				? [
						{
							title: lang.obStepTitles[FreelanceOnboardingStatuses.WAITING_EMAIL_VALIDATION],
							complete: status > FreelanceOnboardingStatuses.WAITING_EMAIL_VALIDATION,
							current: status === FreelanceOnboardingStatuses.WAITING_EMAIL_VALIDATION,
							status: FreelanceOnboardingStatuses.WAITING_EMAIL_VALIDATION,
							stepIndex: values.indexOf(FreelanceOnboardingStatuses.WAITING_EMAIL_VALIDATION),
							content: <EmailValidationHelp />,
						},
						{
							title: lang.obStepTitles[FreelanceOnboardingStatuses.WAITING_FISCAL],
							complete: status > FreelanceOnboardingStatuses.WAITING_FISCAL,
							current: status === FreelanceOnboardingStatuses.WAITING_FISCAL,
							status: FreelanceOnboardingStatuses.WAITING_FISCAL,
							stepIndex: values.indexOf(FreelanceOnboardingStatuses.WAITING_FISCAL),
							content: <UploadFiscalDocs />,
						},
						{
							title: lang.obStepTitles[FreelanceOnboardingStatuses.WAITING_STRIPE],
							complete: status > FreelanceOnboardingStatuses.WAITING_STRIPE,
							current: status === FreelanceOnboardingStatuses.WAITING_STRIPE,
							status: FreelanceOnboardingStatuses.WAITING_STRIPE,
							stepIndex: values.indexOf(FreelanceOnboardingStatuses.WAITING_STRIPE),
							content: <StripeOnboard />,
						},
						{
							title: lang.obStepTitles[FreelanceOnboardingStatuses.WAITING_INFORMATIONS],
							complete: status > FreelanceOnboardingStatuses.WAITING_INFORMATIONS,
							current: status === FreelanceOnboardingStatuses.WAITING_INFORMATIONS,
							status: FreelanceOnboardingStatuses.WAITING_INFORMATIONS,
							stepIndex: values.indexOf(FreelanceOnboardingStatuses.WAITING_INFORMATIONS),
							content: <InfosForm />,
						},
						{
							title: lang.obStepTitles[FreelanceOnboardingStatuses.WAITING_ADDRESS],
							complete: status > FreelanceOnboardingStatuses.WAITING_ADDRESS,
							current: status === FreelanceOnboardingStatuses.WAITING_ADDRESS,
							status: FreelanceOnboardingStatuses.WAITING_ADDRESS,
							stepIndex: values.indexOf(FreelanceOnboardingStatuses.WAITING_ADDRESS),
							content: <AddAddress />,
						},
						{
							title: lang.obStepTitles[FreelanceOnboardingStatuses.WAITING_MEETING_PLAN],
							complete: status > FreelanceOnboardingStatuses.WAITING_MEETING_PLAN,
							current: status === FreelanceOnboardingStatuses.WAITING_MEETING_PLAN,
							content: <ScheduleOnboardMeeting />,
							status: FreelanceOnboardingStatuses.WAITING_MEETING_PLAN,
							stepIndex: values.indexOf(FreelanceOnboardingStatuses.WAITING_MEETING_PLAN),
						},
						{
							title: lang.obStepTitles[FreelanceOnboardingStatuses.WAITING_MEETING],
							complete: status > FreelanceOnboardingStatuses.WAITING_MEETING,
							current: status === FreelanceOnboardingStatuses.WAITING_MEETING,
							content: <MeetingScheduled />,
							status: FreelanceOnboardingStatuses.WAITING_MEETING,
							stepIndex: values.indexOf(FreelanceOnboardingStatuses.WAITING_MEETING),
						},
						{
							title: lang.obStepTitles[FreelanceOnboardingStatuses.WAITING_ONBOARD_VALIDATION],
							complete: status > FreelanceOnboardingStatuses.WAITING_ONBOARD_VALIDATION,
							current: status === FreelanceOnboardingStatuses.WAITING_ONBOARD_VALIDATION,
							content: <MeetingScheduled />,
							status: FreelanceOnboardingStatuses.WAITING_ONBOARD_VALIDATION,
							stepIndex: values.indexOf(FreelanceOnboardingStatuses.WAITING_ONBOARD_VALIDATION),
						},
				  ]
				: [],
		[type, values, lang, status],
	);

	const currentStep = useMemo(() => steps.find(({ current }) => current), [steps]);

	return {
		steps,
		myOnboardingStatuses,
		currentStep,
	};
};

export default useOnboardingSteps;

import toast from "components/toast"
import Typography from "components/Typography"
import useLanguage from "hooks/useLanguage"
import { useMemo } from "react"
import classes from './TransferIndications.module.scss'
import classNames from "classnames"

const TransferIndications = ({
    reference, price, currency, invoicePF
}) => {
    const lang = useLanguage('pay').transferIndications

    const copyOnClipBoard = (text) => {
        navigator.clipboard.writeText(text)
        toast.success(lang.copied)
    }

    const Element = ({
        title, body, withSpace = false, toCopy = false, noMargin
    }) => <div className={noMargin ? '' : classes.elementContainer}>
            <Typography fontWeight={500}>{title}</Typography>
            <br />
            <Typography
                classes={{ root: classes.copyText }}
                onClick={() => copyOnClipBoard(toCopy ? toCopy : withSpace ? body : body.replace(/\s+/g, ''))}
            >
                {body}
            </Typography>
        </div>

    const toCopy = useMemo(() => `Banque: 16958
        Guichet: 00001
        Compte: 33863678352
        Clé: 13`, [])

    return (
        <div className={classes.mb1}>
            <Typography variant="subTitle3" html={lang.info.replace('$1', `${price} ${currency}`).replace('$2', reference)} />
            <div>
                <Typography fontWeight={600}>
                    {lang.reference} : {reference}
                </Typography>
            </div>
            <div className={classNames( classes.flexWrap, classes.flex)}>
                <div className={classes.root}>
                    <div className={classes.partContainer}>
                        <Typography classes={{ root: classes.mb }} variant="subTitle2">{lang.accountPrincipal}</Typography>
                        <div className={classes.elementsContainer}>
                            <Element title={'IBAN'} body={'FR76 1695 8000 0133 8636 7835 213'} />
                            <Element title={'BIC/SWIFT'} body={'QNTOFRP1XXX'} />
                        </div>
                        <Element noMargin withSpace title={lang.addressTitulaire} body={'STYLEEP 80 AVENUE DU BAC 94120, SAINT-MAUR DES FOSSES - FR'} />
                    </div>
                    <div className={classes.sectionDivider} />
                    <div className={classes.partContainer}>
                        <Typography classes={{ root: classes.mb }} variant="subTitle2">{lang.infosComplementaires}</Typography>
                        <Element withSpace title={lang.domiciliation} body={'Qonto (Olinda SAS), 20 bis rue La Fayette, 75009 Paris, France'} />
                        <div className={classes.elementsContainer}>
                            <Element noMargin title={lang.bank} body={'16958'} toCopy={toCopy} />
                            <Element noMargin title={lang.guichet} body={'00001'} toCopy={toCopy} />
                            <Element noMargin title={lang.account} body={'33863678352'} toCopy={toCopy} />
                            <Element noMargin title={lang.key} body={'13'} toCopy={toCopy} />
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes.mt1}>
                <Typography primaryColor>
                    {lang.lastInfo}
                </Typography>
            </div>

        </div>
    )
}

export default TransferIndications
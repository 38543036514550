import { getStatusEventType, getLangStatus, getStatusByMissionContext } from 'containers/mission/helpers';
import { useContext, useMemo } from 'react';
import { Store } from 'store/store';
import { useDayStatusesLang } from './useDayStatusesLang';

export const useDayStatuses = (status, mission = {}) => {
	const { state: { user: { type } } } = useContext(Store)
	const dayStatusesLang = useDayStatusesLang()
	const langStatusIndex = useMemo(() => getStatusByMissionContext(status, mission), [status, mission])
	const eventType = useMemo(() => getStatusEventType(langStatusIndex, type), [langStatusIndex, type])
	const langStatus = useMemo(() => getLangStatus(dayStatusesLang, status || langStatusIndex), [status, dayStatusesLang, langStatusIndex])

	return {
		dayStatusesLang,
		eventType,
		langStatus
	}
};

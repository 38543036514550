export const styleDayStatus = {
	morning: {
		background: 'linear-gradient(to top, #fff 0% 50%, rgba(88, 61, 114, 0.15) 50%)'
	},
	afternoon: {
		background: 'linear-gradient(to bottom, #fff 0% 50%, rgba(88, 61, 114, 0.15) 50%)'
	},
	day: {
		background: 'rgba(88, 61, 114, 0.15)'
	},
	custom: {
		background: 'rgba(88, 61, 114, 0.15)'
	}
}
import { fetchApi } from 'containers/helpers'

export const getContracts = () => fetchApi('/contracts')

export const getSlotsApi = () => fetchApi('/slots')

export const createMultipleAvailableAPI = (availables) => fetchApi('/availables', {
	method: 'POST',
	body: availables
})

export const getProposalsApi = () => fetchApi('/proposals')

export const deleteMultipleSlotsApi = (days) => fetchApi('/slots', {
	method: 'DELETE',
	body: {
		days
	}
})

export const deleteAvailableAPI = (available) => fetchApi('/available', {
	method: 'DELETE',
	body: available
})

export const createManySlotsAPI = days => fetchApi('/slots', {
	body: {
		days
	}
})
import Loader from 'components/Loader';
import { dayStatuses } from 'constants/DayStatuses';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PayComponent from './Pay';
import { useMission } from 'hooks/useMission';
import RightSide from 'components/RightSide/RightSide';
import MissionTitle from 'containers/mission/components/MissionTitle';
import useLanguage from 'hooks/useLanguage';
import './Stripe.sass';
import Didactiel from 'components/Didactiel';

const PaymentPage = () => {
	const lang = useLanguage('pay');
	const { contractId } = useParams();
	const navigate = useNavigate();
	const { mission, contract, ...props } = useMission(null, contractId);

	useEffect(() => {
		if (contract && (contract.status < dayStatuses.WAITING_FUNDS || contract.status >= dayStatuses.BOOKED)) {
			return navigate(`/mission/${contract.mission}/contract/${contractId}`);
		}
	}, [contract, contractId, navigate]);

	return (
		<RightSide>
			<Didactiel didactielType={'pay_contract'} />
			<MissionTitle mission={mission} {...mission} {...props} subPage={lang.title} />
			{contract ? (
				<PayComponent
					contract={contract}
					price={contract.price}
					onSuccessUrl={`/mission/${contract.mission}/contract/${contractId}`}
				/>
			) : (
				<Loader />
			)}
		</RightSide>
	);
};

export default PaymentPage;

export default {
    FISCAL: 0,
    CONTRACT: 1,
    INVOICE: 2,
    RECEIPT: 3,
    SIGNATURE: 4,
    INITIALS: 5,
    APPROVED: 6,
    INVOICE_PF: 7,
}


import { FreelanceTypes } from "constants/FreelanceTypes.contant"

export const FR = {
    level: 'niveau',
    types: {
        [FreelanceTypes.BEGINNER]: "Débutant",
        [FreelanceTypes.MIDDLE]: "Intermédiaire",
        [FreelanceTypes.CONFIRMED]: "Confirmé",
        [FreelanceTypes.EXPERT]: "Expert",
        [FreelanceTypes.STAR]: "Star",
    }
}

export const EN = {
    level: 'level',
    types: {
        [FreelanceTypes.BEGINNER]: "Beginner",
        [FreelanceTypes.MIDDLE]: "Middle",
        [FreelanceTypes.CONFIRMED]: "Confirmed",
        [FreelanceTypes.EXPERT]: "Expert",
        [FreelanceTypes.STAR]: "Star",
    }
}

export default {
    fr: FR,
    us: EN,
}

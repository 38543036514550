import React from 'react';
import BadgeComponent from 'components/BadgeComponent'
import classes from './FreelanceBadges.module.scss'
const FreelanceBadges = ({ badges = {} }) => {
    return (
        <div className={classes.root}>
            {Object.entries(badges)
                .map(([name, intensity], index) => <div key={index}>
                    <BadgeComponent
                        intensity={intensity}
                        name={name}
                    />
                </div>)
            }
        </div>
    );
};

export default FreelanceBadges;
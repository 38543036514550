import React, { useEffect, useMemo } from 'react'
import useOnboardingSteps from '../components/useOnboardingSteps'
import classes from './Onboarding.module.scss'
import illustationWaitingReview from 'images/waitingVerification.png'
import { onboardingStatuses } from '../constants/OnboardingStatuses';
import { useNavigate } from 'react-router-dom';
import IssueLinkButton from 'components/IssueLinkButton';
import { types } from 'containers/issue/issue.enum';
import Typography from 'components/Typography'
import Image from 'components/Image/Image';
import classNames from 'classnames'
import useIsMobile from 'hooks/useIsMobile'
import { useStoreUtils } from 'hooks/useStoreUtils'

function Onboarding() {

	const isMobile = useIsMobile()
	const navigate = useNavigate()
    const { state:{ user, language: { onboard: lang }}, regenerateTokens } = useStoreUtils();

	const { status, type } = user

	useEffect(() => {
		if (status != null && status === onboardingStatuses.ACTIVE) {
			regenerateTokens()
			navigate('/')
		}
	}, [status])

	const { currentStep = {}, steps } = useOnboardingSteps({
		lang,
		status,
		type,
	})

	const component = useMemo(() => currentStep && currentStep.content ? React.cloneElement(currentStep.content) : <div></div>, [currentStep])

	const stepIndexLang = useMemo(() =>
		lang.stepIndex
			.replace('$1', (currentStep || { stepIndex: 0 }).stepIndex + 1)
			.replace('$2', steps.length + 1),
		[lang, steps, currentStep])

	return (<>
		<div className={classNames(classes.container, isMobile && classes.mobile)}>
			<Typography primaryColor>
				{stepIndexLang}
			</Typography>
			<div className={classes.titleContainer}>
				<Typography variant='title'>
					{currentStep.title}
				</Typography>
				<IssueLinkButton
					containerClassname={classes.helpButton}
					type={types.ONBOARD}
					elementId={currentStep.status} />
			</div>
			<div className={classes.componentContainer}>
				{component}
			</div>
		</div>
		{[onboardingStatuses.WAITING_FISCAL_REVIEW, onboardingStatuses.WAITING_MEETING].includes(currentStep.status)
			&&
			<Image className={classNames(classes.illustration, isMobile && classes.mobile)}
				src={illustationWaitingReview}
				alt='Waiting verification'
			/>
		}
	</>
	)
}

export default Onboarding
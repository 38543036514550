import useIsMobile from "hooks/useIsMobile"

const Spacer = ({ half }) => {
	const isMobile = useIsMobile()
	return(
	<>
		<br/>
		{!half && !isMobile ? <br/> : null}
	</>
)}

export default Spacer

export const FR = {
    culture: 'fr',
    next: 'suivant',
    previous: 'précédent',
    today: `aujourd'hui`,
    month: 'Mois',
    week: 'Semaine',
    day: 'Journée',
    noEventsInRange: `Il n'y a pas d'événements dans cette plage de dates.`,
    datesAdded: (nDates) => nDates === 1 ? `1 date ajoutée` : `${nDates} dates ajoutées`,
	datesRemoved: (nDates) => nDates === 1 ? `1 date supprimée` : `${nDates} dates supprimées`,
	afternoon: 'Après-midi',
	morning: 'Matin',
	custom: 'Personnalisé',
	full: 'Journée',
	none: 'Absent',
	slot: (start, end) => `de ${start} à ${end}`,
	initialyWas: 'Était initialement à '
}

export const EN = {
    culture: 'en',
    next: 'next',
    previous: 'previous',
    today: 'today',
    month: 'Month',
    week: 'Week',
    day: 'Day',
    noEventsInRange: `No events exists for this date range.`,
    datesAdded: (nDates) => nDates === 1 ? `1 date added` : `${nDates} dates added`,
	datesRemoved: (nDates) => nDates === 1 ? `1 date removed` : `${nDates} dates removed`,
	afternoon: 'Afternoon',
	morning: 'Morning',
	custom: 'Custom',
	full: 'Day',
	none: 'Not here',
	slot: (start, end) => `from ${start} to ${end}`,
	initialyWas: 'Initialy was '
}

export default {
    fr: FR,
    us: EN,
}

export const FR = {
	filterWeekends: 'Filtrer les week-ends',
	remove: 'Supprimer',
	chooseOnly: 'Choisir uniquement cette date',
	recurrenceFormHeader: ['Ajouter une récurrence', 'Supprimer une récurrence'],
	noDatesToCancel: 'Aucune date à annuler.',
	noDatesToProgram: 'Aucune date à programmer.',
	addRecurrence: 'Ajouter une récurrence',
	cancelRecurrence: 'Annuler une récurrence',
	cancelPlannedDates: 'Supprimer les dates planifiées',
	done: "J'ai terminé",
	copy: [
		{
			daysLabel: 'Journées disponibles',
			actionButton: 'Ajouter les dates',
		},
		{
			daysLabel: 'Journées à supprimer',
			actionButton: 'Supprimer les dates',
		},
	],

	monday: 'Lundi',
	tuesday: 'Mardi',
	wedensday: 'Mercredi',
	thursday: 'Jeudi',
	friday: 'Vendredi',
	saturday: 'Samedi',
	sunday: 'Dimanche',
	week: 'semaine',
	firstDateLastDate: 'Première journée - Dernière journée',
	repeatsEvery: 'Répéter tous les',
	chooseRepetition: 'Choisissez une répétition',
	chooseDays: 'Selectionnez des jours',
	confirmAddDates: {
		actionText: 'Confirmer',
		cancelText: 'Annuler',
		headerText: `Confirmer les dates selectionnées`,
		deleteAllDates: 'Supprimer toutes les dates',
	},
	confirmRemoveDates: {
		confirmRemoveDates: `Confirmer la suppression des dates`,
		cancel: 'Annuler',
		delete: 'Supprimer',
	},
	nRecurringDatesAdded: (nDates) => (nDates === 1 ? `1 date ajoutée` : `${nDates} dates ajoutées`),
	nRecurringDatesRemoved: (nDates) => (nDates === 1 ? `1 date supprimée` : `${nDates} dates supprimées`),
	morning: 'matin',
	afternoon: 'après-midi',
	day: 'journée',
};

export const EN = {
	filterWeekends: 'Filter weekends',
	remove: 'Remove',
	chooseOnly: 'Choose only this date',
	recurrenceFormHeader: ['Add recurrence', 'Remove recurrence'],
	noDatesToCancel: 'No dates to cancel.',
	noDatesToProgram: 'No dates to program.',
	addRecurrence: 'Add a recurrence',
	cancelRecurrence: 'Cancel a recurrence',
	cancelPlannedDates: 'Cancel planned dates',
	done: 'Done',
	copy: [
		{
			daysLabel: 'Availables days',
			actionButton: 'Add dates',
		},
		{
			daysLabel: 'Days to remove',
			actionButton: 'Remove dates',
		},
	],

	monday: 'Monday',
	tuesday: 'Tuesday',
	wedensday: 'Wedensday',
	thursday: 'Thursday',
	friday: 'Friday',
	saturday: 'Saturday',
	sunday: 'Sunday',
	week: 'week',
	firstDateLastDate: 'First Date - Last Date',
	repeatsEvery: 'Repeats Every',
	chooseRepetition: 'Choose a repetition',
	chooseDays: 'Select Days',
	confirmAddDates: {
		actionText: 'Confirm',
		cancelText: 'Cancel',
		headerText: `Confirm selected dates`,
		deleteAllDates: 'Delete all dates',
	},
	confirmRemoveDates: {
		confirmRemoveDates: `Are you sure to want to delete these dates`,
		cancel: 'Cancel',
		delete: 'Remove',
	},
	nRecurringDatesAdded: (nDates) => (nDates === 1 ? `1 date added` : `${nDates} dates added`),
	nRecurringDatesRemoved: (nDates) => (nDates === 1 ? `1 date removed` : `${nDates} dates removed`),
	morning: 'morning',
	afternoon: 'afternoon',
	day: 'full day',
};

export default {
	fr: FR,
	us: EN,
};

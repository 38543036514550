import RightSide from 'components/RightSide/RightSide';
import 'containers/payment/Pay/Stripe.sass';
import SubscriptionPayementComponent from './SubscriptionPayementComponent';
import TitlePage from 'components/TitlePage/TitlePage';
import useLanguage from 'hooks/useLanguage';
import { useEffect, useState } from 'react';
import { getPriceOfSubscriptionApi } from './helpers';

const PRICE_ID = process.env.REACT_APP_PREMIUM_PRICE_ID;

const SubscriptionPaymentPage = () => {
	const lang = useLanguage('pay');
	const [price, setPrice] = useState(0);

	useEffect(() => {
		getPriceOfSubscriptionApi(PRICE_ID).then(setPrice);
	}, []);

	return (
		<RightSide>
			<TitlePage>{lang.subscribeTitle}</TitlePage>
			<SubscriptionPayementComponent price={price} />
		</RightSide>
	);
};

export default SubscriptionPaymentPage;

import { useCallback, useContext } from 'react';
import { Store } from '../store/store';

export const useSockets = () => {
    const { dispatch, state: { socketConnected } } = useContext(Store);
    
    const socketIsConnected = useCallback(
        () => {
            dispatch({ type: 'SOCKET_CONNECTED', payload: true });
        },
        [dispatch]
    );

    const socketIsDisconnected = useCallback(
        () => {
            dispatch({ type: 'SOCKET_DISCONNECTED', payload: false });
        },
        [dispatch]
    );

    return {
        socketConnected,
        socketIsConnected,
        socketIsDisconnected,
    };
};

import ReviewQuestionTypes from 'constants/ReviewQuestionTypes';
import { average, fetchApi } from 'containers/helpers';
import { upperFirst } from 'lodash';

export const getCustomerForFreelance = (userId) => fetchApi('/customer/' + userId);

export const getCustomerDisplayName = ({ firstname, lastname }) => upperFirst(firstname) + ' ' + upperFirst(lastname);

export const getPublicAppointmentReviews = (appointmentId) => fetchApi(`/public/review/appointment/${appointmentId}`);

export const getRatingFromReviews = (reviews) =>
	Math.round(
		average(reviews.filter(({ type }) => type === ReviewQuestionTypes.SLIDE).map(({ answer }) => Number(answer))),
	);

import omit from 'lodash/omit'
import DateWithActions from './DateWithActions'
import Typography from './Typography'
import classes from 'styles/classes.module.scss'

const FreelanceMissionProposals = ({
	freelance = {
		days: [],
		displayName: 'Jean',
	},
	declineProposal,
}) => {
	return (
		<div className={classes.mb2}>
			<Typography variant='subTitle2'>{freelance.displayName}</Typography>
			<div>
				{Object.keys(freelance.days).map((date, index) => (
					<div
						key={index}
						className={classes.mb05}
					>
						<DateWithActions
							date={date}
							user={omit(freelance, ['dates'])}
							declineProposal={declineProposal}
						/>
					</div>
				))}
			</div>
		</div>
	)
}

export default FreelanceMissionProposals

import React from 'react';
import { useContext, useMemo, useState } from 'react'
import { Divider } from 'semantic-ui-react'
import ChooseUserTypeForm from '../../containers/account/SignUp/components/ChooseUserTypeForm'
import FormComponent from '../Form/Form'
import { useParams } from 'react-router-dom'
import classNames from 'classnames'
import Image from 'components/Image/Image'
import { Store } from '../../store/store'
import classes from './UnAuthLayout.module.scss'
import Typography from 'components/Typography'
import AuthFormHeader from './AuthFormHeader';
import GoogleButton from 'components/GoogleButton/GoogleButton';

const LeftSide = ({
    page = 'login',
    message = '',
    inputs,
    sendForm: sendFormFromParams,
    messageLinkeable,
    goToUserTypePage,
    submitMessage,
}) => {
    const {
        state: {
            language: { login: copy },
        },
    } = useContext(Store)
    const [loading, setLoading] = useState(false)
    const { userType } = useParams()
    const sendForm = async (params) => {
        setLoading(true)
        try {
            await sendFormFromParams(params)
        } catch (error) {
            console.log('error', error)
        } finally {
            setLoading(false)
        }
    }
    const confirmAccountPage = useMemo(() => page === 'confirmAccount' && (
        <Typography variant='titleLittle' classes={{ root: classes.confirmAccountText }}>
            {copy.titleConfirm}
        </Typography>
    ), [page, copy])

    const loginOrSignUpPage = useMemo(() => page === 'login' || page === 'signup' ? (
        <AuthFormHeader
            page={page}
            copy={copy}
        />
    ) : (
        <Typography variant='text'>
            {message}
        </Typography>
    ), [page, copy, message])

    return (
        <div className={classNames(classes.fullHeight, classes.leftSideContainer)} >
            <div className={classes.imageContainer}>
                <Image fullHeight centered size='small' link='/' alt='Logo' src={'/assets/styleep-logo.png'} />
            </div>
            <div className={classNames(classes.actionContainer, classes.centeredContainer)}>
                <div>
                    {(page === "signup" || page === 'login') && <Typography
                        classes={{ root: classes.taCenter }}
                        primaryColor
                        variant='subTitle2'
                        underline
                    >
                        {copy.forThe[userType]}
                    </Typography>}
                    <div className={classes.switchSignContainer}>
                        <div >
                            {confirmAccountPage}
                            {loginOrSignUpPage}
                        </div>
                    </div>
                    <div>
                        {!userType && page !== 'forgot' && page !== 'confirmAccount' && page !== 'reset' ? (
                            <ChooseUserTypeForm
                                onClick={goToUserTypePage}
                            />
                        ) : page === 'confirmAccount' || page === 'reset' || page === 'forgot' ? <div >
                            <FormComponent
                                responsive={false}
                                segment={false}
                                inputs={inputs}
                                sendForm={sendForm}
                                messageLinkeable={messageLinkeable}
                                messages={{ submit: submitMessage }}
                                loading={loading}
                                noConfirmationMailLang={
                                    copy.noConfirmationMail
                                }
                            />
                        </div> : (
                            <div >
                                <GoogleButton
                                    text={
                                        page === 'login'
                                            ? copy.loginGoogle
                                            : copy.signUpGoogle
                                    }
                                />
                                <Divider horizontal className={classes.divider}>
                                    {copy.or}
                                </Divider>
                                <FormComponent
                                    responsive={false}
                                    segment={false}
                                    inputs={inputs}
                                    sendForm={sendForm}
                                    messageLinkeable={messageLinkeable}
                                    messages={{ submit: submitMessage }}
                                    loading={loading}
                                    noConfirmationMailLang={
                                        copy.noConfirmationMail
                                    }
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LeftSide;
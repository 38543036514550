import { isLoggedIn, updateLanguage } from 'containers/helpers'
import { useContext } from 'react'
import { Flag, Dropdown, Menu } from 'semantic-ui-react'

import { Store } from '../store/store'

const LanguageSelection = () => {
    const options = [
        {
            key: 1,
            text: 'English',
            value: 'us',
            content: <Flag name="us" />,
        },
        {
            key: 2,
            text: 'Français',
            value: 'fr',
            content: <Flag name="france" />,
        },
    ]

    const {
        dispatch,
        state: { languageValue },
    } = useContext(Store)

    const changeLanguage = async (newLang) => {
        try {
            isLoggedIn() && await updateLanguage(newLang)
            dispatch({ type: 'CHANGE_LANGUAGE', payload: newLang })
        } catch (error) {
            console.log('error', error)
        }
    }

    const handleChange = async (e, { value }) => {
        changeLanguage(value)
    }

    return (
        <Menu compact>
            <Dropdown
                trigger={<Flag name={languageValue} />}
                options={options}
                onChange={handleChange}
                item
                closeOnChange
            />
        </Menu>
    )
}

export default LanguageSelection

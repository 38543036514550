import { useCallback, useEffect, useMemo, useState } from 'react'
import FormComponent from 'components/Form/Form'
import { useContract } from 'hooks/useContract'
import Loader from 'components/Loader'

function CreateContractIssue({
    onSubmit: onSubmitFromParams,
    lang,
    contractId: contractIdFromParams,
    issueType,
}) {
    const [contractId, setContractId] = useState(Number(contractIdFromParams) || null)
    useEffect(() => {
        if (contractIdFromParams) {
            setContractId(Number(contractIdFromParams))
        }
    }, [contractIdFromParams])
    const { contract } = useContract(contractId)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (contract && JSON.stringify(contract) !== '{}') {
            setLoading(false)
        }
    }, [contract])

    const onSubmit = useCallback((values) => {
        const toSubmit = {
            type: issueType,
            title: values.title,
            description: values.description,
            mission: contract.mission,
            contract: contractId,
        }

        return onSubmitFromParams(toSubmit)


    }, [contract, contractId, issueType, onSubmitFromParams])

    const inputs = useMemo(() => [
        {
            type: 'text',
            name: 'title',
            label: lang.form.title.label,
            placeholder: lang.form.title.placeholder,
            required: true,
            silentRequired: true,
        },
        {
            type: 'textArea',
            name: 'description',
            label: lang.form.description.label,
            placeholder: lang.form.description.placeholder,
            required: true,
            silentRequired: true,
            legend: lang.form.description.legend,
            maxLength: 1000,
        },
    ].filter(e => !!e), [lang])

    return (loading ? <Loader /> :
            <FormComponent sendForm={onSubmit} inputs={inputs} />
    )
}

export default CreateContractIssue

import { godfatherStatuses } from "constants/godfather.constant"

export const FR = {
  title: 'Parrainages',
  myCode: 'Mon code de parrainage',
  arrayOf: 'Tableau de vos codes utilisés',
  status: 'Statut',
  createdAt: 'Créé le',
  noGodson: 'Pas de filleul.',
  statuses: {
    [godfatherStatuses.WAITING_FIRST_MISSION]: "En attente de sa première mission effectuée",
    [godfatherStatuses.FIRST_MISSION_DONE]: "Première mission effectuée, contactez nous pour réclamer votre rémunération",
    [godfatherStatuses.PAYMENT_DONE]: "Paiement effectué",
  }
  }
  
  export const EN = {
  title: 'Sponsorships',
  myCode: 'My godfather code',
  arrayOf: 'Table of your used codes',
  status: 'Status',
  createdAt: 'Created at',
  noGodson: 'No godson.',
  statuses: {
      [godfatherStatuses.WAITING_FIRST_MISSION]: "Waiting first mission",
      [godfatherStatuses.FIRST_MISSION_DONE]: "First mission done, you can ask us your funds",
      [godfatherStatuses.PAYMENT_DONE]: "Payment done",
    }
    }
  
  export default {
    fr: FR,
    us: EN,
  }
  
export const arr2obj = ({ arr, value }) => {
	const res = {};
	for (const key of arr) {
		res[key] = value;
	}
	return res;
};


export const onlyKeysWithValue = (obj, value) =>
	Object.keys(obj).filter((key) => obj[key] === value)

export const onlyTrueKeys = (obj) => onlyKeysWithValue(obj, true)
export const onlyFalseKeys = (obj) => onlyKeysWithValue(obj, false)
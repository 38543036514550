import ReviewQuestionTypes from "constants/ReviewQuestionTypes";
import { average, fetchApi } from "containers/helpers";
import { upperFirst } from "lodash";

export const getStudioForFreelance = userId => fetchApi('/freelance/studio/' + userId)

export const getStudioDisplayName = ({
    studio_name
}) => upperFirst(studio_name)

export const getPublicAppointmentReviews = appointmentId =>
    fetchApi(`/public/review/appointment/${appointmentId}`)

export const getRatingFromReviews = reviews =>
    Math.round(
        average(
            reviews
                .filter(({ type }) => type === ReviewQuestionTypes.SLIDE)
                .map(({ answer }) => Number(answer))
        )
    )

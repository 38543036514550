import IssueLinkButton from "components/IssueLinkButton"
import RightSide from "components/RightSide/RightSide"
import { types } from "containers/issue/issue.enum"
import useLanguage from "hooks/useLanguage"
import TitlePage from 'components/TitlePage'

const LevelUpMeeting = () => {
    const lang = useLanguage('profile')
    return (
        <RightSide>
            <TitlePage>{lang.askLevelUp}</TitlePage>
            <IssueLinkButton textButton={lang.sendRequest} isButton type={types.UPDATE_LEVEL} />
        </RightSide>
    )
}

export default LevelUpMeeting
import { dayStatuses } from 'constants/DayStatuses'
import { UserTypes } from 'constants/UserTypes'
import useLanguage from 'hooks/useLanguage'
import { memo, useMemo } from 'react'
import Button from 'components/Button'
import Typography from 'components/Typography'

function ReviewButton({ appointment, status, type }) {
	const lang = useLanguage('review')
	const reviewLink = useMemo(() => `/review/create/${appointment}`, [appointment])
	const ReviewButtonWithLink = memo(({ text }) => <Button link={reviewLink}>{text}</Button>, [reviewLink])
	const ThanksButton = memo(() => <Typography primaryColor>{lang.thanks}</Typography>, [lang])

	if (status === dayStatuses.FINISHED) return null

	if (status === dayStatuses.WAITING_REVIEW) {
		if (type === UserTypes.FREELANCE)
			return <ReviewButtonWithLink text={lang.leaveReviewForStudio} />
		else if (type === UserTypes.STUDIO)
			return <ReviewButtonWithLink text={lang.leaveReviewForFreelance} />
	}

	if (status === dayStatuses.WAITING_FREELANCE_REVIEW) {
		if (type === UserTypes.FREELANCE)
			return <ReviewButtonWithLink text={lang.leaveReviewForStudio} />
		if (type === UserTypes.STUDIO)
			return <ThanksButton />
	}

	if (status === dayStatuses.WAITING_STUDIO_REVIEW) {
		if (type === UserTypes.FREELANCE)
			return <ThanksButton />
		if (type === UserTypes.STUDIO)
			return <ReviewButtonWithLink text={lang.leaveReviewForFreelance} />
	}
}

export default ReviewButton

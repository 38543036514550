import Image from 'components/Image';
import LevelBar from 'components/LevelBar';
import Typography from 'components/Typography';
import { UserTypes } from 'constants/UserTypes';
import { getImageUrl } from 'containers/helpers';
import useLanguage from 'hooks/useLanguage';
import { upperFirst } from 'lodash';
import { getDateFormatted } from 'modules/dates';
import React, { useContext, useMemo } from 'react';
import { Store } from 'store/store';
import classes from './InfoProfileCard.module.scss'

const InfoProfileCard = ({
    email,
    phone,
    firstname,
    lastname,
    company_name,
    address,
    created_at,
    image,
    // level,
    studio_name,
    legal_name,
    denomination = false
}) => {
    const { user: { type } } = useContext(Store).state
    const lang = useLanguage('profile')
    const profilePicture = useMemo(() => image &&
        <Image
            contain
            className={classes.image}
            size="larger"
            src={getImageUrl(image)} />
        , [image])

    return (
        <div className={classes.root}>
            <div className={classes.imageContainer}>
                {profilePicture}
                {/* {type === UserTypes.FREELANCE &&
                    <LevelBar className={classes.levelBar} fullWidth displayLevel level={Number(level)} />
                } */}
            </div>
            <div className={classes.infosContainer}>
                <div className={classes.top}>
                    <Typography variant='subTitle2' classes={{ root: classes.mb0 }}>
                        <div>
                            {upperFirst(firstname)} {upperFirst(lastname)} - <Typography italic>{
                                upperFirst(type === UserTypes.FREELANCE ? company_name : studio_name)
                            }</Typography>
                        </div>
                        {denomination && <Typography>{upperFirst(legal_name)}</Typography>}
                    </Typography>
                    <div className={classes.addressContainer}>
                        <Typography variant='littleText' italic >{address}</Typography>
                    </div>
                </div>
                <div className={classes.middle}>
                    <Typography fontWeight={500} >{phone}</Typography>
                    <Typography fontWeight={500} >{email}</Typography>
                </div>
                <div className={classes.bottom}>
                    <Typography italic >{lang.memberSince} {getDateFormatted(created_at)}</Typography>
                </div>
            </div>
        </div>
    );
};

export default InfoProfileCard;
import classNames from 'classnames';
import React, { memo } from 'react';
import { Fade } from 'react-awesome-reveal';
import classes from './UnAuthRightSide.module.scss'

const UnAuthRightSide = memo(({ lang }) => {

    return (
        <div className={classNames(classes.fullHeight, classes.centeredContainer, classes.container)} >
            <div>
                <Fade duration={2000}>
                    <span className={classes.styleep}>Styleep,<br /></span>
                </Fade>
                <Fade delay={2000} duration={3000}>
                    <span>{lang.rightSideText}</span>
                </Fade>
            </div>
        </div>
    );
});

export default UnAuthRightSide;
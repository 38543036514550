import CheckDates from 'components/CheckDates';
import useLanguage from 'hooks/useLanguage';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import toast from 'components/toast';
import { Store } from 'store/store';
import { sendConfirmationsAPI } from '../helpers';
import TitlePage from 'components/TitlePage';
import Typography from 'components/Typography';
import Button from 'components/Button';
import { UserTypes } from 'constants/UserTypes';
import { date2unix } from 'modules/dates';
import BinaryModal from 'components/BinaryModal/BinaryModal';
import { isEmpty } from 'lodash';

const ContractDatesComponent = ({ days = {}, id: contractId, mission: missionId, freelanceName, studioName }) => {
	const [openModal, setOpenModal] = useState();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [checkedDays, setCheckedDays] = useState({});
	const {
		state: { user },
	} = useContext(Store);
	const lang = useLanguage('contractDates', user.type);
	const [defaultDaysStatuses, setDefaultDaysStatuses] = useState(
		Object.entries(days || {}).reduce((prev, [date, { dayStatus }]) => {
			prev[date] = dayStatus;
			return prev;
		}, {}),
	);

	useEffect(() => {
		setCheckedDays(
			Object.entries(days).reduce((prev, [date, { dayStatus }]) => {
				prev[date] = {
					checked: true,
					dayStatus,
				};
				return prev;
			}, {}),
		);
		setDefaultDaysStatuses(
			Object.entries(days).reduce((prev, [date, { dayStatus }]) => {
				prev[date] = dayStatus;
				return prev;
			}, {}),
		);
	}, [days]);

	const sendConfirmations = useCallback(async () => {
		setLoading(true);
		try {
			const checkedDaysWithDates = Object.entries(checkedDays).reduce((prev, [date, { dayStatus }]) => {
				prev[date] = {
					dayStatus,
					dates: days[date].dates.map(date2unix),
				};
				return prev;
			}, {});

			const syncContract = await sendConfirmationsAPI(contractId, checkedDaysWithDates);
			navigate(`/mission/${missionId}/contract/${syncContract.id}`);
			toast.success('Succès');
		} catch (error) {
			toast.error('Erreur ' + error.message);
		} finally {
			setLoading(false);
		}
	}, [checkedDays, contractId, missionId, navigate, days]);

	const subTitleWithFreelanceName = useMemo(
		() => (user.type === UserTypes.STUDIO ? lang.subtitle.replace('$1', freelanceName) : lang.subtitle),
		[lang, user.type, freelanceName],
	);

	const diffDays = useMemo(
		() =>
			Object.entries(days)
				.filter(([date, e]) => {
					const concernedDay = checkedDays[date] || {};
					return !!!concernedDay.checked || concernedDay.dayStatus !== defaultDaysStatuses[date];
				})
				.map(([date]) => date),
		[checkedDays, defaultDaysStatuses, days],
	);

	return (
		<>
			<TitlePage subtitle={<Typography variant="subTitle1">{subTitleWithFreelanceName}</Typography>}>
				{lang.title}
			</TitlePage>
			<CheckDates
				days={days}
				checkedDays={checkedDays}
				setCheckedDays={setCheckedDays}
				defaultDaysStatuses={defaultDaysStatuses}
			/>
			<Button
				loading={loading}
				disabled={loading}
				onClick={() => (isEmpty(diffDays) ? sendConfirmations() : setOpenModal(true))}
			>
				{lang.confirmButton}
			</Button>
			<BinaryModal
				loading={loading}
				open={openModal}
				onCancel={() => setOpenModal(false)}
				onAction={sendConfirmations}
				header={lang.areYourSure}
				description={lang.dateDifferes(diffDays.join(', '))}
				cancelText={lang.notSure}
				actionText={lang.validTheseDays}
			/>
		</>
	);
};

export default ContractDatesComponent;

import { useState, useRef, useContext } from 'react';
import Zoom from 'react-awesome-reveal';
import { Label } from 'semantic-ui-react';
import { Store } from '../../store/store';
import TextInput from './TextInput';
import classes from './Form.module.scss'
import classNames from 'classnames';

const KeyWordsInput = ({
    size = 3,
    onChange: onChangeInitial,
    onKeyPress: onKeyPressInitial,
    handleEffect: handleEffectInitial,
    placeholders = [],
    classNameKeyWords,
    value: initialValue = null,
    ...props
}) => {
    const [keywords, setKeywords] = useState(initialValue || new Array(size).fill(''));
    const [inputValue, setInputValue] = useState('');
    const [actual, setActual] = useState(-1);
    const updateKeywords = (keyword) => keywords.map((elem, index) => (index === actual ? keyword : elem));
    const isEnoughtItems = (keywordsEntered) => !(keywordsEntered.filter((e) => !!e).length < size);
    const handleEffect = (event, { value: receivedValue } = {}) => {
        setInputValue(receivedValue);
        setKeywords(updateKeywords(receivedValue));
        handleEffectInitial({
            target: {
                value: isEnoughtItems(updateKeywords(receivedValue)) ? updateKeywords(receivedValue) : null,
            },
        });
    };

    const onKeyPress = (e) => {
        if (e.key === 'Enter') {
            setActual((actual + 1) % size);
            setInputValue(keywords[(actual + 1) % size] || '');
        }
    };

    const inputRef = useRef(null);
    let labelFocused = false;
    const focusLabel = (index) => {
        setActual(index);
        setInputValue(keywords[index]);
        labelFocused = true;
        inputRef.current.focus();
    };

    const onFocus = () => {
        if (!labelFocused) {
            setInputValue(keywords[0]);
            setActual(0);
        } else {
            labelFocused = false;
        }
    };

    const onBlur = () => {
        setActual(-1);
        setInputValue('');
    };
    const {
        state: {
            language: {
                form: { onPressMessage: onPressMessageLang },
            },
        },
    } = useContext(Store);
    return (
        <div className={classes.field}>
            <TextInput
                {...props}
                maxLength={15}
                handleEffect={handleEffect}
                onKeyPress={onKeyPress}
                value={inputValue}
                onBlur={onBlur}
                forwardRef={inputRef}
                onFocus={onFocus}
            >
                <Zoom opposite when={!!inputValue}>
                    <Label>{onPressMessageLang}</Label>
                </Zoom>
            </TextInput>

            <div className={classNames(classes.keywordsContainer, classNameKeyWords)}>
                {keywords.map((value, index) => (
                    <Label
                        className={index === actual || !!keywords[index] ? classes.focus : ''}
                        key={index}
                        size="medium"
                        onClick={() => focusLabel(index)}
                    >
                        {value || placeholders[index]}
                    </Label>
                ))}
            </div>
        </div>
    );
};

export default KeyWordsInput;

import { useEffect, useMemo, useState } from 'react';
import { getFreelanceBadges } from '../containers/marketplace/freelance/helpers';
import { getImageUrl } from '../containers/helpers';
import useLanguage from './useLanguage';
import { LevelTypes } from 'constants/FreelanceTypes.contant';

export const useFreelanceUtils = (freelance = {}) => {
	const level = useMemo(() => Math.trunc(parseFloat(freelance.level)), [freelance]);
	const [priceHour, setPriceHour] = useState('En cours de calcul');
	const freelanceLang = useLanguage('freelance');
	// const [missionPrice, setMissionPrice]  = useState('En cours de calcul')

	const initPrices = async (freelance, level) => {
		try {
			setPriceHour(freelance.hourly_rate);
		} catch (e) {
			console.log('e', e);
		}
	};
	const [badges, setBadges] = useState({});

	const initBadges = async (freelance) => {
		try {
			const newBadges = await getFreelanceBadges(freelance.id);
			setBadges(newBadges);
		} catch (error) {
			console.log('error', error);
		}
	};

	useEffect(() => {
		if (freelance && freelance.days && freelance.id) {
			initPrices(freelance, level);
			initBadges(freelance);
		}
	}, [freelance, level]);
	const imageUrl = useMemo(() => freelance.image && getImageUrl(freelance.image), [freelance]);
	const type = useMemo(() => (level ? LevelTypes[level] : 0), [level]);
	const typeLang = useMemo(() => type != null && freelanceLang.types[type], [type, freelanceLang]);
	return {
		...freelance,
		level,
		priceHour,
		// withoutReductionPriceDay,
		// withoutReductionPriceDays,
		// reductionPercentile,
		badges,
		imageUrl,
		type,
		typeLang,
	};
};

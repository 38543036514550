export const FR = {
	commissionTextOne: (feePourcentage) => `(prix de la prestation) - ${feePourcentage}% (commission styleep)`,
	sumReceived: '(la somme que je perçois)',
	usage: 'Il peut parfois être difficile de se faire payer sa prestation, c’est pourquoi styleep a mis en place un service de pré-paiement : vous assurant d’être payé en 24h après la fin de la mission. Voici comment cela fonctionne :',
	steps: {
		0: "L'entreprise d'accueil effectue un paiement à l'avance à Styleep, couvrant le montant total de la mission, avant son commencement.",
		1: 'À la fin de la mission, nous vous transférons automatiquement vos fonds, garantissant une transaction sécurisée.',
	},
	commission: (feePourcentage) =>
		`La plateforme retient une commission de ${feePourcentage}% sur le montant total de la commande pour couvrir les frais de paiement et les services de la plateforme.`,
	titleModal: 'Méthode de pré-paiement',
	cancelModal: 'Peut-être une prochaine fois',
	acceptModal: 'Je souhaite utiliser la méthode de prépaiement',
	cancelModalTitle: 'Annuler la méthode de prépaiement',
	cancelModalCancelText: 'Continuer avec le prépaiement',
	cancelModalActionText: 'Je ne veux plus utiliser le prépaiement',
	cancelModalDescription:
		'Si vous annuler la methode de prépaiement vous ne pourrez plus compter sur styleep pour assurer la sécurité de votre versement.',
};

export const EN = {
	commissionTextOne: (feePourcentage) => `(price of the service) - ${feePourcentage}% (styleep commission)`,
	sumReceived: '(the amount I receive)',
	usage: "It can sometimes be challenging to receive payment for your services, which is why Styleep has implemented a prepayment service: ensuring you get paid within 24 hours after the completion of the mission. Here's how it works.",
	steps: {
		0: 'The studio makes an advance payment to Styleep, covering the total amount of the mission, before it begins.',
		1: 'At the end of the mission, we automatically transfer the funds to the freelance freelance, ensuring a secure transaction.',
	},
	commission: (feePourcentage) =>
		`The platform retains a ${feePourcentage}% commission on the total order amount to cover payment processing and platform services fees.`,
	titleModal: 'Prepayment method',
	cancelModal: 'Perhaps another time',
	acceptModal: 'I wish to use the prepayment method.',
	cancelModalTitle: 'Cancel the prepayment method',
	cancelModalCancelText: 'Proceed with the prepayment',
	cancelModalActionText: 'I no longer want to use the prepayment method',
	cancelModalDescription:
		"If you cancel the prepayment method, you won't be able to rely on Styleep to ensure the security of your payment.",
};

export default {
	fr: FR,
	us: EN,
};

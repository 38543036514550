import timestamp2date from 'common/utils/date/timestamp2date'
import { fetchApi } from '../helpers'

const setFrontEndMissionDates = (missions) =>
	missions.map((mission) => {
		const m = { ...mission }
		m.start && (m.start = timestamp2date(m.start))
		m.end && (m.end = timestamp2date(m.end))
		return m
	})

export const getMeetingsByElement_api = (elementId) =>
	fetchApi(`/meeting/getMeetingsByElement/${elementId}`).then(
		setFrontEndMissionDates
	)

export const getAllUnrelatedMeetings_api = (elementId) =>
	fetchApi(`/meeting/getAllUnrelatedMeetings/${Number(elementId)}`).then(
		setFrontEndMissionDates
	)

export const getAllOtherUsersStartTime_api = () =>
	fetchApi('/meeting/getAllOtherUsersStartTime').then(setFrontEndMissionDates)

export const getUserMeeting_api = async () =>
	fetchApi('/meeting/getUserMeeting').then(setFrontEndMissionDates)

export const scheduleMeeting_api = ({ userId, start, end, elementId }) =>
	fetchApi('/meeting', {
		body: {
			user: userId,
			start,
			end,
			element: elementId
		},
	})

export const cancelMeeting_api = async ({ meetingId }) => {
	try {
		return fetchApi(`/meeting/${meetingId}`, { method: 'DELETE' })
	} catch (e) {
		console.error(e)
		return null
	}
}

import Typography from 'components/Typography';
import useLanguage from 'hooks/useLanguage';
import React, { useEffect, useMemo, useState } from 'react';
import { getPublicAppointmentReviews } from '../helpers'
import MissionReview from './MissionReview';
import classes from './StudioPage.module.scss'

const StudioReviews = ({
    appointments = []
}) => {
    const [reviews, setReviews] = useState({})
    const lang = useLanguage('studio')
    const chargeAppointmentReviews = async appointmentId => {
        try {
            const appointmentReviews = await getPublicAppointmentReviews(appointmentId)
            if (appointmentReviews && appointmentReviews.length > 0) {
                setReviews(
                    reviews =>
                    ({
                        ...reviews,
                        [appointmentId]: {
                            reviews: appointmentReviews,
                            ...appointments.find(({ id }) => appointmentId === id)
                        }
                    })
                )
            }
        } catch (error) {
            console.log('error', error)
        }
    }

    const chargeAppointmentsReviews = (appointments) => {
        appointments
            .map(appointment => chargeAppointmentReviews(appointment.id))
    }

    useEffect(() => {
        if (appointments && appointments.length > 0) {
            chargeAppointmentsReviews(appointments)
        }
    }, [appointments])

    const hasReviews = useMemo(() => Object.keys(reviews).length > 0, [reviews])
    return (!!hasReviews &&
        <div >
            <Typography variant='subTitle2'>{lang.reviewsTitle}</Typography>
            <div className={classes.reviewsContainer}>
                {Object.entries(reviews).map(([key, { title, description, reviews, days }], index) =>
                    <MissionReview
                        key={index}
                        title={title}
                        description={description}
                        reviews={reviews}
                        days={days}
                    />)
                }
            </div>
        </div>
    );
};

export default StudioReviews;
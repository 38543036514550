import { useMemo } from "react"

export const useTestMode = () => {
    const isTestMode = useMemo(() => process.env.REACT_APP_ENVIRONMENT === 'testing'
        || process.env.NODE_ENV === 'development',
        [])

    return {
        isTestMode
    }
}
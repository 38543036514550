import classNames from 'classnames'
import Typography from 'components/Typography'
import useLanguage from 'hooks/useLanguage'
import Button from 'components/Button'
import CheckoutForm from './CheckoutForm'
import CreditCard from './CreditCard'
import classes from './Payment.module.scss'
import { useMemo } from 'react'
import InvoiceProFormat from './InvoiceProFormat'

const PaymentComponent = ({
	price: { price, currency } = {
		price: 300,
		currency: '€',
	},
	card,
	setCard,
	cards,
	payWithElement,
	disabled,
	error,
	onChange,
	onFocus,
	payWithRegisteredCard,
	loading,
	test,
	errorPayment,
	paymentType,
	changePaymentType,
	subscriptionPaymentLanguage,
	sepa,
	setSepa,
	title,
	starterPayLanguage,
	setFirstName,
	setLastName,
	bankTransferSent,
	reference,
	invoicePF
}) => {
	const lang = useLanguage('pay')

	// const handleClickSepa = () => {
	// 	if (paymentType !== 'sepa_debit') {
	// 		changePaymentType('sepa_debit')
	// 		setSepa(false)
	// 	}
	// }
	// const handleClickVirement = () => {
	// 	if (paymentType !== 'bankTransfer') {
	// 		changePaymentType('bankTransfer')
	// 		setSepa(false)
	// 	}
	// }

	// const isDevelopment = useMemo(() => process.env.NODE_ENV !== 'development', [])

	return (
		<div className={classes.cardPaymentContainer}>
			{paymentType === 'card' && cards.length > 0 &&
				<div>
					<Typography variant='subTitle2'>{lang.registeredCardTitle}</Typography>
					<Typography variant='subTitle3'>{lang.registeredCardSubtitle}</Typography>
					<div className={classes.creditCardContainer}>
						{cards.map((creditCard, index) => (
							<CreditCard
								key={index}
								{...creditCard}
								className={card && card.id === creditCard.id ? classes.selected : ''}
								onClick={() => {
									changePaymentType('card')
									setCard(creditCard)
								}}
							/>))}
					</div>
				</div>}
			<div className={classes.paymentCheckoutContainer} >
				<div>
					{paymentType !== 'bankTransfer' && <>
						<Typography variant='subTitle2'>{lang.newCardTitle}</Typography>
						<Typography variant='subTitle3'>{lang.newCardSubtitle}</Typography>
					</>
					}
					<div className={classes.verticalCenteredContainer}>
						<CheckoutForm
							setFirstName={setFirstName}
							setLastName={setLastName}
							setSepa={setSepa}
							paymentType={paymentType}
							pay={payWithElement}
							onChange={onChange}
							onFocus={onFocus}
							saveCardTextLang={lang.saveCardText}
							sepaAuthorizationLang={lang.sepa.authorization}
							price={price}
							currency={currency}
							reference={reference}
							invoicePF={invoicePF}
						>
							{/* <div>
								{isDevelopment && <>
									<Button type={paymentType === 'card' ? 'primary' : 'cancel'} onClick={() => changePaymentType('card')}>{lang.card.title}</Button>
									<Button type={paymentType === 'sepa_debit' ? 'primary' : 'cancel'} onClick={handleClickSepa}>{lang.sepa.title}</Button>
									<Button type={paymentType === 'bankTransfer' ? 'primary' : 'cancel'} onClick={handleClickVirement}>{lang.bankTransfer.selectionButton}</Button>
								</>}
							</div> */}
							{invoicePF && <InvoiceProFormat
							className={classNames(classes.ASflexEnd, classes.verticalCenteredContainer, classes.mt1)}
							invoicePF={invoicePF} />}
							<div className={classes.mt2}>
								<div className={classNames(classes.centeredContainer, classes.columnDirection)}>
									<Typography classes={{ root: classes.errorPayment }}>{errorPayment}</Typography>
									<Typography variant='subTitle2' classes={{ root: classes.price }}>{price + ' ' + currency + ' ' + lang.TTC}</Typography>
									{test
										? <Button >{lang.buttonTest}</Button>
										: <Button
											disabled={
												paymentType === 'bankTransfer'
													? false
													: paymentType === 'card'
														? (!card && !!error) || disabled || loading
														: !sepa || !!error || disabled || loading}
											{...(paymentType === 'bankTransfer' ?
												{ onClick: bankTransferSent } : paymentType === 'card' ? card ?
													{ onClick: payWithRegisteredCard } : { onClick: payWithElement } : { onClick: payWithElement })}
											loading={loading}
										>
											{paymentType === 'sepa_debit' ? lang.sepa.button
												: paymentType === 'bankTransfer' ? lang.bankTransfer.sentBankTransferButton
													: 'card' ? card ? `${lang.buttonPayCard} XXXX XXXX XXXX ${card.last4}` : lang.buttonPayNewCard : lang.pay}
										</Button>
									}
								</div>
							</div>
						</CheckoutForm>
						
					</div>
				</div>

			</div>
		</div >
	)
}

export default PaymentComponent

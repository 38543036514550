import React from 'react';
import LinkSubStep from './LinkSubStep';

const LinkSubSteps = ({ steps = [] }) => {
    return (
        <div>
            {steps.map((step, key) =>
                <LinkSubStep
                    key={key}
                    step={step}
                />)}
        </div>
    );
};

export default LinkSubSteps;
import moment, { isMoment } from 'moment-timezone';
import { timeDay, timeParse } from 'd3';
import { isDate, isEmpty, isString, upperFirst } from 'lodash';
import { getLastElemOfArr, isNumber } from 'containers/helpers';
import { dayStatuses } from 'constants/DayStatuses';
import { getLangStatus } from 'containers/mission/helpers';
import { EventTypes } from 'constants/EventTypes.constant';
import { UserTypes } from 'constants/UserTypes';
export const parisTimezone = 'Europe/Paris';
export const startDayTime = 9;
export const endDayTime = 19;
export const pausedDayTime = 13;

export const daysStatus = {
	day: {
		start: { hour: 9, minute: 0 },
		end: { hour: 18, minute: 0 },
	},
	morning: {
		start: { hour: 9, minute: 0 },
		end: { hour: 13, minute: 0 },
	},
	afternoon: {
		start: { hour: 13, minute: 0 },
		end: { hour: 18, minute: 0 },
	},
};
export const momentFromDateString = (dateString) => moment(dateString, 'DD/MM/YYYY');
export const dateToDateFormat = (date, locale) => moment(date).locale(locale).format('dddd Do MMMM YYYY');

export const dayDateFormat = (date, locale) => moment.unix(date).locale(locale).format('dddd Do MMMM YYYY');

export const formatSlotsCountInTime = (slotsCount: number) => {
	const durationInMinutes = slotsCount * 15;

	const hours = Math.floor(durationInMinutes / 60);
	const minutes = Math.floor(durationInMinutes % 60);
	return `${hours > 0 ? hours + 'h' : ''} ${minutes > 0 ? minutes + 'min' : ''}`;
};

export const dateByDateFormat = (date, format, locale) =>
	moment(date, format).locale(locale).format('dddd Do MMMM YYYY');

export const dateByDateMoment = (date, format, locale) => moment(date, format).locale(locale);

export const shortDateFormat = (ts, locale) => moment(ts).format(locale === 'en' ? 'MM/DD' : 'DD/MM');

const parseTimestamp = timeParse('%s');

export const floorTimestampFromAPIToUnix = (date) =>
	Math.floor(timeDay.floor(moment.unix(timestampFromAPIToUnix(date)).toDate()).getTime() / 1000);

// 2023-09-28T00:00:00.000
// Date format
//

export const floorDate = (dateString) => {
	return timeDay.floor(parseTimestamp(dateString)).getTime() / 1000;
};

export const timeDateFormat = (date) => moment(date).format('YYYY-MM-DD HH:mm');
export const getDateFormatted = (date) => moment(date).format('YYYY-MM-DD');

export const timestamp2date = (ts) => new Date(Date.parse(ts));

export const stripFullTimezoneName = (dateStr) => dateStr.split('(')[0].trim();

export const timestampFromAPIToUnix = (date) => timestamp2unix(new Date(date));

export const dateObj2dateKey = (date) => moment2unix(date);

export const timestamp2unix = (ts) => moment2unix(Date.parse(ts));

export const date2unix = (date) => moment(date).unix();
export const unix2date = (unix) => moment.unix(unix);
export const unix2moment = (unix) => moment.unix(unix);
export const date2moment = (date) => moment(date);

export const moment2unix = (momentObj) => momentObj.unix();

export const diffInMinutesBetweenTwoDates = (date1, date2) => {
	if (!date1 || !date2) return 0;
	return Math.abs(moment(unix2date(date2 - date1)).minutes());
};

export const isSameDay = (d1, d2) => {
	const m1 = moment(d1);
	const m2 = moment(d2);

	return m1.isSame(m2, 'day');
};

export const addDays = (date, days) => {
	const result = new Date(date);
	result.setDate(result.getDate() + days);
	return result;
};

export const timestamp2mili = (ts) => Math.floor(ts * 1000);

export const hasWeekends = ({ start, end }) => {
	if (isWeekend(start) || isWeekend(end)) {
		return true;
	}
	return Math.abs(moment.unix(start).diff(moment.unix(end), 'days')) > 6;
};

export const convertTimeZoneFromDate = (date) => {
	const m = isMoment(date) ? date.tz(parisTimezone) : moment(date).tz(parisTimezone);
	return m.format('YYYY-MM-DDTHH:mm:ss.SSS');
};

export const convertTimeZoneFromUnix = (date) => {
	const m = moment.unix(date).tz(parisTimezone);
	return m.format('YYYY-MM-DDTHH:mm:ss.SSS');
};

export const onlyWeekends = (dates) => {
	for (const date of dates) {
		if (!isWeekend(date)) {
			return false;
		}
	}
	return true;
};

export const getWeekendDaysInRange = ({ start, end }) => {
	let dates = [];
	let current = start;

	while (current < end) {
		if (isWeekend(current)) {
			dates.push(current);
		}
		current = addDays(current, 1);
	}
	return dates;
};

export const removeWeekends = (dates) => dates.filter((date) => !isWeekend(date));

export const removeDatesNotInRange = (dates, start, end) => {
	return dates.filter((date) => {
		return date >= start && date <= end;
	});
};

export const isWeekend = (date) => {
	const _date = moment.unix(date);
	const dayOfWeek = _date.day();
	return dayOfWeek === 0 || dayOfWeek === 6;
};

export const dayToSlots = (momentDate = moment(), dayType = 'full') => {
	const startDay = momentDate.clone().startOf('day');
	const endDay = momentDate.clone().endOf('day');
	const slots = [];
	let actualDayDate = startDay.clone();
	while (actualDayDate.isBefore(endDay)) {
		slots.push(actualDayDate.clone());
		actualDayDate = actualDayDate.add(15, 'minutes');
	}
	return slots;
};

export const getSlotsFromRecurrence = ({ start, end, onDays, repeats }) => {
	let slots = [];
	let currentDate = moment(start);

	while (currentDate <= end) {
		const currentWeekDay = moment(currentDate).isoWeekday();
		for (let j = 0; j < onDays?.length; j++) {
			if (currentWeekDay <= Number(onDays[j])) {
				slots = [...slots, ...dayToSlots(moment(currentDate).isoWeekday(onDays[j]))];
			} else {
				slots = [...slots, ...dayToSlots(moment(currentDate).add(1, 'weeks').isoWeekday(onDays[j]))];
			}
		}
		currentDate = moment(currentDate).add(repeats, 'weeks');
	}
	return slots;
};

export const getDatesFromRecurrence = ({ start, end, onDays, repeats }) => {
	let dates = [];
	let currentDate = moment(start);

	for (let i = 0; currentDate <= end; i++) {
		const currentWeekDay = moment(currentDate).isoWeekday();
		for (let j = 0; j < onDays?.length; j++) {
			if (currentWeekDay <= Number(onDays[j])) {
				dates.push(moment(currentDate).isoWeekday(onDays[j]));
			} else {
				dates.push(moment(currentDate).add(1, 'weeks').isoWeekday(onDays[j]));
			}
		}
		currentDate = moment(currentDate).add(repeats, 'weeks');
	}
	return dates;
};

export const toDateInput = (date) => moment(new Date(date)).format('YYYY-MM-DD');

export const inDateRange = (date, startDate, endDate) => startDate <= date && date <= endDate;

export const isEqual = (d1, d2) => {
	return +d1 == +d2;
};

export const utc2gmt = (dateFromPostgresFromParams) => {
	const dateFromPostgres = isNumber(dateFromPostgresFromParams)
		? Number(dateFromPostgresFromParams)
		: dateFromPostgresFromParams;
	return moment
		.utc(moment(isString(dateFromPostgres) ? new Date(dateFromPostgres) : dateFromPostgres).utc(true))
		.toISOString();
};

export const getEndWorkHourToday = () => {
	const today = new Date();
	today.setHours(23, 59, 59);
	return today;
};

export const padTo2Digits = (num) => {
	return num.toString().padStart(2, '0');
};

export const formatDate = (date) => moment(date).format('DD/MM/YYYY');

export const formatDateWithHour = (date) => {
	return (
		[padTo2Digits(date.getDate()), padTo2Digits(date.getMonth() + 1), date.getFullYear()].join('/') +
		' ' +
		[padTo2Digits(date.getHours()), padTo2Digits(date.getMinutes())].join(':')
	);
};

export const dayDateFormatFromAPI = (date) => moment.unix(timestampFromAPIToUnix(date)).format('dddd, MMMM Do YY');

export const sortDateArr = (dateArr = [new Date(), new Date()]) => dateArr.sort((a, b) => a - b);

export const getStartAndEndDate = (dateArr) => {
	const sortedArr = sortDateArr(dateArr);
	return {
		startDate: sortedArr[0],
		endDate: getLastElemOfArr(sortedArr),
	};
};

export const getTodayMidnightMoment = () => dateToAnotherTime(moment(), 0, 0);

export const getTodayMidnight = () => {
	const d = new Date();
	d.setHours(0, 0, 0, 0);
	return d;
};

export const getFirstDayOfMonth = (date) => new Date(date.getFullYear(), date.getMonth(), 1);

export const getLastDayOfMonth = (date) => new Date(date.getFullYear(), date.getMonth() + 1, 0);

export const getExtremesDatesOfMonth = (dateFromParams = null) => {
	const date = dateFromParams || new Date();
	const firstDay = getFirstDayOfMonth(date);
	const lastDay = getLastDayOfMonth(date);
	return { startDate: firstDay, endDate: lastDay };
};

export const compareDiffTZDates = (d1, d2) =>
	formatDate(new Date(convertTimeZoneFromDate(d1))) === formatDate(new Date(convertTimeZoneFromDate(d2)));

export const getMostRecentDate = (dates = []) =>
	dates.map((d) => (isDate(d) ? d : new Date(d))).sort((a, b) => b.getTime() - a.getTime())[0];

export const getOlderDate = (dates = []) =>
	dates.map((d) => (isDate(d) ? d : new Date(d))).sort((a, b) => a.getTime() - b.getTime())[0];

export const dateToAnotherTime = (date, hours = 0, minutes = 0) => {
	const dateInUTC = isNumber(date) ? moment.unix(date).tz(parisTimezone) : moment.tz(date, parisTimezone);
	// Ajustement de l'heure à UTC
	dateInUTC.set({ hour: hours, minute: minutes, second: 0, millisecond: 0 });
	// Conversion en heure de Paris
	return dateInUTC.clone().tz('Europe/Paris');
};

export const dateToStartWorkDay = (date) => dateToAnotherTime(date, startDayTime);
export const dateToEndWorkDay = (date) => dateToAnotherTime(date, endDayTime);

export const slotToDay = (daySlots) => {
	const [firstDate, ...dateSorted] = daySlots.sort((a, b) => a - b);
	const lastDate = dateSorted[dateSorted.length - 1];
	const startWorkDay = dateToStartWorkDay(daySlots[0]);
	const endWorkDay = dateToEndWorkDay(daySlots[0]);

	if (firstDate <= startWorkDay) {
		if (lastDate >= endWorkDay) {
			return 'day';
		}
		return 'morning';
	}
	if (lastDate >= endWorkDay) {
		return 'afternoon';
	}
	return 'custom';
};

export const getHoursCountFromSlots = (slots) => slots.length / 4;

export const getDaysCountFormSlots = (slots) =>
	slots.reduce((prev, slot) => {
		const day = dateToStartWorkDay(slot).format('YYYY-MM-DDTHH:mm:ss.SSS');
		if (!prev.includes(day)) {
			prev.push(day);
		}
		return prev;
	}, []).length;

export const getFavoriteDayStatus = (dayStatus, currentDay, favoriteDayStatus) => {
	const [start, end] = !isEmpty(favoriteDayStatus) ? favoriteDayStatus : [`0${startDayTime}:00`, `${endDayTime}:00`];

	const startDate = dateToAnotherTime(currentDay, Number(start.split(':')[0]), Number(start.split(':')[1])).unix();

	const endDate = dateToAnotherTime(currentDay, Number(end.split(':')[0]), Number(end.split(':')[1])).unix();

	const pausedDate = moment(currentDay)
		.set({
			hour: pausedDayTime,
			minute: 0,
		})
		.unix();

	switch (dayStatus) {
		case 'morning':
			return { start: startDate, end: pausedDate };
		case 'afternoon':
			return { start: pausedDate, end: endDate };
		default:
			return { start: startDate, end: endDate };
	}
};

export const slotsToDays = (dates, defaultDayStatus = null, favoriteDayStatus = null) =>
	Object.entries(
		dates.reduce((prev, date) => {
			const dateFormatted = formatDate(date);
			return {
				...prev,
				[dateFormatted]: prev[dateFormatted] ? [...prev[dateFormatted], date] : [date],
			};
		}, {}),
	).reduce((prev, [date, dates]) => {
		const dayStatus = defaultDayStatus || slotToDay(dates);
		return {
			...prev,
			[date]: {
				dates: dates.sort((a, b) => a - b),
				...(dayStatus ? { dayStatus } : {}),
				...(favoriteDayStatus
					? {
							favoriteDayStatus: getFavoriteDayStatus(dayStatus, dates[0], favoriteDayStatus),
					  }
					: {}),
			},
		};
	}, {});

export const removeExtraSlotByFavoriteDayStatus = (dates, favoriteDayStatus) =>
	dates.reduce((acc, _date) => {
		const date = date2moment(_date);
		const { start, end } = favoriteDayStatus;

		if (date.isSameOrAfter(unix2moment(start)) && date.isBefore(unix2moment(end))) acc = [...acc, date];
		return acc;
	}, []);

//jours de missions qui ne compte que des jours ni appointment, ni proposals
export const pureMissionDaysToEvents = (missionOpenDays, dayStatusesLang) => {
	if (isEmpty(missionOpenDays)) return [];
	const status = dayStatuses.OPEN;
	return Object.entries(missionOpenDays)
		.map(([missionId, { title, dates: datesObj }]) =>
			Object.values(datesObj).map((dates) => {
				const start = moment(dates[0]);
				const end = moment(dates[dates.length - 1]).add(15, 'minutes');

				return {
					id: missionId,
					resourceId: missionId,
					eventType: EventTypes.OPEN,
					status,
					title: `${title} - ${dayStatusesLang[status]} - ${start.format('HH:mm')} - ${end.format('HH:mm')}`,
					start: start.toDate(),
					end: end.toDate(),
				};
			}),
		)
		.flat();
};

export const proposalsToEvents = (proposals, dayStatusesLang, isFreelance = false) => {
	if (isEmpty(proposals) || isEmpty(proposals.freelances)) return [];
	const { title, missionId, freelances, studioName } = proposals;

	return Object.values(freelances).flatMap(({ days, displayName, ...rest }) => {
		const status = dayStatuses.WAITING_PROPOSAL;

		return Object.values(days).map(({ dates }) => {
			const start = moment(dates[0]);
			const end = moment(dates[dates.length - 1]).add(15, 'minutes');

			return {
				id: missionId,
				resourceId: missionId,
				eventType: EventTypes.PROPOSAL,
				status,
				userType: isFreelance ? UserTypes.FREELANCE : UserTypes.STUDIO,
				freelance: rest,
				title: `${isFreelance ? studioName : displayName} - ${title} - ${
					dayStatusesLang[status]
				} - ${start.format('HH:mm')} - ${end.format('HH:mm')}`,
				start: start.toDate(),
				end: end.toDate(),
			};
		});
	});
};

export const proposalsByMissionsToEvents = (proposals, dayStatusesLang, isFreelance = false) => {
	if (isEmpty(proposals)) return [];

	return Object.entries(proposals).flatMap(([missionId, proposal]) => {
		return proposalsToEvents({ ...proposal, missionId }, dayStatusesLang, isFreelance);
	});
};

export const dateInRangeToSlots = (_start, _end) => {
	let start = isMoment(_start) ? _start : moment(_start);
	const end = isMoment(_end) ? _end : moment(_end);
	const slots = [];

	while (start.isSameOrBefore(end)) {
		slots.push(start.unix());
		start = start.add(15, 'minutes');
	}

	return slots;
};

export const appointmentToEvents = (
	appointment,
	dayStatusesLang,
	lang,
	userType = UserTypes.STUDIO,
	isCalendarPrincipal = false,
	isContract = false,
) => {
	if (isEmpty(appointment)) return [];
	const { mission, status, title, days, id, appointment: appointmentId, contract, price, ...rest } = appointment;

	return Object.values(days).flatMap(({ firstname, lastname, dates }) => {
		const start = moment(dates[0]);
		const end = moment(dates[dates.length - 1]).add(15, 'minutes');

		const freelanceDisplayName = `${upperFirst(firstname || rest.firstname)} ${upperFirst(
			lastname || rest.lastname,
		)}`;
		const titleEvent = [
			(isCalendarPrincipal
				? `${title}${userType === UserTypes.STUDIO ? ` ${lang.with} ` : !mission && !contract ? 'salut' : ''}`
				: '') + (userType === UserTypes.STUDIO ? freelanceDisplayName : ''),
			getLangStatus(dayStatusesLang, status),
		]
			.filter((e) => !!e)
			.join(' - ');

		return {
			id: mission,
			status,
			appointmentId: isContract ? appointmentId : id,
			contractId: contract,
			eventType: EventTypes.APPOINTMENT,
			type: userType,
			displayName: freelanceDisplayName,
			title: (contract ? '📄 ' : '') + titleEvent + ` - ${start.format('HH:mm')} - ${end.format('HH:mm')}`,
			start: start.toDate(),
			end: end.toDate(),
			price,
		};
	});
};

export const appointmentBtocToEvents = (appointment, dayStatusesLang, lang, isCalendarPrincipal = false) => {
	if (isEmpty(appointment)) return [];

	const {
		status,
		days,
		id,
		customer_firstname: firstname,
		customer_lastname: lastname,
		customer_address: address,
	} = appointment;

	return Object.values(days).flatMap(({ dates }) => {
		const start = moment(dates[0]);
		const end = moment(dates[dates.length - 1]).add(15, 'minutes');
		const customerDisplayName = `${upperFirst(firstname)} ${upperFirst(lastname)}`;
		const titleEvent = [address].filter((e) => !!e).join(' - ') + ' - ' + getLangStatus(dayStatusesLang, status);

		return {
			id: id,
			status: status,
			eventType: EventTypes.APPOINTMENT_BTOC,
			type: UserTypes.CUSTOMER,
			displayName: customerDisplayName,
			title: titleEvent + ` - ${start.format('HH:mm')} - ${end.format('HH:mm')}`,
			start: start.toDate(),
			end: end.toDate(),
			price: 0,
		};
	});
};

export const appointmentsToEvents = (appointments, dayStatusesLang, lang, userType, isCalendarPrincipal = false) => {
	if (isEmpty(appointments)) return [];

	return appointments.reduce((events, appointment) => {
		if (!appointment.mission && !appointment.contract && appointment.customer)
			return events.concat(appointmentBtocToEvents(appointment, dayStatusesLang, lang, isCalendarPrincipal));
		return events.concat(appointmentToEvents(appointment, dayStatusesLang, lang, userType, isCalendarPrincipal));
	}, []);
};

import classNames from 'classnames';
import DatesFromMissionDays from 'components/DatesFromMissionDays';
import StarRating from 'components/StarRating';
import Typography from 'components/Typography';
import { getStartAndEndDate } from 'modules/dates';
import React, { useMemo, useState } from 'react';
import { getRatingFromReviews } from '../helpers';
import MissionReviewsExtended from './MissionReviewsExtended';
import classes from './StudioPage.module.scss'
import moment from 'moment';

const MissionReview = ({
    title,
    description,
    reviews,
    days,
}) => {
	const dates = useMemo(() => Object.keys(days), [days])
    const [active, setActive] = useState(false)
    const { startDate, endDate } = useMemo(() => getStartAndEndDate(dates.map(date => moment(date, 'DD/MM/YYYY').toDate())), [dates])
    const rating = useMemo(() => getRatingFromReviews(reviews), [reviews])
    return (
        <>
            <div
                onClick={() => setActive(active => !active)}
                className={classNames(classes.cardShadow, classes.reviewCard, active && classes.active)}>
                <Typography primaryColor variant='subTitle2' classes={{ root: 'noMargin' }}>{title}</Typography>
                <Typography italic>
                    <DatesFromMissionDays
                        noTypo
                        daysCount={dates.length}
                        startDate={startDate}
                        endDate={endDate} />
                </Typography>
                <StarRating rating={rating} />
            </div>
            {active && <MissionReviewsExtended reviews={reviews} />}
        </>
    );
};

export default MissionReview;
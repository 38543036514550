import { useDayStatuses } from 'hooks/useDayStatuses';
import React from 'react';
import StatusMissionComponent from './StatusMissionComponent'

const StatusMission = ({
    status,
    mission = {}
}) => {
    const { eventType, langStatus } = useDayStatuses(status, mission)
    return (
        !!eventType && <StatusMissionComponent
            eventType={eventType}
            langStatus={langStatus}
        />
    );
};

export default StatusMission;
import useLanguage from 'hooks/useLanguage';
import Loader from 'components/Loader';
import useRemoteData from 'hooks/useRemoteData';
import { useParams } from 'react-router-dom';
import IssueElem from 'components/IssueElem';
import Typography from 'components/Typography';
import RightSide from 'components/RightSide/RightSide';
import TitlePage from 'components/TitlePage';
import IssueStatusComponent from 'components/IssueStatusComponent';

function IssuePage() {
	const lang = useLanguage('issue.issue');
	const { id } = useParams();

	const { data, error, loading, setData } = useRemoteData(`/issue/${id}`);
	const updateIssue = (updatedFields) => {
		setData((data) => ({
			...data,
			...updatedFields,
		}));
	};

	return (
		<RightSide>
			<TitlePage subtitle={data && <IssueStatusComponent status={data.status} />}>
				{lang.title} {(data || {}).title}
			</TitlePage>
			{loading ? (
				<Loader />
			) : error ? (
				<Typography>{lang.error}</Typography>
			) : (
				<IssueElem
					title={data.title}
					description={data.description}
					status={data.status}
					id={Number(id)}
					reference={data.reference}
					updateIssue={updateIssue}
				/>
			)}
		</RightSide>
	);
}

export default IssuePage;

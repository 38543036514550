import RightSide from 'components/RightSide/RightSide'
import TitlePage from 'components/TitlePage'
import useLanguage from 'hooks/useLanguage'
import { useContext } from 'react'
import { Store } from 'store/store'
import Issues from '../Issues'

function IssuesByUser() {
	const { id } = useContext(Store).state.user
	const lang = useLanguage('issue.myIssues')

	return (
		<RightSide>
			<TitlePage>{lang.title}</TitlePage>
			<Issues src={`/issues/user/${id}`} />
		</RightSide>
	)
}

export default IssuesByUser

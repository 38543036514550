import { useEffect, useState } from 'react'
import debounce from 'lodash/debounce'

function getSize() {
    if (typeof window !== 'undefined') {
        return {
            width: document.body.scrollWidth,
            height: document.body.scrollHeight - window.innerHeight,
        }
    } else {
        return {
            width: 0,
            height: 0,
        }
    }
}

export default function useWindowSize(debounceDelay = 500) {
    const [windowSize, setWindowSize] = useState(getSize)

    useEffect(() => {
        function handleResize() {
            setWindowSize(getSize())
        }

        const debounced = debounce(handleResize, debounceDelay)
        window.addEventListener(`resize`, debounced)

        return () => {
            //    debounce.cancel()
            window.removeEventListener(`resize`, debounced)
        }
    }, [debounceDelay])

    return windowSize
}

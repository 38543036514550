import classNames from 'classnames';
import Image from 'components/Image';
import Typography from 'components/Typography';
import { getImageUrl } from 'containers/helpers';
import useLanguage from 'hooks/useLanguage';
import { shuffle, upperFirst } from 'lodash';
import React, { useMemo } from 'react';
import classes from './StudioVisited.module.scss'

const StudiosVisited = ({
    appointments = [{
        studio_image: 1,
        studio_name: 1,
    }]
}) => {
    const lang = useLanguage('freelanceSelected')
    const rows = useMemo(() => shuffle(
        appointments.filter(
            (appointment, index) => {
                if (index === 0) {
                    return true
                }
                return !!!appointments
                    .slice(0, index)
                    .find(e => e.studio_name === appointment.studio_name)
            })
    ).slice(0, 3), [appointments])
    return (
        <div>
            <Typography primaryColor variant='subTitle2' classes={{root: classes.taCenter}}>{lang.visitedStudios}</Typography>
            {rows.map(({
                studio_image,
                studio_name,
            }, key) => <div key={key} className={classNames(classes.flex, classes.item)}>
                    <Image rounded className={classes.mr1} size='small' src={getImageUrl(studio_image)} />
                    <Typography classes={{root:classes.studioName}} variant='subTitle3' primaryColor>{upperFirst(studio_name)}</Typography>
                </div>)}
        </div>
    );
};

export default StudiosVisited;
export const FR = {
	from: 'Du',
	to: 'au',
}

export const EN = {
	from: 'From',
	to: 'to',
}

export default {
	fr: FR,
	us: EN,
}
  
import React from 'react';
import { Input } from 'semantic-ui-react';
import useLanguage from 'hooks/useLanguage'


const SearchTable = ({
    updateFilters = () => { }
}) => {
    const lang = useLanguage('form')

    const handleSearchChange = (evt) => {
        const { value } = evt.target
        updateFilters(value)
    }

    return (
        <Input
            icon='search'
            placeholder={lang.search}
            onChange={handleSearchChange} />
    );
};

export default SearchTable;
import { UserTypes } from "constants/UserTypes"
const generics = {
    calendar: {
        FR: (isMobile, firstParameter, secondParameter) =>
            `Pour selectionner ${firstParameter}, ${isMobile ? 'restez appuyé' : 'cliquez (ou appuyez si vous êtes sur tablette)'} 2 secondes sur une date du calendrier et scroller vers ${secondParameter}.
        <br/>Vous pouvez ajouter une date séparément en ${isMobile ? " appuyant" : 'cliquant'} dessus.
        <br/>Pour supprimer une date, ${isMobile ? "appuyez" : 'cliquez'} dessus.`,
        EN: (isMobile, firstParameter, secondParameter) =>
            `To select  ${firstParameter}, press and hold a calendar date for 2 seconds and scroll to ${secondParameter}.
        <br/>You can add a date separately by pressing on it.
        <br/>To remove a date, ${isMobile ? "press" : 'click'} on it.`,
    },
    sign_contract: {
        FR: (isMobile) => `Notre outil de signature éléctronique vous permet de consulter et signer votre contrat de mission de manière sécurisée.
<br/>Consultez le contrat.
<br/>Entrez ensuite vos initiales.
<br/>Ajouter la notion lu et approuvé en ${isMobile ? "appuyant" : "cliquant"} sur le bouton "J'ai lu et j'approuve le présent contrat".
<br/>Dessinez votre signature ou ${isMobile ? "appuyez" : "cliquez"} sur le bouton "Écrire en cursive".
<br/>Une fois la signature créée, ${isMobile ? "appuyez" : "cliquez"} sur "Apposer ma signature" pour signer votre contrat.
<br/>Vous pouvez à présent signer votre contrat en ${isMobile ? "appuyant" : "cliquant"} sur le bouton "Signer le contrat".
`,
        EN: (isMobile) => `Our electronic signature tool allows you to view and sign your assignment contract securely.
<br/>Consult the contract.
<br/>Then enter your initials.
<br/>Add the concept read and approved in ${isMobile ? "clicking" : "pressing"} on the button "I have read and I approve of this contract".
<br/>Draw your signature or ${isMobile ? "click" : "press"} the "Write in cursive" button.
<br/>Once the signature is created, ${isMobile ? "click" : "press"} on "Assign my signature" to sign your contract.
<br/>You can now sign your contract in ${isMobile ? "clicking" : "pressing"} on the "Sign the contract" button.`
    },
    pay_contract: {
        FR: (isMobile) => `Notre outil de prépaiement sécurisé permet de réserver la mission
<br/>Consultez le contrat.
<br/>Entrez ensuite vos initiales.
<br/>Ajouter la notion lu et approuvé en ${isMobile ? "appuyant" : "cliquant"} sur le bouton "J'ai lu et j'approuve le présent contrat".
<br/>Dessinez votre signature ou ${isMobile ? "appuyez" : "cliquez"} sur le bouton "Écrire en cursive".
<br/>Une fois la signature créée, ${isMobile ? "appuyez" : "cliquez"} sur "Apposer ma signature" pour signer votre contrat.
<br/>Vous pouvez à présent signer votre contrat en ${isMobile ? "appuyant" : "cliquant"} sur le bouton "Signer le contrat".
`,
        EN: (isMobile) => `Our electronic signature tool allows you to view and sign your assignment contract securely.
<br/>Consult the contract.
<br/>Then enter your initials.
<br/>Add the concept read and approved in ${isMobile ? "clicking" : "pressing"} on the button "I have read and I approve of this contract".
<br/>Draw your signature or ${isMobile ? "click" : "press"} the "Write in cursive" button.
<br/>Once the signature is created, ${isMobile ? "click" : "press"} on "Assign my signature" to sign your contract.
<br/>You can now sign your contract in ${isMobile ? "clicking" : "pressing"} on the "Sign the contract" button.`
    }
}

export const FR = {
    header: 'Didactiel',
    understood: 'J\'ai compris',
    remindMeLater: 'Me le rappeler plus tard',
    [UserTypes.FREELANCE]: {
        calendar_mobile: {
            header: 'Ajoutez vos disponibilités',
            description: generics.calendar.FR(true, 'vos disponibilités', 'la date finale de dispo'),
        },
        calendar: {
            header: 'Ajoutez vos disponibilités',
            description: generics.calendar.FR(false, 'vos disponibilités', 'la date finale de dispo'),
        },
        sign_contract: {
            header: 'Signez votre contrat à distance de manière sécurisée',
            description: generics.sign_contract.FR(false),
        },
        sign_contract_mobile: {
            header: 'Signez votre contrat à distance de manière sécurisée',
            description: generics.sign_contract.FR(true),
        }
    },
    [UserTypes.STUDIO]: {
        create_mission_calendar_mobile: {
            header: 'Ajoutez les dates de la mission',
            description: generics.calendar.FR(true, 'les dates', 'la date finale de la mission'),
        },
        create_mission_calendar: {
            header: 'Ajoutez les dates de la mission',
            description: generics.calendar.FR(false, 'les dates', 'la date finale de la mission'),
        },
        sign_contract: {
            header: 'Signez votre contrat à distance de manière sécurisée',
            description: generics.sign_contract.FR(false),
        },
        sign_contract_mobile: {
            header: 'Signez votre contrat à distance de manière sécurisée',
            description: generics.sign_contract.FR(true),
        },
        pay_contract: {
            header: 'Pré-payez votre mission pour la démarrer',
            description: `Notre module de pré-paiement sécurisé vous permet de réserver la mission.
            <br/>Les fonds sont bloqués chez notre partenaire bancaire.
            <br/>Ils ne seront libérés qu'à la fin de la mission, avec action humaine, si tout s'est bien passé.
            <br/>Si une journée ou plus n'a pas été assurée par le freelance, vos fonds vous serons bien évidemment remboursés.
            <br/>Le freelance est payé qu'une fois vérification de la bonne tenue de la mission, vérification effectuée par nos équipes.`,
        },
        bank_transfer: {
            header: `Privilégiez le virement instantané`,
            description: `Pour une validation immédiate de votre mission, privilégiez le virement instantané.`,
        },
    }

}

export const EN = {
    header: 'Didactiel',
    understood: 'Understood',
    remindMeLater: 'Remind me later',
    [UserTypes.FREELANCE]: {
        calendar_mobile: {
            header: 'Add your availabilities',
            description: generics.calendar.EN(true, 'your availabilities', 'the final date of availability'),
        },
        calendar: {
            header: 'Add your availabilities',
            description: generics.calendar.EN(false, 'your availabilities', 'the final date of availability'),
        },
        sign_contract: {
            header: 'Sign your contract securely remotely',
            description: generics.sign_contract.EN(false),
        },
        sign_contract_mobile: {
            header: 'Sign your contract securely remotely',
            description: generics.sign_contract.EN(true),
        }
    },
    [UserTypes.STUDIO]: {
        create_mission_calendar_mobile: {
            header: 'Add mission dates',
            description: generics.calendar.EN(true, 'your mission dates', 'the final date of mission'),
        },
        create_mission_calendar: {
            header: 'Add mission dates',
            description: generics.calendar.EN(true, 'your mission dates', 'the final date of mission'),
        },
        sign_contract: {
            header: 'Sign your contract securely remotely',
            description: generics.sign_contract.EN(false),
        },
        sign_contract_mobile: {
            header: 'Sign your contract securely remotely',
            description: generics.sign_contract.EN(true),
        },
        pay_contract: {
            header: 'Pre-pay your mission to start it',
            description: `Our secure pre-payment module allows you to book the mission.
        <br/>The funds are blocked with our banking partner.
        <br/>They will only be released at the end of the mission, with human action, if all went well.
        <br/>If a day or more has not been provided by the freelance, your funds will of course be refunded to you.
        <br/>The freelance is paid only once verification of the good performance of the mission, verification carried out by our teams.`,
        },
        bank_transfer: {
            header: `Prefer instant transfer`,
            description: `For immediate validation of your bank transfer, send him as instant transfer.`,
        },
    }
}

export default {
    fr: FR,
    us: EN,
}

import RightSide from 'components/RightSide/RightSide'
import { UserTypes } from 'constants/UserTypes'
import { useContext } from 'react'
import { useParams } from 'react-router-dom'
import FreelanceMissionPageComponent from './FreelanceMissionPageComponent'
import StudioMissionPageComponent from './StudioMissionPageComponent'
import { Store } from 'store/store'
import { useMission } from 'hooks/useMission'
import MissionTitle from '../components/MissionTitle'
import Typography from 'components/Typography'
import MissionActions from '../components/MissionActions'
import classes from './MissionPage.module.scss'
import classNames from 'classnames'

const MissionPage = () => {
	const missionId = Number(useParams().missionId)
    const { mission, ...props } = useMission(missionId)
	const { state: { user: me } } = useContext(Store)

	return (
		<RightSide>
			<MissionTitle
				mission={mission}
				{...mission}
				{...props}
			/>
			<div className={classNames(classes.flex, classes.flexWrap, classes.mb3)}>
				<Typography primaryColor classes={{ root: classes.descriptionContainer }}>
					{mission.description}
				</Typography>
				<MissionActions mission={mission} {...props} />
			</div>
			{me.type === UserTypes.STUDIO ? (
				<StudioMissionPageComponent
					mission={mission}
					{...mission}
					{...props}
				/>
			) : (
				<FreelanceMissionPageComponent
					mission={mission}
					{...mission}
					{...props}
				/>
			)}
		</RightSide>
	)
}

export default MissionPage

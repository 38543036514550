import { useState, useContext, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import ModalComponent from 'components/Modal';
import UnLogGrid from 'components/UnLogGrid/UnLogGrid';
import { Store } from 'store/store';
import { confirmAccount, sendConfirmationMail } from './helper';

const ConfirmAccount = () => {
	const navigate = useNavigate();
	const { token } = useParams();
	const [modal, setModal] = useState(false);
	const [confirmationText, setConfirmationText] = useState('');
	const {
		state: {
			language: {
				confirmAccount: {
					mail: mailLang,
					submit: submitLang,
					accountValidated: accountValidatedLang,
					buttonToLogin: buttonToLoginLang,
					closeModal: closeModalLang,
					modalTitle: modalTitleLang,
					mailConfirmationText: mailConfirmationTextLang,
					errorConfirm: errorConfirmLang,
					error: errorLang,
				},
			},
		},
	} = useContext(Store);
	const inputs = useMemo(
		() => [
			{
				type: 'text',
				name: 'email',
				placeholder: mailLang,
				required: true,
				silentRequired: true,
				fluid: true,
			},
		],
		[mailLang],
	);

	useEffect(() => {
		const handleAccountConfirmation = async () => {
			try {
				await confirmAccount(token);
				setConfirmationText(accountValidatedLang);
				setModal(true);
				return true;
			} catch (e) {
				setConfirmationText(errorLang + ': ' + e.message);
				setModal(true);
				return false;
			}
		};
		if (token) {
			handleAccountConfirmation().then((res) => {
				if (res) {
					setTimeout(() => {
						navigate('/login');
					}, 5000);
				}
			});
		}
	}, [token, accountValidatedLang, errorLang, navigate]);

	const sendLinkConfirm = async (values) => {
		try {
			await sendConfirmationMail(values);
			setConfirmationText(mailConfirmationTextLang);
			setModal(true);
			setTimeout(() => {
				navigate('/login');
			}, 3000);
			return;
		} catch (e) {
			setConfirmationText(e.message);
			setModal(true);
		}
	};

	const closeModal = () => {
		if (confirmationText === accountValidatedLang) {
			return navigate('/login');
		}
		setModal(false);
	};
	return (
		<>
			<UnLogGrid page="confirmAccount" inputs={inputs} submitMessage={submitLang} sendForm={sendLinkConfirm} />
			<ModalComponent
				onClose={closeModal}
				open={modal}
				title={modalTitleLang}
				description={confirmationText}
				onClick={closeModal}
				button={confirmationText === accountValidatedLang ? buttonToLoginLang : closeModalLang}
			/>
		</>
	);
};

export default ConfirmAccount;

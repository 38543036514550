import { useState, useContext, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import ModalComponent from 'components/Modal';
import UnLogGrid from 'components/UnLogGrid/UnLogGrid';
import { Store } from 'store/store';
import { saveTokens } from '../../helpers';
import { sendNewPassword } from './helpers';

const ResetPassword = () => {
    const { token } = useParams()
    const [email, setEmail] = useState('');
    const { dispatch, state } = useContext(Store);
    const navigate = useNavigate();
    
	// eslint-disable-next-line no-unused-vars
    const [modal, setModal] = useState(false);
    const [error, setError] = useState('');
    const { language: { login: copy }} = state;

    const inputs = useMemo(() => [
        {
            type: 'text',
            name: 'email',
            label: copy.mail,
            value: email,
            required: true,
            silentRequired: true,
            fluid: true,
        },
        {
            type: 'password',
            name: 'password',
            label: copy.newPassword,
            required: true,
            silentRequired: true,
            fluid: true,
            legend: 'minimum 5 chars',
            signIn: true,
        },
    ], [copy, email]);

    const sendPassword = async (values) => {
        try {
            const jwt = await sendNewPassword(token, values.email, values.password);
            saveTokens(jwt, dispatch);
            navigate('/home');
            return
        } catch (e) {
            setEmail(email);
            setError(e.message);
            setModal(true);
        }
    };

    return (
        <>
            <UnLogGrid
                inputs={inputs}
                sendForm={sendPassword}
                page="reset"
                message={copy.newPasswordTitle}
            />
            <ModalComponent
                onClose={() => navigate('/login')}
                open={modal}
                title={copy.confirmErrorTitle}
                description={error}
                onClick={() => navigate('/login')}
                button={copy.confirmErrorButton}
            />
        </>
    );
};

export default ResetPassword;

import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useFreelanceUtils } from 'hooks/useFreelanceUtils';
import FreelanceProfileCard from 'components/FreelanceProfileCard';
import classes from './FreelanceFound.module.scss';

const FreelanceFound = ({ freelance = {}, mission = {} }) => {
	const { priceHour, badges, imageUrl, type, typeLang } = useFreelanceUtils(freelance);

	const freelanceSelectionLink = useMemo(
		() => `/mission/marketplace/${mission.id}/freelance/${freelance.id}`,
		[mission.id, freelance.id],
	);

	const hoursCountForOpenDays = useMemo(() => {
		const hours = Object.values(mission.openDays).reduce((prev, { slots, dayStatus }) => {
			let currentHours = slots.length / 4;
			if (dayStatus === 'day') currentHours -= 1;

			return prev + currentHours;
		}, 0);

		return hours;
	}, [mission.openDays]);

	return (
		<Link to={freelanceSelectionLink} className={classes.link}>
			<FreelanceProfileCard
				img={imageUrl}
				availableDays={Object.keys(freelance.days)}
				hourRate={priceHour}
				totalRate={priceHour * hoursCountForOpenDays}
				firstName={freelance.displayName}
				isAvailableForWholeMission={freelance.matchWithAllDates}
				badges={badges}
				rating={freelance.rating}
				nRatings={freelance.nRatings}
				created_at={freelance.created_at}
				type={type}
				typeLang={typeLang}
			/>
		</Link>
	);
};

export default FreelanceFound;

import Button from 'components/Button';
import useLanguage from 'hooks/useLanguage';
import React, { useCallback, useState } from 'react';
import toast from 'components/toast';
import { askToUnlockFundsAPI } from '../helpers';

const UnlockFundsButton = ({
	contractId
}) => {
	const lang = useLanguage('mission')
	const [loading, setLoading] = useState(false)
	const askToUnlockFunds = useCallback(async () => {
		try {
			setLoading(true)
			await askToUnlockFundsAPI(contractId)
		} catch (error) {
			toast(error.message)
		} finally {
			setLoading(false)
		}
	}, [contractId, setLoading])

	return (
		<Button loading={loading} onClick={askToUnlockFunds}>
			{lang.askToUnlockFunds}
		</Button>
	);
}

export default UnlockFundsButton;
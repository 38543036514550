import { UserTypes } from 'constants/UserTypes';
import { onboardingStatuses } from 'containers/onboard/constants/OnboardingStatuses';

const FR = {
	passThisStep: 'Passer cette étape',
	stepIndex: 'Étape $1 sur $2',
	goBack: 'Retourner à cette étape',
	companyName: `Nom de votre société`,
	address: 'Adresse',
	radius: 'Je cherche une mission dans un perimètre de ',
	creationDate: 'Date de création de votre société',
	phone: 'Numéro de téléphone',
	diploma: 'Diplôme(s)',
	professions: 'Profession(s)',
	professionsInfo:
		"Vous pouvez ajouter plus d'une profession, ajoutez ici tous les métiers que vous souhaitez exercer en freelance sur Styleep.",
	noDiploma: 'Sans diplôme',
	diplomaA: 'BP Coiffure',
	diplomaB: 'CAP Coiffure',
	knowBy: 'Comment avez-vous connu Styleep ?',
	degrees: [
		'BP Coiffure',
		'CAP Coiffure',
		'BM Coiffure',
		'BTS Métiers de la coiffure',
		'Certification professionnelle de barbier',
		'CAP Esthétique, option massages bien-être',
		"BTS Métiers de l'esthétique-cosmétique-parfumerie",
		'Certification professionnelle en massage bien-être',
		"Diplôme d'État de masseur-kinésithérapeute",
		'CAP Esthétique Cosmétique Parfumerie',
		'BP Esthétique Cosmétique Parfumerie',
		'Bac Pro Esthétique Cosmétique Parfumerie',
		'Certification de prothésiste ongulaire',
		'Formation professionnelle en onglerie',
		'BTSA Production Horticole, spécialité plantes à parfum',
		'BTS Métiers de la chimie',
		"Diplôme de l'École supérieure du parfum",
		'Master professionnel en création et management du parfum',
		'Certifications en maquillage professionnel',
		'Diplôme de maquilleur artistique',
	],
	pageHeader: `Veuillez compléter les étapes suivantes pour s'intégrer`,

	obStepTitles: {
		[onboardingStatuses.WAITING_STRIPE]: 'Créez votre compte Stripe',
		[onboardingStatuses.WAITING_EMAIL_VALIDATION]: 'Validez votre email',
		[onboardingStatuses.WAITING_INFORMATIONS]: 'Votre profil',
		[onboardingStatuses.WAITING_ADDRESS]: 'Indiquez votre adresse',
		[onboardingStatuses.WAITING_BANK_INFOS]: 'Informations bancaires',
		[onboardingStatuses.WAITING_FISCAL]: 'Vos données fiscales',
		[onboardingStatuses.WAITING_FISCAL_REVIEW]: {
			[UserTypes.STUDIO]: "Analyse de l'entreprise",
			[UserTypes.FREELANCE]: 'Vérification de votre profil',
		},
		[onboardingStatuses.WAITING_MEETING_PLAN]: 'Prenez rendez-vous avec un conseiller Styleep',
		[onboardingStatuses.WAITING_MEETING]: 'Rendez-vous planifié',
		[onboardingStatuses.WAITING_ONBOARD_VALIDATION]: "Dans l'attente du rendez-vous",
		[onboardingStatuses.ACTIVE]: 'Votre compte est actif',
	},
	obStepShortTitles: {
		[onboardingStatuses.WAITING_STRIPE]: 'Créez votre compte Stripe',
		[onboardingStatuses.WAITING_EMAIL_VALIDATION]: 'Validez votre email',
		[onboardingStatuses.WAITING_INFORMATIONS]: 'Votre profil',
		[onboardingStatuses.WAITING_ADDRESS]: 'Votre adresse',
		[onboardingStatuses.WAITING_BANK_INFOS]: 'Votre IBAN',
		[onboardingStatuses.WAITING_FISCAL]: 'Documents fiscaux',
		[onboardingStatuses.WAITING_FISCAL_REVIEW]: {
			[UserTypes.STUDIO]: "Analyse de l'entreprise",
			[UserTypes.FREELANCE]: 'Vérification du profil',
		},
		[onboardingStatuses.WAITING_MEETING_PLAN]: 'Prenez rendez-vous',
		[onboardingStatuses.WAITING_MEETING]: 'Rendez-vous planifié',
		[onboardingStatuses.WAITING_ONBOARD_VALIDATION]: "Dans l'attente du rendez-vous",
		[onboardingStatuses.ACTIVE]: 'Compte validé',
	},

	siret: 'Numéro SIRET',
	rcs_city: `Ville d’immatriculation aux Registre du commerce et des sociétés (RCS)`,
	hq_city: 'Ville de siège social',
	company_role: `Emploi dans l'entreprise`,
	tva_number: 'Numéro de TVA',
	kbisDoc: 'Votre document KBIS',
	legal_name: 'Dénomination Sociale',

	company_roles: {
		president: 'Président',
		ceo: 'PDG',
		director: 'Directeur',
	},
	company_type: "Type d'entreprise",
	company_types: ['EI', 'EURL', 'SARL', 'SASU', 'SAS', 'SA', 'SNC', 'SCS', 'SCA'],
	yourAreNotEISo:
		"Vous n'êtes pas une EI (entreprise individuelle). Vous avez donc un capital social. Renseignez-le svp.",
	fullHeadOffice: 'Votre siège social en entier incluant ville et code postal.',
	head_office: 'Votre siège social',
	reviewingDocsHelp: `Nous examinons vos informations.<br/>Vous devriez avoir de nos nouvelles dans les prochaines heures.`,
	emailSent: 'Email envoyé',
	emailValidationHelp: `Veuillez cliquer sur le lien contenu dans l'email de validation de votre compte que vous avez reçu.<br/><br/>
	Si vous avez accepté l'e-mail, veuillez actualiser la page.<br/><br/>
	Si vous n'avez pas reçu l'email, vérifiez vos spams.<br/><br/>
	Si vous avez vérifié vos spams et que l'email ne s'y trouve pas, cliquez sur le bouton ci-dessous pour renvoyer l'email.<br/><br/>`,
	link_emailValidationHelp: "Renvoyer moi l'email",
	stripeOnboardHelp: `Veuillez cliquer sur le bouton ci-dessous pour lier votre compte Stripe.<br/><br/>
	Stripe est notre partenaire de paiement. Il vous permettra de recevoir vos paiements.<br/><br/>
	Si vous avez déjà un compte Stripe, veuillez vous connecter avec celui-ci.<br/><br/>`,
	knowByChoicesInfo: 'Je peux renseigner un choix spécifique en le tappant dans la barre de selection.',
	knowByChoices: {
		[UserTypes.FREELANCE]: [
			'Par un ami',
			'Sur internet',
			'Sur un prospectus',
			'Dans mon école',
			'Sur Facebook',
			'Sur Instagram @Styleepgram',
			"Styleep m'a contacté par téléphone",
			"Styleep m'a contacté par Facebook",
			"Styleep m'a contacté par Instagram",
			"Styleep m'a contacté par Email",
			"Styleep m'a contacté par Indeed",
		],
		[UserTypes.STUDIO]: [
			'Par un ami',
			'Dans mon entreprise',
			'Sur internet',
			'Sur un prospectus',
			'Sur Facebook',
			'Sur Instagram @Styleepgram',
			"Styleep m'a contacté par téléphone",
			"Styleep m'a contacté par Facebook",
			"Styleep m'a contacté par Instagram",
			"Styleep m'a contacté par Email",
		],
	},
	infoFiscal:
		'Pour pouvoir accéder à notre plateforme, vous devez nous fournir votre document attestant la création de votre entreprise.',
	image: 'Votre photo de profil',
	studioImageInfo: 'Vous pouvez par exemple mettre une photo de votre devanture.',
	bankTitle:
		'Indiquez vos informations bancaires afin que nous puissions vous verser votre rémunération à la fin de chaque mission.',
	bankSubtitle:
		"Cette étape n'est pas obligatoire. Vous pourrez ajouter ces informations dans votre profil une fois votre profil actif.",
	iban: 'Votre IBAN',
	swift: 'Votre code swift',
	capitalContentCentimes: 'Si il contient des centimes, ajoutez ",".',
	capital: 'Capital social de votre entreprise',
	bankAccountOwner: 'Nom du titulaire du compte',
	accountValidated: 'Votre compte a été validé par notre équipe.',
	pleaseChooseMeeting:
		"Veuillez choisir un créneau afin qu'un conseiller vous accompagne dans la prise en main de la plateforme et évaluez votre niveau de départ.",
	hourlyRate: 'Taux horaire',
	statistics: {
		smaller: 'Taux horaire minimum',
		average: 'Taux horaire moyen',
		bigger: 'Taux horaire maximum',
	},
	currentDailyRate: 'Votre tarif journalier actuel',
	linkStripeAccount: 'Lier mon compte Stripe',
	stripeAccountLinked: 'Votre compte Stripe a été lié avec succès.',
};

const EN = {
	passThisStep: 'Pass this step',
	stepIndex: 'Step $1 on $2',
	goBack: 'Go back to this step',
	companyName: 'Name of your company',
	address: 'Address',
	radius: 'Maximum distance willing to travel',
	creationDate: 'Creation date of your company',
	phone: 'Phone number',
	diploma: 'Degree(s)',
	professions: 'Profession(s)',
	professionsInfo: 'You can have more than one profession, add all you can use as freelance.',
	noDiploma: 'No diploma',
	diplomaA: 'BP Hairstyle',
	diplomaB: 'CAP Hairstyle',
	knowBy: 'How did you hear about Styleep ?',
	degrees: [
		'Vocational Baccalaureate in Hairdressing',
		'Hairdressing Vocational Training Certificate',
		'Master Craftsman in Hairdressing',
		'Higher Technician Certificate in Hairdressing Professions',
		'Professional Barber Certification',
		'Vocational Training Certificate in Aesthetics, option in wellness massages',
		'Higher Technician Certificate in Aesthetic-Cosmetic-Perfumery Professions',
		'Professional Certification in Wellness Massage',
		'State Diploma in Physiotherapy',
		'Vocational Training Certificate in Aesthetic Cosmetics Perfumery',
		'Professional Baccalaureate in Aesthetic Cosmetics Perfumery',
		'High School Diploma in Aesthetic Cosmetics Perfumery',
		'Nail Technician Certification',
		'Professional Training in Nail Design',
		'Advanced Technician Certificate in Horticultural Production, speciality in perfume plants',
		'Higher Technician Certificate in Chemistry Professions',
		'Diploma from the Higher School of Perfume',
		'Professional Master’s Degree in Perfume Creation and Management',
		'Professional Makeup Certifications',
		'Diploma in Artistic Makeup',
	],

	pageHeader: 'Please complete the following to onboard',
	emailSent: 'Email sent',

	obStepTitles: {
		[onboardingStatuses.WAITING_EMAIL_VALIDATION]: 'Validate your email',
		[onboardingStatuses.WAITING_INFORMATIONS]: 'Complete Profile',
		[onboardingStatuses.WAITING_ADDRESS]: 'Complete your address',
		[onboardingStatuses.WAITING_BANK_INFOS]: 'Bank transfer informations',
		[onboardingStatuses.WAITING_FISCAL]: 'Your fiscal data',
		[onboardingStatuses.WAITING_FISCAL_REVIEW]: {
			[UserTypes.STUDIO]: 'Review studio',
			[UserTypes.FREELANCE]: 'Review fiscal documents',
		},
		[onboardingStatuses.WAITING_MEETING_PLAN]: 'Schedule meeting with Styleep advisor',
		[onboardingStatuses.WAITING_MEETING]: 'Meeting planned',
		[onboardingStatuses.WAITING_ONBOARD_VALIDATION]: 'Waiting meeting planned',
		[onboardingStatuses.ACTIVE]: 'Your account is active',
	},

	obStepShortTitles: {
		[onboardingStatuses.WAITING_EMAIL_VALIDATION]: 'Validate your email',
		[onboardingStatuses.WAITING_INFORMATIONS]: 'Complete Profile',
		[onboardingStatuses.WAITING_ADDRESS]: 'Complete address',
		[onboardingStatuses.WAITING_BANK_INFOS]: 'Bank informations',
		[onboardingStatuses.WAITING_FISCAL]: 'Fiscal data',
		[onboardingStatuses.WAITING_FISCAL_REVIEW]: {
			[UserTypes.STUDIO]: 'Review studio',
			[UserTypes.FREELANCE]: 'Review profile',
		},
		[onboardingStatuses.WAITING_MEETING_PLAN]: 'Schedule meeting',
		[onboardingStatuses.WAITING_MEETING]: 'Meeting planned',
		[onboardingStatuses.WAITING_ONBOARD_VALIDATION]: 'Waiting meeting planned',
		[onboardingStatuses.ACTIVE]: 'Account validated',
	},

	siret: 'SIRET number',
	rcs_city: `Registration city of RCS`,
	company_role: 'Role at company',
	hq_city: 'City of headquarters',
	tva_number: 'VAT Number',
	kbisDoc: 'Your KBIS document',
	legal_name: 'Legal name of company',

	company_roles: {
		president: 'President',
		ceo: 'CEO',
		director: 'Director',
	},
	company_type: 'Company type',
	company_types: ['EI', 'EURL', 'SARL', 'SASU', 'SAS', 'SA', 'SNC', 'SCS', 'SCA'],
	yourAreNotEISo: 'You are not an EI, so you have a capital social. Please reseign it.',
	fullHeadOffice: 'Your head office full of city and postal code.',
	head_office: 'Your head office',
	reviewingDocsHelp: `We're reviewing your information.<br/>You should hear from us within next hours`,
	emailValidationHelp: `Please click on link present in validation email received.<br/><br/>
	If you have clicked on, please reload the page.<br/><br/>
	If you haven't received the email, please check your spams.<br/><br/>
	If you have verified your spams and the email isn't here, click and the followed button to send it back.<br/><br/>`,
	link_emailValidationHelp: 'Send me the email again',
	knowByChoicesInfo: 'I can fill in a specific choice by typing it in the selection bar.',
	stripeOnboardHelp: `Please click on the button below to link your Stripe account.<br/><br/>
	Stripe is our payment partner. It will allow you to receive your payments.<br/><br/>
	If you already have a Stripe account, please login with it.<br/><br/>`,
	knowByChoices: {
		[UserTypes.FREELANCE]: [
			'By a friend',
			'On the web',
			'On brochure',
			'In my school',
			'On Facebook',
			'On Instagram @Styleepgram',
			'Styleep contacted me by téléphone',
			'Styleep contacted me by Facebook',
			'Styleep contacted me by Instagram',
			'Styleep contacted me by Email',
			'Styleep contacted me by Indeed',
		],
		[UserTypes.STUDIO]: [
			'By a friend',
			'On the web',
			'On brochure',
			'On Facebook',
			'On Instagram @Styleepgram',
			'In my studio',
			'Styleep contacted me by téléphone',
			'Styleep contacted me by Facebook',
			'Styleep contacted me by Instagram',
			'Styleep contacted me by Email',
		],
	},
	infoFiscal: 'To access to our platform, you have to provide your company creation proof document.',
	image: 'Your profile picture',
	studioImageInfo: 'You can, for example, put image of your studio.',
	bankTitle: 'Please provide your bank account informations to send your money at the end of each mission.',
	bankSubtitle:
		"This step isn't required, you will be able to renseign them in your profile page when your account will be active.",
	iban: 'Your IBAN',
	swift: 'Your SWIFT code',
	capital: 'Capital social of your company',
	capitalContentCentimes: 'If contains centimes, add ",".',
	bankAccountOwner: 'Name of bank account owner',
	accountValidated: 'Your account has been validate by our team.',
	pleaseChooseMeeting:
		'Please choose time meeting to evaluate your initial level and discover with an advisor the platform.',
	hourlyRate: 'Hourly rate',
	statistics: {
		smaller: 'Minimum hourly rate',
		average: 'Average hourly rate',
		bigger: 'Maximum hourly rate',
	},
	currentDailyRate: 'Your current daily rate',
	linkStripeAccount: 'Link my Stripe account',

	stripeAccountLinked: 'Your Stripe account has been linked with success.',
};

export default {
	fr: FR,
	us: EN,
};

export const dayStatuses = {
	OPEN: 0,
	DECLINED: 0,
	WAITING_PROPOSAL: 1,
	CONFIRMED: 2,
	WAITING_ALL_CONTRACTS: 3, // --> fixer ensemble dates
	WAITING_FREELANCE_CONTRACT: 4, // --> fixer ensemble dates
	WAITING_STUDIO_CONTRACT: 5, // --> fixer ensemble dates
	WAITING_FUNDS: 6,
	WAITING_TEAM_FUNDS_RECEIVED: 7,
	BOOKED: 8,
	WAITING_STUDIO_DAYS_CONFIRMATION: 9, // on attend que le studio confirme ou non les dates
	WAITING_FREELANCE_DAYS_CONFIRMATION: 10,
	WAITING_ADMIN_REVIEW_DAYS_CONFIRMATION: 11, //si il y a un conflit sur les dates confirmées
	CANCELLED: 12,
	WAITING_REVIEW: 13,
	WAITING_FREELANCE_REVIEW: 14,
	WAITING_STUDIO_REVIEW: 15,
	FINISHED: 16,
};

import classNames from 'classnames';
import Button from 'components/Button';
import StyledModal from 'components/StyledModal';
import Typography from 'components/Typography';
import useLanguage from 'hooks/useLanguage';
import { useContext, useMemo } from 'react';
import { Store } from 'store/store';
import classes from 'styles/classes.module.scss';
import { getStripeFee, getStyleepFee } from 'util/data/styleep.js';

const PrepaymentModal = ({ open, onClose, loading, price, onAction }) => {
	const {
		user: { tva_number },
	} = useContext(Store).state;
	const lang = useLanguage('prepayment');

	const priceWithTVA = useMemo(() => {
		return Boolean(tva_number) ? price * 1.2 : price;
	}, [tva_number, price]);

	const fee = useMemo(() => getStyleepFee(priceWithTVA) + getStripeFee(priceWithTVA), [priceWithTVA]);
	const feePourcentage = useMemo(() => ((fee / priceWithTVA) * 100).toFixed(2), [fee, priceWithTVA]);

	return (
		<StyledModal open={open} onClose={onClose} loading={loading} title={lang.titleModal}>
			<div>
				<Typography
					variant="subTitle3"
					classes={{
						root: classNames(classes.taCenter, classes.mb2),
					}}
				>
					{lang.usage}
				</Typography>

				<ul className={classNames(classes.flex, classes.columnDirection, classes.gap05)}>
					{Object.values(lang.steps).map((step, index) => (
						<li key={index}>
							<Typography variant="body">{step}</Typography>
						</li>
					))}
				</ul>

				<Typography variant="text" italic>
					{lang.commission(feePourcentage)}
				</Typography>

				<Typography variant="subTitle3" classes={{ root: classNames(classes.taCenter) }}>
					{priceWithTVA.toFixed(2)}€ {lang.commissionTextOne(feePourcentage)} ={' '}
					{(priceWithTVA - fee).toFixed(2)}€ {lang.sumReceived}
				</Typography>
			</div>

			<div className={classNames(classes.flex, classes.horizontalCenteredContainer, classes.gap05, classes.mt2)}>
				<Button loading={loading} type="cancel" onClick={onClose}>
					{lang.cancelModal}
				</Button>
				<Button loading={loading} onClick={onAction}>
					{lang.acceptModal}
				</Button>
			</div>
		</StyledModal>
	);
};

export default PrepaymentModal;

import FreelanceMissionProposals from 'components/FreelanceMissionProposals';
import { useCallback, useContext, useMemo, useState } from 'react';
import { UserTypes } from 'constants/UserTypes';
import { useParams } from 'react-router-dom';
import SingleMissionCalendar from 'containers/calendar/components/SingleMissionCalendar';
import { hasReviewsToShow } from 'containers/review/helpers';
import MissionReviews from 'containers/review/components/MissionReviews';
import IssuesByUserMission from 'containers/issue/components/IssuesByUserMission';
import MenuNavigationComponent from '../components/MenuNavigationComponent';
import ContractMissionList from '../components/ContractMissionList';
import useLanguage from 'hooks/useLanguage';
import { Store } from 'store/store';
import ConfirmRemoveDates from 'containers/scheduler/components/ConfirmRemoveDates';
import toast from 'components/toast';
import { deleteMissionProposalsByDatesApi, deleteSlotsForAppointmentsMissionIdApi } from '../helpers';
import { date2unix } from 'modules/dates';
import ConfirmRemoveDatesByFreelances from 'containers/scheduler/components/ConfirmRemoveDatesByFreelances/ConfirmRemoveDatesByFreelances';
import { isEmpty } from 'lodash';
import AppointmentTablePrices from '../components/AppointmentTablePrices';

const ModalTypes = {
	REMOVE_PROPOSAL: 0,
	REMOVE_APPOINTMENT: 1,
};

const StudioMissionPageComponent = ({ mission }) => {
	const {
		state: { user: me },
	} = useContext(Store);
	const lang = useLanguage('mission');
	const missionId = Number(useParams().missionId);
	const [modalOpen, setModalOpen] = useState(false);
	const [modalType, setModalType] = useState(ModalTypes.ADD);
	const [loading, setLoading] = useState(false);
	const [selectedDaysToRemove, setSelectedDaysToRemove] = useState({});

	const closeModal = () => setModalOpen(false);

	const declineProposal = useCallback(
		async (freelanceId) => {
			if (isEmpty(mission.proposals?.freelances)) return;

			const daysToRemove = mission.proposals?.freelances[freelanceId];
			setSelectedDaysToRemove({ [freelanceId]: { ...daysToRemove } });
			setModalType(ModalTypes.REMOVE_PROPOSAL);
			setModalOpen(true);
		},
		[mission.proposals?.freelances],
	);

	const removeSelectedDays = useCallback(
		(freelanceId, dateToDelete) => {
			const nextSelectedDaysToRemove = { ...selectedDaysToRemove };

			if (modalType === ModalTypes.REMOVE_PROPOSAL) {
				nextSelectedDaysToRemove[freelanceId].days = Object.entries(
					nextSelectedDaysToRemove[freelanceId].days,
				).reduce((acc, [date, { dates }]) => {
					if (date !== dateToDelete) acc[date] = { dates };
					return acc;
				}, {});
				if (isEmpty(nextSelectedDaysToRemove[freelanceId].days)) delete nextSelectedDaysToRemove[freelanceId];
			} else delete nextSelectedDaysToRemove[freelanceId];

			setSelectedDaysToRemove(nextSelectedDaysToRemove);
		},
		[selectedDaysToRemove, modalType],
	);

	const contracts = useMemo(() => (mission && mission.contracts ? mission.contracts : []), [mission]);
	const appointments = useMemo(() => (mission && mission.appointments ? mission.appointments : []), [mission]);

	const openModal = (modalType, days) => {
		let daysCopy = {};
		if (modalType === ModalTypes.REMOVE_PROPOSAL) {
			daysCopy = Object.entries(days).reduce(
				(acc, [freelanceId, value]) => ({ ...acc, [freelanceId]: { ...value } }),
				{},
			);
		} else daysCopy = { ...days };

		setSelectedDaysToRemove(daysCopy);
		setModalType(modalType);
		setModalOpen(true);
	};

	const removeAppointmentDates = useCallback(
		async (daysLength) => {
			const unixDates = Object.values(selectedDaysToRemove)
				.map(({ dates }) => dates)
				.flat()
				.map(date2unix);

			try {
				await deleteSlotsForAppointmentsMissionIdApi(unixDates, missionId);
				toast.success(lang.appointmentCanceled(daysLength));
			} catch (err) {
				toast.error(lang.unableToCancelAppointment);
			}
		},
		[lang, missionId, selectedDaysToRemove],
	);

	const removeProposals = useCallback(
		async (dates, daysLength) => {
			const freelancesWithDatesToUnix = Object.entries(selectedDaysToRemove).reduce(
				(acc, [freelanceId, { days }]) => {
					acc[freelanceId] = {
						days: Object.entries(days).reduce((acc, [date, { dates }]) => {
							acc[date] = { dates: dates.map(date2unix) };
							return acc;
						}, {}),
					};
					return acc;
				},
				{},
			);

			try {
				await deleteMissionProposalsByDatesApi(freelancesWithDatesToUnix, me.id, missionId);
				closeModal();
				toast.success(lang.proposalCanceled(daysLength));
			} catch (err) {
				toast.error(lang.unableToDeleteProposals(daysLength));
			}
		},
		[missionId, lang, me.id, selectedDaysToRemove],
	);

	const onSubmit = useCallback(async () => {
		setLoading(true);
		const daysLength = Object.keys(selectedDaysToRemove).length;

		if (modalType === ModalTypes.REMOVE_PROPOSAL) await removeProposals(daysLength);
		else if (modalType === ModalTypes.REMOVE_APPOINTMENT) await removeAppointmentDates(daysLength);

		setLoading(false);
		closeModal();
	}, [selectedDaysToRemove, removeProposals, modalType, removeAppointmentDates]);

	const components = useMemo(
		() =>
			[
				{
					title: lang.calendar,
					component: mission && (
						<SingleMissionCalendar
							declineProposal={() => openModal(ModalTypes.REMOVE_PROPOSAL, mission.proposals.freelances)}
							cancelAppointment={(appointmentId) =>
								openModal(
									ModalTypes.REMOVE_APPOINTMENT,
									Object.values(mission.appointments).find(({ id }) => appointmentId === id).days,
								)
							}
							mission={mission}
							modalOpen={modalOpen}
							isMissionPage
						/>
					),
				},
				{
					title: lang.proposalList,
					component: (
						<div>
							{Object.entries(mission.proposals?.freelances || {}).map(
								([freelanceId, freelance], index) =>
									freelance && (
										<FreelanceMissionProposals
											key={index}
											freelance={freelance}
											declineProposal={() => declineProposal(freelanceId)}
										/>
									),
							)}
						</div>
					),
				},
				contracts.length > 0 && {
					title: lang.contractList,
					component: <ContractMissionList contracts={contracts} />,
				},
				appointments.length > 0 && {
					title: lang.appointmentListStudio,
					component: <AppointmentTablePrices appointments={appointments} />,
				},
				{
					title: lang.myMissionIssues,
					component: <IssuesByUserMission user={me.id} mission={missionId} />,
				},
				hasReviewsToShow({ appointments, type: UserTypes.STUDIO }) && {
					title: lang.review,
					component: <MissionReviews appointments={appointments} />,
				},
			].filter((e) => !!e),
		[lang, mission, declineProposal, me.id, missionId, contracts, appointments, modalOpen],
	);

	return (
		<div>
			<MenuNavigationComponent components={components} />
			{modalType === ModalTypes.REMOVE_PROPOSAL && (
				<ConfirmRemoveDatesByFreelances
					open={modalOpen}
					freelances={selectedDaysToRemove}
					removeDate={removeSelectedDays}
					onCancel={closeModal}
					onSubmit={onSubmit}
					removeView={true}
					loading={loading}
				/>
			)}
			{modalType === ModalTypes.REMOVE_APPOINTMENT && (
				<ConfirmRemoveDates
					open={modalOpen}
					daysToRemove={selectedDaysToRemove}
					removeDate={removeSelectedDays}
					onCancel={closeModal}
					onSubmit={onSubmit}
					removeView={true}
					loading={loading}
				/>
			)}
		</div>
	);
};

export default StudioMissionPageComponent;

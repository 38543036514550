import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'

const SelectButton = ({
    label = '',
    items = [],
    onChange = () => { },
    value = null
}) => {

    return (
        <FormControl  >
            <InputLabel id={"demo-simple-select-label-" + value}>{label}</InputLabel>
            <Select
                style={{ minWidth: 130 }}
                labelId={"demo-simple-select-label-" + value}
                id={"demo-simple-select-" + value}
                value={value}
                label={label}
                onChange={onChange}
            >{items.map(({ value, label }, key) =>
                <MenuItem style={{ width: '100%' }} key={key} value={value}>{label}</MenuItem>
            )}
            </Select>
        </FormControl>
    )
}

export default SelectButton
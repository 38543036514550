import RightSide from 'components/RightSide/RightSide';
import TitlePage from 'components/TitlePage/TitlePage';
import useLanguage from 'hooks/useLanguage';
import React from 'react';
import classes from './SubscribePage.module.scss'
import Button from 'components/Button/Button';
import Typography from 'components/Typography/Typography';
import { useStoreUtils } from 'hooks/useStoreUtils';
import { UserTypes } from 'constants/UserTypes';

const SubscribePage = () => {
	const { state: { user: { type } } } = useStoreUtils()
	const lang = useLanguage('pay')
	return (
		<RightSide>
			<TitlePage>
				{lang.subscribeTitle}
			</TitlePage>
			<div>
				<Typography>
					<ul>
						{lang[(type === UserTypes.FREELANCE ? 'freelance' : 'studio') + 'SubscriptionArguments']
							.map((arg, index) => <li key={index}><Typography>{arg}</Typography></li>)}
					</ul>
				</Typography>
				<div className={classes.centeredContainer}>
					<div>
						<Typography variant='subTitle1'>
							{lang.uniquePrice}
						</Typography>
						<Button link={'/pay/subscription'}>
							{lang.subscribeTitle}
						</Button>
					</div>
				</div>
			</div>
		</RightSide>
	);
};

export default SubscribePage;
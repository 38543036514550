import { useCallback, useContext, useEffect, useState } from 'react';
import { fetchApi } from 'containers/helpers';
import { Store } from 'store/store';
import { socket, removeEvent } from 'socket';

const useRemoteData = (path) => {
	const {
		state: { socketConnected },
	} = useContext(Store);
	const [data, setData] = useState(null);
	const [error, setError] = useState(null);
	const loading = !data && !error;
	const init = useCallback(
		() =>
			fetchApi(path)
				.then((_data) => setData(_data))
				.catch((error) => setError(error)),
		[setData, setError, path],
	);

	useEffect(() => {
		!!path && init(path);
	}, [path]);

	useEffect(() => {
		const [elementType, elementId] = path?.substr(1).split('/');
		if (socketConnected && socket && elementId) {
			socket.emit(`join_${elementType}`, elementId);
			socket.on(`${elementType} updated`, () => {
				init();
			});
		}
		return () => {
			if (socketConnected && socket && elementId) {
				socket.emit(`leave_${elementType}`, elementId);
				removeEvent(`${elementType} updated`);
			}
		};
	}, [path, socketConnected]);

	return { data, error, loading, setData };
};

export default useRemoteData;

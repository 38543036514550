import classNames from 'classnames';
import React from 'react';
import { Divider as SemanticDivider } from 'semantic-ui-react'
import classes from './Divider.module.scss'

const Divider = ({
    children
}) => {
    return (
        children ? <SemanticDivider
            horizontal
            className={classes.root}
        >
            {children}
        </SemanticDivider>
            : <SemanticDivider
                horizontal
                className={classNames(classes.root, classes.noChildren)}
            />
    );
};

export default Divider;
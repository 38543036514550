import Button from 'components/Button';
import Typography from 'components/Typography';
import toast from 'components/toast';
import React, { useCallback, useEffect, useState } from 'react';
import classes from '../Onboarding/Onboarding.module.scss';
import { getStripeOnboardLinkApi, stripeAccountIsLinkedApi } from 'containers/account/confirmAccount/helper';
import { getObjectNextValue } from 'containers/helpers';
import { FreelanceOnboardingStatuses } from '../constants/OnboardingStatuses';
import { updateUserStatusAPI } from '../helpers';
import { useStoreUtils } from 'hooks/useStoreUtils';

const StripeOnboard = () => {
	const {
		state: {
			user,
			language: { onboard: lang },
		},
		updateLocalUser,
	} = useStoreUtils();
	const s = lang.stripeOnboardHelp;

	const [loading, setLoading] = useState(false);

	const accountIsLinked = async () => {
		try {
			const isLinked = await stripeAccountIsLinkedApi();
			if (!isLinked) return;

			const nextStatus = getObjectNextValue(FreelanceOnboardingStatuses, 'WAITING_STRIPE');
			const { status: nextStatusFromAPI } = await updateUserStatusAPI(user.id, nextStatus);
			setLoading(false);
			updateLocalUser({ status: nextStatusFromAPI });
			toast.success(lang.stripeAccountLinked);
		} catch (err) {
			toast.error(err.message);
		}
	};

	useEffect(() => {
		accountIsLinked();
	}, []);

	const navigateToStripeOnboardLink = useCallback(async () => {
		setLoading(true);

		try {
			const link = await getStripeOnboardLinkApi();
			window.open(link, '_blank');
			setLoading(false);
		} catch (err) {
			setLoading(false);
		}
	}, []);

	return (
		<div>
			<Typography primaryColor dangerouslySetInnerHTML={{ __html: s }} />
			<div className={classes.centeredContainer}>
				<Button loading={loading} onClick={navigateToStripeOnboardLink}>
					{lang.linkStripeAccount}
				</Button>
			</div>
		</div>
	);
};

export default StripeOnboard;

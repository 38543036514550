import RightSide from 'components/RightSide/RightSide';
import TitlePage from 'components/TitlePage/TitlePage';
import useLanguage from 'hooks/useLanguage';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { fetchApi } from 'containers/helpers';
import ServiceListTable from './ServiceListTable';
import Button from 'components/Button/Button';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import StyledModal from 'components/StyledModal/StyledModal';
import ManageService from './ManageService';
import toast from 'components/toast';
import WeeklyAvailability from './WeeklyAvailability';
import Typography from 'components/Typography';
import ManageForfait from './ManageForfait';
import { useStoreUtils } from 'hooks/useStoreUtils.js';

const MyServicePage = () => {
	const navigate = useNavigate();
	const {
		state: {
			user: { id },
		},
	} = useStoreUtils();
	const lang = useLanguage('services');
	const params = useParams();
	const serviceId = Number(params?.serviceId || params?.forfaitId || 0);
	const [services, setServices] = useState([]);
	const location = useLocation();
	const isForfait = useMemo(() => location.pathname.includes('/forfait'), [location]);
	const isOpen = useMemo(() => location.pathname !== '/services', [location]);
	const init = useCallback(async () => {
		try {
			const services = await fetchApi('/services');
			const forfaits = await fetchApi('/forfaits');
			setServices([...forfaits.map((e) => ({ ...e, forfait: true })), ...services]);
		} catch (e) {
			console.log(e);
		}
	}, [setServices]);

	useEffect(() => {
		init();
	}, []);

	const isPrepaymentActive = useMemo(() => !!services[0]?.prepayment, [services]);
	const prepayment = useCallback(() => {
		const newPrepaymentValue = !!!services[0]?.prepayment;
		fetchApi('/services', {
			method: 'PATCH',
			body: {
				ids: services.map((s) => s.id),
				toUpdate: { prepayment: newPrepaymentValue },
			},
		})
			.then(() => {
				toast.success();
				setServices((services) => services.map((s) => ({ ...s, prepayment: newPrepaymentValue })));
			})
			.catch((e) => console.log(e));
	}, [services]);

	return (
		<RightSide>
			<TitlePage
				subtitle={
					<div>
						<Button link={`https://styleep.com/freelance/${id}`} external variant="secondary">
							Lien vers votre boutique
						</Button>
						<Button link={'/service/create'}>{lang.createService}</Button>
						{services.length > 0 && <Button link={'/service/forfait/create'}>{lang.createForfait}</Button>}
						<Button type="secondary" onClick={prepayment}>
							{lang[isPrepaymentActive ? 'unactivePrepayment' : 'activePrepayment']}
						</Button>
					</div>
				}
			>
				{lang.title}
			</TitlePage>
			<WeeklyAvailability />
			<Typography variant="subTitle1">{lang.subtitle}</Typography>
			<ServiceListTable services={services} />
			<StyledModal open={isOpen} closeOnEscape closeOnDimmerClick onClose={() => navigate('/services')}>
				{isForfait ? (
					<ManageForfait services={services} onUpdate={init} forfaitId={serviceId} />
				) : (
					<ManageService onUpdate={init} serviceId={serviceId} />
				)}
			</StyledModal>
		</RightSide>
	);
};

export default MyServicePage;

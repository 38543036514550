import ReviewWrapper from 'containers/review/components/ReviewWrapper';
import useLanguage from 'hooks/useLanguage';
import React, { useCallback } from 'react';

const MissionReviewsExtended = ({reviews}) => {
    const lang = useLanguage('review')
    const injectLang = useCallback(reviews => reviews.map(review => ({ ...review, text: lang.questions[review.text_key] })), [lang])

    return (
        <ReviewWrapper reviews={injectLang(reviews)} />
    );
};

export default MissionReviewsExtended;
import { useCallback, useMemo, useState } from 'react';
import FormComponent from 'components/Form/Form';
import { useStoreUtils } from 'hooks/useStoreUtils';
import { getOnlyUpdatedValues, isNull, objectToFormData } from 'containers/helpers';
import { UserTypes } from 'constants/UserTypes';
import { updateFreelance, updateStudio } from '../helpers';
import FileTypes from 'constants/FileTypes';
import toast from 'components/toast';
import { isEmpty } from 'lodash';

function UploadFiscalDocs() {
	const [loading, setLoading] = useState(false);
	const { state, updateLocalUser } = useStoreUtils();
	const [error, setError] = useState('');
	const {
		language: { onboard: lang, form: formLang },
		user,
	} = state;
	const capitalIsValid = (capital) => capital != null && capital !== '0' && capital > 0;

	const inputs = useMemo(
		() => [
			...(user.type === UserTypes.STUDIO
				? [
						{
							type: 'text',
							name: 'legal_name',
							value: user.legal_name,
							placeholder: lang.legal_name,
							label: lang.legal_name,
							required: true,
							silentRequired: true,
							fluid: true,
						},
				  ]
				: []),
			{
				type: 'number',
				name: 'siret',
				value: user.siret,
				placeholder: lang.siret,
				label: lang.siret,
				required: true,
				silentRequired: true,
				fluid: true,
			},
			{
				type: 'text',
				name: 'rcs_city',
				value: user.rcs_city,
				placeholder: lang.rcs_city,
				label: lang.rcs_city,
				required: true,
				silentRequired: true,
				fluid: true,
			},
			{
				type: 'text',
				name: 'head_office',
				value: user.head_office,
				placeholder: lang.head_office,
				label: lang.head_office,
				required: true,
				silentRequired: true,
				fluid: true,
				info: lang.fullHeadOffice,
			},
			{
				type: 'text',
				name: 'hq_city',
				value: user.hq_city,
				placeholder: lang.hq_city,
				label: lang.hq_city,
				required: true,
				silentRequired: true,
				fluid: true,
			},
			{
				type: 'text',
				name: 'tva_number',
				value: user.tva_number,
				placeholder: lang.tva_number,
				label: lang.tva_number,
				...(user.type === UserTypes.STUDIO
					? {
							required: true,
							silentRequired: true,
					  }
					: {}),
				fluid: true,
			},
			...(user.type === UserTypes.STUDIO
				? [
						{
							type: 'select',
							name: 'company_type',
							value: !isNull(user.company_type) ? String(user.company_type) : null,
							fluid: true,
							options: lang.company_types.map((company_type, index) => ({
								key: String(index),
								value: String(index),
								text: company_type,
							})),
							required: true,
							silentRequired: true,
							placeholder: lang.company_type,
							label: lang.company_type,
						},
				  ]
				: []),
			...(user.type === UserTypes.STUDIO
				? [
						{
							type: 'number',
							name: 'capital',
							value: user.capital || null,
							placeholder: '1000',
							label: lang.capital,
							fluid: true,
							info: lang.capitalContentCentimes,
						},
				  ]
				: []),
			...(user.type === UserTypes.STUDIO
				? [
						{
							type: 'select',
							name: 'company_role',
							value: !isNull(user.company_role) ? String(user.company_role) : null,
							fluid: true,
							options: [
								{ key: '0', value: '0', text: lang.company_roles.president },
								{ key: '1', value: '1', text: lang.company_roles.ceo },
								{ key: '2', value: '2', text: lang.company_roles.director },
							],
							required: true,
							silentRequired: true,
							placeholder: lang.company_role,
							label: lang.company_role,
						},
				  ]
				: []),
			{
				type: 'file',
				name: 'fiscal',
				value: (user && user.files && user.files.length > 0
					? user.files.find(({ type }) => FileTypes.FISCAL === type) || {}
					: {}
				).name,
				preview: true,
				label: lang.kbisDoc,
				required: true,
				silentRequired: true,
				info: lang.infoFiscal,
			},
		],
		[lang, user],
	);

	const getAddFiscalFileFuncByType = useCallback(
		() => (user.type === UserTypes.FREELANCE ? updateFreelance : updateStudio),
		[user],
	);

	const sendForm = useCallback(
		async (values) => {
			setLoading(true);
			try {
				const updatedValues = getOnlyUpdatedValues(user, values);
				if (isEmpty(updatedValues)) {
					setLoading(false);
					toast(formLang.nothingUpdated);
					return;
				}
				if (user.type === UserTypes.STUDIO && values.company_type != 0 && !capitalIsValid(values.capital)) {
					setLoading(false);
					toast(lang.yourAreNotEISo);
					return;
				}

				const { status } = await getAddFiscalFileFuncByType()(objectToFormData(updatedValues), user.type_id);
				toast(formLang.success);
				setLoading(false);
				updateLocalUser({ status });
			} catch (e) {
				console.error(e);
				setError(e.message);
				toast(formLang.error + ': ' + e.message);
				setLoading(false);
			}
		},
		[formLang, lang, getAddFiscalFileFuncByType, updateLocalUser, user],
	);

	return (
		<>
			<FormComponent
				inputs={inputs}
				sendForm={sendForm}
				centered={true}
				responsive={false}
				segment={false}
				loading={loading}
			/>
		</>
	);
}

export default UploadFiscalDocs;

import React from 'react'


function DisabledDates(disableFunction) {

	const isBanned = (date) => {
		return disableFunction(date)
	}

	const TimeSlotWrapper = (props) => {
		if (
			props.resource === undefined /* gutter */ ||
			!isBanned(props.value)
		) {
			return props.children
		}

		const child = React.Children.only(props.children)

		return React.cloneElement(child, {
			className: child.props.className + ' rbc-off-range-bg',
		})
	}

	return React.memo(TimeSlotWrapper)
}

export default DisabledDates

import { getDayStatusesLang } from 'containers/mission/helpers';
import { useContext, useMemo } from 'react';
import { Store } from 'store/store';
import useLanguage from './useLanguage';

export const useDayStatusesLang = () => {
    const { state: { user: { type } } } = useContext(Store)
    const dayStatusesLangs = useLanguage('dayStatuses')
    const dayStatusesLang = useMemo(() => getDayStatusesLang(dayStatusesLangs, type), [type, dayStatusesLangs])

    return dayStatusesLang
};

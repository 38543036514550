import classNames from 'classnames';
import Button from 'components/Button';
import FreelanceBadges from 'components/FreelanceBadges';
import InfoProfileCard from 'components/InfoProfileCard';
import { getFreelanceBadges } from 'containers/marketplace/freelance/helpers';
import { useContext, useEffect, useState } from 'react';
import { Store } from 'store/store';
import MyPrice from 'components/MyPrice'
import classes from 'styles/classes.module.scss'

const FreelanceProfileContainer = () => {
    const { state: {
        language: { profile: profileLang },
        user
    } } = useContext(Store)

    const [badges, setBadges] = useState({})

    useEffect(() => {
        getFreelanceBadges(user.id)
            .then(setBadges)
    }, [user])

    return (
        <div>
            <div className={classNames(classes.flex, classes.flexWrap, classes.gap1)}>
                {/* <Button link={'/godsons'} type="secondary">{profileLang.godsonPage}</Button> */}
                {/* <Button link={'/levelup/meeting'} type="success">{profileLang.askLevelUp}</Button> */}
                <Button link={'/user/update'} type="warning">{profileLang.updateInfoButton}</Button>
            </div>
            <InfoProfileCard {...user} />
            <MyPrice hourlyRate={user.hourly_rate} />
            <FreelanceBadges badges={badges} />
        </div>
    )
};

export default FreelanceProfileContainer;
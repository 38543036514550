import classNames from 'classnames';
import { useState, useEffect, useContext, useMemo } from 'react';
import { Form, Input, Label, Icon, Header } from 'semantic-ui-react';
import classes from './Form.module.scss'
import { Store } from '../../store/store';
import InfoInput from './InfoInput';

// eslint-disable-next-line no-unused-vars
const progressBarPassword = [
    'password-progress-bar-25',
    'password-progress-bar-50',
    'password-progress-bar-75',
    'password-progress-bar-100',
];

const InputPassword = ({
    onKeyPress = () => { },
    name,
    onChange,
    value,
    initialValue,
    error,
    labelObject,
    styles = { container: '' },
    placeholder,
    required,
    silentRequired = false,
    toCheck = false,
    disabled,
    options = [],
    maxLength = null,
    fluid = false,
    legend = null,
    inline = true,
    responsive = true,
    style = {},
    signIn,
    labelStyle = {},
    centered = false,
    info = null,
    label,
    handleEffect,
    requiredLang,
    forwardRef = null,
    children,
    onFocus = () => { },
    formError = false
}) => {
    const isError = useMemo(() => formError && error && required, [error, formError, required])

    const {
        state: {
            language: {
                password: { conditionPassword: conditionPasswordLang, strengthWord: strengthWordLang },
            },
        },
    } = useContext(Store);

    const [visible, setVisible] = useState(false);
    const [displayPasswordHeader, setDisplayPasswordHeader] = useState(false);
    const [valuePassword, setValuePassword] = useState('');
    const [strengthPassword, setStrengthPassword] = useState(null);
    const onInputFocus = (one) => {
        setDisplayPasswordHeader(true);
        onFocus(one);
    };
    const show = () => setVisible(!visible);

    useEffect(() => {
        const regexPass = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$');
        const regexUpper = new RegExp('[A-Z]');
        const regexLower = new RegExp('[a-z]');
        const regexNumber = new RegExp('[0-9]');

        if (signIn) {
            if (regexPass.test(valuePassword)) {
                return setStrengthPassword(3);
            }
            return setStrengthPassword(0);

            // revoir ce système plus tard avec les √ qui se colorient et passent en opacité 0
            if (valuePassword === '') return setStrengthPassword(null);
            if (valuePassword.length < 8) return setStrengthPassword(0);
            if (regexPass.test(valuePassword)) {
                return setStrengthPassword(3);
            }

            let count = 0;
            if (regexNumber.test(valuePassword)) count++;
            if (regexLower.test(valuePassword)) count++;
            if (regexUpper.test(valuePassword)) count++;

            setStrengthPassword(count);
        }
    }, [valuePassword]);

    const checkValidityPassword = (event) => {
        setValuePassword(event.target.value);
        const regexPass = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$');
        if (signIn) {
            if (!regexPass.test(event.target.value)) return handleEffect({ target: { value: '' } }, { value: '' });
        }
        handleEffect(event, { value: event.target.value });
    };
    const onBlur = (event) => {
        const regexPass = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$');
        if (signIn) {
            if (!regexPass.test(valuePassword)) return handleEffect({ target: { value: '' } }, { value: '' });
        }
        handleEffect(event, { value: valuePassword });
    };
    return (
        <Form.Field
            required={required}
            className={classNames(styles.container,
                classes.field,
                styles.container,
                responsive
                    ? centered
                        ? classes.horizontalCenteredContainer
                        : classes.flex
                    : '')}
            inline={inline}
        >
            {label ? (
                <label className={classes.conformLabel} style={labelStyle} htmlFor={name + (initialValue ? `-${initialValue}` : '')}>
                    {label}
                </label>
            ) : null}
            <Input
                {...(forwardRef ? { ref: forwardRef } : {})}
                className={classNames(classes.myInputIsChild, isError && classes.error)}
                onKeyPress={onKeyPress}
                id={name + (initialValue ? `-${initialValue}` : '')}
                name={name}
                type={visible ? 'text' : 'password'}
                value={valuePassword || ''}
                placeholder={placeholder}
                onChange={checkValidityPassword}
                onBlur={onBlur}
                fluid={fluid}
                style={style}
                onFocus={onInputFocus}
                {...(maxLength ? { maxLength } : {})}
                icon={<Icon name={visible === false ? 'eye slash' : 'eye'} link onClick={show} />}
            />
            {signIn ? (
                <>
                    {displayPasswordHeader ? (
                        <div className={classes.displayPasswordHeader}>
                            {/* <div className="password-meter">
            <div className={progressBarPassword[strengthPassword]}></div>
          </div> */}
                            <Header
                                as="h5"
                                className={strengthPassword > 0 ? classes.green : classes.red}
                            >
                                {strengthWordLang[strengthPassword]}
                            </Header>
                        </div>
                    ) : null}
                    {legend
                        ? conditionPasswordLang.map((values) => (
                            <div key={values}>
                                <p className={classes.legendInput}>
                                    {'-'}
                                    {values}
                                </p>
                            </div>
                        ))
                        : null}
                </>
            ) : (
                <>
                    {legend ? (
                        <div>
                            <p className={classes.legendInput}>{legend}</p>
                        </div>
                    ) : null}
                </>
            )}

            {info ? <InfoInput info={info} /> : null}
            {children}
            {required && error && !silentRequired ? (
                <Label
                    style={{
                        display: 'inline-table',
                        marginTop: 'auto',
                        marginBottom: 'auto',
                    }}
                    pointing="left"
                    color="red"
                    basic
                >
                    {requiredLang}
                </Label>
            ) : null}
        </Form.Field>
    );
};

export default InputPassword;

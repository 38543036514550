import Button from "./Button"

const NewPageLinkButton = ({
    href,
    children
}) => {
    return (
        <Button type="warning" as={'a'} href={href} target='_blank' rel="noreferrer">
            {children}
        </Button>
    )
}

export default NewPageLinkButton
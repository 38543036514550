import Typography from "components/Typography";
import cn from "classnames";
import classes from "styles/classes.module.scss";
import { useLocation } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useStoreUtils } from "hooks/useStoreUtils";
import { getDailyRateStatisticsApi, getHourlyRateApi, updateUserHourlyRateApi, updateUserStatusAPI } from "../helpers";
import FormComponent from "components/Form/Form";
import toast from "components/toast";
import { getObjectNextValue, roundTo2Dec } from "containers/helpers";
import { FreelanceOnboardingStatuses } from "../constants/OnboardingStatuses";
import Button from "components/Button";

const HourlyRate = () => {
	const [loading, setLoading] = useState(false)
	const { pathname } = useLocation()
	const { state, updateLocalUser } = useStoreUtils()
	const { language: { onboard: lang, form: formLang }, user } = state
	const isOnboard = useMemo(() => pathname.includes('onboarding'), [pathname])
	const [hourlyRate, setHourlyRate] = useState(0)
	const [statistics, setStatistics] = useState({
		smaller: 250,
		bigger: 300,
		average: 400,
	});

	useEffect(() => {
		getDailyRateStatisticsApi()
			.then(setStatistics)
	}, [])

	useEffect(() => {
		getHourlyRateApi(user.id)
			.then(setHourlyRate)
	}, [user])

	const input = useMemo(() => [
		{
			type: 'number',
			name: 'hourly_rate',
			value: parseFloat(hourlyRate) || 0,
			placeholder: lang.hourlyRate,
			label: lang.hourlyRate,
			required: true,
			silentRequired: true,
			fluid: true,
			onChange: (e) => setHourlyRate(e.value),
		}
	], [lang, hourlyRate])

	const sendForm = useCallback(async (values) => {
		const { hourly_rate: hourlyRateFromValues } = values
		try {
			
			if (hourlyRate === hourlyRateFromValues)
				return toast(formLang.nothingUpdated)

			await updateUserHourlyRateApi(user.id, roundTo2Dec(parseFloat(hourlyRateFromValues)));

			toast(formLang.success);
		} catch (err) {
			console.error(err);
			toast(formLang.error + ' : ' + err.message);
		} finally {
			setLoading(false);
		}
	}, [hourlyRate, formLang, user]);

	const goToNextStep = useCallback(async () => {
		setLoading(true)
		try {
			const nextStatus = getObjectNextValue(FreelanceOnboardingStatuses, 'WAITING_BANK_INFOS')
			const { status: nextStatusFromAPI } = await updateUserStatusAPI(user.id, nextStatus)
			setLoading(false)
			updateLocalUser({ status: nextStatusFromAPI })
		} catch (e) {
			setLoading(false)
			console.error(e)
			toast(formLang.error + ' : ' + e.message)
		}
	}, [formLang, updateLocalUser, user]);

	const currentDailyRate = useMemo(() => {
		return hourlyRate * 8;
	}, [hourlyRate]);

	return (
		<div>
			<div className={cn(classes.centeredContainer, classes.gap1, classes.mb2)}>
				{Object.entries(statistics).map(([key, value]) => (
					<div
						key={key}
						className={cn(classes.centeredContainer, classes.columnDirection, classes.mt1)}
					>
						<Typography primaryColor variant='littleTitle'>{lang.statistics[key]}</Typography>
						<Typography variant='littleText'>{Number(value).toFixed(2)} €</Typography>
					</div>
				))}
			</div>

			<div className={cn(classes.centeredContainer, classes.mb1)}>
				<Typography
					primaryColor
					variant='littleTitle'
				>
					{lang.currentDailyRate} : {currentDailyRate} €
				</Typography>
			</div>

			<FormComponent
				inputs={input}
				sendForm={sendForm}
				centered={true}
				responsive={false}
				segmernt={false}
				loading={loading}
			/>

			{isOnboard && (
				<div>
					<Typography primaryColor variant='littleText'>{lang.bankSubtitle}<br /></Typography>
					<Button type='black' onClick={goToNextStep} className={classes.mt1}>
						{lang.passThisStep}
					</Button>
				</div>
			)}
		</div>
	)
}

export default HourlyRate;
import { useEffect, useState, useContext, useCallback, useMemo } from 'react';
import toast from 'components/toast';
import { getAppointmentReviews } from '../helpers';
import ReviewWrapper from './ReviewWrapper';
import { UserTypes } from 'constants/UserTypes';
import { Store } from 'store/store';
import useLanguage from 'hooks/useLanguage';
import { isEmpty } from 'lodash';

const AppointmentReviews = ({ appointmentId }) => {
	const {
		state: {
			user: { type },
		},
	} = useContext(Store);
	const lang = useLanguage('review');
	const [data, setData] = useState(null);

	useEffect(() => {
		const init = async () => {
			try {
				const res = await getAppointmentReviews(appointmentId);
				res && setData(res);
			} catch (e) {
				console.log('e', e);
				toast.error(e.message);
			}
		};
		if (appointmentId) {
			init();
		}
	}, [appointmentId]);

	const injectLang = useCallback(
		(reviews) => reviews.map((review) => ({ ...review, text: lang.questions[review.text_key] })),
		[lang],
	);
	const isDefined = useMemo(
		() => !isEmpty(data) && (!isEmpty(data.reviewsILeft) || !isEmpty(data.reviewsForMe)),
		[data],
	);
	return (
		isDefined && (
			<>
				<ReviewWrapper title={lang.myReview} reviews={injectLang(data.reviewsILeft)} />
				{!!data.reviewsForMe.length && (
					<ReviewWrapper
						title={`${type === UserTypes.FREELANCE ? lang.reviewFromStudio : lang.reviewFromFreelance}`}
						reviews={injectLang(data.reviewsForMe)}
					/>
				)}
			</>
		)
	);
};

export default AppointmentReviews;

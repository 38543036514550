export const FR = {
	title: 'Rendez-vous',
	calendar: 'Calendrier',
	columns: {
		id: 'ID',
		price: 'Prix',
		title: 'Titre',
		description: 'Description',
		time: 'Temps',
	},
	servicesList: 'Liste des services',
	issues: 'Problèmes',
	customerDetails: 'Détails du client',
	accessToAppointmentPage: 'Accéder à la page de rendez-vous',
	cancelAppointment: 'Annuler le rendez-vous',
	appointmentCanceled: 'Rendez-vous annulé',
	appointmentCanceledError: "Erreur lors de l'annulation du rendez-vous",
	review: 'Avis',
	invoice: 'Facture',
};

export const EN = {
	title: 'Appointment',
	calendar: 'Calendar',
	columns: {
		id: 'ID',
		price: 'Price',
		title: 'Title',
		description: 'Description',
		time: 'Time',
	},
	servicesList: 'Services list',
	issues: 'Issues',
	customerDetails: 'Customer details',
	accessToAppointmentPage: 'Access to appointment page',
	cancelAppointment: 'Cancel appointment',
	appointmentCanceled: 'Appointment canceled',
	appointmentCanceledError: 'Error while canceling appointment',
	review: 'Review',
	invoice: 'Invoice',
};

export default {
	fr: FR,
	us: EN,
};

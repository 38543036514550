import { List, Checkbox } from 'semantic-ui-react';
import Button from 'components/Button';
import Typography from 'components/Typography';
import { isWeekend, dateByDateFormat, momentFromDateString } from 'modules/dates';
import classes from './AddDates.module.scss';
import cn from 'classnames';
import externalClasses from 'styles/classes.module.scss';
import useLanguage from 'hooks/useLanguage';
import useLocale from 'hooks/useLocale';
import DaysStatus from './DaysStatus';
import { useStoreUtils } from 'hooks/useStoreUtils';
import { UserTypes } from 'constants/UserTypes';
import FavoriteDayStatus from './FavoriteDayStatus';
import { isEmpty } from 'lodash';

function AddDates({
	days,
	updateDaysStatus,
	updateFavoriteDayStatus,
	filterWeekends,
	toggleFilterWeekends,
	removeDate,
	chooseOnly = false,
	cantRemoveIfOne = false,
	isOnlyWeekends = false,
	includesWeekends = false,
	showDayStatus = false,
	showFavoriteDayStatus = false,
}) {
	const {
		state: {
			user: { type },
		},
	} = useStoreUtils();
	const lang = useLanguage('scheduler');
	const locale = useLocale();

	return (
		<>
			<List divided className={classes.wrapperList}>
				{Object.entries(days)
					.sort(([a], [b]) => momentFromDateString(a).unix() - momentFromDateString(b).unix())
					.map(([key, { dates, dayStatus, favoriteDayStatus }]) => {
						if (!isOnlyWeekends && filterWeekends && isWeekend(key)) return null;

						return (
							<List.Item key={key}>
								<List.Content className={classes.scheduleItem}>
									<div
										className={cn(
											externalClasses.flex,
											externalClasses.verticalCenteredContainer,
											externalClasses.gap1,
										)}
									>
										<Typography variant="primaryBody">
											{dateByDateFormat(key, 'DD/MM/YYYY', locale)}
										</Typography>
										{!!dayStatus && <div className={classes.dayStatus}>{lang[dayStatus]}</div>}
									</div>
									<div className={cn(externalClasses.centeredContainer, externalClasses.gap1)}>
										<DaysStatus
											date={key}
											hidden={!showDayStatus}
											update={(index) => updateDaysStatus(key, index)}
											dayStatus={dayStatus}
										/>
										<FavoriteDayStatus
											date={key}
											dayStatus={dayStatus}
											onUpdate={(favoriteDayStatus) =>
												updateFavoriteDayStatus(key, favoriteDayStatus)
											}
											favoriteDayStatus={favoriteDayStatus}
											hidden={type !== UserTypes.STUDIO || !showFavoriteDayStatus}
											currentDay={isEmpty(dates) ? days[key] : dates[0]}
										/>

										{!(cantRemoveIfOne && days.length === 1) && (
											<Button type="cancel" onClick={() => removeDate(key)}>
												{lang.remove}
											</Button>
										)}
										{chooseOnly && (
											<Button onClick={() => chooseOnly(key)}>{lang.chooseOnly}</Button>
										)}
									</div>
								</List.Content>
							</List.Item>
						);
					})}
			</List>
			{includesWeekends && !isOnlyWeekends ? (
				<Checkbox
					radio={true}
					type="checkbox"
					label={lang.filterWeekends}
					checked={isOnlyWeekends ? false : filterWeekends}
					onClick={toggleFilterWeekends}
				/>
			) : null}
		</>
	);
}

export default AddDates;

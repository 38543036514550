import { apiUrl, fetchApi } from 'containers/helpers';
import { isEqual } from 'modules/dates';

export const deleteContractAPI = (contractId) =>
	fetchApi('/contract/' + contractId, {
		method: 'DELETE',
	});

export const getContract = (contractId) => fetchApi('/contract/' + contractId);
export const getSignatures = () => fetchApi('/user/signatures');
export const createSignatureAPI = (type, image) =>
	fetchApi('/user/signature', {
		body: {
			type,
			image,
		},
	});

export const signContractAPI = (contractId) =>
	fetchApi('/contract/sign/' + contractId, {
		method: 'PATCH',
	});

export const getFile = (fileId) => fetchApi('/file/' + fileId);

export const getFileByIdVisualisationUrl = (token, fileId) => `${apiUrl}/file/visualisation/${fileId}/${token}`;

export const getFileByNameVisualisationUrl = (token, filename) =>
	apiUrl + `/file/name/visualisation/${token}/${filename}?${global.Date.now()}`;
//datesConfirmed = [12214214,4124124]
export const sendConfirmationsAPI = (contractId, confirmedDays) =>
	fetchApi('/confirmations/' + contractId, {
		body: {
			confirmedDays,
		},
	});

export const getFreelanceMissionDay = (freelances, date, missionId) => {
	return freelances.filter((freelance) =>
		freelance.dates.find(({ date: freelanceDate }) => isEqual(new Date(freelanceDate), date)),
	);
};

export const updatePrepaymentStatusAPI = (contractId, status, userId) =>
	fetchApi('/contract/prepayment/' + contractId, {
		method: 'PATCH',
		body: {
			status,
			userId,
		},
	});

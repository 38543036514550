import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useStoreUtils } from 'hooks/useStoreUtils';
import queryString from 'query-string';
import LoginForm from './components/LoginForm';
import { login } from './helpers';
import { decodeJsonData, fetchApi } from 'containers/helpers';

const Login = () => {
	const { state, loginInApp } = useStoreUtils()
	const { search } = useLocation()
	const navigate = useNavigate();
	const { login: copy } = state.language

	const inputs = useMemo(() => [
		{
			type: 'email',
			name: 'email',
			placeholder: copy.mail,
			required: true,
			silentRequired: true,
			fluid: true,
		},
		{
			type: 'password',
			name: 'password',
			placeholder: copy.password,
			required: true,
			silentRequired: true,
			fluid: true,
		},
	].filter((e) => !!e), [copy]);
	const [errorLogin, setErrorLogin] = useState({ validated: true, message: copy.accountNotValidatedLang });
	const [modal, setModal] = useState(false);

	const sendForm = useCallback(async (values) => {
		try {
			const tokens = await login(values);
			loginInApp(tokens)
			return
		} catch (e) {
			if (e.message === 'Please verify your email first') {
				setErrorLogin({ validated: false, message: copy.accountNotValidated });
			} else setErrorLogin({ validated: true, message: e.message });

			return setModal(true);
		}
	}, [loginInApp, copy]);

	const goToForgot = () => navigate(`/forgot`);

	useEffect(() => {
		const queryParams = queryString.parse(search);
		if (queryParams && queryParams.tokens) {
			const datas = decodeJsonData(queryParams.tokens)
			sendForm(datas)
		}
	}, [search, sendForm])

	return (
		<LoginForm
			forgotPassword={goToForgot}
			inputs={inputs}
			sendForm={sendForm}
			modal={modal}
			setModal={setModal}
			forgotPasswordLang={copy.forgotPassword}
			noConfirmationMailLang={copy.noConfirmationMail}
			unlockLang={copy.unlock}
			typeCodeLang={copy.typeCode}
			validLang={copy.valid}
			errorLogin={errorLogin}
			errorTitleLang={copy.errorTitle}
			redirectConfirmLang={copy.redirectConfirm}
		/>
	);
};

export default Login
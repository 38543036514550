import cn from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import classes from './Typography.module.scss';

// See https://www.figma.com/file/3zIW8GUO2eqtilzPXf7E8p/SIMULATEUR-FLAMMEBLEUE?node-id=8%3A2.

const Typography = ({
	// Variant can be either 'body', 'title', 'subTitle1', 'subTitle2' or 'button'. Default is 'body'.
	variant = 'body',
	component: Component = 'span',
	classes: receivedClasses = {},
	children: childrenFromParams,
	link = false,
	linkStyle = false,
	primaryColor = false,
	html: htmlFromParams = null,
	italic = false,
	fontWeight = false,
	underline = false,
	...other
}) => {
	const [html, setHtml] = useState(false);
	const stylizeStyleepWord = (str) => str.replace(/(S|s)tyleep/g, '<styleep>Styleep</styleep>');

	useEffect(() => {
		if (htmlFromParams) {
			setHtml(stylizeStyleepWord(htmlFromParams));
		} else if (typeof childrenFromParams === 'string' && childrenFromParams.match(/(S|s)tyleep/g)) {
			const str = stylizeStyleepWord(childrenFromParams);
			const splitByNewLine = str.replace(/\n/g, '<br/>');
			setHtml(splitByNewLine);
		}
	}, [htmlFromParams, childrenFromParams]);

	const children = useMemo(() => {
		const getWithBr = (arr) =>
			arr.map((str, index) => (
				<span key={index}>
					{str}
					{index !== arr.length - 1 && <br />}
				</span>
			));
		if (typeof childrenFromParams === 'string') {
			const str = stylizeStyleepWord(childrenFromParams);
			const splitByNewLine = str.split('\n');
			return splitByNewLine.length > 1 ? <span>{getWithBr(splitByNewLine)}</span> : str;
		}
		return childrenFromParams;
	}, [childrenFromParams]);

	const typo = useMemo(() => {
		const props = {
			className: cn(
				classes.root,
				classes[variant],
				receivedClasses.root,
				linkStyle && classes.linkStyle,
				primaryColor && classes.primaryColor,
				italic && classes.italic,
				underline && classes.underline,
			),
			...(html ? { dangerouslySetInnerHTML: { __html: html } } : {}),
			...other,
			...(fontWeight ? { style: { ...(other.style || {}), fontWeight } } : {}),
		};
		return html ? (
			<Component {...props} />
		) : variant === 'title' ? (
			<h1 {...props}>{children}</h1>
		) : variant === 'subTitle1' ? (
			<h2 {...props}>{children}</h2>
		) : variant === 'subTitle2' ? (
			<h3 {...props}>{children}</h3>
		) : variant === 'subTitle3' ? (
			<h4 {...props}>{children}</h4>
		) : variant === 'littleText' ? (
			<span {...props}>{children}</span>
		) : (
			<span {...props}>{children}</span>
		);
	}, [children, html, linkStyle, other, primaryColor, receivedClasses, variant, fontWeight, italic, underline]);

	const LinkableTypo = () => <Link to={link}>{typo}</Link>;

	return link ? <LinkableTypo /> : typo;
};

export default Typography;

import DatesFromMissionDays from 'components/DatesFromMissionDays';
import Typography from 'components/Typography';
import { upperFirst } from 'lodash';
import { getStartAndEndDate } from 'modules/dates';
import React, { useMemo } from 'react';
import classes from './LastMission.module.scss'
import moment from 'moment';

const LastMissionItem = ({
    title,
    days,
    status
}) => {
	const dates = useMemo(() => Object.keys(days), [days])
    const { startDate, endDate } = useMemo(() => getStartAndEndDate(dates.map(date => moment(date, 'DD/MM/YYYY').toDate())), [dates])
    return (
        <div className={classes.item}>
            <Typography classes={{root:classes.studioName}} variant='subTitle3' primaryColor>{upperFirst(title)}</Typography>
            <Typography italic primaryColor classes={{root:classes.middleItem}}>
                <DatesFromMissionDays
                    noTypo
                    daysCount={dates.length}
                    startDate={startDate}
                    endDate={endDate} />
            </Typography>
        </div>
    );
};

export default LastMissionItem;
import FormComponent from 'components/Form/Form'
import { useEffect, useState } from 'react'
import { List } from 'semantic-ui-react'
import useLanguage from 'hooks/useLanguage'

const DetailsForm = ({
	initForm = {
		title: '',
		description: ''
	},
	templates,
	onSubmit = () => { }
}) => {
	const lang = useLanguage('createMission')
	const [inputs, setInputs] = useState([])
	const getInputsWithInitialValues = (initForm) => [
		{
			type: 'text',
			name: 'title',
			label: lang.missionTitle,
			initialValue: initForm.title,
			value: initForm.title,
			placeholder: lang.title,
			required: true,
			silentRequired: true,
		},
		{
			type: 'textArea',
			name: 'description',
			label: lang.missionDescription,
			initialValue: initForm.description,
			value: initForm.description,
			placeholder: lang.descriptionPlaceholder,
			required: true,
			silentRequired: true,
			legend: lang.maximum,
			maxLength: 500
		},
	]

	useEffect(() => {
		setInputs(getInputsWithInitialValues(initForm))
	}, [initForm])

	const onClickTemplate = (i) =>
		setInputs(getInputsWithInitialValues(templates[i]))

	return (<>
		{!!(templates.length) && (
			<List divided={true}>
				<List.Header>
					{lang.copyFromPreviousMission}
				</List.Header>
				{templates.map(({ title, description }, i) => (
					<List.Item
						key={i}
						className='hoverable-list-item'
						onClick={() => onClickTemplate(i)}
					>
						<List.Content>
							<strong>{title}</strong>
							<br></br>
							<span>{description}</span>
						</List.Content>
					</List.Item>
				))}
			</List>
		)}
		<FormComponent
			inputs={inputs}
			sendForm={onSubmit}
			messages={{ submit: lang.nextStep }}
		/>
	</>
	);
};

export default DetailsForm;
import Button from 'components/Button';
import Typography from 'components/Typography';
import useLanguage from 'hooks/useLanguage';
import { useEffect, useMemo, useState } from 'react';
import { statuses as IssueStatuses } from 'containers/issue/issue.enum';
import classes from 'styles/classes.module.scss';
import { useParams } from 'react-router-dom';
import ScheduleMeetingIssue from 'containers/issue/ScheduleMeetingIssue';

function IssueElem({ title, description, reference, status, id, updateIssue = () => {} }) {
	const lang = useLanguage('issue');
	const [meetingVisible, setMeetingVisible] = useState(false);
	const params = useParams();
	const isMeeting = useMemo(
		() => (params.action && params.action === 'meeting') || status >= IssueStatuses.ALLOW_SCHEDULE_MEETING,
		[params, status],
	);
	useEffect(() => {
		setMeetingVisible(isMeeting);
	}, [isMeeting]);
	const actions = useMemo(() => {
		switch (status) {
			case IssueStatuses.WAITING_MEETING:
			case IssueStatuses.MEETING_BOOKED:
				return isMeeting ? null : (
					<Button className={classes.mb1} link={`/issue/${id}/meeting`}>
						{status === IssueStatuses.MEETING_BOOKED ? lang.accessToMeetingPage : lang.meetingButton}
					</Button>
				);
			default:
				return null;
		}
	}, [lang, status, id, isMeeting]);

	return (
		<div>
			{actions}
			<div>
				<Typography variant="subTitle2">{lang.list.reference}</Typography>
				<Typography>{reference}</Typography>
			</div>
			<div>
				<Typography variant="subTitle2">{lang.list.description}</Typography>
				<Typography>{description}</Typography>
			</div>
			{meetingVisible && <ScheduleMeetingIssue id={id} updateIssue={updateIssue} />}
		</div>
	);
}

export default IssueElem;

import { getObjectByUserType } from 'containers/helpers'
import { useContext, useMemo } from 'react'
import { Store } from 'store/store'

function useLanguage(key, userType = null) {
	const { state: { language/*, user: { type } */ } } = useContext(Store)
	const keys = useMemo(() => key.split('.'), [key])
	const value = useMemo(() =>
		userType != null
			? getObjectByUserType(keys.reduce((acc, curr) => acc[curr], language), userType)
			: keys.reduce((acc, curr) => acc[curr], language),
		[language, keys, userType])
	// const value = useMemo(() => getObjectByUserType(
	// 	keys.reduce((acc, curr) => acc[curr], language), type),
	// 	[keys, language, type])
	// 	console.log('value', value)

	return value
}

export default useLanguage
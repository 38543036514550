import { useReducer, createContext, useEffect } from 'react'
import reducer, { initialState } from './reducers'
import { fetchApi } from 'containers/helpers'

const Store = createContext()

const Provider = ({ children, initialValues }) => {
	const [state, dispatch] = useReducer(reducer, initialValues || initialState())
	useEffect(() => {
		fetchApi('/professions')
			.then(professions => dispatch({ type: 'UPDATE_PROFESSIONS', payload: professions }))
			.catch(e => console.log(e))
	}, [])
	return <Store.Provider value={{ state, dispatch }}>{children}</Store.Provider>
}

export { Store, Provider }

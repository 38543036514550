import { useCallback, useContext, useMemo } from 'react';
import jwtDecode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

import { fetchApi, saveTokens, logOut, getDidactiels, updateDidactielAPI } from '../containers/helpers';
import { Store } from '../store/store';
import { UserTypes } from 'constants/UserTypes';
import useLanguage from './useLanguage';
import toast from 'components/toast';

export const useStoreUtils = () => {
	const lang = useLanguage('login')
	const { dispatch, state } = useContext(Store);
	const navigate = useNavigate();
	const dispatchAlert = useCallback(
		(alert = { type: 'error', message: 'rergegreg' }) => {
			dispatch({ type: 'ADD_ALERT', payload: alert });
		},
		[dispatch],
	);

	const updateStoreUser = useCallback(
		(user) =>
			dispatch({
				type: 'USER/INIT',
				payload: user,
			}),
		[dispatch],
	);

	const updateDidactiels = useCallback(
		(didactiels) =>
			dispatch({
				type: 'UPDATE_DIDACTIELS',
				payload: didactiels,
			}),
		[dispatch],
	);

	const updateLocalUser = useCallback(
		(newUser) => {
			const user = {
				...JSON.parse(localStorage.getItem('profile')),
				...newUser,
			};
			localStorage.setItem('profile', JSON.stringify(user));
			updateStoreUser(user);
		},
		[updateStoreUser],
	);

	const regenerateTokens = useCallback(async () => {
		try {
			const tokens = await fetchApi('/user/regenerateTokens');
			console.log('Tokens regenerated');
			saveTokens(tokens, dispatch);
			const user = jwtDecode(tokens.token.token);
			getDidactiels().then(updateDidactiels);
			updateStoreUser(user);
		} catch (error) {
			console.log('regenerateTokens error', error);
			logOut();
			console.error(error.message);
		}
	}, [dispatch, updateStoreUser, updateDidactiels]);

	const reloadUser = useCallback(async () => {
		try {
			await regenerateTokens();
		} catch (error) {
			console.log('error during reloadUser', error);
		}
	}, [regenerateTokens]);

	const cleanNotifications = useCallback(() => dispatch({ type: 'REMOVE_ALL_NOTIFICATIONS' }), [dispatch])

	const loginInApp = useCallback((tokens) => {
		dispatch({ type: 'RESET' });
		const user = jwtDecode(tokens.token.token);
		if (user.type === UserTypes.CUSTOMER) {
			return toast.error(lang.youAreCustomer + 'https://styleep.com', 'https://styleep.com');
		}
		updateStoreUser(user);
		saveTokens(tokens);
		const path = JSON.parse(localStorage.getItem('path'));
		if (path) localStorage.removeItem('path');
		cleanNotifications();
		path ? navigate(path) : navigate('/home');
	}, [cleanNotifications, dispatch, lang.youAreCustomer, navigate, updateStoreUser]);

	const setLoadingOn = useCallback(() =>
		dispatch({
			type: 'LOADING_ON',
		}), [dispatch]);
	const setLoadingOff = useCallback(() =>
		dispatch({
			type: 'LOADING_OFF',
		}), [dispatch]);

	const updateDidactielStatus = useCallback(async (didactiel) => {
		try {
			await updateDidactielAPI(didactiel);
			dispatch({
				type: 'UPDATE_DIDACTIEL',
				payload: didactiel,
			});
			console.log('didactiel ' + didactiel + ' is passed to TRUE');
		} catch (error) {
			console.log('error during updating didactiel : ', didactiel);
		}
	}, [dispatch]);

	const setTableLoadingOn = useCallback(() =>
		dispatch({
			type: 'UPDATE_TABLE_LOADING',
			payload: true,
		}), [dispatch]);
	const setTableLoadingOff = useCallback(() =>
		dispatch({
			type: 'UPDATE_TABLE_LOADING',
			payload: false,
		}), [dispatch]);

	const isPremium = useMemo(() => !!state.user.premium, [state.user])

	return {
		dispatchAlert,
		dispatch,
		state,
		regenerateTokens,
		updateLocalUser,
		loginInApp,
		setLoadingOn,
		setLoadingOff,
		reloadUser,
		updateDidactielStatus,
		setTableLoadingOn,
		setTableLoadingOff,
		isPremium
	};
};

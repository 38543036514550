import { useMemo, useCallback, useContext, useState } from 'react';
import toast from 'components/toast';
import { useParams } from 'react-router-dom';
import SingleMissionCalendar from 'containers/calendar/components/SingleMissionCalendar';
import IssuesByUserMission from 'containers/issue/components/IssuesByUserMission';
import MenuNavigationComponent from '../components/MenuNavigationComponent';
import useLanguage from 'hooks/useLanguage';
import StudioPublicProfileContainer from 'containers/studio/StudioPage/StudioPublicProfileContainer';
import { Store } from 'store/store';
import ConfirmAddDates from 'containers/scheduler/components/ConfirmAddDates';
import {
	confirmProposalsApi,
	deleteMissionProposalsByDatesApi,
	deleteSlotsForAppointmentsMissionIdApi,
} from '../helpers';
import ConfirmRemoveDates from 'containers/scheduler/components/ConfirmRemoveDates';
import { date2unix } from 'modules/dates';
import ConfirmRemoveDatesByFreelances from 'containers/scheduler/components/ConfirmRemoveDatesByFreelances/ConfirmRemoveDatesByFreelances';
import { isEmpty } from 'lodash';
import { hasReviewsToShow } from 'containers/review/helpers';
import { UserTypes } from 'constants/UserTypes';
import MissionReviews from 'containers/review/components/MissionReviews';
import ContractMissionList from '../components/ContractMissionList/ContractMissionList';
import AppointmentTablePrices from '../components/AppointmentTablePrices';

const ModalTypes = {
	ADD_PROPOSAL: 0,
	REMOVE_PROPOSAL: 1,
	REMOVE_APPOINTMENT: 2,
};

const FreelanceMissionPageComponent = ({ mission }) => {
	const lang = useLanguage('mission');
	const missionId = Number(useParams().missionId);
	const me = useContext(Store).state.user;
	const [modalOpen, setModalOpen] = useState(false);
	const [modalType, setModalType] = useState(ModalTypes.ADD);
	const [loading, setLoading] = useState(false);
	const [selectedDays, setSelectedDays] = useState({});
	const { proposalsDays } = useMemo(() => mission, [mission]);

	const closeModal = () => setModalOpen(false);

	const openModal = useCallback((modalType, days) => {
		let daysCopy = {};
		if (modalType === ModalTypes.REMOVE_PROPOSAL) {
			daysCopy = Object.entries(days).reduce(
				(acc, [freelanceId, value]) => ({ ...acc, [freelanceId]: { ...value } }),
				{},
			);
		} else daysCopy = { ...days };

		setSelectedDays(daysCopy);
		setModalType(modalType);
		setModalOpen(true);
	}, []);

	const removeSelectedDays = useCallback(
		(freelanceId, dateToDelete) => {
			const nextSelectedDaysToRemove = { ...selectedDays };

			if (modalType === ModalTypes.REMOVE_PROPOSAL) {
				nextSelectedDaysToRemove[freelanceId].days = Object.entries(
					nextSelectedDaysToRemove[freelanceId].days,
				).reduce((acc, [date, { dates }]) => {
					if (date !== dateToDelete) acc[date] = { dates };
					return acc;
				}, {});
				if (isEmpty(nextSelectedDaysToRemove[freelanceId].days)) delete nextSelectedDaysToRemove[freelanceId];
			} else delete nextSelectedDaysToRemove[freelanceId];

			setSelectedDays(nextSelectedDaysToRemove);
		},
		[selectedDays, modalType],
	);

	const removeAppointmentDates = useCallback(
		async (daysLength) => {
			const dates = Object.values(selectedDays)
				.map(({ dates }) => dates)
				.flat()
				.map((date) => date2unix(date));

			try {
				await deleteSlotsForAppointmentsMissionIdApi(dates, missionId);
				toast.success(lang.appointmentCanceled(daysLength));
			} catch (err) {
				toast.error(lang.noAccess);
			}
		},
		[lang, missionId, selectedDays],
	);

	const removeProposals = useCallback(
		async (daysLength) => {
			const freelancesWithDatesToUnix = Object.entries(selectedDays).reduce((acc, [freelanceId, { days }]) => {
				acc[freelanceId] = {
					days: Object.entries(days).reduce((acc, [date, { dates }]) => {
						acc[date] = { dates: dates.map(date2unix) };
						return acc;
					}, {}),
				};
				return acc;
			}, {});

			try {
				await deleteMissionProposalsByDatesApi(freelancesWithDatesToUnix, me.id, missionId);
				toast.success(lang.proposalCanceled(daysLength));
			} catch (err) {
				toast.error(lang.unableToDeleteProposals(daysLength));
			}
		},
		[me.id, missionId, lang, selectedDays],
	);

	const confirmProposals = useCallback(
		async (daysLength) => {
			const dates = Object.values(selectedDays)
				.map(({ dates }) => dates)
				.flat()
				.map((date) => date2unix(date));

			try {
				await confirmProposalsApi(dates, me.id, missionId);
				toast.success(lang.proposalAccepted(daysLength));
			} catch (err) {
				toast.error(lang.unableToAcceptProposals(daysLength));
			}
		},
		[me.id, missionId, lang, selectedDays],
	);

	const onSubmit = useCallback(async () => {
		setLoading(true);
		const daysLength = Object.keys(selectedDays).length;

		if (modalType === ModalTypes.ADD_PROPOSAL) await confirmProposals(daysLength);
		else if (modalType === ModalTypes.REMOVE_PROPOSAL) await removeProposals(daysLength);
		else if (modalType === ModalTypes.REMOVE_APPOINTMENT) await removeAppointmentDates(daysLength);

		setLoading(false);
		closeModal();
	}, [selectedDays, modalType, confirmProposals, removeProposals, removeAppointmentDates]);
	const appointments = useMemo(() => (mission && mission.appointments ? mission.appointments : []), [mission]);

	const contracts = useMemo(() => (mission && mission.contracts ? mission.contracts : []), [mission]);
	const components = useMemo(
		() =>
			[
				{
					title: lang.calendar,
					component: mission && (
						<SingleMissionCalendar
							acceptProposal={() => openModal(ModalTypes.ADD_PROPOSAL, proposalsDays)}
							declineProposal={() => openModal(ModalTypes.REMOVE_PROPOSAL, mission.proposals.freelances)}
							cancelAppointment={(appointmentId) => {
								return openModal(
									ModalTypes.REMOVE_APPOINTMENT,
									Object.values(mission.appointments).find(({ id }) => appointmentId === id).days,
								);
							}}
							mission={mission}
							modalOpen={modalOpen}
							isMissionPage
						/>
					),
				},
				// {
				// 	title: lang.confirmationDates,
				// 	component:
				// 		<ReviewConfirmedDates
				// 			dates={appointmentsDays}
				// 			declineConfirmedDate={() => openModal(
				// 				ModalTypes.REMOVE_APPOINTMENT,
				// 				appointmentsDays
				// 			)}
				// 		/>

				// },
				contracts.length > 0 && {
					title: lang.contractList,
					component: <ContractMissionList contracts={contracts} />,
				},
				appointments.length > 0 && {
					title: lang.appointmentList,
					component: <AppointmentTablePrices appointments={appointments} />,
				},
				{
					title: lang.myMissionIssues,
					component: <IssuesByUserMission user={me.id} mission={missionId} />,
				},
				{
					title: lang.studioDetails,
					component: <StudioPublicProfileContainer userId={mission.user} />,
				},
				hasReviewsToShow({ appointments, type: UserTypes.FREELANCE }) && {
					title: lang.review,
					component: <MissionReviews appointments={appointments} />,
				},
			].filter((e) => !!e),
		[mission, lang, me.id, missionId, openModal, proposalsDays, modalOpen, appointments, contracts],
	);

	return (
		<div>
			<MenuNavigationComponent components={components} />
			{modalType === ModalTypes.ADD_PROPOSAL && (
				<ConfirmAddDates
					days={selectedDays}
					open={modalOpen}
					removeDate={removeSelectedDays}
					onCancel={closeModal}
					onSubmit={onSubmit}
					loading={loading}
				/>
			)}
			{ModalTypes.REMOVE_PROPOSAL === modalType && (
				<ConfirmRemoveDatesByFreelances
					open={modalOpen}
					freelances={selectedDays}
					removeDate={removeSelectedDays}
					onCancel={closeModal}
					onSubmit={onSubmit}
					loading={loading}
				/>
			)}
			{ModalTypes.REMOVE_APPOINTMENT === modalType && (
				<ConfirmRemoveDates
					open={modalOpen}
					daysToRemove={selectedDays}
					removeDate={removeSelectedDays}
					onCancel={closeModal}
					onSubmit={onSubmit}
					loading={loading}
				/>
			)}
		</div>
	);
};

export default FreelanceMissionPageComponent;

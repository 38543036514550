export const FR = {
	title: 'Paiement de la mission',
	card: {
		title: 'carte bancaire',
		errorDefault: 'Veuillez entrer votre carte',
		incomplete: 'Carte incomplète.',
		invalid: 'Carte non valide.',

	},
	sepa: {
		title: 'sepa',
		button: 'Payer par prélèvement SEPA',
		authorization: 'En fournissant votre IBAN et en confirmant ce paiement, vous autorisez Styleep SAS et Stripe, notre prestataire de services de paiement, à envoyer des instructions à votre banque pour débiter votre compte conformément à ces instructions. Vous avez droit à un remboursement de votre banque selon les termes et conditions de votre accord avec votre banque. Un remboursement doit être demandé dans les huit semaines à compter de la date de débit de votre compte.',
	},
	success: 'Paiement réussi',
	bankTransfertSentMessage: 'Vous recevrez une notification lorsque le virement aura été reçu, comptez 3 à 5 jours ouvrés.',

	modalTitle: 'Paiement en cours de traitement',
	modalDescription: 'votre paiement est en cours de traitement, vous recevrez un mail lorsque celui-ci sera traité',
	modalButtonText: 'allez vers la page Home',
	registeredCardTitle: 'Choisir une de mes cartes enregistrées',
	registeredCardSubtitle: 'Le bouton de paiement s\'activera lorsque vous sélectionnerez une de vos cartes ci-dessous.',
	newCardTitle: 'Ajouter un nouveau moyen de paiement',
	newCardSubtitle: 'Le bouton de paiement s\'activera lorsque vous indiquerez un moyen de paiement valide',
	saveCardText: 'Je souhaite enregistrer ma carte pour les prochaines commandes',
	buttonPayNewCard: 'Payer avec cette carte',
	buttonPayCard: 'Payer avec la carte',
	buttonTest: 'Passer à l\'étape suivante',
	HT: 'HT',
	TTC: 'TTC',
	bankTransfer: {
		selectionButton: 'Virement bancaire',
		sentBankTransferButton: 'J\'ai effectué le virement bancaire'
	},
	pay: 'Payer',

	transferIndications: {
		copied: 'Copié',
		info: `Enregistrez Styleep dans vos bénéficiaires auprès de votre banque et effectuez votre virement de $1 en indiquant la référence de virement $2 obligatoire.`,
		reference: 'Référence du virement',
		accountPrincipal: 'Compte principal',
		addressTitulaire: 'Adresse du titulaire',
		infosComplementaires: 'Informations complémentaires',
		domiciliation: 'Domiciliation',
		bank: 'Banque',
		guichet: 'Guichet',
		account: 'Compte',
		key: 'Clé',
		lastInfo: 'Une fois votre virement effectué, comptez environ 3 jours ouvrés de traitement pour une banque française, et 5 jours ouvrés pour une banque étrangère.',
	},
	seeInvoiceProFormat: 'Voir la facture pro format',
	unseeInvoiceProFormat: 'Cacher la facture pro format',
	subscribeTitle: "S'abonner à Styleep",
	studioSubscriptionArguments: [
		"Un accès illimité aux profils freelances",
		"Un nombre de création de mission illimité",
		"Des recommandations automatiques lors de la création d'une mission",
		"Un service client accessible et réactif",
	],
	freelanceSubscriptionArguments: [
		"Mon profil recommandé auprès des clients Styleep",
		"Des missions en salon ou à domicile",
		"Un logiciel de gestion de mes rendez-vous en salon ou à domicile",
		"Un système de pré-paiement optionnel",
		"Un service client accessible et réactif",
	],
	uniquePrice: 'Un prix unique : 49€ HT / mois'
}

export const EN = {
	title: 'Mission payment',
	card: {
		title: 'card',
		errorDefault: 'Please enter your card entrer votre carte',
		incomplete: 'Incomplete card.',
		invalid: 'Not valid card.',
	},
	sepa: {
		title: 'Sepa',
		button: 'Pay by SEPA debit',
		authorization: 'By providing your IBAN and confirming this payment, you are authorizing Styleep SAS and Stripe, our payment service provider, to send instructions to your bank to debit your account in accordance with those instructions. You are entitled to a refund from your bank under the terms and conditions of your agreement with your bank. A refund must be claimed within eight weeks starting from the date on which your account was debited.',
	},

	success: 'Success payment',
	bankTransfertSentMessage: 'You\'ll receive an notification when bank transfer will be received by us. Count 3 to 5 open days.',


	modalTitle: 'Payment in process',
	modalDescription: 'Your payment is being processed, you will receive an email when it is processed',
	modalButtonText: 'Go to the Home page',
	registeredCardTitle: 'Use a registered card',
	registeredCardSubtitle: 'The payment button will be actived when you select one of your card',
	newCardTitle: 'Add a new payment method',
	newCardSubtitle: 'The payment button will be actived when you enter a payment method',
	saveCardText: 'I want to save my card to future orders',
	buttonPayNewCard: 'Pay with this card',
	buttonPayCard: 'Pay with the card',
	buttonTest: 'Next step',
	HT: 'excl. taxes',
	TTC: 'taxes incl.',

	bankTransfer: {
		selectionButton: 'Bank transfer',
		sentBankTransferButton: 'I made the bank transfer'
	},
	pay: 'Pay',

	transferIndications: {
		copied: 'Copied',
		info: `Register Styleep in your beneficiaries with your bank and make your $1 transfer by indicating the mandatory $2 transfer reference.`,
		reference: 'Bank transfer reference',
		accountPrincipal: 'Principal account',
		addressTitulaire: 'Owner address',
		infosComplementaires: 'additional information',
		domiciliation: 'address',
		bank: 'Bank',
		guichet: 'Counter',
		account: 'Account',
		key: 'Key',
		lastInfo: 'Once your transfer has been made, allow around 3 working days for processing for a French bank, and 5 working days for a foreign bank.',
	},
	seeInvoiceProFormat: 'See pro format invoice',
	unseeInvoiceProFormat: 'Hide pro format invoice',
	subscribeTitle: "Subscribe to Styleep",
	studioSubscriptionArguments: [
		"Unlimited access to freelance profiles",
		"Unlimited number of mission creation",
		"Automatic recommendations when creating a mission",
		"An accessible and responsive customer service",
	],
	freelanceSubscriptionArguments: [
		"My profile recommended to Styleep customers",
		"Missions in salon or at home",
		"A software to manage my appointments in salon or at home",
		"An optional prepayment system",
		"An accessible and responsive customer service",
	],
	uniquePrice: 'Unique price 49€ HT / month'
}

export default {
	fr: FR,
	us: EN,
}

import AddDates from 'components/AddDates';
import RightSide from 'components/RightSide/RightSide';
import { timestampFromAPIToUnix } from 'modules/dates';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from 'semantic-ui-react';

import toast from 'components/toast';
import StudioInfos from 'components/StudioInfos';
import { getFreelanceForMission, updateWorkingPropositionStatus } from 'containers/marketplace/freelance/helpers';
import { dayStatuses } from 'constants/DayStatuses';
import { Store } from 'store/store';

const StudioSelected = () => {
    const missionId = Number(useParams().missionId)
	const me = useContext(Store).state.user

    const [dates, setDates] = useState([])
    const [studio, setStudio] = useState()
    const [freelance, setFreelance] = useState()
    const [mission, setMission] = useState()
    const matchingResult = useMemo(() => {
        if (freelance && mission) {
            const availabilitiesCount = freelance.availables.length
            const daysCount = mission.days.length
            return availabilitiesCount === daysCount ?
                'Tous les jours de la mission sont possibles avec cette entreprise.'
                : `${availabilitiesCount} sur ${daysCount} jours de mission sont disponibles pour cette entreprise.`
        }
        return ''
    }, [freelance, mission])

    useEffect(() => {
        if (missionId) {
            getFreelanceForMission(me.id, missionId)
                .then(({ mission, studio, freelance }) => {
                    setStudio(studio)
                    setMission(mission)
                    setFreelance(freelance)
                    setDates(
                        freelance
                            .availables
                            .map(({ date }) => date)
                            .sort((a, b) => new Date(a) - new Date(b))
                            .map(timestampFromAPIToUnix))

                })
        }
    }, [missionId])

    const chooseOnly = useCallback((indexInArr) => {
        setDates(dates => dates.filter((d, index) => index == indexInArr))
    }, [setDates])

    const removeDate = useCallback((indexInArr) => {
        setDates(dates => dates.filter((d, index) => index != indexInArr))
    }, [setDates])

    const sendProposal = useCallback(async () => {
        try {
            const availabilitiesUpdated = await updateWorkingPropositionStatus(
                missionId,
                dates,
                dayStatuses.FREELANCE_PROPOSAL,
                me.id
            )
            if (availabilitiesUpdated.length === 0) {
                return toast(`les données ont déjà été actualisées`)
            }
            return toast(`datas saved`)
        } catch (error) {
            return toast(`error`)
        }
    }, [dates, missionId])

    return (
        <RightSide>
            <div >
                <div>{matchingResult}</div>
                <StudioInfos {...studio} />
                <AddDates
                    dates={dates}
                    filterWeekends={false}
                    toggleFilterWeekends={false}
                    chooseOnly={chooseOnly}
                    removeDate={removeDate}
                    cantRemoveIfOne
                />
                {dates.length > 0 && <Button primary onClick={sendProposal}>
                    Envoyer la proposition de collaboration à l'entreprise
                </Button>}
            </div>
        </RightSide>
    );
};

export default StudioSelected;
import classes from './CustomerPublicProfile.module.scss';
import useLanguage from 'hooks/useLanguage';
import Typography from 'components/Typography';
import StarRating from 'components/StarRating/StarRating';
import { getDateFormatted } from 'modules/dates';
import classNames from 'classnames';

const CustomerPublicProfile = ({ img, displayName, phone, address, rating, nRatings, created_at }) => {
	const profileLang = useLanguage('profile');

	return (
		<div className={classes.container}>
			<div className={classes.topContainer}>
				<div
					style={{
						background: `url(${img || '/assets/default-img.png'}`,
					}}
					className={classes.img}
				/>
				<div className={classes.freelanceInfosContainer}>
					<div>
						<div className={classes.inlineBlock}>
							<Typography
								classes={{ root: classNames(classes.name, classes.inlineBlock) }}
								primaryColor
								variant="subTitle2"
							>
								{displayName}
							</Typography>
						</div>
					</div>
				</div>
			</div>
			<div className={classes.bottomContainer}>
				<div className={classNames(classes.padding1, classes.fullWidth, classes.bottomContainerContent)}>
					<div className={classes.badgesContainer}>
						<Typography primaryColor>{address}</Typography>
					</div>
					<Typography primaryColor>{phone}</Typography>
					<Typography italic>
						{profileLang.memberSince} {getDateFormatted(created_at)}
					</Typography>
				</div>
			</div>
		</div>
	);
};
export default CustomerPublicProfile;

import MyTable from 'components/MyTable/MyTable';
import useLanguage from 'hooks/useLanguage';
import { useProfession } from 'hooks/useProfession';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

const ServiceListTable = ({ services = [] }) => {
	const { userProfessions, getProfessionLang } = useProfession();
	const navigate = useNavigate();
	const lang = useLanguage('services');
	const columns = useMemo(
		() =>
			[
				userProfessions.length > 1 && {
					title: lang.columnList.profession,
					render: (props) => getProfessionLang(props.profession),
				},
				{ title: lang.columnList.forfait, render: (props) => (!!props.forfait ? lang.yes : lang.no) },
				{ title: lang.columnList.title, render: (props) => props.title },
				{ title: lang.columnList.description, render: (props) => props.description },
				{
					title: lang.columnList.time,
					render: (props) => (props.slots >= 4 ? props.slots / 4 : props.slots) + ' h',
				},
				{ title: lang.columnList.price, render: (props) => props.price + ' €' },
			].filter((e) => !!e),
		[lang, getProfessionLang, userProfessions],
	);

	const onRowClick = (cellMeta, service) => {
		return navigate(!!service.forfait ? `/service/forfait/${service.id}` : `/service/${service.id}`);
	};

	return <MyTable onRowClick={onRowClick} columns={columns} data={services} options={{ paging: false }} />;
};

export default ServiceListTable;

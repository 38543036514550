import FormComponent from "components/Form/Form";
import classes from "./SearchAddressFields.module.scss";
import { useCallback, useMemo, useState } from "react";
import toast from 'components/toast';
import useLanguage from "hooks/useLanguage";

const SearchAddressFields = ({ initialAddress, onSelect }) => {
	/* Context */
	const language = useLanguage('addressForm')

	/* State */
	const [loading, setLoading] = useState(false);
	const [address, setAddress] = useState(initialAddress);

	const inputs = useMemo(() => [
		{
			type: 'text',
			name: 'address',
			label: language.address,
			placeholder: '1 rue de la paix',
			required: true,
			fluid: true,
			silentRequired: true,
		},
		{
			type: 'text',
			name: 'city',
			label: language.city,
			placeholder: 'Paris',
			required: true,
			fluid: true,
			silentRequired: true,
		},
		{
			type: 'text',
			name: 'country',
			label: language.country,
			placeholder: 'France',
			required: true,
			fluid: true,
			silentRequired: true,
		},
		{
			type: 'number',
			name: 'zipcode',
			label: language.zipcode,
			placeholder: '75000',
			required: true,
			fluid: true,
			silentRequired: true,
		}
	], [language]);

	const onSubmit = useCallback(async (values) => {
		setLoading(true);

		try {
			const locations = await fetch(`https://nominatim.openstreetmap.org/search?
				street=${values.address}&
				city=${values.city}&
				country=${values.country}&
				postalcode=${values.zipcode}&
				format=json&limit=1
			`).then(res => res.json());

			if (!locations.length)
				throw new Error(language.noLocationFound);

			const addressString = `${values.address}, ${values.city}, ${values.zipcode}, ${values.country}`

			setAddress(addressString);
			onSelect({
				...locations[0],
				display_name: addressString,
			});
		} catch(err) {
			toast.error(err.message)
		} finally {
			setLoading(false);
		}
	}, [language, onSelect]);

	const onUpdate = (values) => {
		const addressArray = [
			values.address,
			values.city,
			values.zipcode,
			values.country,
		].filter(value => value != null && value !== '').join(', ');

		setAddress(addressArray);
	}

	return (
		<div className={classes.root}>
			<FormComponent
				inputs={inputs}
				onUpdate={onUpdate}
				sendForm={onSubmit}
				loading={loading}
                responsive={false}
			/>
			{!!address && (
				<p>{address}</p>
			)}
		</div>
	);
};

export default SearchAddressFields;

import RightSide from 'components/RightSide/RightSide';
import { useStoreUtils } from 'hooks/useStoreUtils';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BookkeepingTable from './BookkeepingTable';
import { getAppointments, getPayments } from './helpers';
import TitlePage from 'components/TitlePage';
import useLanguage from 'hooks/useLanguage';
import Typography from 'components/Typography';
import InfoBox from 'components/InfoBox';
import MenuNavigationComponent from 'containers/mission/components/MenuNavigationComponent';

const FreelanceBookkeeping = () => {
	const lang = useLanguage('bookkeeping');
	const {
		state: { user },
	} = useStoreUtils();
	const navigate = useNavigate();
	const { setTableLoadingOn, setTableLoadingOff } = useStoreUtils();
	const [payments, setPayments] = useState([]);
	const [appointments, setAppointments] = useState([]);

	useEffect(() => {
		if (user) {
			if (!user.since || !user.siret || user.periodicity_type == null) {
				navigate('/user/fiscal/update');
			}
		}
	}, [user]);

	const init = useCallback(async () => {
		try {
			const _appointments = await getAppointments();
			const _payments = await getPayments();

			setAppointments(_appointments);
			setPayments(_payments);
		} catch (err) {
			console.log(err);
		}
	}, []);

	useEffect(() => {
		setTableLoadingOn();
		init().finally(setTableLoadingOff);
	}, [init]);

	const components = useMemo(
		() => [
			{
				title: lang.all,
				component: <BookkeepingTable payments={payments} appointments={appointments} tva={!!user.tva_number} />,
			},
			{
				title: lang.missionsWithoutContract,
				component: <BookkeepingTable payments={payments} tva={!!user.tva_number} />,
			},
			{
				title: lang.missionsWithContract,
				component: (
					<BookkeepingTable
						appointments={appointments}
						payments={payments}
						tva={!!user.tva_number}
						onlyAppointments
					/>
				),
			},
		],
		[payments, appointments, user.tva_number],
	);

	return (
		<RightSide>
			<TitlePage
				subtitle={
					<div>
						<Typography variant="subTitle3">{lang.subtitle}</Typography>
						<InfoBox msg={lang.info} />
					</div>
				}
			>
				{lang.title}
			</TitlePage>
			<MenuNavigationComponent components={components} />
		</RightSide>
	);
};

export default FreelanceBookkeeping;

export const FR = {
    addressUpdated: 'Adresse modifiée',
    editMode: "Mode édition",
    saveMyAddress: 'Sauvegarder mon adresse',
    myAddress: 'Mon adresse',
    minimum: 'Minimum',
    chars: 'caractères',
    perimeterSearch: 'Je cherche une mission dans un périmètre de',
    perimeter:'Périmètre',
    address: 'Adresse',
    distance: 'Distance',
	zipcode: 'Code postal',
	city: 'Ville',
	country: 'Pays',
	noLocationFound: 'Aucune adresse trouvée',
}

export const EN = {
    addressUpdated: 'Address updated',
    editMode: "Edit mode",
    saveMyAddress: 'Save my address',
    myAddress: 'My address',
    minimum: 'Minimum',
    chars: 'chars',
    perimeterSearch: 'I search a mission in a perimeter of',
    perimeter:'Perimeter',
    address: 'Address',
    distance: 'Distance',
	zipcode: 'Zipcode',
	city: 'City',
	country: 'Country',
	noLocationFound: 'No location found',
}

export default {
	fr: FR,
	us: EN,
}

import { UserTypes } from 'constants/UserTypes';
import React, { useContext } from 'react';
import { Store } from 'store/store';
import FreelanceMissionActions from './FreelanceMissionActions';
import StudioMissionActions from './StudioMissionActions';
import classes from '../../MissionPage/MissionPage.module.scss'

const MissionActions = ({
    mission,
	...props
}) => {
    const { state: { user } } = useContext(Store)

    return (
        <div className={classes.actionContainer}>
            {user.type === UserTypes.FREELANCE
                ? <FreelanceMissionActions mission={mission} {...props} />
                : <StudioMissionActions mission={mission} {...props} />}
        </div>
    );
};

export default MissionActions;
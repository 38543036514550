import React from 'react';

const ExternalLink = ({
    link,
    text = null
}) => {
    return (
        <a href={link} target='_blank' rel="noreferrer">
        {text || link}
    </a>
    );
};

export default ExternalLink;
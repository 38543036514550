export const FR = {
	titleForm: 'Veuillez indiquer vos informations relatives à votre création de société',
	datasUpdated: 'Données actualisées',
	title: 'Ma comptabilité',
	subtitle: "Cette page vous permet d'avoir accès à votre comptabilité en tant que freelance",
	info: `Basez-vous dessus pour effectuer la déclaration auprès de l'urssaf via <a href="https://www.autoentrepreneur.urssaf.fr">https://www.autoentrepreneur.urssaf.fr</a> <br/>
Il faudra ajouter votre comptabilité externe si vous effectuez des prestations hors styleep.`,
	creationDate: 'Date de création de votre activité',
	declarationType: "J'ai opté pour une déclaration par ",
	declarationTypeChoices: {
		quartely: 'Trimestre',
		monthly: 'Mensuelle',
	},
	contract: 'Facturation',
	mission: 'Mission',
	unableToRetrieveInvoice: 'Impossible de récupérer la facture',
	generateInvoiceLink: 'Générer le lien de la facture',
	sum: 'Somme',
	start_date: 'Date de début de mission',
	end_date: 'Date de fin de mission',
	payment_date: "Date d'émission du paiement",
	tva: 'Suis-je soumis à la TVA ?',
	quarter: 'Trimestre',
	missionsWithoutContract: 'Missions (sans contrat)',
	missionsWithContract: 'Missions (avec contrat)',
	all: 'Toutes les missions',
	quarters: [
		'Tous les trimestres',
		'Premier trimestre',
		'Deuxième trimestre',
		'Troisième trimestre',
		'Quatrième trimestre',
	],
	month: 'Mois',
	months: [
		'Tous les mois',
		'Janvier',
		'Février',
		'Mars',
		'Avril',
		'Mai',
		'Juin',
		'Juillet',
		'Août',
		'Septembre',
		'Octobre',
		'Novembre',
		'Décembre',
	],
	year: 'Année',
	allYears: 'Toutes les années',
	selectYear: 'Choisissez une année pour pouvoir selectionner la période.',
	navigateToInvoice: 'Voir la facture',
	navigateToMission: 'Voir la mission',
	transactionList: 'Liste des transactions',
	periodTotal: 'Total de la période',
	yearTotal: "Total sur l'année",
	percentileMicro: 'Pourcentage du plafond de micro-entreprise',
	topLimit2022WithoutTVA: 'plafond 2022 sans TVA',
	yes: 'Oui',
	no: 'Non',
};

export const EN = {
	titleForm: 'Please provide creation company infos',
	datasUpdated: 'Datas updated',
	title: 'My bookkeeping',
	subtitle: 'This page allows you to have access to your bookkeeping as a freelance',
	info: `Use it to make the declaration to urssaf via <a href="https://www.autoentrepreneur.urssaf.fr">https://www.autoentrepreneur.urssaf.fr</a><br/>
  You will have to add your external accounting if you provide services outside styleep.`,
	creationDate: 'Creation date of your activity',
	declarationType: 'I have choose my declaration period by ',
	declarationTypeChoices: {
		quartely: 'Quarter',
		monthly: 'Month',
	},
	contract: 'Billing',
	mission: 'Mission',
	generateInvoiceLink: 'Generate invoice link',
	sum: 'Sum',
	start_date: 'Date of beginning of mission',
	end_date: 'End date of mission',
	payment_date: 'Date of Bank transfer',
	tva: 'TVA ?',
	missionsWithoutContract: 'Missions (without contrat)',
	missionsWithContract: 'Missions (with contrat)',
	all: 'All',
	quarter: 'Quarter',
	quarters: ['All quarters', 'First quarter', 'Second quarter', 'Third quarter', 'Fourth quarter'],
	month: 'Month',
	months: [
		'All months',
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December',
	],
	year: 'Year',
	allYears: 'All years',
	unableToRetrieveInvoice: 'Unable to retrieve invoice',
	selectYear: 'Please choose a year to select a period.',
	navigateToInvoice: 'See invoice',
	navigateToMission: 'See mission',
	transactionList: 'Transaction list',
	periodTotal: 'Total of period',
	yearTotal: 'Total of year',
	percentileMicro: 'Percentile of top limit micro-entrepreneur status fiscal',
	topLimit2022WithoutTVA: 'top limit 2022 without VAT',
	yes: 'Yes',
	no: 'No',
};

export default {
	fr: FR,
	us: EN,
};

export const FR = {
	success: 'Succès',
	error: 'Echec',
}

export const EN = {
	success: 'Success',
	error: 'Failure',
}

export default {
	fr: FR,
	us: EN,
}
  
export const FR = {
  required: 'Ce champ est requis',
  emptyFields: 'Champs manquants',
  submit: 'Valider',
  file: 'Choisissez un fichier',
  fileError: 'Taille du fichier trop large, veuillez en choisir un autre.',
  fileUpdate: 'Modifier le fichier',
  tooBig: 'Fichier trop volumineux.',
  onPressMessage: 'Pressez la touche Entrer pour valider chaque mot.',
  uploadFile: 'Télécharger',
  error: 'Erreur',
  success: 'Succès',
  nothingUpdated: 'Rien n\'a été actualisé dans le formulaire.',
  invalidEmail: 'Email invalide',
  search: 'Rechercher',
}

export const EN = {
  required: 'Field required',
  emptyFields: 'Empty fields',
  submit: 'Submit',
  file: 'Choose File',
  fileError: 'Size of file too large, please choose another one.',
  fileUpdate: 'Update file',
  tooBig: 'File too big.',
  onPressMessage: 'Press Enter to store each word',
  uploadFile: 'Upload File',
  error: 'Error',
  success: 'Success',
  nothingUpdated: 'Nothing updated into form',
  invalidEmail: 'Invalid email',
  search: 'Search',
}

export default {
  fr: FR,
  us: EN,
}

import { useState } from 'react'
import useWindowSize from './useWindowSize'

function useIsMobile() {
	const [isMobile, setIsMobile] = useState(false)
	const window = useWindowSize()

	if (window.width) {
		if (window.width <= 779 && !isMobile) {
			setIsMobile(true)
		} else if (window.width > 779 && isMobile) {
			setIsMobile(false)
		}
	}

	return isMobile
}

export default useIsMobile
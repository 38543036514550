import useLanguage from 'hooks/useLanguage'
import ExternalScheduler from 'components/ExternalScheduler'
import { updateApi } from 'containers/helpers'
import { getAllUnrelatedMeetings_api, getMeetingsByElement_api } from 'containers/meeting/helpers'
import { useCallback } from 'react'
import toast from 'components/toast'
import { statuses as IssueStatuses } from '../issue.enum'

function ScheduleMeetingIssue({
	id,
	updateIssue = () => { }
}) {
	const langToast = useLanguage('toast')
	const onChangeMeeting = useCallback(async (meetingId) => {
		try {
			await updateApi('/issues', {
				ids: [id],
				meeting: meetingId,
				status: meetingId
					? IssueStatuses.MEETING_BOOKED
					: IssueStatuses.WAITING_MEETING,
			})
			updateIssue({
				meeting: meetingId,
				status: meetingId
					? IssueStatuses.MEETING_BOOKED
					: IssueStatuses.WAITING_MEETING,
			})
			toast.success(langToast.success)
		} catch (error) {
			toast.error(langToast.error)
		}
	}, [id, langToast, updateIssue])

	return (
		<ExternalScheduler
			elementId={id}
			onChangeMeeting={onChangeMeeting}
			getBlockedTimes={() => getAllUnrelatedMeetings_api(id)}
			getScheduledTimes={() => getMeetingsByElement_api(id)}
		/>
	)
}

export default ScheduleMeetingIssue

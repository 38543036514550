export const FreelanceTypes = {
    BEGINNER: 0,
    MIDDLE: 1,
    CONFIRMED: 2,
    EXPERT: 3,
    STAR: 4
}

export const LevelTypes = {
    1: FreelanceTypes.BEGINNER,
    2: FreelanceTypes.BEGINNER,
    3: FreelanceTypes.MIDDLE,
    4: FreelanceTypes.MIDDLE,
    5: FreelanceTypes.CONFIRMED,
    6: FreelanceTypes.CONFIRMED,
    7: FreelanceTypes.CONFIRMED,
    8: FreelanceTypes.EXPERT,
    9: FreelanceTypes.EXPERT,
    10: FreelanceTypes.STAR
}

import { useCallback, useMemo, useState } from 'react'
import ModalComponent from 'components/Modal'
import FormComponent from 'components/Form/Form'
import { updateFreelance } from '../helpers'
import { useStoreUtils } from 'hooks/useStoreUtils'
import { toDateInput } from 'modules/dates'
import { getOnlyUpdatedValues, objectToFormData } from 'containers/helpers'
import toast from 'components/toast'
import { isEmpty } from 'lodash'

function FreelanceInfoForm({ /*setLoading = () => { },*/ onSuccess = () => { }, onError = () => { } }) {
	const [loading, setLoading] = useState(false)
	const { state, updateLocalUser } = useStoreUtils()
	const [modal, setModal] = useState(false)
	const [error, setError] = useState('')
	const {
		language: { onboard: copy, form: formLang }, user, professionsReceived: professionsReceivedFromState, languageValue
	} = state
	const professionsToAutoComplete = useMemo(() => professionsReceivedFromState.map(p => ({ ...p, title: p[languageValue] })),
		[languageValue, professionsReceivedFromState])
	const knowByChoices = useMemo(() => copy.knowByChoices[user.type], [copy, user])

	const inputs = useMemo(() => [
		{
			type: 'text',
			name: 'company_name',
			value: user.company_name,
			placeholder: copy.companyName,
			label: copy.companyName,
			required: true,
			silentRequired: true,
			fluid: true,
		},
		{
			type: 'image',
			name: 'image',
			label: copy.image,
			value: user.image,
			preview: true,
			required: true,
			silentRequired: true,
			fluid: true,
		},
		{
			type: 'date',
			name: 'since',
			value: user.since ? toDateInput(user.since) : '',
			fluid: true,
			placeholder: copy.creationDate,
			required: true,
			silentRequired: true,
			label: copy.creationDate,
		},
		{
			type: 'phone',
			name: 'phone',
			value: user.phone,
			placeholder: copy.phone,
			label: copy.phone,
			required: true,
			silentRequired: true,
			fluid: true,
		},
		{
			type: 'autocomplete',
			name: 'professions',
			placeholder: copy.professions,
			label: copy.professions,
			value: user.professions || null,
			required: true,
			silentRequired: true,
			fluid: true,
			options: professionsToAutoComplete,
			info: copy.professionsInfo,
			multi: true,
			idForExtern: true,
		},
		{
			type: 'autocomplete',
			name: 'degree',
			value: user.degree || null,
			fluid: true,
			options: copy.degrees,
			placeholder: copy.diploma,
			label: copy.diploma,
			multi: true,
		},
		{
			type: 'autocompleteAdd',
			name: 'know_by',
			placeholder: copy.knowBy,
			label: copy.knowBy,
			value: user.know_by,
			required: true,
			silentRequired: true,
			fluid: true,
			options: knowByChoices,
			info: copy.knowByChoicesInfo
		},
	].filter(e => !!e), [copy, user, knowByChoices, professionsToAutoComplete])

	const sendForm = useCallback(async (values) => {
		setLoading(true)
		try {
			const updatedValues = getOnlyUpdatedValues(user, values)
			if (isEmpty(updatedValues)) {
				setLoading(false)
				toast(formLang.nothingUpdated)
				return
			}
			if (updatedValues.company_name) {
				updatedValues.legal_name = updatedValues.company_name
			}
			const freelance = await updateFreelance(objectToFormData(updatedValues), user.type_id)
			setLoading(false)
			updateLocalUser(freelance)
			toast(formLang.success)
		} catch (e) {
			setLoading(false)
			console.error(e)
			setError(e.message)
			setModal(true)
			toast(formLang.error + ': ' + error.message)
		}
	}, [error, formLang, updateLocalUser, user])

	const closeErrorModal = () => {
		setError('')
		setModal(false)
	}

	return (
		<>
			<FormComponent
				inputs={inputs}
				sendForm={sendForm}
				centered={true}
				responsive={false}
				segment={false}
				loading={loading}
			/>
			<ModalComponent
				onClose={closeErrorModal}
				open={modal}
				title={error}
			/>
		</>
	)
}

export default FreelanceInfoForm

export const FR = {
	title: 'Paramètres',
	mailUnSubscribeSubject: 'Je veux me désabonner de la plateforme Styleep',
	mailRemoveSubject: 'Je veux supprimer mon compte de la plateforme Styleep',
	mailUnSubscribeBody: (phoneNumber, email, fullname) => `Cher(e) équipe de Styleep,%0A%0AJe souhaite officiellement me désabonner de la plateforme Styleep. Veuillez prendre les mesures nécessaires pour supprimer mon compte. Merci de confirmer la réception de cette demande.%0A%0ACordialement,%0A${phoneNumber}%0A${email}%0A${fullname}`,
	mailRemoveBody: (phoneNumber, email, fullname) => `Cher(e) équipe de Styleep,%0A%0AJe souhaite officiellement me désinscrire de la plateforme Styleep. Veuillez prendre les mesures nécessaires pour supprimer mon compte. Merci de confirmer la réception de cette demande.%0A%0ACordialement,%0A${phoneNumber}%0A${email}%0A${fullname}`,
}

export const EN = {
	title: 'Settings',
	mailUnSubscribeSubject:'Unsubscribe from Styleep Platform',
	mailRemoveSubject:'Remove my account from Styleep Platform',
	mailUnSubscribeBody:'Dear Styleep Team,%0A%0AI would like to formally request my removal from the Styleep platform. Please take the necessary steps to delete my account. Kindly confirm the receipt of this request.%0A%Sincerely,%0A',
	mailRemoveBody:'Dear Styleep Team,%0A%0AI would like to formally request my removal from the Styleep platform. Please take the necessary steps to delete my account. Kindly confirm the receipt of this request.%0A%Sincerely,%0A',
}

export default {
	fr: FR,
	us: EN,
}
  
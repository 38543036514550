import { fetchApi } from 'containers/helpers'
import { UserTypes } from 'constants/UserTypes'
import { FreelanceIssueTypes, StudioIssueTypes, types as issueTypes } from './issue.enum'

export const getIssueTypes = (type) => {
    const types = type === UserTypes.FREELANCE ? FreelanceIssueTypes : StudioIssueTypes
    return types
}
export const createIssue = ({ type, title, mission, description, contract, step }) => fetchApi(`/issue`, {
    method: 'POST',
    body: {
        type,
        title,
        description,
        ...(mission ? {mission}: {}),
        ...(contract ? {contract} : {}),
        ...(step ? {step} : {}),
    },
})

export const issueTypeName = {
    levelUp: issueTypes.UPDATE_LEVEL,
    contract: issueTypes.CONTRACT,
    mission: issueTypes.MISSION,
    onboard: issueTypes.ONBOARD,
    other: issueTypes.OTHER
}

import { useEffect, useContext, useState, useCallback } from 'react';
import PaymentComponent from 'containers/payment/Pay/PaymentComponent';
import { Store } from 'store/store';
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements, useStripe, useElements, IbanElement } from '@stripe/react-stripe-js';
import { getCustomerCards } from 'containers/payment/Pay/helpers';
import { stripePublicKey } from 'helpers';
import { useNavigate } from 'react-router-dom';
import toast from 'components/toast';
import useLanguage from 'hooks/useLanguage';
import StyledModal from 'components/StyledModal';
import { upperFirst } from 'lodash';
import { socket } from 'socket';
import Didactiel from 'components/Didactiel';
import { subscribeApi } from './helpers';
import { onboardingStatuses } from 'containers/onboard/constants/OnboardingStatuses';
import { useStoreUtils } from 'hooks/useStoreUtils';

const SubscriptionPayementComponent = ({ onSuccessUrl = '/', price = '49.99' }) => {
	const { updateLocalUser } = useStoreUtils();
	const navigate = useNavigate();
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const { state, dispatch } = useContext(Store);
	const [cards, setCards] = useState([]);
	const [card, setCard] = useState();
	const [loading, setLoading] = useState(false);
	const [errorPayment, setErrorPayment] = useState(null);
	const stripe = useStripe();
	const elements = useElements();
	const [paymentType, setPaymentType] = useState('card');
	const [sepa, setSepa] = useState(false);
	const [modal, setModal] = useState(false);
	const { stripe: stripeSocket, socketConnected } = state;
	const lang = useLanguage('pay');

	const getCards = async () => {
		try {
			const cards = await getCustomerCards();
			return cards;
		} catch (e) {
			console.log(e.message);
			return [];
		}
	};

	const success = useCallback(() => {
		toast(lang.success);
		navigate(onSuccessUrl);
	}, [lang.success, navigate, onSuccessUrl]);

	useEffect(() => {
		if (socketConnected && socket && stripeSocket) {
			if (stripeSocket.status === 'success') {
				setLoading(false);
				updateLocalUser({
					...state.user,
					status: onboardingStatuses.ACTIVE,
				});
				success();
				return dispatch({ type: 'STRIPE/RESET' });
			} else if (stripeSocket.status === 'not payed') {
				setErrorPayment(stripeSocket.message);
				setLoading(false);
				return dispatch({ type: 'STRIPE/RESET' });
			}
		}
	}, [stripeSocket, socketConnected, dispatch, success, state, updateLocalUser]);

	useEffect(() => {
		getCards()
			.then((data) => {
				setCards(data.cards);
				dispatch({ type: 'LOADING_OFF' });
			})
			.catch((e) => {
				console.log('e', e);
				//  navigate('/home')
			});
	}, [dispatch]);

	const [error, setError] = useState(lang.card.errorDefault);

	const onChange = ({ complete, error }) => {
		if (!complete) {
			if (paymentType === 'sepa_debit') {
				setSepa(true);
			}
			return setError(lang.card.incomplete);
		} else if (error) {
			setSepa(false);
			return setError(lang.card.invalid);
		}
		setSepa(true);
		setError(null);
		process.env.NODE_ENV !== 'production' && console.log(error);
	};

	const onFocus = () => {
		setCard(null);
	};

	//paiement avec une carte enregistrée
	const payWithRegisteredCard = useCallback(
		async (event) => {
			event.preventDefault();
			if (!stripe || !elements) {
				return;
			}
			setLoading(true);
			try {
				const { clientSecret } = await subscribeApi(card.id, process.env.REACT_APP_PREMIUM_PRICE_ID);
				if (clientSecret) {
					const { error } = await stripe.confirmCardPayment(clientSecret, {
						payment_method: card.id,
					});
					if (error) {
						setErrorPayment(error.message);
						setLoading(false);
					}
				}
			} catch (e) {
				process.env.NODE_ENV !== 'production' && console.log('error:', e.message);
				setErrorPayment(e.message);
				setLoading(false);
			}
		},
		[elements, stripe, card],
	);

	//paiement avec l'élément stripe
	const payWithElement = useCallback(
		async (event) => {
			setCard(null);
			event.preventDefault();
			if (!stripe || !elements) {
				return;
			}

			try {
				setLoading(true);
				const cardElement =
					paymentType === 'card' ? elements.getElement(CardElement) : elements.getElement(IbanElement);
				const { error, paymentMethod } = await stripe.createPaymentMethod({
					type: paymentType,
					billing_details: {
						name: `${firstName} ${lastName}`,
						email: 'klack@test.io',
					},
					...(paymentType === 'card' ? { card: cardElement } : { sepa_debit: cardElement }),
				});
				if (error) return setError(error.message);

				const { clientSecret } = await subscribeApi(paymentMethod.id, process.env.REACT_APP_PREMIUM_PRICE_ID);

				if (clientSecret) {
					const { error } = await stripe.confirmCardPayment(clientSecret, {
						payment_method: paymentMethod.id,
					});

					if (error) {
						setErrorPayment(error.message);
						setLoading(false);
					}
				}
			} catch (e) {
				process.env.NODE_ENV !== 'production' && console.log('error:', e.message);
				setErrorPayment(e.message);
				setLoading(false);
			}
		},
		[elements, firstName, lastName, paymentType, stripe],
	);

	const changePaymentType = (type) => {
		setPaymentType(type);
		setCard();
	};
	const redirectToHome = () => navigate('/');

	return (
		<>
			{paymentType === 'bankTransfer' && <Didactiel didactielType={'bank_transfer'} />}
			<PaymentComponent
				setFirstName={setFirstName}
				setLastName={setLastName}
				payWithElement={payWithElement}
				subscriptionPaymentLanguage={state.language.subscriptionPayment}
				card={card}
				setCard={setCard}
				cards={cards}
				disabled={!stripe || !elements}
				error={error}
				onChange={onChange}
				onFocus={onFocus}
				payWithRegisteredCard={payWithRegisteredCard}
				loading={loading}
				price={{
					price: price,
					currency: '€',
				}}
				errorPayment={errorPayment}
				campaign={state.campaign}
				paymentType={paymentType}
				changePaymentType={changePaymentType}
				sepa={sepa}
				setSepa={setSepa}
				starterPayLanguage={state.language.starterPay}
				//   reference={contract.reference}
				//   invoicePF={contract.studio_invoice_pf}
			/>
			<StyledModal
				onClose={redirectToHome}
				open={modal}
				title={lang.modalTitle}
				onClick={redirectToHome}
				button={lang.modalButtonText}
			>
				{upperFirst(lang.modalDescription)}
			</StyledModal>
		</>
	);
};

const stripePromise = loadStripe(stripePublicKey);
const Wrapper = (props) => {
	return (
		<Elements stripe={stripePromise}>
			<SubscriptionPayementComponent {...props} />
		</Elements>
	);
};

export default Wrapper;

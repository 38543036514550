import React, { useCallback, useMemo, useState } from 'react'
import SignatureCanvas from 'react-signature-canvas'
import classes from './CreateSvg.module.scss'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { IconButton, Tooltip } from '@material-ui/core'
import toast from 'components/toast'
import useLanguage from 'hooks/useLanguage'
import useIsMobile from 'hooks/useIsMobile'
import Typography from './Typography'
import FileTypes from 'constants/FileTypes'
import CreateCursive from './CreateCursive'
import html2canvas from 'html2canvas'
import Button from './Button'

const CreateSvgFormDocument = React.forwardRef(({
    type,
    title = '',
    onFinish = () => { },
}, ref) => {
    const [isSignatureCursive, setIsSignatureCursive] = useState(false)
    const isCanvas = useMemo(() => {
        switch (type) {
            case FileTypes.SIGNATURE:
                return true
            case FileTypes.INITIALS:
            case FileTypes.APPROVED:
                return false
            default:
                return false
        }
    }, [type])
    const [loading, setLoading] = useState(false)
    const isMobile = useIsMobile()
    const lang = useLanguage('contract')
    const clear = useCallback(() => {
        ref.current.clear()
    }, [ref])

    const submit = useCallback(async () => {
        try {
            setLoading(true)
            const element = ref.current;
            let data
            if (!isCanvas || isSignatureCursive) {
                if (type === FileTypes.INITIALS) {
                    if (element.innerText == null
                        || element.innerText === ''
                        || element.innerText === lang.fileText[FileTypes.INITIALS]) {
                        return toast.error(lang.youCannotRegisterEmptyElement)
                    }
                }
                if (type === FileTypes.SIGNATURE) {
                    if (element.innerText == null
                        || element.innerText === ''
                        || element.innerText === lang.fileText[FileTypes.SIGNATURE]) {
                        return toast.error(lang.youCannotRegisterEmptyElement)
                    }
                }
                const canvas = await html2canvas(element);
                data = canvas.toDataURL('image/png');
                return onFinish(data)
            }

            if (element.isEmpty()) {
                return toast.error(lang.youCannotRegisterEmptyElement)
            }
            await onFinish(element.toDataURL())
        } catch (error) {
            console.log('error', error)
        } finally {
            setLoading(false)
        }

    }, [lang, ref, onFinish, isCanvas, type, isSignatureCursive])
    const langText = useMemo(() => lang.fileText[type], [type, lang])

    const [inputValue, setInputValue] = useState('')

    const onInputValueChange = (newValue) => {
        setInputValue(newValue)
    }
    const inputIsEmpty = useMemo(() =>
        (!isCanvas || isSignatureCursive) &&
        type !== FileTypes.APPROVED &&
        (inputValue == null || inputValue === ''), [inputValue, type, isCanvas])

    return (
        <div>
            <Typography variant='subTitle2'>{title}</Typography>
            <div>
                {isCanvas && !isSignatureCursive ? <SignatureCanvas
                    ref={ref}
                    canvasProps={{
                        width: isMobile ? 400 : 500,
                        height: 200,
                        className: classes.shadow
                    }}
                /> :
                    <CreateCursive
                        onChange={onInputValueChange}
                        ref={ref}
                        text={langText}
                        input={type === FileTypes.INITIALS || type === FileTypes.SIGNATURE}
                    />
                }
            </div>
            <div className={classes.mt1} >
                {isCanvas && <Button
                    onClick={() => setIsSignatureCursive(isSignatureCursive => !isSignatureCursive)}
                    className={classes.readAndApproved}
                >
                    {isSignatureCursive ? lang.drawSignature : lang.writeInCursive}
                </Button>}
                {isCanvas &&
                    <Tooltip title={lang.clearCanvas}>
                        <IconButton onClick={clear} >
                            <HighlightOffIcon />
                        </IconButton>
                    </Tooltip>}
                <Button
                    disabled={inputIsEmpty}
                    type='success'
                    onClick={submit}
                    loading={loading}>
                    {lang.buttonIts[type]}
                </Button>
            </div>
        </div>
    )
})

export default CreateSvgFormDocument
import classNames from 'classnames'
import Typography from 'components/Typography'
import { UserTypes } from 'constants/UserTypes'
import useLanguage from 'hooks/useLanguage'
import { useContext, useMemo } from 'react'
import { Store } from 'store/store'
import classes from './LevelBar.module.scss'

function LevelBar({
	level,
	displayLevel,
	fullWidth,
	className = '',
	type,
	typeLang,
}: {
	level: number,
	displayLevel: Boolean,
	fullWidth: Boolean,
	type: number,
	typeLang: String
}) {
	const lang = useLanguage('profile')
	const wrapper = useMemo(() => <div className={
		classNames(
			classes.wrapper,
			fullWidth && classes.fullWidth
		)
	} >
		<div className={classNames(
			classes.bar,
			classes['type' + type],
		)} />
	</div>, [type, fullWidth])
	const userType = (useContext(Store).state.user || { type: 1 }).type
	return displayLevel ? <div className={classNames(classes.inlineBlock, classes.fitContent, className)}>
		<div className={classes.taCenter}>
			<Typography primaryColor>{lang.level} {userType === UserTypes.FREELANCE ? `${level} ` : ''}<Typography primaryColor fontWeight={800}>{typeLang}</Typography></Typography>
		</div>
		{wrapper}
	</div> : wrapper
}

export default LevelBar

import { Navigate, useLocation } from 'react-router-dom';
import { isFalse, isLoggedIn /* , isAccessible */ } from '../containers/helpers';
import { isOnboardedUser, isUnsubscribedUser } from 'containers/onboard/helpers';
import { UserTypes } from 'constants/UserTypes';
import { useStoreUtils } from 'hooks/useStoreUtils';

const AuthRouteTypped = ({ children, type: routeUserType }) => {
	const location = useLocation();
	const {
		isPremium,
		state: {
			user: { status, type },
		},
	} = useStoreUtils();
	if (!isLoggedIn()) {
		localStorage.setItem('path', JSON.stringify(location));
	}

	const returnByUserType = isFalse(routeUserType) || type === routeUserType ? children : <Navigate replace to="/" />;
	if (isLoggedIn() /* && isAccessible(exceptAccessibility) */) {
		if (isUnsubscribedUser({ status, type })) {
			if (!location.pathname.includes('subscription')) {
				return <Navigate replace to="/subscription" />;
			}
			return returnByUserType;
		} else if (isOnboardedUser({ status, type })) {
			if (location.pathname.includes('/onboarding')) {
				return <Navigate replace to="/" />;
			} else if (location.pathname.includes('/subscription')) {
				if (type === UserTypes.STUDIO || isPremium) {
					return <Navigate replace to="/" />;
				}
				return returnByUserType;
			}
			return returnByUserType;
		} else if (location.pathname.includes('/onboarding')) {
			if (location.pathname !== '/onboarding') {
				return <Navigate replace to="/onboarding" />;
			}
			return returnByUserType;
		} else if (location.pathname.includes('/issue')) {
			return returnByUserType;
		}
		return <Navigate replace to="/onboarding" />;
	}
	return <Navigate replace to="/login" />;
};

export const AuthRoute = ({ children }) => <AuthRouteTypped>{children}</AuthRouteTypped>;

export const FreelanceRoute = ({ children }) => (
	<AuthRouteTypped type={UserTypes.FREELANCE}>{children}</AuthRouteTypped>
);

export const StudioRoute = ({ children }) => <AuthRouteTypped type={UserTypes.STUDIO}>{children}</AuthRouteTypped>;

export const UnAuthRoute = ({ children }) => (!isLoggedIn() ? children : <Navigate replace to={{ pathname: '/' }} />);

export const DefaultRoute = () => <Navigate to={isLoggedIn() ? '/' : 'login'} />;

export const FR = {
	subscription: 'Abonnement',
	onboard: 'Démarrage',
	missions: 'Mes missions',
	calendar: 'Calendrier',
	profile_studio: 'Mon Entreprise',
	profile_freelance: 'Mon profil',
	bookkeeping: 'Ma compta',
	settings: 'Réglages',
	notifications: 'Notifications',
	services: 'Ma boutique',
	issues: 'Mes demandes',
	signOut: 'Déconnexion'
	
}

export const EN = {
	subscription: 'Subscription',
	onboard: 'Onboard',
	missions: 'My missions',
	calendar: 'Calendar',
	profile_studio: 'My Studio',
	profile_freelance: 'My Profile',
	bookkeeping: 'My bookkeeping',
	settings: 'Settings',
	notifications: 'Notifications',
	services: 'My Store',
	issues: 'Issues',
	signOut: 'Sign Out'
}
  
export default {
	fr: FR,
	us: EN,
}
  
import { useState, useEffect, useCallback, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { getAppointmentReviews, getQuestions, submitReviews } from 'containers/review/helpers';
import ReviewForm from './components/ReviewForm';
import { useNavigate } from 'react-router-dom';
import Loader from 'components/Loader';
import toast from 'components/toast';
import { UserTypes } from 'constants/UserTypes';
import RightSide from 'components/RightSide/RightSide';
import TitlePage from 'components/TitlePage';
import useLanguage from 'hooks/useLanguage';
import { isEmpty, isInteger } from 'lodash';
import { Store } from 'store/store';

function CreateReviewPage() {
	const lang = useLanguage('review');
	const appointmentId = Number(useParams().appointmentId);
	const navigate = useNavigate();
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);
	const [questions, setQuestions] = useState(null);
	const { type } = useContext(Store).state.user;

	const init = useCallback(async () => {
		try {
			const questions = await getQuestions();
			const currentReviews = await getAppointmentReviews(appointmentId);
			if (currentReviews?.reviewsILeft?.length > 0) {
				setError(true);
				toast.error(lang.alreadyDone);
				return navigate(-1);
			}
			setQuestions(questions);
			setLoading(false);
		} catch (e) {
			setError(true);
			toast.error(e.message);
			setLoading(false);
		}
	}, [appointmentId, lang.alreadyDone, navigate]);

	useEffect(() => {
		init();
	}, [appointmentId]);

	const onSubmitReview = useCallback(
		async (reviewsFromParams) => {
			const reviews = Object.entries(reviewsFromParams).reduce((prev, [key, value]) => {
				if (!isInteger(value) && isEmpty(value)) return prev;

				return {
					...prev,
					[key]: isInteger(value) ? value + 1 : value,
				};
			}, {});

			setLoading(true);
			try {
				await submitReviews({ appointmentId, reviews });
				setLoading(false);
				toast.success(lang.reviewSent);
				navigate(-1);
			} catch (e) {
				toast.error(e.message);
				setError(e);
				setLoading(false);
			}
		},
		[lang, appointmentId, navigate],
	);

	return (
		<RightSide>
			<TitlePage>
				{type === UserTypes.FREELANCE ? lang.leaveReviewForStudio : lang.leaveReviewForFreelance}
			</TitlePage>
			{loading ? <Loader /> : error ? <div /> : <ReviewForm questions={questions} onSubmit={onSubmitReview} />}
		</RightSide>
	);
}

export default CreateReviewPage;

export const FR = {
	with: 'avec',
	seeStudioDetails: 'Voir les détails de l\'entreprise',
	studioDetails: 'Détails de l\'entreprise',
	myInvoice: 'Ma facture',
	ofStudio: 'de l\'entreprise',
	accessToMissionPage: 'Accéder à la page de la mission',
	confirmed: 'Confirmée',
	mission: 'mission',
	missionList: 'Mes missions',
	columns: {
		id: 'ID',
		title: 'Titre',
		description: 'Description',
		dates: 'Dates',
		dayCount: 'Nombre de jours',
		status: 'Statut',
		contract: 'Contrat',
		actions: 'Actions',
		contractSignedCount: 'Contrats signés',
	},
	bookedDays: 'jours planifiés',
	to: 'au',
	days: 'jours',
	day: 'journée',

	seeContract: 'Voir le contrat',
	signContract: 'Signer le contract',
	seeContractToSign: 'Signer le contrat avec $1',
	payMission: 'Payer la mission pour la démarrer',
	payMissionWith: 'Payer la mission avec $1 pour la démarrer',
	accessContract: 'Accéder au contrat',
	accessContractWith: 'Accéder au contrat avec $1',
	createContractWith: `Créer le contrat avec $1 pour démarrer la mission`,
	infoCreateContract: `En créant le contrat vous annulez les propositions faites aux autres freelances sur ces dates`,
	askToUnlockFunds: `Demander à débloquer les fonds`,
	verifyContractConformity: `Notre équipe est entrain de vérifier la conformité du contrat.`,
	automaticBankTransferThen: `Vos fonds vous serons virés automatiquement dès que sa conformité aura été validé.`,
	contractCreated: 'Contrat créé',

	acceptAll: 'Accepter toutes les dates',
	declineAll: 'Décliner toutes les dates',
	reviewDates: 'Passer en revue les dates proposées',
	acceptDates: 'Accepter les dates proposées',
	declineDates: 'Décliner les dates proposées',
	actionAccept: `Cette action acceptera $1 dates`,
	actionDecline: `Cette action déclinera $1 dates`,
	cancel: 'Annuler',
	youHaveDecline: 'Vous avez décliné la proposition de mission',
	datesAccepted: `Vous avez accepté $1 dates`,
	dateAccepted: `Accepté`,
	dateDeclined: `Refusé`,
	delete: 'Supprimer',
	addAgain: 'Ajouter à nouveau',

	calendar: 'Calendrier',
	confirmationDates: 'Mes dates confirmées',
	myMissionIssues: 'Mes demandes',
	review: 'Avis',
	contractList: 'Liste des contrats',
	appointmentList: 'Total des rémunérations',
	appointmentListStudio: 'Total des paiements',
	proposalList: 'Mes propositions envoyées',

	removeMission: 'Supprimer la mission',
	accessProfiles: 'Accéder aux profils recommandés pour cette mission',
	updateMission: 'Modifier la mission',
	updateMissionDates: 'Modifier les dates',

	cancelAppointment: 'Annuler mon rendez-vous',

	cancelMyProposal: 'Annuler ma proposition',
	refuseThisProposal: 'Refuser cette proposition',
	visitFreelanceProfile: 'Visiter le profil du freelance',
	acceptFreelanceProposal: 'Accepter la proposition du freelance',
	declineFreelanceProposal: 'Décliner la proposition du freelance',
	acceptStudioProposal: 'Accepter la proposition de l\'entreprise',
	declineStudioProposal: 'Décliner la proposition de l\'entreprise',
	showStudioProposal: 'Voir les propositions de l\'entreprise',

	openDaysCount: (daysCount) => `${daysCount} jour${daysCount > 1 ? 's' : '' } ouvert${daysCount > 1 ? 's' : '' }`,
	appointmentsCount: (appointmentCount) => `${appointmentCount} rendez-vous`,
	proposalsCount: (proposalsCount) => `${proposalsCount} proposition${proposalsCount > 1 ? 's' : '' }`,

	proposalCanceled: (proposalsLength) => `${proposalsLength > 1 ? proposalsLength + ' p' : 'P' }roposition${proposalsLength > 1 ? 's' : '' } supprimée${proposalsLength > 1 ? 's' : '' }!`,
	unableToDeleteProposals: (proposalsLength) => `Unable to delete proposal${proposalsLength > 1 ? 's' : '' }`,
	proposalAccepted: (proposalsLength) => `${proposalsLength > 1 ? proposalsLength + ' p' : 'P' }roposition${proposalsLength > 1 ? 's' : '' } acceptée${proposalsLength > 1 ? 's' : '' }!`,
	unableToAcceptProposals: (proposalsLength) => `Unable to accept proposal${proposalsLength > 1 ? 's' : '' }`,
	appointmentCanceled: (daysLength) => `${daysLength > 1 ? daysLength + ' d' : 'D' }ate${daysLength > 1 ? 's' : '' } annulée${daysLength > 1 ? 's' : '' }!`,
	unableToCancelAppointment: 'Impossible d\'annuler le rendez-vous',
	noAccess: 'Vous n\'avez plus accès à cette mission',
	missionContractFreelanceLink: 'Accéder à la page de mission avec ce freelance',
	myCalendar: 'Mon calendrier',
	myContract: 'Mon contrat',
	createMission: 'Créer une nouvelle mission',
	unGetMissionsOnly: 'Montrer que les missions non-prises',
	create: {
		newMissionCreated: `Nouvelle mission créée!`,
		missionModified: ({ nDatesDeleted, nDatesAdded }) =>
			`Mission modifiée! (${nDatesDeleted === 1 ? `1 date supprimée` : `${nDatesDeleted} dates supprimées`}, ${nDatesAdded === 1 ? '1 date ajoutée' : `${nDatesAdded} dates ajoutées`})`,
		titleAndDescriptionModified: `Titre et description modifiés!`,
		noDetails: `Vous n'avez pas fourni de détails sur votre nouvelle mission. Veuillez cliquer sur "retour" ci-dessus pour les ajouter.`,
		noDates: `Vous n'avez pas fourni de dates pour votre nouvelle mission. Veuillez ajouter des dates dessus.`,
		modify: 'Modifier',
		create: 'Créer',
		modifyDates: 'Modifier les dates',
		createNewMission: 'Créer une nouvelle mission',
		confirmDeleteAllDates: `Confirmer la suppression des toutes les dates`,
		cancel: 'Annuler',
		delete: `Supprimer`,
		thisWillDelete: (nDates) => `Cela supprimera ${nDates === 1 ? `1 date` : `${nDates} dates`}`
	},
	slots: (startDate, endDate) => `de ${startDate} à ${endDate}`,
}

export const EN = {
	with: 'with',
	signContract: 'Sign contract',
	seeStudioDetails: 'See studio details',
	studioDetails: 'Studio details',
	myInvoice: 'My invoice',
	ofStudio: 'of studio',
	accessToMissionPage: 'Access to mission page',
	mission: 'mission',
	missionList: 'My missions',
	columns: {
		id: 'ID',
		title: 'Title',
		description: 'Description',
		dates: 'Dates',
		dayCount: 'Number of days',
		status: 'Status',
		contract: 'Contract',
		actions: 'Actions',
		contractSignedCount: 'Contacts signed',
	},
	bookedDays: 'days booked',
	to: 'to',
	days: 'days',
	day: 'day',
	seeContract: 'See contract',
	seeContractToSign: 'See contract with $1 to sign',
	payMission: 'Pay mission to start',
	payMissionWith: 'Pay mission with $1 to start',
	accessContract: 'Access to contract to start mission',
	accessContractWith: 'Access to contract  with $1',
	createContractWith: `Create contract with $1 to start mission`,
	infoCreateContract: `In creating contract, you decline all proposals sent to other freelances at theses dates`,
	askToUnlockFunds: `Ask to unlock my funds`,
	verifyContractConformity: `Our team is reviewing contract conformity.`,
	automaticBankTransferThen: `Your funds will be send automaticaly as conformity of contract validated.`,
	contractCreated: 'Contract created',
	acceptAll: 'Accept all dates',
	declineAll: 'Decline all dates',
	reviewDates: 'Review all dates proposals',
	acceptDates: 'Accept proposal dates',
	declineDates: 'Decline proposal dates',
	actionAccept: `This action will accept $1 dates`,
	actionDecline: `This action will decline $1 dates`,
	cancel: 'Cancel',
	youHaveDecline: 'You have declined mission proposal',
	datesAccepted: `You have accepted $1 dates`,
	dateAccepted: `Accepted`,
	dateDeclined: `Declined`,
	delete: 'Delete',
	addAgain: 'Add again',
	calendar: 'Calendar',
	confirmationDates: 'My confirm dates',
	myMissionIssues: 'My issues',
	review: 'Reviews',
	contractList: 'Contract list',
	appointmentList: 'Total of amount',
	appointmentListStudio: 'Total of paids',
	proposalList: 'My proposals sent',

	openDaysCount: (daysCount) => `${daysCount} open day${daysCount > 1 ? 's' : '' }`,
	appointmentsCount: (appointmentCount) => `${appointmentCount} appointment${appointmentCount > 1 ? 's' : '' }`,
	proposalsCount: (proposalsCount) => `${proposalsCount} proposal${proposalsCount > 1 ? 's' : '' }`,

	cancelMyProposal: 'Cancel my proposal',
	refuseThisProposal: 'Refuse this proposal',
	cancelAppointment: 'Cancel my appointment',
	visitFreelanceProfile: 'Visit freelance profile',
	acceptFreelanceProposal: 'Accept freelance proposal',
	declineFreelanceProposal: 'Decline freelance proposal',
	acceptStudioProposal: 'Accept studio proposal',
	declineStudioProposal: 'Decline studio proposal',
	showStudioProposal: 'Show studio proposal',
	confirmed: 'Confirmed',
	missionContractFreelanceLink: 'Access to the mission page with this freelance',
	myCalendar: 'My calendar',
	myContract: 'My contract',
	createMission: 'Create new mission',
	unGetMissionsOnly: 'Show only not booked missions',

	proposalCanceled: (proposalsLength) => `${proposalsLength > 1 ? proposalsLength + ' p' : 'P' }roposal${proposalsLength > 1 ? 's' : '' } canceled!`,
	unableToDeleteProposals: (proposalsLength) => `Impossible de supprimer ${proposalsLength > 1 ? 'les' : 'la' } proposition${proposalsLength > 1 ? 's' : '' }`,
	proposalAccepted: (proposalsLength) => `${proposalsLength > 1 ? proposalsLength + ' p' : 'P' }roposal${proposalsLength > 1 ? 's' : '' } accepted!`,
	unableToAcceptProposals: (proposalsLength) => `Unable to accept ${proposalsLength > 1 ? 'proposals' : 'proposal' }`,
	appointmentCanceled: (daysLength) => `${daysLength > 1 ? daysLength + ' d' : 'D' }ay${daysLength > 1 ? 's' : '' } canceled!`,
	unableToCancelAppointment: 'Unable to cancel appointment',
	noAccess: 'You have no access to this mission',

	create: {
		newMissionCreated: 'New mission created!',
		missionModified: ({ nDatesDeleted, nDatesAdded }) =>
			`Mission modified! (${nDatesDeleted === 1 ? '1 date deleted' : `${nDatesDeleted} dates deleted`}, ${nDatesAdded === 1 ? '1 date added' : `${nDatesAdded} dates added)`})`,
		titleAndDescriptionModified: `Title and description modified!`,
		noDetails: `You have not provided details of your new mission. Please click "back" above to add them.`,
		noDates: `You have not provided any dates for your new missioonos. Please add at least one.`,
		modify: 'Modify',
		create: 'Create',
		modifyDates: 'Modify dates',
		createNewMission: 'Create a new mission',
		confirmDeleteAllDates: `Confirm deleting all dates`,
		cancel: 'Cancel',
		delete: `Delete`,
		thisWillDelete: (nDates) => `This will delete ${nDates === 1 ? `1 date` : `${nDates} dates`}`

	},
	slots: (startDate, endDate) => `from ${startDate} to ${endDate}`,
}

export default {
	fr: FR,
	us: EN,
}

import { Route, Routes } from 'react-router-dom';
import CreateMission from 'containers/mission/CreateMission';
import { AuthRoute, FreelanceRoute, StudioRoute } from 'components/GuardRoutes';
import LogGrid from 'components/LogGrid/LogGrid';
import Onboard from 'containers/onboard/Onboarding';
import Profile from 'containers/profile/Profile';
import CalendarPage from 'containers/calendar/CalendarPage';
import MissionPage from 'containers/mission/MissionPage';
import FreelanceMarketplace from 'containers/marketplace/freelance/layouts/FreelanceMarketplace/FreelanceMarketplace';
import FreelanceSelected from 'containers/marketplace/freelance/FreelanceSelected/FreelanceSelected';
import MissionsPage from 'containers/mission/MissionsPage/MissionsPage';
import StudioSelected from 'containers/marketplace/studio/StudioSelected/StudioSelected';
import CreateReviewPage from 'containers/review/CreateReviewPage';
import PaymentPage from 'containers/payment/Pay/PaymentPage';
import FreelanceBookkeeping from 'containers/bookkeeping/FreelanceBookkeeping/FreelanceBookkeeping';
import FiscalInfosFreelanceUpdate from 'containers/bookkeeping/FiscalInfosFreelanceUpdate/FiscalInfosFreelanceUpdate';
import NotificationPage from 'containers/notification/NotificationPage/NotificationPage';
import LevelUpMeeting from 'containers/level/LevelUpMeeting/LevelUpMeeting';
// import FAQ from 'containers/faq/FAQ'
import CreateIssue from 'containers/issue/CreateIssue';
import IssuePage from 'containers/issue/IssuePage';
import IssuesByUser from 'containers/issue/components/IssuesByUser';
import UserUpdateInfos from 'containers/profile/UserUpdateInfos/UserUpdateInfos';
import ContractDates from 'containers/contract/ContractDates/ContractDates';
import GodsonsPage from 'containers/profile/godsons/GodsonsPage/GodsonsPage';
import MissionContractPage from 'containers/mission/MissionContractPage';
import Settings from 'containers/settings/Settings';
import StudioPage from 'containers/studio/StudioPage';
import SubscriptionPayment from 'containers/payment/SubscriptionPayment';
import SubscribePage from 'containers/subscription/SubscribePage/SubscribePage';
import MyServices from 'containers/service/MyServices/MyServices';
import InvoicesPage from 'containers/invoices/Invoices/InvoicesPage';
import AppointmentPage from 'containers/appointment/AppointmentPage';
import LatestOpenInvoicePage from 'containers/invoices/LatestOpenInvoicePage/LatestOpenInvoicePage';

const ConnectedRoutes = () => (
	<LogGrid>
		<Routes>
			<Route
				path="/notifications"
				element={
					<AuthRoute>
						<NotificationPage />
					</AuthRoute>
				}
			/>
			<Route
				path="/onboarding"
				element={
					<AuthRoute>
						<Onboard />
					</AuthRoute>
				}
			/>
			<Route
				path="/calendar"
				element={
					<AuthRoute>
						<CalendarPage />
					</AuthRoute>
				}
			/>
			<Route
				path="/profile"
				element={
					<AuthRoute>
						<Profile />
					</AuthRoute>
				}
			/>
			<Route
				path="/settings"
				element={
					<AuthRoute>
						<Settings />
					</AuthRoute>
				}
			/>
			<Route
				path="/godsons"
				element={
					<AuthRoute>
						<GodsonsPage />
					</AuthRoute>
				}
			/>
			<Route
				path="/missions"
				element={
					<AuthRoute>
						<MissionsPage />
					</AuthRoute>
				}
			/>
			<Route
				path="/mission/marketplace/:missionId/freelance/:userId"
				element={
					<StudioRoute>
						<FreelanceSelected />
					</StudioRoute>
				}
			/>
			<Route
				path="/mission/marketplace/:missionId/studio"
				element={
					<FreelanceRoute>
						<StudioSelected />
					</FreelanceRoute>
				}
			/>
			<Route
				path="/mission/marketplace/:missionId"
				element={
					<StudioRoute>
						<FreelanceMarketplace />
					</StudioRoute>
				}
			/>
			<Route
				path="/mission/:missionId/contract/:contractId"
				element={
					<AuthRoute>
						<MissionContractPage />
					</AuthRoute>
				}
			/>
			<Route
				path="/mission/page/:missionId"
				element={
					<AuthRoute>
						<MissionPage />
					</AuthRoute>
				}
			/>
			<Route
				path="/appointment/:appointmentId"
				element={
					<AuthRoute>
						<AppointmentPage />
					</AuthRoute>
				}
			/>
			<Route
				path="/contract/:contractId/payment"
				element={
					<AuthRoute>
						<PaymentPage />
					</AuthRoute>
				}
			/>
			<Route
				path="/review/create/:appointmentId"
				element={
					<AuthRoute>
						<CreateReviewPage />
					</AuthRoute>
				}
			/>
			<Route
				//mode = create or edit
				//step = details or dates (0, 1)
				path="/mission/:mode/:step"
				element={
					<StudioRoute>
						<CreateMission />
					</StudioRoute>
				}
			/>
			<Route
				//mode = edit
				//step = details or dates (0, 1)
				path="/mission/:mode/:step/:missionId"
				element={
					<AuthRoute>
						<CreateMission />
					</AuthRoute>
				}
			/>
			<Route
				path="/bookkeeping"
				element={
					<FreelanceRoute>
						<FreelanceBookkeeping />
					</FreelanceRoute>
				}
			/>
			<Route
				path="/user/update/:toUpdate"
				element={
					<AuthRoute>
						<UserUpdateInfos />
					</AuthRoute>
				}
			/>
			<Route
				path="/user/update"
				element={
					<AuthRoute>
						<UserUpdateInfos />
					</AuthRoute>
				}
			/>
			<Route
				path="/studio/:userId"
				element={
					<AuthRoute>
						<StudioPage />
					</AuthRoute>
				}
			/>
			<Route
				path="/user/fiscal/update"
				element={
					<FreelanceRoute>
						<FiscalInfosFreelanceUpdate />
					</FreelanceRoute>
				}
			/>
			<Route
				path="/levelup/meeting"
				element={
					<FreelanceRoute>
						<LevelUpMeeting />
					</FreelanceRoute>
				}
			/>
			{/* <Route
				path='/faq'
				element={
					<AuthRoute>
						<FAQ />
					</AuthRoute>
				}
			/> */}
			<Route
				path="/issues"
				element={
					<AuthRoute>
						<IssuesByUser />
					</AuthRoute>
				}
			/>
			<Route
				path="/issue/:id"
				element={
					<AuthRoute>
						<IssuePage />
					</AuthRoute>
				}
			/>
			<Route
				path="/issue/:id/:action"
				element={
					<AuthRoute>
						<IssuePage />
					</AuthRoute>
				}
			/>
			<Route
				path="/issue/create/:issueType/:elemId"
				element={
					<AuthRoute>
						<CreateIssue />
					</AuthRoute>
				}
			/>
			<Route
				path="/issue/create/:issueType"
				element={
					<AuthRoute>
						<CreateIssue />
					</AuthRoute>
				}
			/>
			<Route
				path="/issue/create"
				element={
					<AuthRoute>
						<CreateIssue />
					</AuthRoute>
				}
			/>
			<Route
				path="/contract/dates/:contractId"
				element={
					<AuthRoute>
						<ContractDates />
					</AuthRoute>
				}
			/>
			<Route
				path="/subscription"
				element={
					<AuthRoute>
						<SubscribePage />
					</AuthRoute>
				}
			/>
			<Route
				path="/pay/subscription"
				element={
					<AuthRoute>
						<SubscriptionPayment />
					</AuthRoute>
				}
			/>
			<Route
				path="/invoices"
				element={
					<AuthRoute>
						<InvoicesPage />
					</AuthRoute>
				}
			/>
			<Route
				path="/invoice/past_due"
				element={
					<AuthRoute>
						<LatestOpenInvoicePage />
					</AuthRoute>
				}
			/>
			<Route
				path="/services"
				element={
					<FreelanceRoute>
						<MyServices />
					</FreelanceRoute>
				}
			/>
			<Route
				path="/service/create"
				element={
					<FreelanceRoute>
						<MyServices />
					</FreelanceRoute>
				}
			/>
			<Route
				path="/service/forfait/create"
				element={
					<FreelanceRoute>
						<MyServices />
					</FreelanceRoute>
				}
			/>
			<Route
				path="/service/forfait/update/:forfaitId"
				element={
					<FreelanceRoute>
						<MyServices />
					</FreelanceRoute>
				}
			/>
			<Route
				path="/service/forfait/:forfaitId"
				element={
					<FreelanceRoute>
						<MyServices />
					</FreelanceRoute>
				}
			/>
			<Route
				path="/service/update/:serviceId"
				element={
					<FreelanceRoute>
						<MyServices />
					</FreelanceRoute>
				}
			/>
			<Route
				path="/service/:serviceId"
				element={
					<FreelanceRoute>
						<MyServices />
					</FreelanceRoute>
				}
			/>
			<Route
				index
				path="*"
				element={
					<AuthRoute>
						<CalendarPage />
					</AuthRoute>
				}
			/>
		</Routes>
	</LogGrid>
);

export default ConnectedRoutes;

import { daysStatus } from 'modules/dates';
import classes from './TimeSlots.module.scss';
import externalClasses from 'styles/classes.module.scss';
import cn from 'classnames';
import Typography from 'components/Typography';
import useLanguage from 'hooks/useLanguage';

const DaysStatus = ({ hidden = false, date, update, dayStatus, defaultDayStatus, noneDaysStatus = false }) => {
	const lang = useLanguage('calendar');
	const timeSlotsArr = [...Object.keys(daysStatus), noneDaysStatus ? 'none' : null].filter((e) => !!e);

	return (
		!hidden && (
			<div className={classes.menuContainer}>
				{timeSlotsArr.map((key) => (
					<div
						key={key}
						onClick={() => update(key)}
						className={cn(
							externalClasses.centeredContainer,
							classes.itemContainer,
							key === dayStatus && classes.active,
							key === 'none' && classes.notHere,
							key === defaultDayStatus && classes.defaultDayStatus,
						)}
					>
						<Typography classes={{ root: classes.item }}>{lang[key]}</Typography>
					</div>
				))}
			</div>
		)
	);
};

export default DaysStatus;

import BinaryModal from 'components/BinaryModal'
import useLanguage from 'hooks/useLanguage'
import AddDatesByFreelances from './AddDatesByFreelances'

const ConfirmRemoveDatesByFreelances = ({
	open,
	freelances,
	removeDate,
	onSubmit,
	onCancel,
	loading = false
}) => {
	const lang = useLanguage('scheduler').confirmRemoveDates

	return (
		<BinaryModal
			loading={loading}
			open={open}
			header={lang.confirmRemoveDates}
			description={
				<AddDatesByFreelances
                    freelances={freelances}
                    removeDate={removeDate}
                />
			}
			onClose={onCancel}
			cancelText={lang.cancel}
			actionText={lang.delete}
			onAction={onSubmit}
			onCancel={onCancel}
		/>
	)
}

export default ConfirmRemoveDatesByFreelances

import { dayStatuses } from 'constants/DayStatuses';
import { UserTypes } from 'constants/UserTypes';
import { useMemo, useContext, memo } from 'react';
import Button from 'components/Button';
import ReviewButton from 'containers/review/components/ReviewButton';
import { Store } from 'store/store';
import UnlockFundsButton from '../MissionPage/UnlockFundsButton';
import classes from '../MissionPage/MissionPage.module.scss';
import useLanguage from 'hooks/useLanguage';
import Typography from 'components/Typography';
import { useDayStatusesLang } from 'hooks/useDayStatusesLang';
import { PrepaymentButton } from './PrepaymentListButton/PrepaymentListButton';

const ContractButton = ({
	missionId,
	dates, //[123214,4214214,24124124,2144214]
	contractId = null,
	status,
	user: userId,
	firstname,
	lastname,
	displayName,
	company_name,
	isMissionPage = false,
	appointment,
	studio_name: studioName,
	price,
	contractDays,
	proposals,
	cancelAppointment = false,
}) => {
	const dayStatusesLang = useDayStatusesLang();
	const lang = useLanguage('mission');
	const {
		state: {
			user: { type },
		},
	} = useContext(Store);
	const isFreelance = type === UserTypes.FREELANCE;

	const NavigateToContractButton = memo(() => (
		<Button type="black" link={`/mission/${missionId}/contract/${contractId}?nav=1`}>
			{isFreelance ? lang.accessContract : lang.accessContractWith.replace('$1', displayName)}
		</Button>
	));

	const daysRangeText = useMemo(() => {
		if (!contractDays) return '';
		const daysKeys = Object.keys(contractDays).sort();
		return `- ${daysKeys[0]} ${daysKeys.length > 1 ? lang.to + ' ' + daysKeys[daysKeys.length - 1] : ''}`;
	}, [contractDays, lang]);

	const freelanceButtonFromStatus = useMemo(() => {
		const actionButton = () => {
			switch (status) {
				case dayStatuses.CONFIRMED:
					if (!contractId) {
						return (
							<>
								<PrepaymentButton
									{...{
										status,
										daysRangeText,
										studio_name: studioName,
										id: appointment,
										contract: contractId,
										price,
									}}
								/>
								{!!cancelAppointment && <Button type="danger" onClick={() => cancelAppointment(appointment)}>
									{lang.cancelAppointment} {daysRangeText}
								</Button>}
							</>
						);
					}
					return null;
				case dayStatuses.WAITING_ALL_CONTRACTS:
				case dayStatuses.WAITING_FREELANCE_CONTRACT:
					return (
						<>
							<Button link={`/mission/${missionId}/contract/${contractId}?nav=1`}>
								{lang.signContract} {daysRangeText}
							</Button>
							<PrepaymentButton
								daysRangeText={daysRangeText}
								id={appointment}
								studio_name={studioName}
								contract={contractId}
								status={status}
								price={price}
							/>
						</>
					);
				case dayStatuses.WAITING_STUDIO_CONTRACT:
					return (
						<PrepaymentButton
							daysRangeText={daysRangeText}
							id={appointment}
							studio_name={studioName}
							contract={contractId}
							status={status}
							price={price}
						/>
					);
				case dayStatuses.WAITING_FUNDS:
					return null;
				case dayStatuses.WAITING_TEAM_FUNDS_RECEIVED:
				case dayStatuses.BOOKED:
					return null;
				case dayStatuses.WAITING_FREELANCE_DAYS_CONFIRMATION:
					return (
						<Button link={`/contract/dates/${contractId}`}>
							{dayStatusesLang[dayStatuses.WAITING_FREELANCE_DAYS_CONFIRMATION]}
						</Button>
					);
				case dayStatuses.WAITING_STUDIO_DAYS_CONFIRMATION:
					return null;
				case dayStatuses.WAITING_REVIEW:
				case dayStatuses.WAITING_FREELANCE_REVIEW:
					return <ReviewButton appointment={appointment} status={status} type={type} />;
				case dayStatuses.WAITING_STUDIO_REVIEW:
					return null;
				case dayStatuses.REVIEWED_DAYS:
					return <UnlockFundsButton contractId={contractId} />;
				case dayStatuses.WAITING_UNLOCK_FUNDS:
					return (
						<div>
							<Typography primaryColor>
								{lang.verifyContractConformity}
								<br />
								{lang.automaticBankTransferThen}
							</Typography>
						</div>
					);
				case dayStatuses.CANCELLED:
				case dayStatuses.FINISHED:
					return null;
				default:
					return null;
			}
		};

		const contractPageButton = (className) => {
			if (!status || status < dayStatuses.WAITING_STUDIO_CONTRACT || !!!contractId) {
				return null;
			}
			return isMissionPage ? <NavigateToContractButton /> : null;
		};
		const actionButtonComponent = actionButton();
		return (
			<div className={classes.actionContainer}>
				{actionButtonComponent}
				{isMissionPage && actionButtonComponent != null
					? null
					: contractPageButton(actionButtonComponent ? classes.fitContent : '')}
			</div>
		);
	}, [isMissionPage, status, contractId, missionId, lang, daysRangeText, appointment, studioName, price, dayStatusesLang, type, cancelAppointment]);

	const studioButtonFromStatus = useMemo(() => {
		const actionButton = () => {
			switch (status) {
				case dayStatuses.CONFIRMED:
					return (
						!!cancelAppointment && <Button type="danger" onClick={() => cancelAppointment(appointment)}>
							{lang.cancelAppointment} {daysRangeText}
						</Button>
					);
				case dayStatuses.WAITING_ALL_CONTRACTS:
				case dayStatuses.WAITING_STUDIO_CONTRACT:
					return (
						<Button type="primary" link={`/mission/${missionId}/contract/${contractId}?nav=1`}>
							{isMissionPage ? lang.seeContractToSign.replace('$1', displayName) : lang.signContract}{' '}
							{daysRangeText}
						</Button>
					);
				case dayStatuses.WAITING_FREELANCE_CONTRACT:
					return null;
				case dayStatuses.WAITING_FUNDS:
					return (
						<Button link={'/contract/' + contractId + '/payment'}>
							{isMissionPage ? lang.payMissionWith.replace('$1', displayName) : lang.payMission}
						</Button>
					);
				case dayStatuses.WAITING_TEAM_FUNDS_RECEIVED:
				case dayStatuses.BOOKED:
					return null;
				case dayStatuses.WAITING_STUDIO_DAYS_CONFIRMATION:
					return (
						<Button link={`/contract/dates/${contractId}`}>
							{dayStatusesLang[dayStatuses.WAITING_STUDIO_DAYS_CONFIRMATION]}
						</Button>
					);
				case dayStatuses.WAITING_FREELANCE_DAYS_CONFIRMATION:
					return null;
				case dayStatuses.WAITING_REVIEW:
				case dayStatuses.WAITING_STUDIO_REVIEW:
					return <ReviewButton appointment={appointment} status={status} type={type} />;
				case dayStatuses.WAITING_FREELANCE_REVIEW:
					return null;
				case dayStatuses.WAITING_UNLOCK_FUNDS:
				case dayStatuses.CANCELLED:
				case dayStatuses.FINISHED:
					return null;
				default:
					return null;
			}
		};

		const contractPageButton = (className) => {
			if (!!!contractId) {
				return null;
			}
			switch (status) {
				case dayStatuses.CONFIRMED:
				case dayStatuses.WAITING_ALL_CONTRACTS:
				case dayStatuses.WAITING_STUDIO_CONTRACT:
					return null;
				default:
					return isMissionPage ? <NavigateToContractButton /> : false;
			}
		};
		const actionButtonComponent = actionButton();
		return (
			<div className={classes.actionContainer}>
				{actionButtonComponent}
				{isMissionPage && actionButtonComponent != null
					? null
					: contractPageButton(actionButtonComponent ? classes.fitContent : '')}
			</div>
		);
	}, [lang, displayName, contractId, status, dayStatusesLang, type, missionId, isMissionPage, appointment, cancelAppointment, daysRangeText]);
	return isFreelance ? freelanceButtonFromStatus : studioButtonFromStatus;
};

export default ContractButton;

import { useEffect, useState } from 'react';
import { getBadges, isLoggedIn } from 'containers/helpers';
import { getEnums, getUsersConnected } from 'containers/helpers';
import { socket, reloadSocket, removeEvent } from 'socket';
import { useStoreUtils } from 'hooks/useStoreUtils';
import useNotification from 'hooks/useNotification';
import { useSockets } from 'hooks/useSockets';
import LogGridComponent from './LogGridComponent';

const LogGrid = ({ children }) => {
	// si pas de header au final justea enlever les grid row
	const { dispatch, reloadUser } = useStoreUtils();

	const { receiveNotification, initNotification } = useNotification();
	const { socketIsConnected, socketConnected } = useSockets();
	const dispatchUsers = (users) =>
		dispatch({
			type: 'INIT_USERS',
			payload: users,
		});

	const initSockets = () => {
		getUsersConnected().then(dispatchUsers);

		socket.on('update user connected list', (users) => {
			dispatchUsers(users);
		});

		socket.on('connect', () => {
			socketIsConnected();
			console.log('SOCKET CONNECT');
			console.log('socket.id :>> ', socket.id);
		});

		socket.on('delete notification', (notificationId) => {
			dispatch({ type: 'REMOVE_NOTIFICATION', payload: notificationId });
		});
		socket.on('notification', (notification) => {
			receiveNotification(notification);
		});
		socket.on('new mail', (mail) => {
			dispatch({ type: 'ADD_MAIL', payload: mail.mail });
		});
		socket.on('subscriptionPayment', (data) => {
			dispatch({ type: 'STRIPE/PAYMENT', payload: { ...data } });
		});
		socket.on('singlePayment', (data) => {
			dispatch({ type: 'STRIPE/PAYMENT', payload: { ...data } });
		});
	};

	const initBadges = async () => {
		const badges = await getBadges();
		dispatch({
			type: 'INIT_BADGES',
			payload: badges,
		});
	};

	useEffect(() => {
		if (socketConnected && socket) {
			socket.on('user updated', () => {
				reloadUser();
			});
		}
		return () => {
			if (socketConnected && socket) {
				removeEvent('user updated');
			}
		};
	}, [socketConnected, reloadUser]);
	const [loading, setLoading] = useState(false);
	const initLogIn = async () => {
		try {
			await reloadUser();
			reloadSocket();
			initSockets();
			initNotification();
			getEnums().then((enums) =>
				dispatch({
					type: 'CHARGE_ENUMS',
					payload: enums,
				}),
			);
			initBadges();
		} catch (error) {
			console.log('error', error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (isLoggedIn()) {
			initLogIn();
		}
	}, []);

	return (
		<LogGridComponent>
			{/* {loading ? <Loader /> : children} */}
			{children}
		</LogGridComponent>
	);
};

export default LogGrid;

import { UserTypes } from 'constants/UserTypes';
import React, { useContext } from 'react';
import { Store } from 'store/store';
const DisplayAddressComponent = ({
    user = {}
}) => {
    const {state:{ language: {addressForm: addressFormLang}}} = useContext(Store)
    return (
        <div>
            <div>{addressFormLang.address} : {user.address}</div>
            {user.type === UserTypes.FREELANCE && <div>{addressFormLang.distance} : {user.radius} Kms</div>}
            {/* <div>Latitude : {user.lat}</div>
            <div>Longitude : {user.lon}</div> */}
        </div>
    );
};

export default DisplayAddressComponent;
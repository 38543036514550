import FormComponent from 'components/Form/Form';
import { objectToFormData } from 'containers/helpers';
import { updateStudio } from 'containers/onboard/helpers';
import useLanguage from 'hooks/useLanguage';
import { useStoreUtils } from 'hooks/useStoreUtils';
import { debounce } from 'lodash';
import React, { useCallback, useMemo } from 'react';

const FavoriteStudioSlots = ({ withoutLabels = false }) => {
	const lang = useLanguage('createMission');
	const {
		state: {
			user: { favorites_slots = [], id: userId },
		},
		updateLocalUser,
	} = useStoreUtils();

	const inputs = useMemo(
		() => [
			{
				type: 'time',
				name: 'start',
				value: favorites_slots[0] || '09:00',
				...(!withoutLabels && { label: lang.startDate }),
			},
			{
				type: 'time',
				name: 'end',
				value: favorites_slots[1] || '19:00',
				...(!withoutLabels && { label: lang.endDate }),
			},
		],
		[favorites_slots, lang, withoutLabels],
	);

	const updateFavoriteSlots = useCallback(
		debounce(async ({ start, end }) => {
			const newStudio = { favorites_slots: [start, end] };
			try {
				await updateStudio(objectToFormData(newStudio), userId);
				updateLocalUser(newStudio);
			} catch (error) {
				console.log('error', error);
			}
		}, 500),
		[updateLocalUser, userId],
	);

	return (
		<div>
			<FormComponent sideInputs inputs={inputs} button={false} onUpdate={updateFavoriteSlots} responsive={true} />
		</div>
	);
};

export default FavoriteStudioSlots;

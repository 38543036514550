import { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import ModalComponent from 'components/Modal';
import UnLogGrid from 'components/UnLogGrid/UnLogGrid';
import { useStoreUtils } from 'hooks/useStoreUtils';
import { createFreelance, createStudio } from './helpers';

function SignUp() {
	const { userType } = useParams();
	const { state, loginInApp } = useStoreUtils();
	const [modal, setModal] = useState(false);
	const [error, setError] = useState('');
	const navigate = useNavigate();
	const [googleSignUp, setGoogleSignUp] = useState(false);
	const {
		language: { login: copy },
	} = state;

	const inputs = useMemo(
		() =>
			[
				{
					type: 'text',
					name: 'firstname',
					placeholder: copy.firstname,
					label: copy.firstname,
					required: true,
					silentRequired: true,
					fluid: true,
				},
				{
					type: 'text',
					name: 'lastname',
					placeholder: copy.lastname,
					label: copy.lastname,
					required: true,
					silentRequired: true,
					fluid: true,
				},
				userType === 'studio' && {
					type: 'text',
					name: 'studio_name',
					placeholder: copy.studioName,
					label: copy.studioName,
					required: true,
					silentRequired: true,
					fluid: true,
				},
				{
					type: 'email',
					name: 'email',
					placeholder: copy.mail,
					label: copy.mail,
					required: true,
					silentRequired: true,
					fluid: true,
				},
				{
					type: 'phone',
					name: 'phone',
					placeholder: copy.phone,
					label: copy.phone,
					required: true,
					silentRequired: true,
					fluid: true,
				},
				{
					type: 'password',
					name: 'password',
					placeholder: copy.password,
					label: copy.password,
					required: true,
					silentRequired: true,
					fluid: true,
					legend: 'minimum 5 chars',
					signUp: true,
				},
				// userType === 'freelance' && {
				//     type: 'text',
				//     name: 'godfather',
				//     placeholder: copy.godfather,
				//     label: copy.godfather,
				//     fluid: true,
				// },
				{
					type: 'checkbox',
					name: 'userAgreement',
					label: (
						<label>
							{copy.termsAndConditionsAgreement}
							<a href="https://pro.styleep.com/conditions-generales" target="_blank" rel="noreferrer">
								{copy.termsAndConditions}
							</a>
						</label>
					),
					placeholder: copy.userAgreementPlaceholder,
					labelRight: true,
					required: true,
				},
				userType === 'freelance' && {
					type: 'checkbox',
					name: 'userAgreement',
					label: (
						<label>
							{copy.termsAndConditionsAgreement}
							<a href="https://pro.styleep.com/mandat-de-facturation" target="_blank" rel="noreferrer">
								{copy.invoiceMandate}
							</a>
						</label>
					),
					placeholder: copy.userInvoiceMandatePlaceholder,
					labelRight: true,
					required: true,
				},
			].filter((e) => !!e),
		[copy, userType],
	);

	const getCreationFunction = () => {
		switch (userType) {
			case 'freelance':
				return createFreelance;
			case 'studio':
				return createStudio;
			default:
				return async () => {
					throw new Error(copy.errorUserType);
				};
		}
	};

	const sendForm = async (values) => {
		try {
			const tokens = await getCreationFunction()(values);
			loginInApp(tokens);
			return;
		} catch (e) {
			setError(e.message);
			setModal(true);
		}
	};

	const goToLogin = () => navigate('/login');

	const goToUserTypePage = (userType) => navigate(`/signup/${userType}`);

	const closeErrorModal = () => {
		setError('');
		setModal(false);
	};

	return (
		<>
			<UnLogGrid
				inputs={inputs}
				sendForm={sendForm}
				page="signup"
				submitMessage={copy.validUp}
				goToUserTypePage={goToUserTypePage}
			/>
			<ModalComponent
				onClose={error !== '' ? closeErrorModal : goToLogin}
				open={modal}
				title={error !== '' ? copy.errorTitle : copy.signupModalTitle}
				description={error !== '' ? error : googleSignUp ? copy.googleAccountText : copy.signupModalTextSuccess}
				onClick={error !== '' ? closeErrorModal : goToLogin}
				button={error !== '' ? copy.signupModalButtonError : copy.signupModalButtonSuccess}
			/>
		</>
	);
}

export default SignUp;

import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import RadiusInput from 'components/RadiusInput';
import toast from 'components/toast';
import { FormControlLabel, Switch } from '@material-ui/core';
import DisplayAddressComponent from './DisplayAddressComponent';
import { updateFreelance, updateStudio } from '../helpers';
import Loader from 'components/Loader';
import { isEmpty, pick } from 'lodash';
import { Store } from 'store/store';
import { getOnlyUpdatedValues, objectToFormData } from 'containers/helpers';
import Button from 'components/Button/Button';
import classes from '../Onboarding/Onboarding.module.scss'
import { useTestMode } from 'hooks/useTestMode';
import { useStoreUtils } from 'hooks/useStoreUtils';
import SearchAddressFields from 'components/SearchAddressFields/SearchAddressFields';
import { UserTypes } from 'constants/UserTypes';

const AddAddress = ({
    setLoading: setLoadingFromParent = () => { }
}) => {
    const { updateLocalUser } = useStoreUtils()
    const { state: { user, language: { addressForm: addressFormLang, form: formLang } } } = useContext(Store)
    const [address, setAddress] = useState(user && user.address ? pick(user, ['address', 'lat', 'lon']) : null)

    useEffect(() => {
        if (user) {
            if (!!!user.address && !!address) {
                return
            }
            setAddress(user && user.address ? pick(user, ['address', 'lat', 'lon']) : null)
        }
    }, [user]);

    const [radius, setRadius] = useState(user.radius || null)
    const [loading, setLoading] = useState(false)
    
	useEffect(() => {
        setLoadingFromParent(loading)
    }, [loading]);

    const updateAddress = (newAddress) => {
		setAddress(newAddress)
	}
    const updateRadius = (newRadius) => setRadius(newRadius)
    const active = useMemo(() => {
        return !!address && !!(user.type === UserTypes.FREELANCE ? radius : true)
    }, [user.type, address, radius])
    const isAlreadySetUp = useMemo(() => !!user.address, [user])
    const [updateMode, setUpdateMode] = useState(!isAlreadySetUp)

    useEffect(() => {
        if (isAlreadySetUp) {
            setUpdateMode(false)
        }
    }, [isAlreadySetUp])

    const getAPIFunc = useMemo(() => user.type === UserTypes.FREELANCE ? updateFreelance : updateStudio, [user])

    const updateAddressClick = useCallback(async () => {
        setLoading(true)
        try {
            const values = {
                ...pick(address, ['lat', 'lon']),
                address: address.display_name,
                radius
            }
            const updatedValues = getOnlyUpdatedValues(user, values)

            if (isEmpty(updatedValues)) {
                toast(formLang.nothingUpdated)
                return
            }
            const newUser = await getAPIFunc(objectToFormData(updatedValues), user.type_id)
            setUpdateMode(false)
            toast(addressFormLang.addressUpdated)
            updateLocalUser(newUser)
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false)
        }
    }, [user, address, radius, getAPIFunc, addressFormLang, formLang, updateLocalUser])

    const { isTestMode } = useTestMode()

    const sendFakeAddress = useCallback(async () => {
        if (!isTestMode) {
            return
        }
        setLoading(true)
        try {
            const values = {
                lat: '48.8487608',
                lon: '2.3530309',
                address: '7, Cité du Cardinal Lemoine, Quartier Saint-Victor, Paris 5e Arrondissement, Paris, Île-de-France, France métropolitaine, 75005, France',
                radius: 500
            }

            const updatedValues = getOnlyUpdatedValues(user, values)

            if (isEmpty(updatedValues)) {
                toast(formLang.nothingUpdated)
                return
            }
            const newUser = await getAPIFunc(objectToFormData(updatedValues), user.type_id)
            toast(addressFormLang.addressUpdated)
            setUpdateMode(false)
            updateLocalUser(newUser)
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false)
        }
    }, [isTestMode, user, getAPIFunc, addressFormLang, formLang])

    return (
        loading ? <Loader /> :
            <div>
                {isAlreadySetUp && <FormControlLabel
                    control={<Switch checked={updateMode} onChange={() => setUpdateMode(updateMode => !updateMode)} />}
                    label={addressFormLang.editMode}
                />}
                {updateMode ? <div>
					<SearchAddressFields initialAddress={user.address} onSelect={updateAddress} />
                    {user.type === UserTypes.FREELANCE && <RadiusInput initialRadius={user.radius} onSelect={updateRadius} />}
                    <Button
						className={classes.mt2}
						loading={loading}
						disabled={!active || loading}
						onClick={updateAddressClick}
					>
                        {addressFormLang.saveMyAddress}
                    </Button>
                    {isTestMode && <Button className={classes.mt2} loading={loading} disabled={loading} onClick={sendFakeAddress}>
                        Fake address
                    </Button>}
                </div> : <DisplayAddressComponent user={user} />}
            </div>
    );
};

export default AddAddress;
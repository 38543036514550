import { Routes as RoutesFromRouter, Route } from 'react-router-dom';
import { UnAuthRoute, DefaultRoute } from 'components/GuardRoutes';
import ForgotPassword from 'containers/account/ForgotPassword';
import Login from 'containers/account/Login';
import GoogleConnect from 'containers/account/Login/components/GoogleConnect';
import ResetPassword from 'containers/account/ResetPassword';
import SignUp from 'containers/account/SignUp';
import ConfirmAccount from 'containers/account/confirmAccount';
import ConnectedRoutes from '../ConnectedRoutes/ConnectedRoutes';
import Marketing from 'containers/marketing/Marketing';

export const Routes = () => (
	<RoutesFromRouter>
		<Route
			index
			exact
			path="/login"
			element={
				<UnAuthRoute>
					<Login />
				</UnAuthRoute>
			}
		/>
		<Route
			exact
			path="/login/:userType"
			element={
				<UnAuthRoute>
					<Login />
				</UnAuthRoute>
			}
		/>
		<Route exact path="/googleConnect/:token" element={<GoogleConnect />} />
		<Route
			exact
			path="/signup"
			element={
				<UnAuthRoute>
					<SignUp />
				</UnAuthRoute>
			}
		/>
		<Route
			exact
			path="/signup/:userType"
			element={
				<UnAuthRoute>
					<SignUp />
				</UnAuthRoute>
			}
		/>
		<Route
			exact
			path="/verify/:token"
			element={
				<UnAuthRoute>
					<ConfirmAccount />
				</UnAuthRoute>
			}
		/>
		<Route
			exact
			path="/confirmAccount"
			element={
				<UnAuthRoute>
					<ConfirmAccount />
				</UnAuthRoute>
			}
		/>
		<Route exact path="/confirmAccount/:token" element={<ConfirmAccount />} />
		<Route
			path="/forgot"
			element={
				<UnAuthRoute>
					<ForgotPassword />
				</UnAuthRoute>
			}
		/>
		<Route path="/resetPassword/:token" element={<ResetPassword />} />
		<Route path="/marketing/:campaignKey/:email" element={<Marketing />} />
		<Route path="/*" element={<ConnectedRoutes />} />
		<Route path="*" element={<DefaultRoute />} />
	</RoutesFromRouter>
);

import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { UserTypes } from 'constants/UserTypes';
import { ReactComponent as IconOnboard } from 'icons/onboard.svg';
import { ReactComponent as IconMissions } from 'icons/missions.svg';
import { ReactComponent as IconCalendar } from 'icons/calendar.svg';
import { ReactComponent as IconSettings } from 'icons/settings.svg';
import { ReactComponent as IconProfile } from 'icons/profile.svg';
import { ReactComponent as IconNotificationsActive } from 'icons/notifications-active.svg';
import { ReactComponent as IconNotifications } from 'icons/notifications.svg';
import classes from './SideBar.module.scss';
import { Link, useLocation } from 'react-router-dom';
import { Store } from 'store/store';
import useLanguage from 'hooks/useLanguage';
import classNames from 'classnames';
import useNotification from 'hooks/useNotification';
import {
	onboardingStatuses,
	FreelanceOnboardingStatuses,
	StudioOnboardingStatuses,
} from 'containers/onboard/constants/OnboardingStatuses';
import LinkSubSteps from './LinkSubSteps';
import Typography from 'components/Typography';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import StorefrontIcon from '@mui/icons-material/Storefront';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';

const pageKeys = {
	subscription: 'subscription',
	onboard: 'onboard',
	missions: 'missions',
	calendar: 'calendar',
	profile: 'profile',
	settings: 'settings',
	bookkeeping: 'bookkeeping',
	issues: 'issues',
	notification: 'notifications',
	services: 'services',
};

const SideBarLinksComponent = ({ setOpositeToActualOpen = () => {} }) => {
	const {
		state: {
			user: { type, status },
		},
	} = useContext(Store);
	const { notifications } = useNotification();
	const location = useLocation();
	const pathname = useMemo(() => (location ? location.pathname : ''), [location]);
	const [stepsOpenned, setStepsOpenned] = useState(pathname.includes(pageKeys.onboard));
	useEffect(() => {
		setStepsOpenned(pathname.includes(pageKeys.onboard));
	}, [pathname]);
	const lang = useLanguage('sideBar');
	const onboardLang = useLanguage('onboard');
	const { obStepShortTitles } = onboardLang;
	const myOnboardingStatuses = useMemo(
		() => (type === UserTypes.FREELANCE ? FreelanceOnboardingStatuses : StudioOnboardingStatuses),
		[type],
	);

	const isActiveAccount = useMemo(() => status === onboardingStatuses.ACTIVE, [status]);
	const isUnSubscribedAccount = useMemo(() => status === onboardingStatuses.WAITING_SUBSCRIPTION, [status]);

	const disabledLinks = useMemo(
		() =>
			!isActiveAccount
				? {
						[pageKeys.missions]: true,
						[pageKeys.calendar]: true,
						[pageKeys.profile]: true,
						[pageKeys.settings]: true,
						[pageKeys.bookkeeping]: true,
						[pageKeys.notification]: true,
						[pageKeys.services]: true,
				  }
				: {},
		[isActiveAccount],
	);

	const links = useMemo(() => {
		const items = [
			{
				key: pageKeys.missions,
				disabled: !!disabledLinks[pageKeys.missions],
				label: lang.missions,
				Icon: () => <IconMissions />,
			},
			{
				key: pageKeys.calendar,
				disabled: !!disabledLinks[pageKeys.calendar],
				label: lang.calendar,
				Icon: () => <IconCalendar />,
			},
			{
				key: pageKeys.profile,
				disabled: !!disabledLinks[pageKeys.profile],
				label: type === UserTypes.FREELANCE ? lang.profile_freelance : lang.profile_studio,
				Icon: () => <IconProfile />,
			},
			...(type === UserTypes.FREELANCE
				? [
						{
							key: pageKeys.bookkeeping,
							disabled: !!disabledLinks[pageKeys.bookkeeping],
							label: lang.bookkeeping,
							Icon: () => <AutoGraphIcon style={{ color: 'white' }} />,
						},
						{
							key: pageKeys.services,
							disabled: !!disabledLinks[pageKeys.services],
							label: lang.services,
							Icon: () => <StorefrontIcon style={{ color: 'white' }} />,
						},
				  ]
				: []),
			{
				key: pageKeys.settings,
				disabled: !!disabledLinks[pageKeys.settings],
				label: lang.settings,
				Icon: () => <IconSettings />,
			},
			{
				key: pageKeys.notification,
				disabled: !!disabledLinks[pageKeys.notification],
				label: lang.notifications,
				Icon: () => (notifications.length > 0 ? <IconNotificationsActive /> : <IconNotifications />),
			},
			{
				key: pageKeys.issues,
				disabled: !!disabledLinks[pageKeys.issues],
				label: lang.issues,
				Icon: () => <QuestionMarkIcon style={{ color: 'white' }} />,
			},
		].filter((e) => !!e);

		if (!isActiveAccount) {
			if (isUnSubscribedAccount) {
				return [
					{
						key: pageKeys.subscription,
						disabled: !!disabledLinks[pageKeys.subscription],
						label: lang.subscription,
						Icon: () => <IconOnboard />,
					},
					...items,
				];
			}
			return [
				{
					key: pageKeys.onboard,
					disabled: !!disabledLinks[pageKeys.onboard],
					label: lang.onboard,
					Icon: () => <IconOnboard />,
					steps: Object.entries(myOnboardingStatuses)
						.filter(
							([key, value]) =>
								!!(value === onboardingStatuses.WAITING_FISCAL_REVIEW
									? obStepShortTitles[value][type]
									: obStepShortTitles[value]),
						)
						.map(([key, value]) => ({
							label:
								value === onboardingStatuses.WAITING_FISCAL_REVIEW
									? obStepShortTitles[value][type]
									: obStepShortTitles[value],
							key,
							status: value,
							done: status > onboardingStatuses[key],
							active: status === onboardingStatuses[key],
						})),
				},
				...items,
			];
		}

		return items;
	}, [
		disabledLinks,
		lang,
		type,
		isActiveAccount,
		isUnSubscribedAccount,
		notifications.length,
		myOnboardingStatuses,
		obStepShortTitles,
		status,
	]);

	const isActive = useCallback(
		(key) => {
			const homePageKey = pageKeys.calendar;
			if (key === homePageKey && (pathname === '' || pathname === '/')) {
				return true;
			}
			//issueCase
			if (pathname.includes('issue') && key !== pageKeys.issues) {
				return false;
			}

			if (pathname.includes('user/update') && key === pageKeys.settings) {
				return true;
			}

			return pathname.includes(key) || (key.endsWith('s') && pathname.includes(key.substring(0, key.length - 1)));
		},
		[pathname],
	);

	return (
		<div className={classes.linkContainer}>
			{links.map(({ label, key, Icon, disabled, steps }, i) => (
				<div key={i}>
					<div
						className={classNames(
							classes.link,
							isActive(key) && classes.active,
							disabled && classes.disabled,
						)}
					>
						<Icon />
						{disabled ? (
							<span className={classes.label}>{label}</span>
						) : (
							<Link onClick={setOpositeToActualOpen} to={`/${key}`} className={classes.label}>
								<Typography variant="text"> {label}</Typography>
							</Link>
						)}
					</div>
					{stepsOpenned && steps && <LinkSubSteps steps={steps} />}
				</div>
			))}
		</div>
	);
};

export default SideBarLinksComponent;

import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import FormComponent from 'components/Form/Form'
import useRemoteData from 'hooks/useRemoteData'
import { Store } from 'store/store'
import { useMission } from 'hooks/useMission'
import { UserTypes } from 'constants/UserTypes'
import { toDateInput } from 'modules/dates'
import { getLastElemOfArr, isNull } from 'containers/helpers'

function CreateMissionIssue({
    onSubmit: onSubmitFromParams,
    lang,
    missionId: missionIdFromParams,
    issueTypes,
}) {
    const [contract, setContract] = useState()
    const [missionId, setMissionId] = useState(Number(missionIdFromParams) || null)
    useEffect(() => {
        if (missionIdFromParams) {
            setMissionId(Number(missionIdFromParams))
        }
    }, [missionIdFromParams])
    const { state: { user } } = useContext(Store)
    const { mission: { contracts } } = useMission(missionId)
    const missionUsersUnique = useMemo(() =>
        contracts.length > 0 ?
            [{
                freelances: [-1],
                freelanceName: lang.form.noFreelance
            },
            ...contracts
                .reduce(
                    (prev, actualContract) =>
                        prev.find(({ freelances }) => actualContract.freelances[0] === freelances[0]) ? prev : [...prev, actualContract]
                    , [])]
            : []
        , [contracts, lang])

    const { data: missions } = useRemoteData(`/missions`)
    const [freelance, setFreelance] = useState(-1)

    const dates = useMemo(() => {
        if (freelance !== -1 && freelance && contracts.length > 0) {
            const freelanceContracts = contracts
                .filter(({ freelances }) => Number(freelances[0]) === freelance)
            if (freelanceContracts.length > 1) {

                return freelanceContracts.map(contract => ({
                    ...contract,
                    text: contract.dates.length > 1
                        ? `${toDateInput(contract.dates[0])} - ${toDateInput(getLastElemOfArr( contract.dates))}`
                        : toDateInput(contract.dates[0])
                }))
            }
            return null
        }
        return null
    }, [contracts, freelance])

    const onSubmit = useCallback((values) => {
        const toSubmit = {
            type: contract ? issueTypes.CONTRACT : issueTypes.MISSION,
            title: values.title,
            description: values.description,
            mission: missionId,
        }
        if (contract) {
            toSubmit.contract = contract
        }

        return onSubmitFromParams(toSubmit)


    }, [contract, issueTypes, missionId, onSubmitFromParams])

    const onUpdate = useCallback(
        (values, [name, value]) => {
            if (name === 'mission' && Number(value) !== missionId) {
                setMissionId(Number(value))
                setContract(null)
            }
            if (name === 'user' && Number(value) !== freelance) {
                setFreelance(Number(value))
                if (Number(value) === -1) {
                    setContract(null)
                } else {
                    const freelanceContracts = contracts
                        .filter(({ freelances }) => Number(freelances[0]) === freelance)
                    if (freelanceContracts && freelanceContracts.length === 1) {
                        setContract(freelanceContracts[0].id)
                    }
                }
            }
            if (name === 'dates') {
                setContract(Number(value))
            }
        }, [missionId, freelance, contracts])

    const inputs = useMemo(() => [
        ...(missions && missions.length > 0 ? [
            {
                type: 'select',
                name: 'mission',
                required: true,
                label: lang.form.mission.label,
                value: isNull(missionId) ? null : String(missionId),
                silentRequired: true,
                options: missions.length
                    ? missions.map(mission => ({ key: String(mission.id), value: String(mission.id), text: mission.title }))
                    : [{ key: '-1', value: '-1', text: lang.form.noMissions }],
                placeholder: lang.form.mission.placeholder
            }]
            : [])
        ,
        (missionUsersUnique.length > 0 && user.type === UserTypes.STUDIO ?
            {
                type: 'select',
                name: 'user',
                label: lang.form.user.label,
                info: lang.form.notRequired,
                options: missionUsersUnique
                    .map(contract => ({ key: String(contract.freelances[0]), value: String(contract.freelances[0]), text: contract.freelanceName })),
                placeholder: lang.form.user.placeholder
            }
            : null)
        ,
        (dates && dates.length > 0 ?
            {
                type: 'select',
                name: 'dates',
                required: true,
                label: lang.form.dates.label,
                silentRequired: true,
                options: dates
                    .map(contract => ({ key: String(contract.id), value: String(contract.id), text: contract.text })),
                placeholder: lang.form.dates.placeholder
            }
            : null)
        ,
        {
            type: 'text',
            name: 'title',
            label: lang.form.title.label,
            placeholder: lang.form.title.placeholder,
            required: true,
            silentRequired: true,
        },
        {
            type: 'textArea',
            name: 'description',
            label: lang.form.description.label,
            placeholder: lang.form.description.placeholder,
            required: true,
            silentRequired: true,
            legend: lang.form.description.legend,
            maxLength: 1000,
        },
    ].filter(e => !!e), [missions, lang, missionUsersUnique, user.type, dates, missionId])

    return (
        <FormComponent sendForm={onSubmit} onUpdate={onUpdate} inputs={inputs} />
    )
}

export default CreateMissionIssue

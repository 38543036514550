import { statuses, types, IssueLabels_FR } from 'containers/issue/issue.enum'

export const FR = {
	reportAnIssue: 'Signaler un problème',
	list: {
		title: 'Mes tickets',
		creationDate: 'Date de création',
		titleField: 'Titre',
		description: 'Description',
		reference: 'Référence',
		status: 'Statut'
	},
	statuses: IssueLabels_FR,
	ref: `réf.`,
	myIssues: {
		title: 'Mes demandes'
	},
	issues: {
		empty: 'Pas de demande',
		error: `Impossible de charger vos problèmes pour le moment. Veuillez actualiser la page ou réessayer plus tard si le problème persiste.`,
	},
	issue: {
		title: 'Page de la demande',
		error: `Impossible de charger vos problèmes pour le moment. Veuillez actualiser la page ou réessayer plus tard si le problème persiste.`,
	},
	create: {
		title: 'Précisez-nous votre demande',
		levelUp: {
			buttonTitle: 'Je veux augmenter de level'
		},
		form: {
			title: {
				label: 'Titre',
				placeholder: 'Titre',
			},
			description: {
				label: 'Description ',
				placeholder: 'Veuillez décrire votre problème en détail',
				legend: 'Caractères maximum 500'
			},
			type: {
				label: `De quoi s'agit-il ?`,
				placeholder: 'Sélectionner un sujet'
			},
			mission: {
				label: 'Sélectionner une mission',
				placeholder: 'Sélectionner une mission'
			},
			step: {
				label: 'Selectionner une étape',
				placeholder: 'Selectionner une étape'
			},
			user: {
				label: 'Sélectionner un freelance',
				placeholder: 'Sélectionner un freelance'
			},
			dates: {
				label: 'Sélectionner une plage de dates avec ce freelance',
				placeholder: 'Sélectionner une plage de dates avec ce freelance'
			},
			noFreelance: 'J\'ai un problème avec la mission en général',
			noMission: 'Aucune mission associée disponible',
			notRequired: 'Ce champ n\'est pas obligatoire'
		},
		types: {
			[types.CONTRACT]: 'Contrat',
			[types.MISSION]: 'Mission',
			[types.ONBOARD]: 'Démarrage',
			[types.UPDATE_LEVEL]: 'Mettre à jour mon niveau',
			[types.REPAYMENT]: 'Remboursement de mission',
			[types.OTHER]: 'Autre'
		},
		success: `Votre problème a été reçu. Nous vous répondrons dès que possible.`,
		erreur: `Nous n'avons pas pu soumettre votre problème pour le moment. Veuillez réessayer plus tard.`
	},
	meeting: {
		title: `Selectionnez un meeting pour votre demande`
	},
	meetingButton: 'Choisissez un créneau pour traiter la demande avec un membre de l\'équipe',
	accessToMeetingPage: 'Accéder à la page du meeting'

}

export const EN = {
	reportAnIssue: 'Report an issue',
	list: {
		title: 'My issues',
		creationDate: 'Creation date',
		titleField: 'Title',
		description: 'Description',
		reference: 'Reference',
		status: 'Status',
	},
	ref: `ref.`,
	statuses: {
		[statuses.CLOSE]: 'Closed',
		[statuses.OPEN]: 'Open',
		[statuses.IN_PROGRESS]: 'In-progress',
		[statuses.MEETING_BOOKED]: 'Meeting booked',
		[statuses.WAITING_MEETING]: 'Schedule a meeting',
	},
	myIssues: {
		title: 'My Issues'
	},
	issue: {
		title: 'Issue page of',
		error: `Could not load this issue at the time. Please refresh the page or try again later if issue persists.`,
	},
	issues: {
		empty: 'No issues',
		error: `Could not load these issues at the time. Please refresh the page or try again later if issue persists.`,
	},
	create: {
		title: 'Create an Issue',
		levelUp: {
			buttonTitle: 'I want to level up'
		},
		form: {
			title: {
				label: 'Title',
				placeholder: 'Title',
			},
			description: {
				label: 'Description ',
				placeholder: 'Please describe your problem in detail',
				legend: 'Maximum characters 500',
			},
			type: {
				label: `What's this issue regarding?`,
				placeholder: 'Select a subject'
			},
			mission: {
				label: 'Select a mission',
				placeholder: 'Select a mission'
			},
			step: {
				label: 'Select a step',
				placeholder: 'Select a step'
			},
			user: {
				label: 'Select a freelance',
				placeholder: 'Select a freelance',
			},
			dates: {
				label: 'Select dates range with this freelance',
				placeholder: 'Select dates range with this freelance',
			},
			noFreelance: 'I have an issue with this mission in general',
			noMission: 'No related missions available',
			notRequired: 'This field isn\'t required'
		},
		types: {
			[types.CONTRACT]: 'Contract',
			[types.MISSION]: 'Mission',
			[types.ONBOARD]: 'Onboard',
			[types.UPDATE_LEVEL]: 'Update my level',
			[types.REPAYMENT]: 'Repayment of mission',
			[types.OTHER]: 'Other'
		},
		success: `You're issue was recieved. We'll get back to you as soon as possible.`,
		error: `We could not submit your issue at this time. Please consider trying again later.`
	},
	meeting: {
		title: `Selectionnez un meeting pour votre issue`
	},
	meetingButton: 'Please choose a meeting to fix issue with team member',
	accessToMeetingPage: 'Access to meeting page'

}

export default {
	fr: FR,
	us: EN,
}

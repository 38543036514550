export const FR = {
	title: 'Foire Aux Questions',
	create_issue: 'Contactez-nous'
}

export const EN = {
	title: 'Frequently Asked Questions',
	create_issue: 'Get in touch'
}

export default {
	fr: FR,
	us: EN,
}
  
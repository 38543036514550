import classNames from 'classnames'
import { useState, useEffect } from 'react'
import { Icon } from 'semantic-ui-react'
import classes from './Payment.module.scss'

const types = [
  {
    name: 'Visa', test: true, icon: 'cc visa', regex: new RegExp('^4'),
  },
  {
    name: 'Mastercard', icon: 'cc mastercard', regex: /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/,
  },
  {
    name: 'AMEX', icon: 'cc amex', regex: new RegExp('^3[47]'),
  },
  {
    name: 'Discover', icon: 'cc discover', regex: new RegExp('^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)'),
  },
  {
    name: 'Diners', icon: 'cc diners club', regex: new RegExp('^36'),
  },
  {
    name: 'Diners - Carte Blanche', icon: 'cc diners club', regex: new RegExp('^30[0-5]'),
  },
  {
    name: 'JCB', icon: 'cc jcb', regex: new RegExp('^35(2[89]|[3-8][0-9])'),
  },
  {
    name: 'Visa Electron', icon: 'cc visa', regex: new RegExp('^(4026|417500|4508|4844|491(3|7))'),
  },
]

const CreditCard = ({
  onClick = () => { },
  className = '',
  brand = null,
  numbers = '1234567812345678',
  last4 = null,
  date = '11/23',
  exp_month: expMonth = null,
  exp_year: expYear = null,
}) => {
  const [type, setType] = useState({})
  const getType = number =>
    types[types.findIndex(({ regex, test = false }) => test ? !!regex.test(number) : !!number.match(regex))] || {}

  const getNormalStr = str => str.toLowerCase().replace(/\s+/g, '')

  useEffect(() => {
    setType(
      brand ? (
        types.find(
          ({ name }) =>
            getNormalStr(brand) === getNormalStr(name)) || {}
      ) : getType(numbers)
    )
  }, [brand, numbers])

  const getNumbersStr = numbers => {
    if (numbers.length === 4) {
      return 'XXXX XXXX XXXX ' + numbers
    }
    const v = numbers.replace(/\s+/g, '').replace(/[^0-9]/gi, '')
    const matches = v.match(/\d{4,16}/g)
    const match = (matches && matches[0]) || ''
    const parts = []

    for (let i = 0, len = match.length; i < len; i += 4) {
      parts.push(match.substring(i, i + 4))
    }

    if (parts.length) {
      return parts.join(' ')
    } else {
      return numbers
    }
  }

  const getDate = () => (expMonth && expYear) ? (expMonth < 10 ? '0' + expMonth : expMonth) + '/' + expYear : date
  return (
    <div className={classNames(classes.creditCard, className)} onClick={onClick}>
      <div className={classes.centeredContainer}>
        <div className={classes.type}>
          <Icon name={type.icon} />
        </div>
        <div >{getNumbersStr(last4 || numbers)}</div>
      </div>
      <div className={classNames(classes.flex, classes.spaceBetween)}>
        <Icon size="big" name="credit card" />
        <div>{getDate()}</div>
      </div>
    </div>
  )
}

export default CreditCard

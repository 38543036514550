import classes from './InfoBox.module.scss'
import Button from 'components/Button'
import ExclamationIcon from 'icons/exclamation.svg'
import WaitingIcon from 'icons/waiting.svg'
import ExclamationPrimaryIcon from 'icons/exclamationPrimary.svg'
import Typography from 'components/Typography'

type InfoBoxTypes = 'primary' | 'error' | 'waiting'
type InfoBoxActions = [{ label: string, onClick: Function }]

const icons = {
	primary: ExclamationPrimaryIcon,
	error: ExclamationIcon,
	waiting: WaitingIcon,
}

function InfoBox({
	msg,
	type = 'primary',
	actions = null,
}: {
	msg: string,
	type: InfoBoxTypes,
	actions?: InfoBoxActions,
}) {
	return (
		<div className={classes.root}>
			<img
				className={classes.icon}
				src={icons[type]}
				alt={`${type} icon`}
			/>
			<Typography html={msg}/>
			{actions
				? actions.map(({ label, onClick }) => (
						<Button className={classes.button} onClick={onClick}>
							{label}
						</Button>
				  ))
				: null}
		</div>
	)
}

export default InfoBox

import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import FormComponent from 'components/Form/Form'
import { FreelanceOnboardingStatuses, StudioOnboardingStatuses } from 'containers/onboard/constants/OnboardingStatuses'
import { Store } from 'store/store'
import { UserTypes } from 'constants/UserTypes'
import useLanguage from 'hooks/useLanguage'
import { getObjectByUserType, isNull } from 'containers/helpers'

function CreateOnboardIssue({
    onSubmit: onSubmitFromParams,
    lang,
    stepId: stepIdFromParams,
    issueType,
}) {
    const { state: { user } } = useContext(Store)
    const onboardLang = useLanguage('onboard').obStepTitles
    const stepsLang = useMemo(() => getObjectByUserType(onboardLang, user.type), [onboardLang, user.type])
    const steps = useMemo(() => user.type === UserTypes.STUDIO ? StudioOnboardingStatuses : FreelanceOnboardingStatuses, [user])
    const [stepId, setstepId] = useState(Number(stepIdFromParams) || null)
    useEffect(() => {
        if (stepIdFromParams) {
            setstepId(Number(stepIdFromParams))
        }
    }, [stepIdFromParams])


    const onSubmit = useCallback((values) => {
        const toSubmit = {
            type: issueType,
            title: values.title,
            description: values.description,
            step: values.step,
        }

        return onSubmitFromParams(toSubmit)


    }, [issueType, onSubmitFromParams])

    const inputs = useMemo(() => [

        {
            type: 'select',
            name: 'step',
            required: true,
            label: lang.form.step.label,
            value: isNull(stepId) ? null : String(stepId),
            silentRequired: true,
            options: Object.entries(steps)
                .map(([key, value]) =>
                    ({ key: String(value), value: String(value), text: stepsLang[value] })
                ),
            placeholder: lang.form.step.placeholder
        },
        {
            type: 'text',
            name: 'title',
            label: lang.form.title.label,
            placeholder: lang.form.title.placeholder,
            required: true,
            silentRequired: true,
        },
        {
            type: 'textArea',
            name: 'description',
            label: lang.form.description.label,
            placeholder: lang.form.description.placeholder,
            required: true,
            silentRequired: true,
            legend: lang.form.description.legend,
            maxLength: 1000,
        },
    ].filter(e => !!e), [lang, stepId, steps, stepsLang])

    return (
        <FormComponent sendForm={onSubmit} inputs={inputs} />
    )
}

export default CreateOnboardIssue

import classes from './FreelanceProfileCard.module.scss';
import useLanguage from 'hooks/useLanguage';
import useLocale from 'hooks/useLocale';
import Typography from 'components/Typography';
import StarRating from 'components/StarRating/StarRating';
import { getDateFormatted } from 'modules/dates';
import FreelanceBadges from 'components/FreelanceBadges';
import classNames from 'classnames';
import moment from 'moment-timezone';
import { roundTo2Dec } from 'containers/helpers';

function FreelanceProfileCard({
	img,
	firstName,
	city,
	rating,
	nRatings,
	isAvailableForWholeMission,
	hourRate,
	totalRate,
	availableDays,
	hourlyRate,
	badges,
	priceHour,
	priceHours,
	created_at,
	type,
	typeLang,
}) {
	const lang = useLanguage('marketPlace');
	const locale = useLocale();
	const profileLang = useLanguage('profile');

	// const percentileText = useMemo(() => getPercentile(reductionPercentile), [reductionPercentile])

	return (
		<div className={classes.container}>
			<div className={classes.topContainer}>
				<div className={classes.circleWrapper}>
					<span className={classNames(classes.circle, isAvailableForWholeMission && classes.success)} />
				</div>
				<div
					style={{
						background: `url(${img || '/assets/default-img.png'}`,
					}}
					className={classes.img}
				/>
				<div className={classes.freelanceInfosContainer}>
					<div>
						<div className={classes.inlineBlock}>
							<Typography
								classes={{ root: classNames(classes.name, classes.inlineBlock) }}
								primaryColor
								variant="subTitle2"
							>
								{firstName}
							</Typography>
						</div>
						<div className={classes.ratingLine}>
							<StarRating size="little" rating={rating} />
							<span className={classes.ratingsCount}>({nRatings || 0})</span>
						</div>
						<div className={classes.labelContainer}>
							<div className={classNames(classes.label, classes.tarif, classes.relative)}>
								{lang.tarif} : {roundTo2Dec(Number(hourRate))}€ HT/{lang.hour}
								{/* {reductionPercentile !== 0 && <InfoReduction
								percentile={percentileText}
								initialPrice={withoutReductionPriceDay}
								price={priceHour}
							/>} */}
							</div>
							<div className={classNames(classes.label, classes.tarifTotal, classes.relative)}>
								{lang.tarifTotal} : {roundTo2Dec(Number(totalRate))}€ HT
								{/* {reductionPercentile !== 0 && <InfoReduction
								percentile={percentileText}
								initialPrice={withoutReductionPriceDays}
								price={priceHours}
							/>} */}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className={classes.bottomContainer}>
				<div className={classNames(classes.padding1, classes.fullWidth, classes.bottomContainerContent)}>
					{/* <LevelBar level={level} displayLevel type={type} typeLang={typeLang} /> */}
					<div>
						{isAvailableForWholeMission ? (
							<div className={classes.isAvailableContainer}>
								<Typography italic>{lang.availableForAll}</Typography>
								<span className={classNames(classes.circle, classes.success)} />
							</div>
						) : (
							<div className={classNames(classes.isAvailableContainer, classes.flexWrap)}>
								<Typography italic>{lang.availableFor(availableDays.length)} :</Typography>
								<div className={classes.daysWrapper}>
									{availableDays.slice(0, 2).map((day, i) => (
										<span className={classNames(classes.dayItem, classes.bullets)} key={i}>
											{moment(day, 'DD/MM').format(locale === 'en' ? 'MM/DD' : 'DD/MM')}
										</span>
									))}
									{availableDays.length > 2 && (
										<span className={classNames(classes.dayItem, classes.bullets)}>...</span>
									)}
									<div className={classes.fullDays}>
										{availableDays.map((day, i) => (
											<span className={classes.dayItem} key={i}>
												{moment(day, 'DD/MM').format(locale === 'en' ? 'MM/DD' : 'DD/MM')}
											</span>
										))}
									</div>
								</div>
							</div>
						)}
						{/* {reductionPercentile !== 0 && <div>
						{lang.reductionAboutDays(percentileText, availableDays.length)}
					</div>} */}
					</div>
					<div className={classes.badgesContainer}>
						<FreelanceBadges badges={badges} />
					</div>
					{created_at && (
						<Typography italic>
							{profileLang.memberSince} {getDateFormatted(created_at)}
						</Typography>
					)}
				</div>
			</div>
		</div>
	);
}

export default FreelanceProfileCard;

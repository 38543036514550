import { fetchApi } from '../../helpers';
import { Buffer } from 'buffer'

export const login = ({ email, password, code }) =>
    fetchApi(`/user/login/${email}`, {
        method: 'POST',
        headers: { 'x-code': code },
        body: { password },
    });

export const parseEncodeObject = (encoded) =>
    JSON.parse(Buffer.from(decodeURIComponent(encoded), 'base64').toString('utf-8'));

export default {
    login,
    parseEncodeObject,
};

import MyTable from 'components/MyTable/MyTable';
import useLanguage from 'hooks/useLanguage';
import { toDateInput } from 'modules/dates';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

const IssueListTable = ({
    issues
}) => {
    const navigate = useNavigate()
    const lang = useLanguage('issue.list')
    const statuses = useLanguage('issue.statuses')
    const columns = useMemo(() => [
        { title: lang.reference, render: props => <div>{props.reference}</div> },
        { title: lang.creationDate, render: props => <div>{toDateInput(props.created_at)}</div> },
        { title: lang.titleField, render: props => <div>{props.title}</div> },
        { title: lang.description, render: props => <div>{props.description}</div> },
        { title: lang.status, render: props => <div>{statuses[props.status]}</div> },

    ], [lang, statuses])

    const onRowClick = (cellMeta, issue) => {
        return navigate(`/issue/${issue.id}`)
    }

    return (
        <MyTable
            onRowClick={onRowClick}
            columns={columns}
            data={issues}
            options={{ paging: false }}
        />
    );
};

export default IssueListTable;
const StudioInfos = ({
    studio_name,
    address,
    collaborators,
    since,
    franchise,
}) => {
    return (
        <div>
            {!!studio_name && <div><span className='mr-3'>studio_name : </span><span>{studio_name}</span></div>}
            {!!address && <div><span className='mr-3'>address : </span><span>{address}</span></div>}
            {!!collaborators && <div><span className='mr-3'>collaborators : </span><span>{collaborators}</span></div>}
            {!!since && <div><span className='mr-3'>since : </span><span>{since}</span></div>}
            {!!franchise && <div><span className='mr-3'>franchise : </span><span>{franchise}</span></div>}
        </div>
    )
}

export default StudioInfos
import { useContext, useEffect, useState, useMemo, useCallback, Children, cloneElement } from 'react'
import { Store } from 'store/store'
import Spacer from 'components/Spacer'
import { Popover } from 'antd'
import { Checkbox } from 'semantic-ui-react'
import Button from 'components/Button'
import Calendar from 'components/Calendar'
import dayStatusEventProp from '../dayStatusEventProp/dayStatusEventProp'
import { dayStatuses } from 'constants/DayStatuses'
import { useSockets } from 'hooks/useSockets'
import useLanguage from 'hooks/useLanguage'
import { socket, removeEvent } from 'socket'
import { getDayStatusesLang, getAppointmentIdsApi, getAppointmentApi, getStudioProposalsApi, getUserDaysApi, getStudioMissionOpenDays } from 'containers/mission/helpers'
import moment from 'moment-timezone'
import { appointmentsToEvents, proposalsByMissionsToEvents, utc2gmt, pureMissionDaysToEvents } from 'modules/dates'
import { styleDayStatus } from 'components/Calendar/helpers'
import { EventTypes } from 'constants/EventTypes.constant'

const StudioCalendar = () => {
	const [checked, setChecked] = useState(false)
	const setCheckChanges = () => setChecked(checked => !checked)
	const lang = useLanguage('mission')
	const { user } = useContext(Store).state
	const dayStatusesLangs = useLanguage('dayStatuses')
	const dayStatusesLang = useMemo(() => getDayStatusesLang(dayStatusesLangs, user.type), [user.type, dayStatusesLangs])
	const [loading, setLoading] = useState(false)
	const [events, setEvents] = useState([])
	const [days, setDays] = useState({})

	const BackgroundWrapper = useCallback((props) => {
		const { children, value } = props
		const dateTs = utc2gmt(value)
		const child = Children.only(props.children)

		const isScheduled = days[Object.keys(days).find(date => utc2gmt(moment(date, 'DD/MM/YYYY')) === dateTs)]
		if (!isScheduled) return props.children

		return cloneElement(child, {
			style: {
				...children.style,
				...styleDayStatus[isScheduled.dayStatus]
			}
		})
	}, [days])

	const getPopoverContent = ({ id, status, contractId, ...rest }) => {
		return <Button link={'/mission/page/' + id}>
			{lang.accessToMissionPage}
		</Button>
	}

	const EventWrapper = (props) => {
		const {
			children,
			event,
		} = props
		return (
			<Popover
				title={event.title}
				trigger='click'
				content={getPopoverContent(event)}
			>
				{children}
			</Popover>
		)
	}

	const components = {
		eventWrapper: EventWrapper,
		dateCellWrapper: BackgroundWrapper
	}

	const init = useCallback(async () => {
		setLoading(true)

		try {
			const appointmentIds = await getAppointmentIdsApi()
			const proposals = await getStudioProposalsApi(user.id)
			const studioDays = await getUserDaysApi()
			const openMissionDays = await getStudioMissionOpenDays()

			setDays(studioDays)
			const appointments = await Promise.all(appointmentIds.map(getAppointmentApi))

			setEvents([
				...proposalsByMissionsToEvents(proposals, dayStatusesLang),
				...pureMissionDaysToEvents(openMissionDays, dayStatusesLang),
				...appointmentsToEvents(appointments, dayStatusesLang, lang, user.type, true)
			].sort((a, b) => a.start - b.start)
				.filter(({ start, eventType }) => !([EventTypes.OPEN, EventTypes.PROPOSAL]
					.includes(eventType) && moment(start).isBefore(moment())))
			)
		} catch (err) {
			console.error('error', err)
		} finally {
			setLoading(false)
		}
	}, [dayStatusesLang, lang, user, setEvents])

	useEffect(() => {
		init()
	}, [init])

	const { socketConnected } = useSockets()

	useEffect(() => {
		if (socketConnected && socket) {
			socket.on(`missions updated`, () => {
				init()
			})
		}
		return () => {
			if (socketConnected && socket) {
				removeEvent(`missions updated`)
			}
		}
	}, [socketConnected, init])

	const eventsWithFilter = useMemo(() =>
		checked
			? events.filter(({ status }) => status < dayStatuses.CONFIRMED)
			: events, [checked, events])

	return (
		<>
			<Checkbox label={lang.unGetMissionsOnly} onChange={setCheckChanges} />
			<Spacer />
			<Calendar
				defaultDate={events && events.length ? new Date(events[0]?.start) : new Date()}
				components={components}
				eventPropGetter={dayStatusEventProp}
				events={eventsWithFilter}
				loading={loading}
				style={{ height: '600px', width: '100%' }}
			/>
		</>
	)
}

export default StudioCalendar

import { UserTypes } from 'constants/UserTypes'
import { dateByDateFormat } from 'modules/dates'
import { dayStatuses } from 'constants/DayStatuses'
import { useContext, useMemo } from 'react'
import { Store } from 'store/store'
import useLanguage from 'hooks/useLanguage'
import useLocale from 'hooks/useLocale'
import StatusMissionComponent from './StatusMission/StatusMissionComponent'
import classes from 'styles/classes.module.scss'
import Typography from './Typography'
import classNames from 'classnames'
import Button from './Button'

const DateWithActions = ({
	date,
	declineProposal = () => { },
}) => {
	const me = useContext(Store).state.user
	const lang = useLanguage('mission')
	const locale = useLocale()

	const buttonFromStatus = useMemo(() => {
		if (me.type === UserTypes.STUDIO) {
			return (
				<Button type="danger" onClick={declineProposal}>
					{lang.cancelMyProposal}
				</Button>
			)
		} else if (me.type === UserTypes.FREELANCE) {
			if (date.status === dayStatuses.STUDIO_PROPOSAL) {
				return (
					<>
						<Button primary onClick={declineProposal}>
							{lang.acceptStudioProposal}
						</Button>
						<Button secondary onClick={declineProposal}>
							{lang.declineStudioProposal}
						</Button>
					</>
				)
			} else if (date.status === dayStatuses.FREELANCE_PROPOSAL) {
				return (
					<Button secondary onClick={declineProposal}>
						{lang.cancelMyProposal}
					</Button>
				)
			} else if (
				date.status === dayStatuses.CONFIRMED ||
				date.status === dayStatuses.WAITING_FREELANCE_CONTRACT
			) {
				return (
					<>
						<Button secondary onClick={declineProposal}>
							{lang.acceptStudioProposal}
						</Button>
					</>
				)
			}
		}
		return null
	}, [date, lang, me, declineProposal])
	return (
		<div className={classNames(classes.flex, classes.flexWrap, classes.spaceBetween)}>
			<div className={classNames(classes.flex)}>
				<Typography variant='subTitle3' classes={{ root: classNames(classes.alignSelf, classes.mr1) }}>
					{dateByDateFormat(date, 'DD/MM/YYYY', locale)}{' '}
				</Typography>
				{date.status === dayStatuses.CONFIRMED && (
					<StatusMissionComponent
						eventType={'open'}
						langStatus={lang.confirmed} />
				)}
			</div>
			{buttonFromStatus}
		</div>
	)
}

export default DateWithActions

import { useContext, useState, useEffect } from 'react'
import { Store } from "../../../store/store"
import { List } from "@material-ui/core"
import { NotificationComponent } from '../components/Notification'
import useNotification from 'hooks/useNotification'
import RightSide from 'components/RightSide/RightSide'
import TitlePage from 'components/TitlePage'
import useLanguage from 'hooks/useLanguage'
import Button from 'components/Button'
import classes from './NotificationPage.module.scss'
import { Checkbox } from 'semantic-ui-react'
import Typography from 'components/Typography'

const NotificationPage = () => {
    const lang = useLanguage('notification')

    const {
        state: { notifications: notificationsFromContext = [],
            language: { notification: { read: readLang, markAllAsRead: markAllAsReadLang } } }
    } = useContext(Store)

    const [notifications, setNotifications] = useState([])
    const [all, setAll] = useState(false)
    const {
        initAllNotification,
        initNotification,
        readAllNotifications
    } = useNotification()
    const [checked, setChecked] = useState(false)
    const handleChange = async () => {
        const newChecked = !checked
        newChecked ? setAll(true) : setAll(false)
        const ns = await (newChecked ? initAllNotification : initNotification)(true)
        setNotifications(ns)
        setChecked(newChecked)
    }

    useEffect(() => {
        if (!all) {
            setNotifications(notificationsFromContext)
        } else {
            setNotifications(notifications => [...new Set([...notifications, ...notificationsFromContext].map(JSON.stringify))].map(JSON.parse))
        }
    }, [notificationsFromContext])

    const markAllAsRead = () => {
        readAllNotifications(!all)
    }

    return (
        <RightSide>
            <TitlePage subtitle={<div className={classes.displayReadContainer}>
                {notifications.length > 0 && <Button type='warning' onClick={markAllAsRead}>
                    {markAllAsReadLang}
                </Button>}
                <div className={classes.displayReadContainer}>
                    <Typography>
                        {readLang}
                    </Typography>
                    <div>
                        <Checkbox toggle onChange={handleChange} />
                    </div>
                </div>
            </div>}>
                {lang.title}
            </TitlePage>
            <List classes={{ root: classes.list }}>
                {notifications.sort((a, b) => b.id - a.id).map((notification, i) => (
                    <NotificationComponent
                        tryBrowser={false}
                        {...notification}
                        key={i}
                    />
                ))}
            </List>
        </RightSide>
    )
}

export default NotificationPage

const FR = {
	myInvoices: 'Mes factures',
	amount: 'Coût TTC',
	invoiceDate: 'Date de facturation',
	link: 'Lien vers la facture',
	pastDue: {
		title: 'Facture impayée',
		description:
			'Si une votre méthode de paiment a été refusée, vous pouvez la mettre à jour ici, si vous ne mettez pas à jour votre méthode de paiment, votre compte sera suspendu.',
		noInvoice: "Vous n'avez aucune facture impayée",
	},
};

const EN = {
	myInvoices: 'My invoices',
	amount: 'Amount all taxes includes',
	invoiceDate: 'Invoice date',
	link: 'Link to invoice',
	pastDue: {
		title: 'Past due invoice',
		description: 'If your payment method has been declined, you can update it here.',
		noInvoice: 'You have no past due invoice',
	},
};

export default {
	fr: FR,
	us: EN,
};

import { IconButton, Tooltip } from '@material-ui/core';
import { getMostRecentDate, getOlderDate, formatDate, momentFromDateString } from 'modules/dates';
import React, { useMemo } from 'react';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { isArray, isDate, isObject, isString } from 'lodash';
import Typography from './Typography';
import useLanguage from 'hooks/useLanguage';

const DateTooltip = ({
	dates = [],
}) => {
	const arrDates = useMemo(() => isArray(dates)
		? dates.map(d => isDate(d) ? d : new Date(d))
		: isObject(dates)
			? Object.keys(dates).map(d => momentFromDateString(d).toDate())
			: dates, [dates])

	const lang = useLanguage('date')
	const receivedDates = useMemo(() => isString(arrDates) ? arrDates : arrDates.map(formatDate).join('\n'), [arrDates])
	const to = useMemo(() => (!arrDates || arrDates.length === 0) ? '' : formatDate(getMostRecentDate(arrDates)), [arrDates])
	const from = useMemo(() => (!arrDates || arrDates.length === 0) ? '' : formatDate(getOlderDate(arrDates)), [arrDates])
	if (!arrDates || arrDates.length === 0) {
		return null
	}
	return (
		<div>
			{to === from ? <Typography>{from}</Typography> : <div>
				<Typography>{lang.from + ' ' + from + ' ' + lang.to + ' ' + to}</Typography>
				<Tooltip title={receivedDates}>
					<IconButton>
						<CalendarMonthIcon />
					</IconButton>
				</Tooltip>
			</div>}
		</div>
	);
};

export default DateTooltip;
import { updateUserStatusAPI } from 'containers/onboard/helpers';
import { useCallback } from 'react';
import toast from 'components/toast';
import { useStoreUtils } from './useStoreUtils';

export const useSteps = () => {
    const { updateLocalUser, state: { user } } = useStoreUtils()

    const goToStep = useCallback(async (statusToUpdate) => {
        try {
            const { status: newStatus } = await updateUserStatusAPI(user.id, statusToUpdate)
            updateLocalUser({ ...user, status: newStatus })
        } catch (error) {
            toast.error(error.message)
        }
    }, [user, updateLocalUser])

    return {
        goToStep,
    };
};

import { useStoreUtils } from 'hooks/useStoreUtils';
import { useState, useEffect, useMemo, useCallback } from 'react';
import SelectButton from './SelectButton';
import { PeriodicityTypes } from 'constants/PeriodicityTypes';
import { QuarterDates } from 'constants/QuartersDates';
import BookkeepingNumbers from './BookkeepingNumbers';
import MyTable from 'components/MyTable/MyTable';
import Button from 'components/Button';
import classes from './Bookkeeping.module.scss';
import classNames from 'classnames';
import {
	dateByDateMoment,
	getExtremesDatesOfMonth,
	getFirstDayOfMonth,
	getLastDayOfMonth,
	getTodayMidnight,
} from 'modules/dates';
import useLocale from 'hooks/useLocale';
import { getFileByIdVisualisationUrl } from 'containers/contract/helpers';
import { getToken } from 'containers/helpers';

const BookkeepingTable = ({
	payments: initialPayments = [],
	appointments: initialAppointments = [],
	onlyAppointments = false,
	tva = false,
}) => {
	const locale = useLocale();
	const {
		state: {
			language: { bookkeeping: bookkeepingLang },
			user: { periodicity_type, created_at },
		},
	} = useStoreUtils();
	const [periodicity, setPeriodicity] = useState(null);

	const getMyQuarter = () => {
		const today = getTodayMidnight();
		const year = today.getFullYear();
		const quarters = QuarterDates({
			startYear: year,
			endYear: year,
		});
		const myQuarter = quarters.find(({ startDate, endDate }) => today >= startDate && today <= endDate);
		return myQuarter;
	};

	const [referenceDate, setReferenceDate] = useState(
		periodicity_type === PeriodicityTypes.QUARTELY ? getMyQuarter() : getExtremesDatesOfMonth(),
	);

	const parsedAppointments = useMemo(() => {
		return Object.values(initialAppointments).reduce((prev, { price, days, contract, mission }) => {
			const daysKeys = Object.keys(days);

			prev.push({
				mission,
				contract,
				payment_date: dateByDateMoment(daysKeys[daysKeys.length - 1], 'DD/MM/YYYY', locale).toISOString(),
				sum: price,
				tva,
				start_date: dateByDateMoment(daysKeys[0], 'DD/MM/YYYY', locale).toISOString(),
				end_date: dateByDateMoment(daysKeys[daysKeys.length - 1], 'DD/MM/YYYY', locale).toISOString(),
			});

			return prev;
		}, []);
	}, [initialAppointments, locale, tva]);

	const payments = useMemo(() => {
		const appointmentsWithoutPayments = parsedAppointments.filter(
			({ contract }) => !!!initialPayments.find(({ contract: paymentContract }) => paymentContract === contract),
		);

		return [...appointmentsWithoutPayments, ...(!onlyAppointments ? initialPayments : [])].filter(
			({ payment_date }) => {
				const toCompare = new Date(payment_date);
				return toCompare >= referenceDate.startDate && toCompare <= referenceDate.endDate;
			},
		);
	}, [initialPayments, parsedAppointments, onlyAppointments, referenceDate]);

	const columns = useMemo(
		() => [
			{
				title: bookkeepingLang.contract,
				field: 'contract',
				render: (rowData) =>
					!!rowData.contract ? (
						<Button type="black" external link={getFileByIdVisualisationUrl(getToken(), rowData.invoice)}>
							{bookkeepingLang.navigateToInvoice}
						</Button>
					) : (
						'Facturation hors plateforme'
					),
			},
			{
				title: bookkeepingLang.mission,
				field: 'mission',
				render: (rowData) => (
					<Button link={`/mission/${rowData.mission}/contract/${rowData.contract}`}>
						{bookkeepingLang.navigateToMission}
					</Button>
				),
			},
			{ title: bookkeepingLang.sum, field: 'sum', type: 'numeric' },
			{ title: bookkeepingLang.start_date, field: 'start_date', type: 'date' },
			{ title: bookkeepingLang.end_date, field: 'end_date', type: 'date' },
			{ title: bookkeepingLang.payment_date, field: 'payment_date', type: 'date' },
			{ title: bookkeepingLang.tva, render: ({ tva }) => (!!tva ? bookkeepingLang.yes : bookkeepingLang.no) },
		],
		[bookkeepingLang],
	);

	const getMyQuarterIndex = () => {
		const today = getTodayMidnight();
		const year = today.getFullYear();
		const quarters = QuarterDates({
			startYear: year,
			endYear: year,
		});
		const myQuarterIndex = quarters.findIndex(({ startDate, endDate }) => today >= startDate && today <= endDate);
		return myQuarterIndex;
	};

	//initialisation à la période actuelle
	const initActual = useCallback((periodicity_type, years) => {
		const today = getTodayMidnight();
		setYear(years.indexOf(today.getFullYear()));
		setPeriodicity(getMyQuarterIndex() + 1);
		if (periodicity_type === PeriodicityTypes.QUARTELY) {
			const myQuarter = getMyQuarter();
			setReferenceDate(myQuarter);
		} else {
			setReferenceDate({
				startDate: getFirstDayOfMonth(today),
				endDate: getLastDayOfMonth(today),
			});
		}
	}, []);

	const periodicityItems = useMemo(
		() =>
			periodicity_type === PeriodicityTypes.QUARTELY
				? bookkeepingLang.quarters.map((label, value) => ({ label, value }))
				: bookkeepingLang.months.map((label, value) => ({ label, value })),
		[periodicity_type, bookkeepingLang],
	);

	const onPeriodicityChange = useCallback(
		(value) => {
			const startYear = referenceDate.startDate.getFullYear();
			const endYear = referenceDate.endDate.getFullYear();
			if (value === 0) {
				return setReferenceDate({
					startDate: new Date(startYear, 0, 1),
					endDate: new Date(endYear, 11, 31),
				});
			}
			if (periodicity_type === PeriodicityTypes.QUARTELY) {
				return setReferenceDate(
					QuarterDates({
						startYear,
						endYear,
					})[value - 1],
				);
			}
			const month = ('0' + value).slice(-2);
			return setReferenceDate({
				startDate: getFirstDayOfMonth(new Date(`${startYear}-${month}-05`)),
				endDate: getLastDayOfMonth(new Date(`${endYear}-${month}-05`)),
			});
		},
		[periodicity_type, referenceDate],
	);

	const handleChangePeriodicity = useCallback(
		({ target: { value: newValue } }) => {
			setPeriodicity(Number(newValue));
			onPeriodicityChange(Number(newValue));
		},
		[onPeriodicityChange],
	);

	const periodicityButton = useMemo(
		() =>
			periodicity_type === PeriodicityTypes.QUARTELY ? (
				<SelectButton
					label={bookkeepingLang.quarter}
					items={periodicityItems}
					onChange={handleChangePeriodicity}
					value={periodicity}
				/>
			) : (
				<SelectButton
					label={bookkeepingLang.month}
					items={periodicityItems}
					onChange={handleChangePeriodicity}
					value={periodicity}
				/>
			),
		[periodicity_type, bookkeepingLang, periodicityItems, handleChangePeriodicity, periodicity],
	);

	const currentYear = new Date().getFullYear();

	const years = useMemo(() => {
		let startYear = new Date(created_at).getFullYear();
		const years = [bookkeepingLang.allYears];
		startYear = startYear || 1980;
		while (startYear <= currentYear) {
			years.push(startYear++);
		}
		return years;
	}, [created_at, bookkeepingLang, currentYear]);

	const onYearChange = (value) => {
		if (value === 0) {
			return setReferenceDate({
				startDate: new Date(`${new Date(created_at).getFullYear()}-01-01`),
				endDate: new Date(`${currentYear}-12-31`),
			});
		}
		const startDate = new Date(referenceDate.startDate);
		startDate.setFullYear(years[value]);
		const endDate = new Date(referenceDate.endDate);
		endDate.setFullYear(years[value]);
		return setReferenceDate({
			startDate,
			endDate,
		});
	};

	const reset = () => {
		handleChangePeriodicity({ target: { value: 0 } });
		handleChangeYear({ target: { value: 0 } });
	};
	const [year, setYear] = useState(0);

	const handleChangeYear = ({ target: { value: newValue } }) => {
		setYear(newValue);
		onYearChange(newValue);
	};

	useEffect(() => {
		if (periodicity == null && years && years.length > 0) {
			initActual(periodicity_type, years);
		}
	}, [periodicity_type, years, periodicity, initActual]);

	const allYearPayments = useMemo(() => {
		const startDate = new Date(`${referenceDate.startDate.getFullYear()}-01-01`);
		const endDate = new Date(`${referenceDate.endDate.getFullYear()}-12-31`);
		return payments.filter(({ payment_date }) => {
			const toCompare = new Date(payment_date);
			return toCompare >= startDate && toCompare <= endDate;
		});
	}, [payments, referenceDate]);

	return (
		<div>
			<div className={classNames(classes.mb1, classes.buttonContainer)}>
				<Button onClick={reset}>RESET</Button>
				<SelectButton
					label={bookkeepingLang.year}
					items={years.map((label, value) => ({ label, value }))}
					onChange={handleChangeYear}
					value={year}
				/>
				{year === 0 ? <h4>{bookkeepingLang.selectYear}</h4> : periodicityButton}
			</div>

			<BookkeepingNumbers payments={payments} year={years[year]} allYearPayments={allYearPayments} />

			<MyTable columns={columns} data={payments} title={bookkeepingLang.transactionList} />
		</div>
	);
};

export default BookkeepingTable;

import { getImageUrl } from 'containers/helpers';
import useLanguage from 'hooks/useLanguage';
import { useEffect, useMemo, useState } from 'react';
import { upperFirst } from 'lodash';
import TitlePage from 'components/TitlePage';
import RightSide from 'components/RightSide/RightSide';
import classes from './CustomerPage.module.scss';
import { getCustomerDisplayName, getCustomerForFreelance } from '../helpers';
import CustomerPublicProfile from 'components/CustomerPublicProfile/CustomerPublicProfile';

const CustomerPublicProfileContainer = ({ isPage = false, userId = false }) => {
	const lang = useLanguage('customer');
	const [customer, setCustomer] = useState();

	useEffect(() => {
		if (userId) {
			getCustomerForFreelance(userId).then(setCustomer);
		}
	}, [userId]);

	const imageUrl = useMemo(() => customer && customer.image && getImageUrl(customer.image), [customer]);
	const displayName = useMemo(() => customer && getCustomerDisplayName({ ...customer }), [customer]);

	const component = useMemo(
		() =>
			!!customer && (
				<div className={classes.container}>
					<CustomerPublicProfile
						img={imageUrl}
						address={customer.address}
						phone={customer.phone}
						displayName={displayName}
						rating={customer.rating}
						nRatings={customer.nRatings}
					/>
				</div>
			),
		[displayName, imageUrl, customer],
	);
	return !!customer ? (
		isPage ? (
			<RightSide>
				<TitlePage>
					{upperFirst(lang.customer)} {displayName}
				</TitlePage>

				{component}
			</RightSide>
		) : (
			component
		)
	) : null;
};

export default CustomerPublicProfileContainer;

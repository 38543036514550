import { useCallback, useMemo } from 'react';
import { useStoreUtils } from './useStoreUtils';

export const useProfession = () => {
	const { state: { user, professionsReceived, languageValue, professions } } = useStoreUtils()

	const getProfessionLang = useCallback((professionId) => {
		return (
			professionsReceived
				.find(p => p.id === professionId) || {}
		)[languageValue]
	}, [professionsReceived, languageValue])
	const userProfessions = useMemo(() =>
		professionsReceived
			.filter(p => user.professions.includes(p.id))
			.map(p => ({ ...p, name: p[languageValue] })),
		[professionsReceived, user, languageValue])
	return {
		getProfessionLang,
		userProfessions,
		professionsLang: professions
	};
};

import StatusMissionComponent from 'components/StatusMission/StatusMissionComponent'
import React from 'react'
import classes from 'styles/classes.module.scss'
import cn from 'classnames'
import useLanguage from 'hooks/useLanguage'

const GroupedDaysStatus = ({
	proposalsLength = 0,
	appointmentsLength = 0,
	openDaysLength = 0,
}) => {
	const lang = useLanguage('mission')

	return (
		<div className={cn(classes.flex, classes.gap1)}>
			{!!openDaysLength && <StatusMissionComponent
				eventType={'open'}
				langStatus={lang.openDaysCount(openDaysLength)}
			/>}
			{!!proposalsLength && <StatusMissionComponent
				eventType={'proposal'}
				langStatus={lang.proposalsCount(proposalsLength)}
			/>}
			{!!appointmentsLength && <StatusMissionComponent
				eventType={'appointment'}
				langStatus={lang.appointmentsCount(appointmentsLength)}
			/>}
		</div>
	)
}

export default GroupedDaysStatus
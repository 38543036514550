import React from 'react';
import FiltersTable from './FiltersTable';
import classes from '../../styles/classes.module.scss'
import SearchTable from './SearchTable';
import { useTableHeader } from './useTableHeader';
import classNames from 'classnames';

const HeaderTable = ({
    rows = [],
    setRows = () => { },
    filters: filtersFromParams = null
}) => {
    const { updateFilters } = useTableHeader(rows, setRows)

    return (
        <div className={classNames( classes.flex,  classes.flexWrap)}>
            <SearchTable
                updateFilters={value => updateFilters('search', value)}
            />
            {filtersFromParams && <FiltersTable
                filters={filtersFromParams}
                setRows={setRows}
                updateFilters={value => updateFilters('filter', value)}

            />}
        </div>
    );
};

export default HeaderTable;
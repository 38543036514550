import React, { useContext }  from 'react'
import { Store } from '../../store/store'
import LanguageSelection from '../LanguageSelection'
import UnAuthLayout from './UnAuthLayout'
import useIsMobile from 'hooks/useIsMobile'
import classes from './UnAuthLayout.module.scss'
import UnAuthRightSide from './UnAuthRightSide'
import LeftSide from './LeftSide'

const UnLogGrid = ({
	page = 'login',
	message = '',
	inputs,
	sendForm: sendFormFromParams,
	messageLinkeable,
	goToUserTypePage,
	submitMessage,
}) => {
	const isMobile = useIsMobile()

	const {
		state: {
			language: { login: copy },
		},
	} = useContext(Store)

	return (
		<UnAuthLayout>
			{isMobile ? <LeftSide
				page={page}
				message={message}
				inputs={inputs}
				sendForm={sendFormFromParams}
				messageLinkeable={messageLinkeable}
				goToUserTypePage={goToUserTypePage}
				submitMessage={submitMessage}
			/> : <>
				<LeftSide
					page={page}
					message={message}
					inputs={inputs}
					sendForm={sendFormFromParams}
					messageLinkeable={messageLinkeable}
					goToUserTypePage={goToUserTypePage}
					submitMessage={submitMessage}
				/>
				<UnAuthRightSide lang={copy} />
				<div className={classes.language}>
					<LanguageSelection />
				</div>
			</>}
		</UnAuthLayout>
	)
}

export default UnLogGrid

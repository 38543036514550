import Calendar from 'components/Calendar';
import React, { Children, cloneElement, useCallback, useEffect, useState } from 'react';
import { Popover } from 'antd';
import dayStatusEventProp from './dayStatusEventProp';
import { appointmentBtocToEvents, convertTimeZoneFromDate } from 'modules/dates';
import { styleDayStatus } from 'components/Calendar/helpers';
import { isEmpty } from 'lodash';
import { useDayStatusesLang } from 'hooks/useDayStatusesLang';
import useLanguage from 'hooks/useLanguage';
import AppointmentButton from 'containers/appointment/components/AppointmentButton';

const SingleAppointmentCalendar = ({ appointment, cancelAppointment = () => {} }) => {
	const [events, setEvents] = useState([]);
	const lang = useLanguage('mission');
	const [loading, setLoading] = useState(true);
	const appointmentDays = appointment?.days || {};
	const dayStatusesLang = useDayStatusesLang();

	function BackgroundWrapper(props) {
		const { children, value } = props;
		const dateTs = convertTimeZoneFromDate(value);
		const child = Children.only(props.children);

		if (appointmentDays[dateTs]) {
			return cloneElement(child, {
				style: { ...children.style, ...styleDayStatus[appointmentDays[dateTs].dayStatus] },
			});
		}
		return children;
	}

	const EventWrapper = useCallback(
		(props) => {
			const { children, event } = props;

			return (
				<Popover
					title={event.title}
					trigger="click"
					content={
						<AppointmentButton {...event} appointment={event.id} cancelAppointment={cancelAppointment} />
					}
				>
					{children}
				</Popover>
			);
		},
		[cancelAppointment],
	);

	useEffect(() => {
		if (isEmpty(appointment)) return;

		setEvents(appointmentBtocToEvents(appointment, dayStatusesLang, lang, false));
		setLoading(false);
	}, [appointment, dayStatusesLang, lang]);

	const components = useCallback(
		{
			eventWrapper: EventWrapper,
			dateCellWrapper: BackgroundWrapper,
		},
		[EventWrapper],
	);

	return (
		<Calendar
			defaultDate={events && events.length ? new Date(events[0]?.start) : new Date()}
			components={components}
			eventPropGetter={dayStatusEventProp}
			events={events}
			style={{ height: '600px', width: '100%' }}
			loading={loading}
		/>
	);
};

export default SingleAppointmentCalendar;

import IssueListTable from './IssueListTable'
import IssueLinkButton from 'components/IssueLinkButton'
import classes from 'styles/classes.module.scss'
import { useCallback, useState, useEffect } from 'react'
import { useStoreUtils } from 'hooks/useStoreUtils'
import { getIssues } from './helper'

function Issues({ src, type = null, elementId = null }) {
	const [data, setData] = useState([])
	const { setTableLoadingOn, setTableLoadingOff } = useStoreUtils()
	
	const initIssues = useCallback(
		() => getIssues(src)
			.then(setData)
			.finally(setTableLoadingOff),
		[src]
	)

	useEffect(() => {
		setTableLoadingOn()
		initIssues()
	}, [])

	return <div>
		<div className={classes.mb1}>
			<IssueLinkButton isButton
				{...(type != null ? { type } : {})}
				{...(elementId != null ? { elementId } : {})}
			/>
		</div>
		<IssueListTable issues={data} />
	</div>
}

export default Issues

import RightSide from 'components/RightSide/RightSide';
import { UserTypes } from 'constants/UserTypes';
import AddAddress from 'containers/onboard/components/AddAddress';
import InfosForm from 'containers/onboard/components/InfosForm';
import useLanguage from 'hooks/useLanguage';
import React, { useContext, useMemo } from 'react';
import { Store } from 'store/store';
import TitlePage from 'components/TitlePage';
import MenuNavigationComponent from 'containers/mission/components/MenuNavigationComponent';
import classes from 'styles/classes.module.scss';
import HourlyRate from 'containers/onboard/components/HourlyRate';

const UserUpdateInfos = () => {
	const {
		state: { user },
	} = useContext(Store);
	const isFreelance = useMemo(() => (user && user.type != null ? user.type === UserTypes.FREELANCE : false), [user]);
	const lang = useLanguage('profile');

	const components = useMemo(
		() =>
			[
				{
					title: lang.menuTitles[0],
					component: <InfosForm />,
				},
				{
					title: lang.menuTitles[1],
					component: <AddAddress />,
				},
				// isFreelance && {
				//     title: lang.menuTitles[2],
				//     component: <BankAccountOnboard />
				// },
				isFreelance && {
					title: lang.menuTitles[3],
					component: <HourlyRate />,
				},
			].filter((e) => !!e),
		[lang, isFreelance],
	);
	return (
		<RightSide>
			<TitlePage>{lang.updateInfoButton}</TitlePage>
			<div className={classes.formsChildLimited}>
				<MenuNavigationComponent components={components} />
			</div>
		</RightSide>
	);
};

export default UserUpdateInfos;

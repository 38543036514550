import React, { useCallback, useMemo, useState } from 'react';
import StyledModal from 'components/StyledModal';
import Button from 'components/Button';
import ConfirmAddDates from './components/ConfirmAddDates';
import ConfirmRemoveDates from './components/ConfirmRemoveDates';
import 'common/components/DisabledDates/DisabledDates.css';
import Calendar from 'components/Calendar';
import {
	removeWeekends,
	onlyWeekends,
	isSameDay,
	getSlotsFromRecurrence,
	getTodayMidnightMoment,
	slotsToDays,
	convertTimeZoneFromDate,
	getFavoriteDayStatus,
	date2moment,
} from 'modules/dates';
import { arr2obj } from 'modules/data';
import './Scheduler.scss';
import RecurrenceForm from './components/RecurrenceForm';
import toast from 'components/toast';
import scheduleModes from './constants/scheduleModes';
import useLanguage from 'hooks/useLanguage';
import moment from 'moment-timezone';
import { styleDayStatus } from 'components/Calendar/helpers';
import { useStoreUtils } from 'hooks/useStoreUtils';
import { UserTypes } from 'constants/UserTypes';
import { isEmpty } from 'lodash';

const _6AM = new Date(2020, 1, 0, 6, 0, 0);
const _8PM = new Date(2020, 1, 0, 20, 0, 0);

function SelectableScheduler({
	loading,
	scheduledDates,
	pushDates,
	deleteDates,
	events = [],
	moreComponents,
	eventPropGetter,
	monthOnly = true,
	showDayStatus = false,
	showFavoriteDayStatus = false,
}) {
	const {
		state: {
			user: { favorites_slots = [], type },
		},
	} = useStoreUtils();
	const now = getTodayMidnightMoment();
	const [editMode, setEditMode] = useState(scheduleModes.add);
	const [modalOpen, setModalOpen] = useState(false);
	const [recurrenceFormOpen, setRecurrenceFormOpen] = useState(false);
	const [selectedDays, setSelectedDays] = useState({});
	const [filterWeekends, setFilterWeekends] = useState(false);
	const lang = useLanguage('scheduler');
	const recurrenceFormHeaders = useMemo(() => lang.recurrenceFormHeader, [lang]);
	const inCreateMode = useMemo(() => editMode === scheduleModes.add, [editMode]);
	const slots = useMemo(
		() =>
			arr2obj({
				arr: Object.values(scheduledDates || {})
					.map(({ dates }) => dates)
					.flat()
					.map(convertTimeZoneFromDate),
				value: true,
			}),
		[scheduledDates],
	);

	const selectedDaysLen = useMemo(() => Object.keys(selectedDays).length, [selectedDays]);

	const toggleFilterWeekends = useCallback(() => {
		setFilterWeekends(!filterWeekends);
	}, [filterWeekends]);

	const BackgroundWrapper = useCallback(
		(props) => {
			const { children, value } = props;
			const dateTs = moment(value);
			const child = React.Children.only(props.children);

			//selected look
			if (child.props.className.includes('rbc-selected-cell')) {
				return React.cloneElement(child, {
					className: `${child.props.className} ${
						editMode === scheduleModes.remove ? 'scheduler-remove-selected' : 'scheduler-add'
					}`,
				});
			}

			if (now.isAfter(dateTs)) {
				return React.cloneElement(child, {
					className: child.props.className + ' rbc-off-range-bg',
					style: {
						...children.style,
						pointerEvents: 'none !important',
					},
				});
			}

			const isScheduled = scheduledDates[dateTs.format('DD/MM/YYYY')];
			if (!isScheduled) {
				return props.children;
			}

			//scheduled look
			return React.cloneElement(child, {
				className: `${child.props.className} ${
					editMode === scheduleModes.remove ? 'scheduler-remove' : 'scheduler-add'
				}`,
				style: {
					...children.style,
					...styleDayStatus[isScheduled.dayStatus],
				},
			});
		},
		[editMode, now, scheduledDates],
	);

	const onSelectSlot = useCallback(
		({ start, end }) => {
			if (loading) return;
			if (moment(start).isSameOrBefore(moment())) return;

			if (isSameDay(start, end)) {
				start = moment(start).startOf('day');
				end = moment(start).endOf('day');
			} else {
				start = moment(start);
				end = moment(end).subtract(1, 'seconds');
			}

			if (start.isBefore(now)) {
				return;
			}

			const _slots = [];
			while (start.isSameOrBefore(end)) {
				_slots.push(start);
				start = moment(start).add(15, 'minutes');
			}

			const nextTimeStamps = _slots
				.map(convertTimeZoneFromDate)
				.filter((slot) => !events.some(({ start }) => date2moment(start).isSame(date2moment(slot), 'day'))); // in unix format (array of numbers)
			let nextSelectedDates = null;
			if (inCreateMode) {
				const oldDates = nextTimeStamps.filter((ts) => slots[ts]);

				// If user only selected dates that are already scheduled, goes into cancel mode
				if (oldDates.length !== 0) {
					nextSelectedDates = slotsToDays(nextTimeStamps, 'day');

					setEditMode(scheduleModes.remove);
					setSelectedDays(nextSelectedDates);
					openConfirmChangeModal();
					return;
				}

				nextSelectedDates = slotsToDays(
					nextTimeStamps,
					'day',
					type === UserTypes.STUDIO ? favorites_slots : null,
				);
			} else {
				const datesToRemove = nextTimeStamps.filter((ts) => slots[ts]);

				if (datesToRemove.length === 0) return;

				nextSelectedDates = slotsToDays(
					nextTimeStamps,
					'day',
					type === UserTypes.STUDIO ? favorites_slots : null,
				);
			}

			if (isEmpty(nextSelectedDates)) return;

			setSelectedDays(nextSelectedDates);
			openConfirmChangeModal();
		},
		[inCreateMode, now, slots, favorites_slots, type, events, loading],
	);

	const removeSelectedDates = useCallback(async () => {
		if (selectedDaysLen <= 0) return;

		deleteDates(Object.keys(selectedDays));
		resetSelection();
		setEditMode(scheduleModes.add);
	}, [deleteDates, selectedDays, selectedDaysLen]);

	const scheduleSelectedDates = useCallback(() => {
		let selectedDaysKeys = Object.keys(selectedDays).map((date) => moment(date, 'DD/MM/YYYY').unix());
		const keysLen = selectedDaysKeys.length;
		let nextScheduledDates = {};
		let newDaysLen = 0;

		if (filterWeekends && keysLen > 1 && !onlyWeekends(selectedDaysKeys)) {
			selectedDaysKeys = removeWeekends(selectedDaysKeys);
			newDaysLen = selectedDaysKeys.length;
			const selectedDaysWithoutWeekends = { ...selectedDays };
			Object.keys(selectedDays).map((key) => !!!selectedDaysKeys[key] && delete selectedDaysWithoutWeekends[key]);
			nextScheduledDates = selectedDaysWithoutWeekends;
		} else {
			newDaysLen = keysLen;
			nextScheduledDates = { ...selectedDays };
		}

		if (newDaysLen > 0) {
			pushDates(nextScheduledDates);
		}
		resetSelection();
	}, [filterWeekends, pushDates, selectedDays]);

	const resetSelection = () => {
		setSelectedDays({});
		setModalOpen(false);
	};

	const components = {
		dateCellWrapper: BackgroundWrapper,
		...moreComponents,
	};

	const openConfirmChangeModal = () => setModalOpen(true);
	const closeConfirmChangeModal = () => setModalOpen(false);
	const openRecurrenceForm = () => setRecurrenceFormOpen(true);
	const closeRecurrenceModal = () => setRecurrenceFormOpen(false);

	const toggleScheduleMode = useCallback(() => {
		if (editMode === scheduleModes.add) {
			setEditMode(scheduleModes.remove);
		}
		if (editMode === scheduleModes.remove) {
			setEditMode(scheduleModes.add);
		}
	}, [editMode]);

	const popSelectedDate = useCallback(
		(key) => {
			let nextDays = { ...selectedDays };
			delete nextDays[key];

			if (Object.keys(nextDays).length <= 0) closeConfirmChangeModal();
			setSelectedDays(nextDays);
		},
		[selectedDays],
	);

	const updateDaysStatus = useCallback(
		(key, index) => {
			const nextDays = { ...selectedDays };

			nextDays[key] = {
				...nextDays[key],
				dayStatus: index,
				...(type === UserTypes.STUDIO
					? { favoriteDayStatus: getFavoriteDayStatus(index, nextDays[key].dates[0], favorites_slots) }
					: {}),
			};
			setSelectedDays(nextDays);
		},
		[selectedDays, favorites_slots, type],
	);

	const updateFavoriteDayStatus = useCallback(
		(key, favoriteDayStatus) => {
			const nextDays = { ...selectedDays };

			nextDays[key] = {
				...nextDays[key],
				favoriteDayStatus,
			};

			setSelectedDays(nextDays);
		},
		[selectedDays],
	);

	const inRemoveMode = useMemo(() => editMode === scheduleModes.remove, [editMode]);

	const onRemoveRecurrence = useCallback(
		(recurr) => {
			const slotsToRemoveDates = getSlotsFromRecurrence(recurr);

			if (!slotsToRemoveDates.length) {
				toast.error(lang.noDatesToCancel);
				closeRecurrenceModal();
				return;
			}

			const slotsToRemove = slotsToRemoveDates
				.map((d) => convertTimeZoneFromDate(d))
				.filter((date) => slots[date]);

			if (slotsToRemove.length > 0) {
				const daysToDelete = slotsToDays(slotsToRemove, 'day');
				const daysToDeleteKeys = Object.keys(daysToDelete);

				deleteDates(daysToDeleteKeys);
				resetSelection();
				setEditMode(scheduleModes.add);
				toast.success(lang.nRecurringDatesRemoved(daysToDeleteKeys.length));
			} else {
				toast(lang.noDatesToCancel);
			}

			closeRecurrenceModal();
		},
		[deleteDates, lang, slots],
	);

	const onAddRecurrence = useCallback(
		(recurr) => {
			const slotsToAdd = getSlotsFromRecurrence(recurr);

			if (!slotsToAdd.length) {
				toast.error(lang.noDatesToProgram);
				closeRecurrenceModal();
				return;
			}

			const newSlots = slotsToAdd.map((d) => convertTimeZoneFromDate(d)).filter((date) => !slots[date]);

			if (newSlots.length > 0) {
				const newDays = slotsToDays(newSlots, 'day', type === UserTypes.STUDIO ? favorites_slots : null);
				pushDates(newDays);
				toast.success(lang.nRecurringDatesAdded(Object.keys(newDays).length));
			} else {
				toast(lang.noDatesToCancel);
			}
			closeRecurrenceModal();
		},
		[lang, pushDates, slots, favorites_slots, type],
	);

	return (
		<>
			<div className={'buttonWrapper'}>
				<Button type="primary" onClick={openRecurrenceForm}>
					{editMode === scheduleModes.add ? lang.addRecurrence : lang.cancelRecurrence}
				</Button>
				<Button type={editMode === scheduleModes.add ? 'warning' : 'success'} onClick={toggleScheduleMode}>
					{editMode === scheduleModes.add ? lang.cancelPlannedDates : lang.done}
				</Button>
			</div>
			<Calendar
				defaultDate={events && events.length ? new Date(events[0]?.start) : new Date()}
				monthOnly={monthOnly}
				eventPropGetter={eventPropGetter}
				events={events}
				loading={loading}
				components={components}
				selectable={true}
				onSelectSlot={onSelectSlot}
				style={{ height: '600px', width: '100%' }}
				timeslots={4}
				step={30}
				min={_6AM}
				max={_8PM}
			/>
			<StyledModal
				open={recurrenceFormOpen}
				closeIcon={true}
				closeOnEscape={true}
				onClose={closeRecurrenceModal}
				closeOnDimmerClick={true}
				title={recurrenceFormHeaders[editMode]}
			>
				<RecurrenceForm
					editMode={editMode}
					onClose={closeRecurrenceModal}
					onChangeRecurrence={inCreateMode ? onAddRecurrence : onRemoveRecurrence}
					open={recurrenceFormOpen}
				/>
			</StyledModal>
			{modalOpen && inCreateMode && (
				<ConfirmAddDates
					days={selectedDays}
					open={modalOpen && inCreateMode}
					filterWeekends={filterWeekends}
					removeDate={popSelectedDate}
					toggleFilterWeekends={toggleFilterWeekends}
					onCancel={resetSelection}
					onSubmit={scheduleSelectedDates}
					loading={loading}
					showDayStatus={showDayStatus}
					showFavoriteDayStatus={showFavoriteDayStatus}
					updateDaysStatus={updateDaysStatus}
					updateFavoriteDayStatus={updateFavoriteDayStatus}
				/>
			)}
			{modalOpen && inRemoveMode && (
				<ConfirmRemoveDates
					scheduledDates={slots}
					open={modalOpen && inRemoveMode}
					daysToRemove={selectedDays}
					removeDate={popSelectedDate}
					onCancel={resetSelection}
					onSubmit={removeSelectedDates}
					removeView={true}
					loading={loading}
				/>
			)}
		</>
	);
}

export default SelectableScheduler;

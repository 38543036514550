import { useNavigate } from 'react-router-dom'
import { Modal, Button } from 'semantic-ui-react'

import UnLogGrid from 'components/UnLogGrid/UnLogGrid'

const LoginForm = ({
    inputs = [],
    sendForm = () => {},
    modal = false,
    forgotPassword = () => {},
    setModal,
    forgotPasswordLang,
    errorTitleLang,
    validLang,
    redirectConfirmLang,
    errorLogin = { validated: true, message: '' },
    noConfirmationMailLang,
}) => {
    const navigate = useNavigate()
    const goToConfirmAccount = () => {
        navigate('/confirmAccount')
    }

    const goToUserTypePage = (userType) => navigate(`/login/${userType}`)

    return (
        <>
            <UnLogGrid
				page='login'
                submitMessage={validLang}
                inputs={inputs}
                sendForm={sendForm}
                messageLinkeable={{
                    message: forgotPasswordLang,
                    onClick: forgotPassword,
                }}
                noConfirmationMailLang={{
                    message: noConfirmationMailLang,
                    onClick: goToConfirmAccount,
                }}
                goToUserTypePage={goToUserTypePage}
            />
            <Modal
                open={modal}
                onClose={() => setModal(false)}
                dimmer="inverted"
                closeIcon
                className="modal-login-container"
            >
                <Modal.Header>{errorTitleLang}</Modal.Header>
                <Modal.Content>
                    <Modal.Description>{errorLogin.message}</Modal.Description>
                </Modal.Content>
                <Button
                    className="modal-login-button"
                    onClick={errorLogin.validated ? () => setModal(false) : goToConfirmAccount}
                >
                    {!errorLogin.validated ? redirectConfirmLang : 'Ok'}
                </Button>
            </Modal>
            </>
    )
}

export default LoginForm
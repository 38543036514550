import languages from '../languages'
import { isEqual } from 'lodash'
import { getUser } from '../containers/helpers'
import createMissionLocalStorageKey from 'containers/mission/constants/localStorageKey'

const initialUser = getUser

const initialSocket = {
	status: '',
	message: '',
}

const initialCreateMissionForm = {
	title: '',
	description: '',
}

const createMissionForm = localStorage.getItem(createMissionLocalStorageKey)
	? JSON.parse(localStorage.getItem(createMissionLocalStorageKey))
	: initialCreateMissionForm
const defaultEmails = []

export const initialState = () => ({
	socketConnected: false,
	users: [],
	mails: defaultEmails,
	mailsLength: 0,
	notifications: [],
	alerts: [],
	user: initialUser(),
	languageValue: 'fr',
	language: languages.fr,
	languageHidden: false,
	rightSideLoading: false,
	menu: true,
	stripe: initialSocket,
	folderId: 0,
	folderType: 'private',
	parentType: 'folder',
	smtp: false,
	enums: {
		OnboardingStatuses: {},
		UserTypes: {},
		UserRoles: {},
	},
	createMissionForm,
	badges: {},
	didactiels: {},
	tableLoading: false,
	professionsReceived: [],
	professions: [],//lang parsed
})

const namesReducer = (reducer, name) => {
	const namedReducer = {}
	if (!name) {
		return reducer
	}
	Object.keys(reducer).map((key) => {
		const objectReducer = (state, { payload }) => ({
			...state,
			[name.toLowerCase()]: reducer[key](state[name.toLowerCase()], {
				payload,
			}),
		})
		return (namedReducer[`${name}/${key}`] = objectReducer)
	})
	return namedReducer
}

const addMail = (mails, newMail) =>
	mails.find((mail) => isEqual(mail, newMail)) ? mails : [...mails, newMail]

const APIreducer = {
	RESET: initialState,
	INIT_NOTIFICATIONS: (state, { payload }) => ({
		...state,
		notifications: [...new Set([...payload].map(JSON.stringify))].map(
			JSON.parse
		),
	}),
	ADD_NOTIFICATION: (state, { payload }) => ({
		...state,
		notifications: [
			...new Set([...state.notifications, payload].map(JSON.stringify)),
		].map(JSON.parse),
	}),
	REMOVE_NOTIFICATION: (state, { payload }) => ({
		...state,
		notifications: state.notifications.filter(
			(notification) => notification.id !== payload
		),
	}),
	REMOVE_PERSISTANT_FROM_NOTIFICATION: (state, { payload }) => ({
		...state,
		notifications: state.notifications.map((notification) =>
			notification.id === payload
				? { ...notification, persistant: false, inProgress: false }
				: notification
		),
	}),
	READ_NOTIFICATION: (state, { payload }) => ({
		...state,
		notifications: state.notifications.map((notification) =>
			notification.id === payload
				? { ...notification, read: true }
				: { ...notification }
		),
	}),
	READ_ALL_NOTIFICATIONS: (state) => ({
		...state,
		notifications: state.notifications.map((notification) => ({
			...notification,
			read: true,
		})),
	}),
	REMOVE_ALL_NOTIFICATIONS: (state) => ({ ...state, notifications: [] }),
	CHANGE_LANGUAGE: (state, { payload = 'fr' }) => {
		localStorage.setItem('language', payload)
		return {
			...state,
			language: languages[payload],
			languageValue: payload,
			professions: state.professionsReceived.map(obj => obj[payload])
		}
	},
	SHOW_MENU: (state) => ({ ...state, menu: true }),
	HIDE_MENU: (state) => ({ ...state, menu: false }),
	HIDE_LANGUAGE: (state) => ({ ...state, languageHidden: true }),
	LOADING_ON: (state) => ({ ...state, rightSideLoading: true }),
	LOADING_OFF: (state) => ({ ...state, rightSideLoading: false }),
	SMTP_CONNECT: (state) => ({ ...state, smtp: true }),
	SMTP_DISCONNECT: (state) => ({ ...state, smtp: false, mails: [] }),
	ADD_MAIL: (state, { payload }) => ({
		...state,
		mails: addMail(state.mails, payload),
	}),
	SEE_MAIL: (state, { payload }) => ({
		...state,
		mails: state.mails.map((mail, index) =>
			index === payload ? { ...mail, seen: true } : mail
		),
	}),
	CHARGE_MAILS: (state, { payload }) => ({ ...state, mails: [...payload] }),
	MAILS_DISTANT_LENGHT: (state, { payload }) => ({
		...state,
		mailsLength: payload,
	}),
	UPDATE_STRIPE_SOCKET: (state, { payload }) => ({ ...state, stripeResponse: payload }),
	UPDATE_FOLDER: (state, { payload }) => ({ ...state, ...payload }),
	ADD_ALERT: (state, { payload }) => ({
		...state,
		alerts: [...state.alerts, { ...payload }],
	}),
	REMOVE_ALERT: (state, { payload }) => ({
		...state,
		alerts: state.alerts.filter((alert, id) => id !== payload),
	}),
	CREATE_MISSION__FORM_DATA__SET: (state, { payload }) => {
		localStorage.setItem(
			createMissionLocalStorageKey,
			JSON.stringify(payload)
		)
		return {
			...state,
			createMissionForm: { ...payload },
		}
	},
	CREATE_MISSION__FORM_DATA__UNSET: (state) => {
		if (localStorage.getItem(createMissionLocalStorageKey)) {
			localStorage.removeItem(createMissionLocalStorageKey)
		}

		return {
			...state,
			createMissionForm: { ...initialCreateMissionForm },
		}
	},
	CHARGE_ENUMS: (state, { payload }) => ({
		...state,
		enums: { ...state.enums, ...payload },
	}),
	UPDATE_ONBOARD_STATUS: (state, { payload }) => ({
		...state,
		user: { ...state.user, status: payload },
	}),
	INIT_USERS: (state, { payload }) => ({ ...state, users: [...payload] }),
	INIT_BADGES: (state, { payload }) => ({ ...state, badges: payload }),
	SOCKET_CONNECTED: (state, { payload }) => ({ ...state, socketConnected: true }),
	SOCKET_DISCONNECTED: (state, { payload }) => ({ ...state, socketConnected: false }),
	UPDATE_DIDACTIELS: (state, { payload }) => ({ ...state, didactiels: payload }),
	UPDATE_DIDACTIEL: (state, { payload }) => ({ ...state, didactiels: { ...state.didactiels, [payload]: true } }),

	UPDATE_TABLE_LOADING: (state, { payload: tableLoading }) => ({ ...state, tableLoading }),
	UPDATE_PROFESSIONS: (state, { payload }) => ({
		...state,
		professionsReceived: payload,
		professions: payload.map(obj => obj[state.languageValue])
	}),
}

const userReducer = {
	INIT: (state, { payload }) => ({ ...payload }),
	UPDATE: (state, { payload }) => ({ ...state, ...payload }),
	RESET: (state) => ({ ...initialUser() }),
}

const subscriptionReducer = {
	INIT: (state, { payload }) => ({ ...payload }),
	PAYMENT: (state, { payload }) => ({ ...state, ...payload }),
	RESET: () => ({ ...initialSocket }),
}

const reducers = {
	...APIreducer,
	...namesReducer(userReducer, 'USER'),
	...namesReducer(subscriptionReducer, 'STRIPE'),
}

const reducer = (state = initialState(), action) =>
	action.type in reducers ? reducers[action.type](state, action) : state

export default reducer

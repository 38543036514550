import Loader from 'components/Loader';
import RightSide from 'components/RightSide/RightSide';
import { dayStatuses } from 'constants/DayStatuses';
import { UserTypes } from 'constants/UserTypes';
import { useContract } from 'hooks/useContract';
import React, { useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Store } from 'store/store';
import ContractDatesComponent from './ContractDatesComponent';

const ContractDates = () => {
    const contractId = Number(useParams().contractId)
    const { contract } = useContract(contractId)
    const { state: { user } } = useContext(Store)
    const isPagePermitted = useMemo(() => 
        user.type === UserTypes.FREELANCE
            ? contract.status === dayStatuses.WAITING_FREELANCE_DAYS_CONFIRMATION
            : contract.status === dayStatuses.WAITING_STUDIO_DAYS_CONFIRMATION
            ,
        [contract, user.type])

    return (
        <RightSide>
                {!!!contract || !isPagePermitted
					? <Loader />
					: <ContractDatesComponent {...contract } />}
        </RightSide>
    );
};

export default ContractDates;
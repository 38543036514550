/* eslint-disable import/no-anonymous-default-export */
import { UserTypes } from 'constants/UserTypes'
import TitlePage from 'components/TitlePage'
import FreelanceProfileContainer from '../FreelanceProfile'
import StudioProfile from '../StudioProfile/StudioProfile'
import { useContext } from 'react'
import { Store } from 'store/store'
import RightSide from 'components/RightSide/RightSide'
import useLanguage from 'hooks/useLanguage'

export default () => {
	const lang = useLanguage('profile')
	const { state: { user: { type } } } = useContext(Store)
	return <RightSide>
		<TitlePage>{lang.title}</TitlePage>
		{type === UserTypes.FREELANCE ? <FreelanceProfileContainer /> : <StudioProfile />}
	</RightSide>
}
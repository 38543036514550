import React from 'react';
import { Slider as MuiSlider } from '@mui/material'
import './Slider.scss'

const Slider = ({
    ...props
}) => {
    return (
        <MuiSlider
            {...props}
        />
    );
};

export default Slider;
import 'intersection-observer'
import ReactDOM from 'react-dom'
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom'
import { Application } from './Application'
import { history } from './containers/helpers'
import reportWebVitals from './reportWebVitals'
import { MediaContextProvider } from './store/mediaProvider'
import { Provider } from './store/store'
import './styles/global.scss'
import './style.css'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'

ReactDOM.render(
	<HistoryRouter history={history}>
		<Provider>
			<MediaContextProvider>
				<Application />
				<ToastContainer />
			</MediaContextProvider>
		</Provider>
	</HistoryRouter>,
	document.getElementById('root')
)
reportWebVitals()

import fetchApi from 'common/utils/api/fetchApi';

export const getLatestOpenInvoice = (email) => fetchApi(`/stripe/subscription/latestOpenInvoice/${email}`);

export const updateSubscriptionDefaultPaymentMethodApi = (subscriptionId, paymentMethodId) =>
	fetchApi(`/stripe/subscription/updateDefaultPaymentMethod`, {
		method: 'PATCH',
		body: {
			paymentMethodId,
			subscriptionId,
		},
	});

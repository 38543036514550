import classNames from 'classnames';
import useIsMobile from 'hooks/useIsMobile';
import React from 'react';
import classes from './LogGrid.module.scss'
import SideBar from 'components/SideBar/SideBar';

const SideBarComponent = ({
    setOpositeToActualOpen = () => {}
}) => {
    const isMobile = useIsMobile()

    return (<div
        className={classNames(classes.sideBarContainer, isMobile && classes.mobile)
        }
    >
        <SideBar setOpositeToActualOpen={setOpositeToActualOpen} />
    </div>
    );
};

export default SideBarComponent;
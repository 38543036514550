import classNames from 'classnames';
import { dayStatuses } from 'constants/DayStatuses';
import useLanguage from 'hooks/useLanguage';
import React, { useMemo } from 'react';
import classes from './ContractsStatusCount.module.scss'

const ContractsStatusCount = ({
    contracts
}) => {
    const lang = useLanguage('contract')
    const signed = useMemo(() => contracts.filter(({status}) => status >= dayStatuses.BOOKED).length, [contracts])
    const text = useMemo(() => contracts.length === 0 ? lang.noContracts : `${signed} / ${contracts.length} ${contracts.length > 1 ? lang.contracts : lang.contract}`, [signed, contracts, lang])
    const type = useMemo(() => {
        if (contracts.length === 0){
            return 'open'
        }

        switch (contracts.length - signed) {
            case 0:
                return 'finished'
            case contracts.length:
                return 'open'
            default:
                return 'waiting'
        }
    }, [signed, contracts])
    return (
        <div className={classNames(classes.root, classes[type] || '')}>
            {text}
        </div>
    );
};

export default ContractsStatusCount;
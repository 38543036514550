export const FR = {
	title: 'Tableau des réductions par nombre de jours',
    dayNumbers: 'Nombre de jours',
    reduction: 'Réduction',
    to: 'Jusqu\'à',
    anInfinityOf:  "une infinité de",
    days: 'jours',
    between: 'Entre',
    from: 'À partir de',
    and: 'et',
}

export const EN = {
	title: 'Table of reduction by number of days',
    dayNumbers: 'Days number',
    reduction: '% off',
    to: 'To',
    anInfinityOf:  "an infinity of",
    days: 'days',
    between: 'Between',
    from: 'From',
    and: 'and',
}

export default {
	fr: FR,
	us: EN,
}
  
import classNames from 'classnames';
import useIsMobile from 'hooks/useIsMobile';
import React from 'react';
import classes from './LogGrid.module.scss'

const ContentContainer = ({ children }) => {
    const isMobile = useIsMobile()

    return (<div
        className={classNames(classes.contentContainer, isMobile && classes.mobile)}
    >
        {children}
    </div>
    );
};

export default ContentContainer;